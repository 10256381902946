import {getLabel} from "../../../../Utils/labels";

export const posterHolderSizeOptions = [
    {
        title: getLabel('format-a4'),
        value: 'format-a4',
        price: 85
    },
    {
        title: getLabel('format-a3'),
        value: 'format-a3',
        price: 115
    },
    {
        title: getLabel('format-a2'),
        value: 'format-a2',
        price: 200
    },
    {
        title: getLabel('round-a4'),
        value: 'round-a4',
        price: 150
    },
    {
        title: getLabel('round-a3'),
        value: 'round-a3',
        price: 190
    },
    {
        title: getLabel('round-a2'),
        value: 'round-a2',
        price: 290
    },
    {
        title: getLabel('square-shape'),
        value: 'square-shape',
        price: 205
    },
    {
        title: getLabel('banner-2x-a3'),
        value: 'banner-2x-a3',
        price: 270
    },
    {
        title: getLabel('banner-3x-a3'),
        value: 'banner-3x-a3',
        price: 365
    },
];

export const colorisOptions = [
    {
        title: getLabel('black'),
        value: 'coloris-black',
    },
    {
        title: getLabel('grey'),
        value: 'coloris-grey',
    },
];

export const railOptions = [
    {
        title: getLabel('size-1m'),
        value: 'size-1m',
        price: 55
    },
    {
        title: getLabel('size-1m5'),
        value: 'size-1m5',
        price: 65
    },
];

export const intermediateCableKitOptions = [
    {
        title: getLabel('length-50mm'),
        value: 'length-50mm',
        price: 3
    },
    {
        title: getLabel('length-100mm'),
        value: 'length-100mm',
        price: 4
    },
    {
        title: getLabel('length-150mm'),
        value: 'length-150mm',
        price: 5
    },
    {
        title: getLabel('length-custom-made'),
        value: 'length-custom-made',
        price: 10
    },
];

export const transformerOptions = [
    {
        title: getLabel('transformer-24v-100w'),
        value: 'transformer-24v-100w',
        price: 45
    },
    {
        title: getLabel('transformer-24v-150w'),
        value: 'transformer-24v-150w',
        price: 55
    },
];

export const blackLightPaperOptions = [
    {
        title: getLabel('laser-a4'),
        value: 'laser-a4',
        price: 31
    },
    {
        title: getLabel('inkjet-a4'),
        value: 'inkjet-a4',
        price: 59
    },
    {
        title: getLabel('laser-a3'),
        value: 'laser-a3',
        price: 45
    },
    {
        title: getLabel('inkjet-a3'),
        value: 'inkjet-a3',
        price: 99
    },
];

export const impressionsOptions = [
    {
        title: getLabel('round-a4'),
        value: 'round-a4',
        price: 50
    },
    {
        title: getLabel('round-a3'),
        value: 'round-a3',
        price: 65
    },
    {
        title: getLabel('vice-versa-a2'),
        value: 'vice-versa-a2',
        price: 25
    },
    {
        title: getLabel('square-shape'),
        value: 'square-shape',
        price: 25
    },
    {
        title: getLabel('banner-2x-a3'),
        value: 'banner-2x-a3',
        price: 40
    },
    {
        title: getLabel('banner-3x-a3'),
        value: 'banner-3x-a3',
        price: 60
    },
];
