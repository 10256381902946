import React from 'react'
import Labeled from './Labeled'
import SelectItem from './SelectItem'

class MySelect extends React.Component {
  render () {
    return (
      <Labeled {...this.props}>
        <SelectItem {...this.props} />
      </Labeled>
    )
  }
}

export default MySelect
