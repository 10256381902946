import React from 'react';
import styled from 'styled-components';
import { easeOutBack, green } from '../../Utils/styleConstants';
import Checkmark from './Checkmark';

const Container = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    padding: 8px;
    background: ${green};
    border-radius: 50%;
    opacity: ${props => props.checked ? 1 : 0};
    transform: translateY(${props => props.checked ? 0 : '40px'});
    inset: ${props => props.notBottomCheckmark ? '0' : 'auto'} ${props => props.notBottomCheckmark ? '0' : 'auto'} ${props => props.notBottomCheckmark ? 'auto' : '-17px'} ${props => props.notBottomCheckmark ? 'auto' : '50%'};
    margin-left: ${props => props.notBottomCheckmark ? 'auto' : '-15px'};
    margin-right: ${props => props.notBottomCheckmark ? '-15px' : 'auto'};
    margin-top: ${props => props.notBottomCheckmark ? '-15px' : 'auto'};
    transition: opacity 0.2s ease-in, transform 0.5s ${easeOutBack};
`;

const CheckmarkContainer = props => {
    return (
        <Container
            checked={props.checked}
            notBottomCheckmark={props.notBottomCheckmark}
        >
            <Checkmark />
        </Container>
    )
}

export default CheckmarkContainer;
