import ApolloClient, { gql } from 'apollo-boost';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

const client = new ApolloClient({
    uri: `${process.env.REACT_APP_SERVER_URI}/graphql/`,
    request: (operation) => {
        const token = cookies.get('thetoken');

        operation.setContext({
            headers: {
                authorization: token ? `Bearer ${token}` : ''
            }
        });
    }
});

const createQuery = gql`
    mutation createDocument($typeOfDocument: String!, $user: String!, $clientRef: String!, $walletId: String!) {
        createDocument(typeOfDocument: $typeOfDocument, user: $user, clientRef: $clientRef, walletId: $walletId) {
            id,
            user,
            clientRef,
            walletId,
            data,
            createDate,
            updateDate,
            contractNum
        }
    }
`;

const getQuery = gql`
    query getDocumentClient($walletId: String!) {
        DocumentClient(walletId: $walletId) {
            id,
            user,
            clientRef,
            walletId,
            data,
            createDate,
            updateDate,
            contractNum
        }
    }
`;

const updateQuery = gql`
    mutation updateDocument($id: ID!, $data: String!, $user: String!) {
        updateDocument(id: $id, data: $data, user: $user)
    }
`;

const server = {
    createDocument: async ({ clientRef, walletId, typeOfDocument, user }) => {
        const result = await client.mutate({
            mutation: createQuery,
            variables: {
                clientRef: clientRef.toString(),
                walletId: walletId.toString(),
                typeOfDocument,
                user
            }
        });

        return result.data.createDocument;
    },

    getDocument: async ({ clientRef, walletId, forcenew, typeOfDocument, user }) => {
        if (forcenew) {
            return await server.createDocument({ clientRef, walletId, typeOfDocument, user });
        }

        const result = await client.query({
            query: getQuery,
            variables: { walletId }
        });

        if (result.data.DocumentClient === null) {
            result.data.DocumentClient = await server.createDocument({ clientRef, walletId, typeOfDocument, user });
        }

        return {
            ...result.data.DocumentClient,
            data: JSON.parse(result.data.DocumentClient.data)
        };
    },

    saveDocument: async ({ id, data, user }) => {
        await client.mutate({
            mutation: updateQuery,
            variables: {
                id,
                data: JSON.stringify(data),
                user
            }
        })

        return cookies.get('thetoken');
    }
};

export default server;
