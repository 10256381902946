import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectDataState } from '../Store/selectors';
import { multipleClearFinalPriceOfTarifs, setInDataState, getInDataState } from '../Utils/storeHelpers';
import { borderGrey, grey } from '../Utils/styleConstants';

const Wrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: fit-content;
    border: 1px solid ${borderGrey};
    border-radius: ${props => props.fontSize ? `calc(${props.fontSize} + 10px)` : '25px'};
    margin: ${props => props.margin ?? 'auto'};
    ${props => props.width && `width: ${props.width}`};
    background-color: white;

    & > p {
        font-size: ${props => props.fontSize ? props.fontSize : '15px'};
    }
`;

const Count = styled.p`
    font-family: 'Roboto Mono', monospace;
    color: ${grey};
    user-select: none;
`;

const Control = styled(Count)`
    cursor: pointer;
    padding: 5px 10px;
`;

const InputCounter = styled.input`
    padding: 0.2em 0.5em;
    font-size: 1em;
    font-family: Roboto, sans-serif;
    border: none;
    width: ${props => props.length ? parseInt(props.length)+2+'ch' : '3ch'};
    text-align: center;
`;

const Counter = props => {

     // Use of redux selector
     const dataState = useSelector(selectDataState);

    // Use of hooks
    const [count, setCount] = useState(null);
    const [min, setMin] = useState(null);
    const [max, setMax] = useState(null);

    // useEffect whenever dataState changes
    useEffect(() => {
        const isExcludedOption = props.excludedOptionsFromCounter && props.excludedOptionsFromCounter.includes(props.name);

        if (props.pathInDataStateQuantity && props.counterDependsOn && !isExcludedOption) {
            let max = 0;
            props.counterDependsOn.forEach(value => {
                max += getInDataState(value) ? Object.keys(getInDataState(value)).length : 0;
            });

            setMax(max);

            const counter = getInDataState(`${props.masterId}.${props.name}`);

            if (parseInt(counter) > max) {
                setCount(max);
                // Clear some finalPrice indicated in the json file configuration
                multipleClearFinalPriceOfTarifs(props.clearFinalPrice);

                props.callback && props.callback(max, undefined, false, props.index);
            }
        }
    }, [props, dataState]);

    // useEffect when component is mounting
    useEffect(() => {
        if (null === count) {
            setCount(
                props.value !== '' && !isNaN(parseInt(props.value)) ?
                    props.value
                    : (props.initialValue ?? 0)
            );
            props.min && setMin(props.min);
            props.max && setMax(props.max);
        }
    }, [props, count]);

    // useEffect whenever count value change
    useEffect(() => {
        null !== count && !props.dontStandAlone && setInDataState(`${props.masterId}.${props.name}`, count);
    }, [props, count]);

    // Callback to deal with counter incrementation
    const handleDecrement = useCallback((event) => {
        // Prevent onClick trigger of parent component
        event.preventDefault();

        null !== min ?
            parseInt(count) > min && setCount(parseInt(count) - 1)
            : parseInt(count) >= 1 && setCount(parseInt(count) - 1);

        // Clear some finalPrice indicated in the json file configuration
        multipleClearFinalPriceOfTarifs(props.clearFinalPrice);

        // 1st param: counter value
        // 2nd param: event
        // 3rd param: increment or not (boolean)
        // 4th param: index of the counter (eg: if used in a map)
        null !== min ?
            props.callback && props.callback(parseInt(count) > min ? parseInt(count) - 1 : parseInt(count), event, false, props.index)
            : props.callback && props.callback(parseInt(count) - 1, event, false, props.index);
    }, [min, count, props]);

    // Callback to deal with counter decrementation
    const handleIncrement = useCallback((event) => {
        // Prevent onClick trigger of parent component
        event.preventDefault();

        null !== max ?
            parseInt(count) < max && setCount(parseInt(count) + 1)
            : setCount(parseInt(count) + 1);

        // Clear some finalPrice indicated in the json file configuration
        multipleClearFinalPriceOfTarifs(props.clearFinalPrice);

        // 1st param: counter value
        // 2nd param: event
        // 3rd param: increment or not (boolean)
        // 4th param: index of the counter (eg: if used in a map)
        null !== max ?
            props.callback && props.callback(parseInt(count) < max ? parseInt(count) + 1 : parseInt(count), event, true, props.index)
            : props.callback && props.callback(parseInt(count) + 1, event, true, props.index);
    }, [max, count, props]);

    const stopEvent = useCallback((event) => {
        // Prevent onClick trigger of parent component
        event.stopPropagation();
    }, []);

    const updateCounterWithInput = useCallback((event) => {
        let value = event.target.value;
        const re = /^[0-9\b]+$/;
        if (value === '' || re.test(value)) {
            setCount(parseInt(value));
            props.callback && props.callback(value, event, true, props.index);
        }
    }, [props]);

    return (
        <Wrapper
            margin={props.margin}
            fontSize={props.fontSize}
            width={props.width}
        >
            <Control onClick={(event) => handleDecrement(event)}>-</Control>
            {
                props.inputCounter ? (
                    <InputCounter
                        id={`inputcounter${props.index}`}
                        type="text"
                        placeholder=''
                        autoComplete='off'
                        value={count ? count : ''}
                        onChange={(event) => updateCounterWithInput(event)}
                        onClick={(event) => stopEvent(event)}
                        length={count ? count.toString().length : 0}
                    />
                ) : (
                    null !== props.count ? (
                        <Count>{count}</Count>
                    ) : (
                        <Count>_</Count>
                    )
                )
                
                
            }
            <Control onClick={(event) => handleIncrement(event)}>+</Control>
        </Wrapper>
    )
}

export default Counter;
