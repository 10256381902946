import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PriceElement from './PriceElement';
import Counter from './Counter';
import PromoTag from './PromoTag';
import PromoFnaimTag from './PromoFnaimTag';
import personnalisationHektor from '../Images/HK_offert.png';
import logoHektor from '../Images/logo-hektor.png';
import { getLabel } from '../Utils/labels';
import { manualDependsOnTarifsInfo } from '../Utils/jsonInterpreter';
import {
    SubscriptionContainer,
    SubscriptionStructure,
    SubscriptionInsteadOf,
    SubscriptionLogo,
    SubscriptionOfferName,
    SubscriptionCommissioning
} from '../Utils/styleConstants';
import {
    setInDataState,
    getInTarifsState,
    getHektorTypeFromHektorOffer,
    getInDataState,
    setFinalPriceInTarifsState,
    resetFinalPriceHektorOptions,
    inHektorOfferMixte
} from '../Utils/storeHelpers';

const Image = styled.img`
    padding: 0 5% 0 0;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
`;

const LogoStructure = styled.div`
    display: flex;
    flex-direction: column;
`;

const AdditionalElementStructure = styled.div`
    display: flex;
    align-items: center;
`;

const Commissioning = styled.p`
    order: -1;
    white-space: nowrap;
`;

const AbonnementSimple = props => {
    // Use of redux
    const hektorType = getHektorTypeFromHektorOffer(props.hektorOffer);
    const tarifsHektorType = getInTarifsState(hektorType);
    const tarifsInfo = manualDependsOnTarifsInfo(tarifsHektorType, props.hektorOffer);
    const hektorMixte = inHektorOfferMixte();

    // useEffect when component is mounting
    useEffect(() => {
        
        // Set commissioning in dataState
        if (props.commissioningName) {
            let commissioning = hektorMixte ? false : true;
            setInDataState(`${props.pathInDataState}.${props.commissioningName}`, commissioning);
        }
    }, [props.pathInDataState, props.commissioningName, hektorMixte])

    // Callback used when the user counter change
    const handleUserCounterChange = useCallback(() => {
        // Whenever the user change the counter of additionalUsers, we reset its finalPrice
        setFinalPriceInTarifsState(props.additionalUsers, null, undefined, props.hektorOffer);
    }, [props.additionalUsers, props.hektorOffer]);

    // Callback used when the madataires counter change
    const handleMandCounterChange = useCallback(() => {
        // Whenever the user change the counter of additionalMandataires, we reset its finalPrice
        setFinalPriceInTarifsState(props.additionalMandataires, null, undefined, props.hektorOffer);
        // We also reset the finalPrice of hektor option add
        resetFinalPriceHektorOptions(true, props.hektorOffer);
    }, [props.additionalMandataires, props.hektorOffer]);

    return (
        <SubscriptionContainer>
            <Image src={personnalisationHektor} alt='Personnalisation offerte' />
            <Content>
                <LogoStructure>
                    <SubscriptionLogo src={logoHektor} alt="Logo Hektor" />
                    <SubscriptionOfferName>{props.offerName}</SubscriptionOfferName>
                </LogoStructure>
                {
                    props.hektorOffer && tarifsInfo && (
                        <SubscriptionStructure>
                            <PriceElement
                                tarifsName={hektorType}
                                dependsOn={props.hektorOffer}
                                fontSize='6em'
                            />
                            {
                                tarifsInfo.insteadOf && typeof tarifsInfo.insteadOf !== 'object' && (
                                    <SubscriptionInsteadOf>{`Au lieu de ${tarifsInfo.insteadOf} € ${tarifsInfo.priceUnit}`}</SubscriptionInsteadOf>
                                )
                            }
                            {
                                tarifsInfo.nbrOfUsers && typeof tarifsInfo.nbrOfUsers !== 'object' && (
                                    <p>{`Pour ${tarifsInfo.nbrOfUsers} utilisateurs`}</p>
                                )
                            }
                        </SubscriptionStructure>
                    )
                }
                {
                   props.additionalUsers && hektorType && (
                        <AdditionalElementStructure>
                            <p>Utilisateur(s) supplémentaire(s)</p>
                            <Counter
                                masterId={`${props.pathInDataState}.${props.additionalUsers}`}
                                initialValue={getInDataState(`${props.pathInDataState}.${props.additionalUsers}`) ?? 0}
                                margin='0 45px'
                                callback={handleUserCounterChange}
                            />
                            <PriceElement
                                tarifsName={props.additionalUsers}
                                dependsOn={hektorType}
                            />
                        </AdditionalElementStructure>
                    )
                }
                {
                    props.additionalMandataires && hektorType && (
                        <AdditionalElementStructure>
                            <p>Nombre de mandataire(s)</p>
                            <Counter
                                masterId={`${props.pathInDataState}.${props.additionalMandataires}`}
                                initialValue={getInDataState(`${props.pathInDataState}.${props.additionalMandataires}`) ?? 1}
                                margin='0 45px'
                                callback={handleMandCounterChange}
                                min={1}
                            />
                            <PriceElement
                                tarifsName={props.additionalMandataires}
                                dependsOn={hektorType}
                            />
                        </AdditionalElementStructure>
                    )
                }
                {
                    props.commissioningName && hektorType && !hektorMixte && (
                        <SubscriptionCommissioning>
                            <Commissioning>{getLabel(props.commissioningName)}</Commissioning>
                            <PriceElement
                                tarifsName={props.commissioningName}
                                dependsOn={props.hektorOffer}
                            />
                        </SubscriptionCommissioning>
                    )
                }
            </Content>
            {
                props.promoTag && (
                    <PromoTag
                        tarifsName={`${hektorType}-promotion`}
                        promotionId={props.hektorOffer}
                        {...props.promoTag}
                    />
                )
            }
            {
                props.promoFnaimTag && (
                    <PromoFnaimTag {...props.promoFnaimTag} />
                )
            }
        </SubscriptionContainer>
    );
};

export default AbonnementSimple;
