import { getInDataState } from './storeHelpers'

const compareFn = (actual, expected) => (
    actual === expected ||
    (
        Array.isArray(actual) && actual.indexOf(expected) > -1
    )
)

const modsDef = {
    not: fn => (a, e) => !fn(a, e)
}

function checkCondition(condition) {
    const actualValue = condition.pathInDataState && getInDataState(condition.pathInDataState)
    const mods = condition.mods ? condition.mods : []
    
    if (condition.value) {
        const compareValue = mods.reduce((acc, cur) => modsDef[cur](acc), compareFn)
        return compareValue(actualValue, condition.value)
    }
    if (condition.isObject) {
        return 'object' === typeof actualValue;
    }
    if (condition.hasValue) {
        return undefined !== actualValue;
    }
    if (condition.hasOwnProperty) {
        return actualValue ? actualValue.hasOwnProperty(condition.hasOwnProperty) : false;
    }
}

const classicalCheck = conditions => !conditions.some(result => result === false)
const orCheck = conditions => conditions.some(result => result === true)

function checkConditions(checkFn, conditions, toReturn) {
    return Array.isArray(conditions) ? (
        checkFn(conditions
            .map(condition => {
                switch (condition.mode) {
                    case 'or': {
                        return checkConditions(orCheck, condition.conditions)
                    }
                    case 'not': {
                        return !checkConditions(classicalCheck, condition.conditions);
                    }
                    default: {
                        return checkCondition(condition)
                    }
                }
            })
        )
    ) : (
        toReturn !== undefined ? toReturn : true
    )
}

function condition({ conditions }, toReturn) {
    return checkConditions(classicalCheck, conditions, toReturn);
}

export default condition;
