import { UPDATE_DATA_VALUE, DELETE_DATA_WITH_TEMPLATE, DELETE_DATA } from '../Store/actions'
import { getComboName } from './jsonInterpreter'
import { beautifulGetLabel } from './labels'
import short from 'short-uuid'

const defaultAction = {
  action: UPDATE_DATA_VALUE,
  actionDelete: DELETE_DATA
}

const getDataValue = data => masterId => name => {
  if (data[masterId] !== undefined && data[masterId][name] !== undefined) {
    return data[masterId][name]
  }
  return ''
}

export const findValue = state => ownProps => {
  const getDataValueFn = getDataValue(state.data)(ownProps.masterId)
  if (ownProps.combo) {
    const valueObject = {}
    ownProps.combo.map(type => {
      valueObject[type] = getDataValueFn(getComboName(ownProps)(type))
      return true
    })
    return valueObject
  } else {
    return getDataValueFn(ownProps.name)
  }
}

export const mapStateToPropsAddable = (state, ownProps) => {
  const addableName = [ownProps.masterId, ownProps.name].join('|')
  const ids = state.data._order[addableName] ? state.data._order[addableName] : []
  // si ids est vide on force le nombre d'elements minimal (min 1)
  if (ids.length === 0 && !ownProps.initialData) {
      const min = ownProps.min !== undefined ? ownProps.min : 1
      for (let i = 0; i < min; i++) {
        ids.push(short.generate())
      }
  }
  return {
      ...defaultAction,
      ids,
      label: ownProps.label ?? beautifulGetLabel(ownProps.name),
      deleteAction: DELETE_DATA_WITH_TEMPLATE 
  }
}

export const mapStateToProps = (state, ownProps) => {
  return ({
  ...defaultAction,
  value: findValue(state)(ownProps)
})
}

export const mapStateWithPriceToProps = (state, ownProps) => ({
  ...defaultAction,
  value: findValue(state)(ownProps),
  tarifs: state.tarifs
})

export const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dispatch: (action) => {
      dispatch(action)
      if (
        ownProps.linkedData !== undefined
        && action.type === UPDATE_DATA_VALUE
        && action.value.value
      ) {
        dispatch({
          ...action,
          value: ownProps.linkedData
        })
      }
    }
  }
}
