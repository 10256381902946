import { connect } from 'react-redux'
import Select from '../Components/Select'
import { beautifulGetLabel } from '../Utils/labels'
import { findValue, mapDispatchToProps } from '../Utils/mapToProps'
import {getInDataState} from "../Utils/storeHelpers";

const mapStateToProps = (state, ownProps) => ({
    ...ownProps,
    label: ownProps.label ?? beautifulGetLabel(ownProps.name),
    value: findValue(state)(ownProps),
    defaultValue : getInDataState(ownProps.defaultValue)

});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Select)
