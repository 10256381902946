import styled from "styled-components";
import ProductInformation from "./Led/ProductInformation";
import CheckmarkContainer from "../Icons/CheckmarkContainer";
import {SimpleCheckbox} from "../../Utils/styleConstants";
import Price from "./Price";
import RadioGroup from "../Radio/RadioGroup";
import Radio from "../Radio/Radio";

const CheckableContainer = styled(SimpleCheckbox)`
  display: flex;
`;

const InformationContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 10%;
`;

const SubTitle = styled.div`
  display: flex;
  flex: 0 0 15%;
  align-items: center;
  border-left: 1px solid #E1E1E1;
`;

const SubTitleText = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 10px;
`;

const GroupContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #E1E1E1;
  border-top: 1px solid #E1E1E1;
  flex: ${({ $size }) => $size ? `1 0 ${$size}%` : "1 0 33.333%" };
  padding: 10px;
  gap: 15px;
`

const Required = styled.span`
    color: red;
`

const RadioOptionsGrid = ({
    title,
    imageSrc,
    required,
    selectableOptions,
    selectedOption,
    setSelectedOption,
    $gridDivision
}) => {
    const isOptionChecked = selectedOption !== null;

    return (
        <CheckableContainer checked={isOptionChecked}>
            <InformationContainer>
                <ProductInformation imageSrc={imageSrc}/>
            </InformationContainer>
            <SubTitle>
                <SubTitleText>
                    <p>
                        {title}
                        {required && <Required> *</Required>}
                    </p>
                </SubTitleText>
            </SubTitle>
            <RadioGroup value={selectedOption} onValueChange={setSelectedOption} GroupContainer={GroupContainer}>
                {selectableOptions.map((option) =>
                    <Box key={option.value} $size={100/ ($gridDivision ?? 3)}>
                        <label>{option.title}</label>
                        <Radio value={option.value} />
                        <Price value={option.price} />
                    </Box>
                )}
            </RadioGroup>
            <CheckmarkContainer checked={isOptionChecked} notBottomCheckmark={true}/>
        </CheckableContainer>
    )
}

export default RadioOptionsGrid;
