const prices = {
    'poster-holder-size': {
        'format-a4': 85,
        'format-a3': 115,
        'format-a2': 200,
        'round-a4': 150,
        'round-a3': 190,
        'round-a2': 290,
        'square-shape': 205,
        'banner-2x-a3': 270,
        'banner-3x-a3': 365,
    },

    'rail': {
        'size-1m': 55,
        'size-1m5': 65,
    },

    'intermediate-cable-kit': {
        'length-50mm': 3,
        'length-100mm': 4,
        'length-150mm': 5,
        'length-custom-made': 10,
    },

    'blacklight-paper': {
        'laser-a4': 31,
        'inkjet-a4': 59,
        'laser-a3': 45,
        'inkjet-a3': 99,
    },

    'impressions': {
        'round-a4': 50,
        'round-a3': 65,
        'vice-versa-a2': 25,
        'square-shape': 25,
        'banner-2x-a3': 40,
        'banner-3x-a3': 60,
    },

    'transformer': {
        'transformer-24v-100w': 45,
        'transformer-24v-150w': 55,
    },

    'order-preparation-and-delivery': 50,
    'piston-suspension-kit-two-packs': 40,
    'tilt-kit': 25,
    'power-cable': 20,
    'inter-rails-rigid-connector': 10,
    'transformer-cover': 20,

    "number-of-columns": {
        1: 230,
        2: 250,
        3: 300,
        4: 350, 
        5: 400,
        6: 480,
        7: 520,
        8: 610,
        9: 660,
        10: 680,
        11: 720,
        12: 780,
        13: 810,
        14: 870,
        15: 900,
        16: 950,
        17: 1000,
        18: 1050,
        19: 1100,
        20: 1150
    }
}

export default prices;
