import {useSelector} from "react-redux";
import {get} from "../../Utils/object";
import {useEffect} from "react";
import {setInDataState} from "../../Utils/storeHelpers";
import BiChoice from "../BiChoice";
import styled from "styled-components";
import WarrantyDuration from "./WarrantyDuration";
import Counter from "./Counter";
import {PaymentType} from "../../types/payment";
import { produce } from "immer";

const FinancingDurationContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

const MiddleContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    margin: 0 0 50px;
    padding: 0 25%;
`

const WarrantyPaymentAssociation = {
    12: 36,
    24: 36,
    36: 36,
    48: 48,
    60: 60
}

const Financing = ({ selectedDuration, onCountChange }) => {
    return (
        <FinancingDurationContainer>
            <p>Durée :</p>
            <Counter initialValue={selectedDuration} onCountChange={onCountChange} min={12} max={60} step={12} />
            <p>mois</p>
        </FinancingDurationContainer>
    )
}

/**
 * @typedef PaymentAndWarrantyProps
 * @property {string} pathInDataState
 */

/**
 *
 * @param {PaymentAndWarrantyProps} props
 * @return {JSX.Element}
 */
const PaymentAndWarranty = ({ pathInDataState, defaultWarrantyDuration }) => {
    const paymentPath = `${pathInDataState}.payment`;
    const paymentWarrantyPath = `${paymentPath}.warrantyDuration`;
    const paymentModePath = `${paymentPath}.type`;

    const paymentMode = useSelector(({ data }) => get(data, paymentModePath));
    const warrantyDuration = useSelector(({ data }) => get(data, paymentWarrantyPath));
    const financingDuration = useSelector(({ data }) => get(data, `${paymentPath}.financing.duration`));

    const handleCountChange = (count) => {
        setInDataState(`${paymentPath}.financing.duration`, count);
        setInDataState(paymentWarrantyPath, WarrantyPaymentAssociation[count]);
    }

    useEffect(() => {
        if (paymentMode === PaymentType.COMPTANT) {
            setInDataState(`${paymentPath}.financing`, undefined);
            setInDataState(paymentWarrantyPath, defaultWarrantyDuration);
        } 
    }, [paymentMode, paymentPath, paymentWarrantyPath, defaultWarrantyDuration])

    return (
        <div>
            <BiChoice
                margin='30px 0'
                pathInDataState={paymentModePath}
                options={['comptant', 'financement']}
                byDefault={0}
            />
            <MiddleContainer>
                {paymentMode === PaymentType.FINANCEMENT &&
                    <Financing selectedDuration={financingDuration} onCountChange={handleCountChange}/>
                }
                <WarrantyDuration duration={warrantyDuration} />
            </MiddleContainer>
        </div>
    )
}

export default PaymentAndWarranty;
