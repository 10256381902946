import styled from "styled-components";
import {setInDataState} from "../../../Utils/storeHelpers";
import {useSelector} from "react-redux";
import {get} from "../../../Utils/object";
import LedChoice from "./LedChoice";
import {produce} from "immer";

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 10px;
`;

const LED_COUNT_PATH = 'offre-lbi-media.led.count';
const LED_CONFIGURATIONS_PATH =  'offre-lbi-media.led.configurations';

const options = [
    {
        title: "Crystal",
        subTitle: "Nombre de porte-affiche(s)",
        checked: false,
        type: "crystal",
    },
    {
        title: "Sublime",
        subTitle: "Nombre de porte-affiche(s)",
        checked: false,
        type: "sublime",
    },
    {
        title: "Vice-Versa",
        subTitle: "Nombre de porte-affiche(s)",
        checked: false,
        type: "vice-versa",
    }
]

const LedChoices = () => {
    const counts = useSelector(({ data }) => get(data, LED_COUNT_PATH, {}));
    const configurations = useSelector(({ data }) => get(data, LED_CONFIGURATIONS_PATH, {}));

    const handleCountChange = (ledType, value) => {
        const count = counts[ledType] ?? 0;

        if (count === 0 && value > 0) {
            setInDataState(`${LED_CONFIGURATIONS_PATH}`, produce(
                configurations,
                draftState => {
                    draftState[ledType] = {};
                }
            ));
        }

        if (count > 0 && value === 0) {
            setInDataState(`${LED_CONFIGURATIONS_PATH}`, produce(
                configurations,
                draftState => {
                    delete draftState[ledType];
                }
            ));
        }

        setInDataState(`${LED_COUNT_PATH}.${ledType}`, value);
    }

    return (
        <Container>
            {options.map((option, index) =>
                <LedChoice
                    counterInitialValue={counts[option.type] ?? 0}
                    key={option.title}
                    type={option.type}
                    title={option.title}
                    subTitle={option.subTitle}
                    onCountChange={handleCountChange}
                    checked={(counts[option.type] ?? 0) > 0}
                />
            )}
        </Container>
    )
}

export default LedChoices;
