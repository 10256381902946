import React, { useCallback } from 'react';
import styled from 'styled-components';
import Moment from 'react-moment';
import logo from '../Data/logo.png';
import { grey, green, lightGrey } from '../Utils/styleConstants';
import fetchDistant from '../Utils/fetch';
import { getInDataState, getGenericState } from '../Utils/storeHelpers';

const BigContainer = styled.section`
    display: flex;
    align-items: center;
    height: 80px;
    background: white;
`;

const Container = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: white;
    padding: 0 40px;
    flex: 1;
`;

const Image = styled.img`
    height: 50px;
    cursor: pointer;
`;

const DateContainer = styled.div`
    text-transform: capitalize;
    color: ${grey};
    font-size: 0.875em;
`

const AvatarContainer = styled.div`
    flex: 0 0 130px;
    height: 100%;
    background: ${green};
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Avatar = styled.div`
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: url(${props => props.avatar}) no-repeat center center;
    background-size: cover;
`;

const ClearButton = styled.button`
    padding: 10px 20px;
    background-color: ${lightGrey};
    border: 2px solid ${green};
    border-radius: 50px;
    color: ${grey};
    cursor: pointer;
    transition: 0.2s;

    :hover {
        color: white;
        background-color: #cedb4d;
    }
`;

const Header = () => {
    // Use of redux
    const genericState = getGenericState();
    const clientRef = getInDataState('agence.reference-client');
    const walletId = getInDataState('agence.wallet-id');

    // Callback called to reload page
    const reload = useCallback(() => {
        window.location.reload();
    }, []);

    // Callback called when user click on clear button
    const handleClick = useCallback(() => {
        const params = `id=${genericState.idDocument}&createDate=${genericState.createDate}&clientRef=${clientRef}&walletId=${walletId}`;

        fetchDistant(`/document/clear?${params}`).then(reload);
    }, [genericState.idDocument, genericState.createDate, clientRef, walletId, reload]);

    return (
        <BigContainer>
            <Container>
                <Image src={logo} alt='Logo' onClick={reload} />
                {
                    process.env.REACT_APP_RESET_MODE && (
                        <ClearButton
                            onClick={() => handleClick()}
                        >
                            Vider les données du document
                        </ClearButton>
                    )
                }
                <DateContainer>
                    <Moment locale='fr' format='dddd D MMMM YYYY' element='span'>
                        {new Date()}
                    </Moment>
                </DateContainer>
            </Container>
            <AvatarContainer>
                <Avatar avatar={genericState.avatar} />
            </AvatarContainer>
        </BigContainer>
    );
};

export default Header;
