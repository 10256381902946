export function selectDataState(state) {
    return state.data;
};

export function selectTarifsState(state) {
    return state.tarifs;
};

export function selectGenericState(state) {
    return state.generic;
};

export function selectCurrentPageState(state) {
    return state.generic.page;
};
