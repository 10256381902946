// Generic
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const UPDATE_DOCUMENT = 'UPDATE_DOCUMENT';
export const SET_MANDATORY_PREDICATE = 'SET_MANDATORY_PREDICATE';
export const MANDATORY_MISSING = 'MANDATORY_MISSING';
export const MANDATORY_NOT_MISSING = 'MANDATORY_NOT_MISSING';
export const DELETE_MANDATORY_PREDICATES = 'DELETE_MANDATORY_PREDICATES';
export const SET_ID_DOCUMENT = 'SET_ID_DOCUMENT';
export const SET_CREATE_DATE = 'SET_CREATE_DATE';
export const SET_UPDATE_DATE = 'SET_UPDATE_DATE';
export const SET_CONTRACT_NUM = 'SET_CONTRACT_NUM';
export const SET_USER_EMAIL = 'SET_USER_EMAIL';
export const SET_USER_AVATAR = 'SET_USER_AVATAR';
export const NOT_CONNECTED = 'NOT_CONNECTED';
export const KO_NETWORK = 'KO_NETWORK';
export const OK_NETWORK = 'OK_NETWORK';
export const WAIT_NETWORK = 'WAIT_NETWORK';

// Tarifs
export const UPDATE_TARIFS = 'UPDATE_TARIFS';
export const CHANGE_PRICE = 'CHANGE_PRICE';
export const UPDATE_TARIF_HEBERGEMENT = 'UPDATE_TARIF_HEBERGEMENT';
export const LOAD_TRADUCTIONS = 'LOAD_TRADUCTIONS';
export const LOAD_DOMAIN_NAMES = 'LOAD_DOMAIN_NAMES';
export const LOAD_EMAILS = 'LOAD_EMAILS';
export const LOAD_GATEWAYS = 'LOAD_GATEWAYS';
export const LOAD_AGENCIES = 'LOAD_AGENCIES';
export const DELETE_FINALPRICE = 'DELETE_FINALPRICE';
export const ADD_IN_FINALPRICES = 'ADD_IN_FINALPRICES';
export const ADD_FINANCING_PRICE = 'ADD_FINANCING_PRICE';
export const LOAD_HEBERGEMENT = 'LOAD_HEBERGEMENT';
export const RESET_FINALPRICE = 'RESET_FINALPRICE';
export const RESET_FINALPRICES = 'RESET_FINALPRICES';
export const LOAD_DOMAINS = 'LOAD_DOMAINS';
export const LOAD_CONTENT_PAGE = 'LOAD_CONTENT_PAGE';
export const LOAD_CRO_CONTENT_PAGE = 'LOAD_CRO_CONTENT_PAGE';

// Data
export const UPDATE_ORDER_ADDABLE = 'UPDATE_ORDER_ADDABLE';
export const UPDATE_DATA_VALUE = 'UPDATE_DATA_VALUE';
export const SWITCH_DATA_VALUE = 'SWITCH_DATA_VALUE';
export const UPDATE_DATA = 'UPDATE_DATA';
export const DELETE_DATA_WITH_TEMPLATE = 'DELETE_DATA_WITH_TEMPLATE';
export const DELETE_BULK_DATA = 'DELETE_BULK_DATA';
export const UPDATE_DATA_NDD = 'UPDATE_DATA_NDD';
export const SET_DATA = 'SET_DATA';
export const DELETE_DATA = 'DELETE_DATA';
