import { useSelector} from "react-redux";
import {get} from "../../Utils/object";
import ScreenOrientation from "./Screen/ScreenOrientation";
import {setInDataState} from "../../Utils/storeHelpers";
import styled from "styled-components";
import {produce} from "immer";

const Container = styled.div`
  width: 100%;
`

const PATH = "offre-lbi-media.software.orientation.value"

const ScreenOrientations = () =>  {
    const items = useSelector(({ data }) => get(data, PATH, []));

    const handleClick = (value, index) => {
        setInDataState(PATH, produce(items, draftState => {
            const item = draftState[index];

            if (item) {
                item.orientation = value;
            }
        }));
    };

    const handlePlayerSelected = (index) => {
        setInDataState(PATH, produce(items, draftState => {
            const item = draftState[index];

            if (item) {
                item.player = !item.player;
            }
        }));
    }

    const handlerSettingsSelected = (index) => {
        setInDataState(PATH, produce(items, draftState => {
            const item = draftState[index];

            if (item) {
                item.settings = !item.settings;
            }
        }));
    }

    return (
        <div>
            {
                items.map((value, index) =>
                    <Container key={index}>
                        <ScreenOrientation
                            onPlayerSelected={() => handlePlayerSelected(index)}
                            playerSelected={value?.player ?? false}
                            onSettingsSelected={() => handlerSettingsSelected(index)}
                            settingSelected={value?.settings ?? false}
                            onClick={(value) => handleClick(value, index)}
                            selected={value.orientation}
                        />
                    </Container>
                )
            }
        </div>
    )
}
export default ScreenOrientations;
