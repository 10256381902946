import React from 'react';
import styled from 'styled-components';
import { grey, green } from '../Utils/styleConstants';
import {getLabel} from "../Utils/labels";

const Wrapper = styled.section`
  display: flex;
  flex-direction: ${props => props.labelTop ? 'column' : 'row'};
  justify-content: center;
  align-items: ${props => props.labelTop ? 'flex-start' : 'center'};
  padding: ${props => props.padding ?? 0};
`;

const Label = styled.p`
  flex: ${props => (props.fitContent !== undefined && props.fitContent) || props.labelTop ? '1 1 fill' : '0 0 30%'};
  color: ${props => props.labelTitle ? green : grey};
  padding: 1em;
  font-weight: ${props => props.labelTitle ? 'bold' : 'normal'};
  font-size: ${props => props.labelTitle ? '1.2em' : '0.9em'};
  text-align: ${props => props.labelTop ? 'center' : 'left'};
  white-space: nowrap;
  ${props => props.required &&
    `&::after {
      content: ' *';
      color: red;
    }`
  };
`;

const ContentWrapper = styled.div`
  flex: ${props => (props.fitContent !== undefined && props.fitContent) || props.labelTop ? '1 1 content' : '0 0 70%'};
  padding: 0.2em;
  display: flex;
  align-items: center;
  order: ${props => props.inverted ? '-1' : 'initial'};
  width: 100%;
`;

class Labeled extends React.Component {
  constructor(props) {
    super(props)
    this.withLabel = this.withLabel.bind(this)
    this.withoutLabel = this.withoutLabel.bind(this)
    const state = {
      labeled: true
    }
    if (this.props.isLabeled !== undefined && !this.props.isLabeled) {
      state.labeled = false
    }
    this.state = state
  }

  withoutLabel () {
    return this.props.children
  }

  withLabel () {
    const label = this.props.withLabel ?? this.props.label;

    return (
      <Wrapper
        labelTop={this.props.labeltop}
        padding={this.props.padding}
      >
        {
          label && (
            <Label
              {...this.props}
              inverted={this.props.inverted !== undefined && this.props.inverted}
              required={this.props.required}
            >
                {this.props.isLabeled ? getLabel(label) : label}
            </Label>
          )
        }
        <ContentWrapper {...this.props} inverted={this.props.inverted !== undefined && this.props.inverted}>
          {this.props.children}
        </ContentWrapper>
      </Wrapper>
    )
  }
  render () {
    return this.state.labeled ? this.withLabel() : this.withoutLabel()
  }
}

export default Labeled
