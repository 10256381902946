import CheckableCountry from '../Components/Checkable/CheckableCountry';
import CheckableImage from '../Components/Checkable/CheckableImage';
import CheckableImageLabel from '../Components/Checkable/CheckableImageLabel';
import CheckableExtendableVertical from '../Components/Checkable/CheckableExtendableVertical';
import CheckableImagedOption from '../Components/Checkable/CheckableImagedOption';
import CheckableExtendableHorizontal from '../Components/Checkable/CheckableExtendableHorizontal';


const checkableComponentFactory = {
    image: CheckableImage,
    imageLabel: CheckableImageLabel,
    country: CheckableCountry,
    extendableVertical: CheckableExtendableVertical,
    imagedOption: CheckableImagedOption,
    extendableHorizontal: CheckableExtendableHorizontal,
};

export default checkableComponentFactory;
