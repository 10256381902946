import CounterOptionsGrid from "../../CounterOptionsGrid";
import CounterOption from "../../CounterOption";
import React from "react";
import styled from "styled-components";
import CheckboxOption from "../../CheckboxOption";
import RadioOptionsGrid from "../../RadioOptionsGrid";
import {
    blackLightPaperOptions,
    colorisOptions, impressionsOptions,
    intermediateCableKitOptions,
    posterHolderSizeOptions,
    railOptions,
    transformerOptions
} from "./optionsData";
import { getLabel } from "../../../../Utils/labels";

const LargeRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  > div {
    flex: 1 0 45%  
  }
`

const ViceVersaConfiguration = ({ configuration, setConfiguration }) => {
    const handleChange = (property, optionConfiguration) => {
        setConfiguration({ ...configuration, [property]: optionConfiguration });
    }

    return (
        <>
            <LargeRowsContainer>
                <CounterOptionsGrid
                    title={"Taille porte-affiches"}
                    imageSrc={"/static/img/lbi-media/led/taille-porte-affiches.svg"}
                    selectableOptions={posterHolderSizeOptions}
                    selectedOptions={configuration["poster-holder-size"] ?? []}
                    setSelectedOptions={(options) => handleChange("poster-holder-size", options)}
                />
                <RadioOptionsGrid
                    title={"Coloris"}
                    imageSrc={"/static/img/lbi-media/led/coloris.svg"}
                    required={true}
                    selectableOptions={colorisOptions}
                    selectedOption={Object.keys(configuration["coloris"] ?? [])[0] ?? null}
                    setSelectedOption={(option) => handleChange("coloris", { [option] : 1 })}
                />
                <CounterOptionsGrid
                    title={"Rail"}
                    imageSrc={"/static/img/lbi-media/led/rail.svg"}
                    selectableOptions={railOptions}
                    selectedOptions={configuration["rail"] ?? []}
                    setSelectedOptions={(options) => handleChange("rail", options)}
                    $gridDivision={2}
                />
            </LargeRowsContainer>
            <GridContainer>
                <CounterOption
                    title={"Connecteur rigide Inter-Rails"}
                    imageSrc={"/static/img/lbi-media/led/connecteur.svg"}
                    price={10}
                    counter={configuration["inter-rails-rigid-connector"]}
                    setCounter={(counter) => handleChange("inter-rails-rigid-connector", counter)}
                />
                <CounterOption
                    title={"Câble alimentation"}
                    imageSrc={"/static/img/lbi-media/led/cable-alimentation.svg"}
                    price={20}
                    counter={configuration["power-cable"]}
                    setCounter={(counter) => handleChange("power-cable", counter)}
                />
            </GridContainer>
            <LargeRowsContainer>
                <CounterOptionsGrid
                    title={"Kit câbles intermédiaires"}
                    imageSrc={"/static/img/lbi-media/led/kit-cables.svg"}
                    selectableOptions={intermediateCableKitOptions}
                    selectedOptions={configuration["intermediate-cable-kit"] ?? []}
                    setSelectedOptions={(options) => handleChange("intermediate-cable-kit", options)}
                    $gridDivision={2}
                />
            </LargeRowsContainer>
            <GridContainer>
                <CounterOption
                    title={"Kit inclinaison (A4-A3)"}
                    imageSrc={"/static/img/lbi-media/led/inclinaison.svg"}
                    price={25}
                    counter={configuration["tilt-kit"]}
                    setCounter={(counter) => handleChange("tilt-kit", counter)}
                />
                <CounterOption
                    title={"Kit de suspension à piston - Lots de 2"}
                    imageSrc={"/static/img/lbi-media/led/piston.svg"}
                    price={40}
                    counter={configuration["piston-suspension-kit-two-packs"]}
                    setCounter={(counter) => handleChange("piston-suspension-kit-two-packs", counter)}
                />
            </GridContainer>
            <LargeRowsContainer>
                <CounterOptionsGrid
                    title={"Transformateur"}
                    imageSrc={"/static/img/lbi-media/led/transformateur.svg"}
                    selectableOptions={transformerOptions}
                    selectedOptions={configuration["transformer"] ?? []}
                    setSelectedOptions={(options) => handleChange("transformer", options)}
                    $gridDivision={2}
                />
            </LargeRowsContainer>
            <GridContainer>
                <CounterOption
                    title={"Cache transformateur"}
                    imageSrc={"/static/img/lbi-media/led/cache-transfo.svg"}
                    counter={configuration["transformer-cover"]}
                    setCounter={(counter) => handleChange("transformer-cover", counter)}
                    price={20}
                />
                <CheckboxOption
                    title={"Préparation de commande & Livraison sur site"}
                    imageSrc={"/static/img/lbi-media/led/livraison-rapide.svg"}
                    isSelected={configuration["order-preparation-and-delivery"]}
                    setSelected={(value) => handleChange("order-preparation-and-delivery", value ? 1 : 0)}
                    price={50}
                />
            </GridContainer>
            <LargeRowsContainer>
                <CounterOption
                    title={getLabel('number-of-columns')}
                    imageSrc={"/static/img/lbi-media/led/colonnes.svg"}
                    counter={configuration["number-of-columns"]}
                    setCounter={(counter) => handleChange("number-of-columns", counter)}
                />
                <CounterOptionsGrid
                    title={"Papier BlackLight"}
                    imageSrc={"/static/img/lbi-media/led/papier-backlight.svg"}
                    selectableOptions={blackLightPaperOptions}
                    selectedOptions={configuration["blacklight-paper"] ?? []}
                    setSelectedOptions={(options) => handleChange("blacklight-paper", options)}
                    $gridDivision={2}
                />
                <CounterOptionsGrid
                    title={"Impressions"}
                    imageSrc={"/static/img/lbi-media/led/impression.svg"}
                    selectableOptions={impressionsOptions}
                    selectedOptions={configuration["impressions"] ?? []}
                    setSelectedOptions={(options) => handleChange("impressions", options)}
                />
            </LargeRowsContainer>
        </>
    )
}

export default ViceVersaConfiguration;
