import React from 'react';
import styled from 'styled-components';
import { getInDataState } from '../Utils/storeHelpers';
import CheckableOption from './Option/CheckableOption';
import { beautifulGetLabel } from '../Utils/labels';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Label = styled.p`
    font-size: 20px;
    text-align: center;
    padding-bottom: 1em;
`;

const OptionSelector = props => {
    return (
        <>
            { 
                props.title && (
                    <Label>
                        {beautifulGetLabel(props.title)}
                    </Label>
                ) 
            }
            <Container>
                
                {
                    props.options.map((option, index) => (
                        <CheckableOption
                            key={index}
                            little={props.little}
                            pathInDataState={props.pathInDataState}
                            isArrayInDataState={props.isArrayInDataState}
                            name={option.name}
                            tarifsName={option.tarifsName}
                            image={option.image}
                            dependsOn={option.dependsOn}
                            partOfId={option.dependsOn}
                            setInDataState={option.setInDataState}
                            clearOnChangeInDataState={option.clearOnChangeInDataState}
                            clearArrayOnChangeInDataState={option.clearArrayOnChangeInDataState}
                            default={option.default}
                            clearFinalPrice={option.clearFinalPrice}
                            setItselfFalse={option.setItselfFalse}
                            disabled={true === option.disabledIfFalse && !getInDataState(`${props.pathInDataState}.${option.name}`)}
                        />
                    ))
                }
            </Container>
        </>
    );
};

export default OptionSelector;
