import React, {useEffect} from 'react';
import styled from "styled-components";
import CheckableOptionLbiMedia from "./CheckableOptionLbiMedia";
import {useSelector} from "react-redux";
import {get} from "../../Utils/object";
import {getInDataState, setFinalPriceInTarifsState, setInDataState} from "../../Utils/storeHelpers";
import {produce} from "immer";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: 100%;
`;

const ScreenCountOptions = ({ options, ...props}) => {
    const { pricesDependOn, pathInDataState } = props;

    const count = useSelector(({ data }) => get(data, `${options.at(0).pathInDataStateQuantity}`, 0));
    const selected = useSelector(({ data }) => get(data, `${pathInDataState}`, false));

    useEffect(() => {
        setFinalPriceInTarifsState('lbi-media-screen-count', null);

        if (count > 0) {
            const items = getInDataState('offre-lbi-media.software.orientation.value') ?? [];

            setInDataState('offre-lbi-media.software.orientation.value', produce(items, draftState => {
                if (count > draftState.length) {
                    draftState.push({orientation: "portrait"});
                } else if (count < draftState.length) {
                    draftState.pop();
                }
            }));
        }
    }, [count])

    useEffect(() => {
        if (!selected) {
            const [first] = getInDataState('offre-lbi-media.software.orientation.value') ?? [];

            if (first) {
                setInDataState('offre-lbi-media.software.orientation.value', [first]);
            }
        }
    }, [selected])

    return (
        <Container>
            {
                options.map((option, index) => (
                    <React.Fragment key={index}>
                        {
                                <CheckableOptionLbiMedia
                                    height="200px"
                                    dependsOn={pricesDependOn ?? option.pricesDependOn}
                                    pathInDataState={pathInDataState}
                                    pathInDataStateQuantity={option.pathInDataStateQuantity}
                                    name={option.name}
                                    image={option.image}
                                    label={option.label}
                                    counterDependsOn={option.counterDependsOn}
                                    full={option.full}
                                    fullOptions={option.fullOptions}
                                    setItselfFalse={option.setItselfFalse}
                                    setInDataState={option.setInDataState}
                                    default={option.default}
                                    disabled={option.disabled}

                                />
                        }
                    </React.Fragment>
                ))
            }
        </Container>
    );
}

export default ScreenCountOptions;
