import React, { useCallback } from 'react';
import styled from 'styled-components'
import Toggle from './Toggle';
import Delete from './Addable/Delete';
import { borderGrey, InputNdd, SimpleSelect } from '../Utils/styleConstants';
import { isValidNdd } from '../Utils/price';

const DomainNameStructure = styled.div`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 2em 3em;
    margin: 0 0 2em;
    border: 2px solid ${borderGrey};
`;

const StyledDelete = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;

const Line = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    padding: 1em 0;
`;

const InputDomainStructure = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 45%;
`;

const InputLabel = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    padding: 0 15px;
    color: #A5A5AA;
    background-color: ${borderGrey};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
`;

const Select = styled(SimpleSelect)`
    width: 20%;
`;

const ToggleStructure = styled.div`
    display: flex;
    align-items: center;
`;

const ToggleLabel = styled.p`
    font-weight: bold;
    padding: 0 1em 0 0;
`;

const OldHostStructure = styled.div`
    display: flex;
    align-items: center;
    width: 45%;
`;

const InputOldHost = styled.input`
    flex-grow: 1;
    margin: 0 0 0 0.5em;
    padding: 0.5em;
    font-size: 1em;
    font-family: Roboto, sans-serif;
    border: 1px solid ${borderGrey};
    border-radius: 5px;
`;

const Error = styled.p`
    color: red;
    padding: 1em 0 0;
`;

const DomainName = (props) => {
    // Dispatch function
    const dispatchUpdateDataValue = useCallback((key, value) => {
        let newDomainName = null;

        switch(key) {
            case 'name':
                newDomainName = { ...props.domainName, name: value.toLowerCase() }
                break;
            case 'action':
                if ('non-gere-ext' === value.value) {
                    newDomainName = { ...props.domainName, action: value, lbi: false }
                } else {
                    newDomainName = { ...props.domainName, action: value, lbi: true }
                }
                break;
            case 'oldHost':
                newDomainName = { ...props.domainName, oldHost: value }
                break;
            case 'primary':
                newDomainName = { ...props.domainName, primary: !props.domainName.primary }
                break;
            case 'lbi':
                newDomainName = { ...props.domainName, lbi: !props.domainName.lbi }
                break;
            default: break;
        };

        props.dispatch({
            type: props.action,
            value: {
                id: props.masterId,
                name: props.name,
                index: props.index,
                primary: key === 'primary',
                value: newDomainName
            }
        });
    }, [props])

    return (
        <DomainNameStructure>
            { 
                props.isDeletable && (
                    <StyledDelete
                        onClick={() => props.deleteCallback(props.index)}
                    >
                        <Delete />
                    </StyledDelete>
                )
            }
            <Line>
                <InputDomainStructure>
                    <InputLabel htmlFor={`domainName${props.index}`}>
                        {'http://'}
                    </InputLabel>
                    <InputNdd
                        id={`domainName${props.index}`}
                        type="text"
                        placeholder='...'
                        autoComplete='off'
                        value={props.domainName.name}
                        error={!isValidNdd(props.prices, props.domainName.name)}
                        onChange={(event) => dispatchUpdateDataValue('name', event.target.value)}
                    />
                    {
                        !isValidNdd(props.prices, props.domainName.name) && (
                            <Error>Votre nom de domaine est invalide</Error>
                        )
                    }
                </InputDomainStructure>
                <Select
                    options={props.options}
                    placeholder='...'
                    value={props.domainName.action}
                    onChange={(event) => dispatchUpdateDataValue('action', event)}
                />
                <ToggleStructure>
                    <ToggleLabel>NDD principal</ToggleLabel>
                    <Toggle
                        value={props.domainName.primary}
                        callback={() => dispatchUpdateDataValue('primary')}
                    />
                </ToggleStructure>
            </Line>
            <Line>
                <OldHostStructure>
                    {
                        props.domainName.action.value === 'transfer' && (
                            <>
                                <label htmlFor={`oldHost${props.index}`}>Ancien hébergeur</label>
                                <InputOldHost
                                    id={`oldHost${props.index}`}
                                    type="text"
                                    placeholder='...'
                                    autoComplete='off'
                                    value={props.domainName.oldHost}
                                    onChange={(event) => dispatchUpdateDataValue('oldHost', event.target.value)}
                                />
                            </>
                        )
                    }
                </OldHostStructure>
                <ToggleStructure>
                    <ToggleLabel>Gérer par La Boîte Immo</ToggleLabel>
                    <Toggle
                        value={props.domainName.lbi}
                        callback={() => dispatchUpdateDataValue('lbi')}
                    />
                </ToggleStructure>
            </Line>
        </DomainNameStructure>
    );
};

export default DomainName;
