/**
 * @param input
 * @returns {boolean}
 */
function isPureObject(input) {
    return null !== input &&
        typeof input === 'object' &&
        Object.getPrototypeOf(input).isPrototypeOf(Object);
}

export default isPureObject;
