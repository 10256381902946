import { connect } from 'react-redux'
import Menu from '../Components/Menu'
import { mapStateToPropsAddable } from '../Utils/mapToProps'

const mapStateToProps = (state, ownProps) => ({
    ...mapStateToPropsAddable(state, ownProps),
    gridElements: ['titre', 'dest', 'note', 'sousmenu', 'typesousmenu'],
    spanElements: ['sousmenuel'],
    refs: id => ({
        titre: {
            type: 'InputTextContainer',
            name: `${id}/titre`,
            label: 'title'
        },
        dest: {
            type: 'SelectContainer',
            name: `${id}/dest`,
            label: 'dest',
            options: ['menu-home', 'menu-redac', 'menu-form', 'menu-listing']
        },
        note: {
            type: 'InputTextContainer',
            name: `${id}/note`,
            label: 'note'
        },
        sousmenu: {
            component: 'ToggleLabeled',
            pathInDataState: `${ownProps.masterId}.${id}/submenu`,
            label: 'submenu',
            type: 'ConditionnalComponentContainer',
            conditions: [
                {
                    pathInDataState: 'offre-site.offre-site-location',
                    value: 'cleenmain-location',
                    mods: ['not']
                },
                {
                    id: 'offre-site',
                    name: 'offre-site-vente',
                    value: 'cleenmain-vente',
                    mods: ['not']
                }
            ],
        },
        typesousmenu: {
            name: `${id}/typesubmenu`,
            type: 'ConditionnalComponentContainer',
            conditions: [
                {
                    pathInDataState: `${ownProps.masterId}.${id}/submenu`,
                    value: true
                }
            ],
            component: 'SelectContainer',
            label: 'typesubmenu',
            options: ['manuel'],
            defaultValue: 'manuel'
        },
        sousmenuel: {
            name: `${id}/submenu-element`,
            type: 'ConditionnalComponentContainer',
            conditions: [
                {
                    pathInDataState: `${ownProps.masterId}.${id}/typesubmenu`,
                    value: 'manuel'
                }
            ],
            component: 'Addable',
            addcomponent: {
                label: 'sousmenuelement',
                type: 'Combo',
                combo: ['input', 'select'],
                input: {
                    name: 'titre',
                    size: 50
                },
                select: {
                    name: 'dest',
                    options: ['menu-home', 'menu-redac', 'menu-form', 'menu-listing'],
                    size: 50
                }
            }
        }
    })
})

export default connect(
    mapStateToProps
)(Menu)
