import React from 'react'
import styled from 'styled-components'
import { green, grey } from '../Utils/styleConstants'

const SvgNext = styled.svg`
  transform: rotate(270deg);
  transition: all 0.5s;
`

const SvgPrev = styled.svg`
  transform: rotate(90deg);
  transition: all 0.5s;
`

const StyledLink = styled.a`
  color: ${props => props.green ? 'white' : grey};
  display: flex;
  background: ${props => props.green ? green : 'white'};
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: 50px;
  width: 270px;
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.5s;

  &:hover {
    background: ${green};
    color: white;
    ${SvgNext}, ${SvgPrev} {
      fill: white;
    }
  }
`

class Link extends React.Component {
  render () {
    return (
      <StyledLink onClick={this.props.handle} green={this.props.green !== undefined}>
        {
          this.props.arrowPrev
          ? <SvgPrev height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></SvgPrev>
          : null
        }
        {this.props.label}
        {
          this.props.arrowNext
          ? <SvgNext height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false"><path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path></SvgNext>
          : null
        }
      </StyledLink>
    )
  }
}

export default Link
