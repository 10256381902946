import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MANDATORY_NOT_MISSING } from '../../Store/actions';
import { selectGenericState } from '../../Store/selectors';
import BaseModal from './BaseModal';

const ModalMandatory = () => {
    // Use of redux
    const dispatch = useDispatch();
    const genericState = useSelector(selectGenericState);

    return (
        genericState.missingMandatory && (
            <BaseModal
                title="Vous ne pouvez pas passer à l'étape suivante !"
                text="Pour passer à l'étape suivante veuillez renseigner tous les champs obligatoires (*)"
                onClose={() => dispatch({ type: MANDATORY_NOT_MISSING })}
            />
        )
    );
};

export default ModalMandatory;