import React from 'react';
import { setInDataState } from '../Utils/storeHelpers';
import styled from 'styled-components';
import { borderGrey } from '../Utils/styleConstants';

const Area = styled.textarea`
    flex: 1;
    width: 98%;
    padding: 1%;
    font-size: 0.9em;
    font-family: Roboto, sans-serif;
    border: 1px solid ${borderGrey};
    resize: none;

    ::placeholder {
        color: ${borderGrey};
    }
`;

const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Label = styled.div`
    font-size: ${props => props.fontSize ?? '0.8em'};
    font-weight: ${props => props.fontWeight ?? 'bold'};
    align-self: flex-start;
    padding: ${props => props.padding ?? '0.5em'};
`;

class TextArea extends React.Component {
    constructor (props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
    }

    componentDidMount() {
        this.props.initKeyInState && this.props.dispatch({
            type: this.props.action,
            value: {
                id: this.props.masterId,
                name: this.props.initKeyInState,
                value: ''
            }
        })
    }

    handleChange (event) {
        setInDataState(`${this.props.masterId}.${this.props.name}`, event.target.value)
    }

    render () {
        return (
            <Container>
                {
                    this.props.label && (
                        <Label
                            fontSize={this.props.labelFontSize}
                            fontWeight={this.props.labelFontWeight}
                            padding={this.props.labelPadding}
                        >
                            {this.props.label}
                        </Label>
                    )
                }
                <Area
                    rows={10}
                    onChange={this.handleChange}
                    value={this.props.value}
                    placeholder={this.props.placeholder ?? ''}
                />
            </Container>
        )
    }
}

export default TextArea
