import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import PriceUpdaterDevis from './PriceUpdaterDevis';
import { priceCSS, FakeInputContainer, FakeInputPrice, PriceUnit } from '../../Utils/styleConstants';
import {setFinalPrice} from '../../Utils/storeHelpers';
import { getTarifsPriceUnit, getTarifsPriceUnitInRecap } from '../../Utils/jsonInterpreter';

const StringFinalPrice = styled.p`
    ${priceCSS};
    text-transform: uppercase;
    cursor: default;
`;

const PriceUpdater = props => {
    const WAIT_INTERVAL = 1500;

    // Use of hook
    const [price, setPrice] = useState(null);
    const [devisMode, setDevisMode] = useState(null);
    const [timer, setTimer] = useState(/** @type {null|number} **/null);

    // useEffect when component is mounting
    useEffect(() => {
        const { index, tarifs } = props;

        if (null === price) {
            if (undefined !== index && tarifs.hasOwnProperty('finalPrices')) {
                setPrice(tarifs.finalPrices[index].price);
            }

            if (tarifs.hasOwnProperty('finalPrice')) {
                setPrice(tarifs.finalPrice);
            }
        }

        if (null === devisMode) {
            if (undefined !== index && tarifs.hasOwnProperty('finalPrices')) {
                setDevisMode(true === tarifs.finalPrices[index].onQuotation);
            }

            if (tarifs.hasOwnProperty('finalPrice')) {
                setDevisMode(true === tarifs.onQuotation);
            }
        }
    }, [price, devisMode, props]);

    // Callback used when user change price
    const handleChangePrice = useCallback((newPrice) => {
        if (newPrice === price) {
            return;
        }

        clearTimeout(timer);

        const floatPrice = '' === newPrice ? 0 : parseFloat(newPrice);

        setPrice(floatPrice);

        setTimer(setTimeout(() => {
            const config = 'finalPrices' in props.tarifs
                ? { name: props.tarifs.id, value: floatPrice, index: props.index }
                : { name: props.tarifs.id, value: floatPrice }

            setFinalPrice({ ...config, discount: true });
        }, WAIT_INTERVAL));

    }, [timer, price, props]);

    return (
        null !== price && null !== devisMode && (
            <>
                {
                    devisMode ? (
                        <PriceUpdaterDevis
                            priceUnit={getTarifsPriceUnitInRecap(props.tarifs) ?? getTarifsPriceUnit(props.tarifs)}
                            tarifs={props.tarifs}
                            index={props.index}
                        />
                    ) : (
                        <FakeInputContainer>
                            {
                                'string' === typeof price && '' !== price ? (
                                    <StringFinalPrice>{price}</StringFinalPrice>
                                )
                                : price === 0 && props.tarifs.freeLimit && props.index < props.tarifs.freeLimit ? (
                                    <StringFinalPrice>inclus</StringFinalPrice>
                                ) : (
                                    <>
                                        <FakeInputPrice
                                            type='text'
                                            parent={props.parent}
                                            value={price}
                                            width={undefined === price || 0 === price.toString().length ? '1ch' : `${price.toString().length}ch`}
                                            onChange={(event) => handleChangePrice(event.target.value)}
                                        />
                                        <PriceUnit>
                                            {`€ ${getTarifsPriceUnitInRecap(props.tarifs) ?? getTarifsPriceUnit(props.tarifs)}`}
                                        </PriceUnit>
                                    </>
                                )
                            }
                        </FakeInputContainer>
                    )
                }
            </>
        )
    );
};

export default PriceUpdater;
