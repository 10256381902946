import React, { useCallback, useEffect, useState } from 'react';
import { getInDataState, setInDataState } from '../../Utils/storeHelpers';
import styled from 'styled-components';
import { borderGrey } from '../../Utils/styleConstants';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Input = styled.input`
  width: 70%;
  border: 1px solid ${borderGrey};
  border-radius: 4px;
  margin: 0.5em 0 1em;
  padding: 0.5em;
  font-size: 1em;
  font-family: Roboto, sans-serif;

  &[type="url"] {
    padding-left: 14px;
  }
`;

const RefNatNdd = props => {
    // Use of hooks
    const [ndd, setNdd] = useState(null);

    // useEffect when component is mounting
    useEffect(() => {
        null === ndd &&
            setNdd(getInDataState(props.pathInDataState));
    }, [ndd, props.pathInDataState])

    // Callback used to format the ndd
    const formatNdd = useCallback((ndd) => {
        return '' === ndd || undefined === ndd ? 'http://' : ndd;
    }, [])

    // Callback used whenever the input value changes
    const handleInputChange = useCallback((event) => {
        setInDataState(props.pathInDataState, event.target.value);
        setNdd(event.target.value);
    }, [props.pathInDataState])

    return (
        null !== ndd && (
            <Container>
                <label htmlFor="refNatNdd">Nom de domaine (NDD) à référencer</label>
                <Input id="refNatNdd" type='text' value={formatNdd(ndd)} onChange={(event) => handleInputChange(event)} />
            </Container>
        )
    )
};

export default RefNatNdd;
