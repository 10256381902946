/** @type {{"24": number, "36": number, "48": number, "18": number, "60": number}} */
const COEFFICIENT_MONTH_ASSOC = {
    12: 0.0621,
    24: 0.0483,
    36: 0.0340,
    48: 0.0283,
    60: 0.0223
}

const DEFAULT_COEFFICIENT = 1;

/**
 *
 * @param {number} price the price to be computed
 * @param {number} duration duration in months
 * @return {number}
 */
const computePrice = (price, duration) => {
    return price * (COEFFICIENT_MONTH_ASSOC[duration] ?? DEFAULT_COEFFICIENT);
}

export default computePrice;
