import React, {useEffect} from "react";
import {getInDataState, setInDataState} from "../Utils/storeHelpers";
import styled from "styled-components";
import {borderGrey} from "../Utils/styleConstants";

const Container = styled.div`
  position: relative;
  width: 100%;
`;

const InputInner = styled.input`
  width: 100%;
  color: initial;
  border: 1px solid ${borderGrey};
  border-radius: 4px;
  padding: 0.5em;
  font-size: 1em;
  font-family: Roboto, sans-serif;

  &[type="url"] {
    padding-left: 14px;
  }
  
  &::placeholder {
    opacity: 0.6;
  }
`;

const getType = ({innerType}) =>
    innerType !== undefined ? innerType : "text";

const GetLabel = ({innerType}) => {
    if (innerType !== undefined) {
        switch (innerType) {
            default:
                return null;
        }
    }
    return null;
};

const getValue = ({innerType, value, defaultValue }) => {

    if (value === undefined) {
        if (innerType === "url") {
            return "http://";
        }

        return defaultValue;
    }

    return value ?? "";
};

const InputItem = (props) => {
    const handleChange = (event) => {
        const value = event.target.value;
        const finalValue = (props.innerType === 'number' && !isNaN(value)) ? Math.ceil(parseFloat(value)) : value;

        setInDataState(`${props.masterId}.${props.name}`, finalValue);
    }

    useEffect(() => {
        const value = getInDataState(`${props.masterId}.${props.name}`);

        if (!value && props.defaultValue) {
            setInDataState(`${props.masterId}.${props.name}`, props.defaultValue);
        }
    }, [props.defaultValue])

    return (
        <Container>
            {GetLabel(props)}
            <InputInner
                type={ getType(props)}
                placeholder={props.placeholder}
                value={getValue(props)}
                onChange={handleChange}
                disabled={props.disabled}
                minLength={props.minLength}
                maxLength={props.maxLength}
            />
        </Container>
    );
}

export default InputItem;
