import React from 'react'
import styled, {css} from 'styled-components'
import Builder from '../Utils/builder'
import DeleteComponent from './Addable/Delete'
import { borderGrey } from '../Utils/styleConstants'
import { beautifulGetLabel } from '../Utils/labels'

const styleOverride = css(({ styleOverride  }) => styleOverride ?? {});

const Container = styled.div`
    ${({ gridRepeat }) => (!gridRepeat || gridRepeat > 1)
        ? `
            display: grid;
            grid-template-columns: repeat(${gridRepeat ? gridRepeat : 2}, 1fr);
        `
        : null
    };

    ${({ gridTemplate }) => gridTemplate !== undefined
        ?`
            display: grid;
            ${gridTemplate};
        `
        : null
    };

    column-gap: 10px;
    position: relative;
    padding: 2em;
    border: 2px solid ${borderGrey};

    ${styleOverride};
`

const Row = styled.div`
    ${({ spanTemplate }) => spanTemplate !== undefined
        ? spanTemplate
        : `grid-column: span 2;`
    };
`

const DeleteContainer = styled.div`
    position: absolute;
    top: 5px; right: 5px;
`

class MenuElement extends React.Component {
    elementFunc(order, cst, id) {
        return { ...cst[id], order };
    }

    render () {
        const els = this.props.refs(this.props.name);
        const deletable = this.props.deletable ?? true;

        return (
                <Container {...this.props}>
                    {
                        !this.props.first && deletable && (
                            <DeleteContainer>
                                <DeleteComponent handleClick={this.props.handleDelete} />
                            </DeleteContainer>
                        )
                    }
                    {
                        this.props.gridElements.map((id, index) => {
                            let element = this.elementFunc(this.props.order, els, id)

                            // Transform label with beautifulGetLabel (specific treatment because this is a menu element)
                            const elementLabel = beautifulGetLabel(element.label)
                            element = { ...element, label: elementLabel}

                            return (
                                <React.Fragment key={index}>
                                    {Builder.create(this.props.masterId, element)}
                                </React.Fragment>
                            )
                        })
                    }
                    {
                        this.props.spanElements && this.props.spanElements.length > 0 &&
                            <Row {...this.props}>
                                {
                                    this.props.spanElements.map((id, index) => {
                                        let element = this.elementFunc(this.props.order, els, id)

                                        // Transform label with beautifulGetLabel (specific treatment because this is a menu element)
                                        const elementLabel = beautifulGetLabel(element.label)
                                        element = { ...element, label: elementLabel}

                                        return (
                                            <React.Fragment key={index}>
                                                {Builder.create(this.props.masterId, element)}
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </Row>
                    }
                </Container>
            )
    }
}

export default MenuElement
