import styled from 'styled-components';
import Checkmark from "../Icons/CheckmarkLittle";

const Container = styled.div`
  display: flex;
  margin: 30px 5%;
`

const CheckmarkContainer = styled.div`
  width: 90px;
  margin-right: 15px;
`

const RefNatAutorisation = () => {
    return (
        <Container>
            <CheckmarkContainer>
                <Checkmark color="green"/>
            </CheckmarkContainer>
            <h3>
                <strong>
                    J'autorise  La  Boîte  Immo  à  indexer  mon  site  (sur  Google  My  Business,  Bing,  Yahoo  et  aux  annuaires),
                    à effectuer du calltracking et à prendre la main sur mon site internet pour ajouter du contenu rédactionnel optimisé.
                </strong>
            </h3>
        </Container>
    )
}

export default RefNatAutorisation;
