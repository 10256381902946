import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Node from './Node';

const Container = styled.div`
    padding: 2em;
`;

const Recap = ({tree}) => {
    return (
        <Container>
            {
                tree.map((node, index) => (
                    <Node
                        key={index}
                        parent={node.parent}
                        groups={node.groups}
                    />
                ))
            }
        </Container>
    );
};

export default Recap;
