import React from 'react'
import styled from 'styled-components'
import Builder from '../Utils/builder'

const Container = styled.div`
    ${props => props.styles ? props.styles : null}
`

const Styler = props => <Container {...props}>{Builder.create(props.masterId, {...props, type: props.styler})}</Container>

export default Styler;
