import React from 'react';
import styled from 'styled-components';
import { setInDataState } from '../Utils/storeHelpers';
import { borderGrey, grey } from '../Utils/styleConstants';
import { getLabel } from '../Utils/labels';

const StyledSelect = styled.select`
  border: 1px solid ${borderGrey};
  text-align: center;
  font-weight: bold;
  border-radius: 30px;
  padding: 0.5em;
  width: 100%;
  color: ${grey};
`;

class SelectItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        setInDataState(`${this.props.masterId}.${this.props.name}`, event.target.value);
    }

    render() {
        return (
            <StyledSelect value={this.props.value} onChange={this.handleChange}>
                {
                    this.props.options.map((option, index) => (
                        <option
                            key={index}
                            value={option}
                        >
                            {getLabel(option)}
                        </option>
                    ))
                }
            </StyledSelect>
        );
    };
};

export default SelectItem;
