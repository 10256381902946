import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import { getLabel } from '../../Utils/labels';
import { getImage } from '../../Utils/jsonInterpreter';
import {
    getInDataState,
    getInTarifsState,
    setInDataState
} from '../../Utils/storeHelpers';
import {
    ContainerOption,
    ImageOption,
    NameStructureOption,
    NameOption
} from '../../Utils/styleConstants';
import MinorOption from "../Option/MinorOption";

const Container = styled(ContainerOption)`
  width: 98%;
`;

const WithCounterChoiceOption = props => {
    const hektorOffer = props.hektorOffer;
    const pathHektorType = props.hektorType;
    const hektorType = getInDataState(pathHektorType)
    const { pathInDataState, subOptions, radio } = props;
    const [toggled, setToggled] = useState(null);
    const [includedIn, setIncludedIn] = useState(null);

    // On mount
    useEffect(() => {
        const isSubOptionToggled = (subOptionName) => {
            const subOptionPath = `${pathInDataState}.${subOptionName}`;
            if (
                getInTarifsState(`${subOptionName}.includedIn`)
                && getInTarifsState(`${subOptionName}.includedIn`)[hektorType]
            ) {
                return true;
            }

            return getInDataState(subOptionPath) ?? false;
        }

        if (!toggled) {
            setToggled(
                subOptions.map(subOption => isSubOptionToggled(subOption.value))
            );
        }
    }, [subOptions, toggled, pathInDataState]);

    // useEffect when component is mounting
    useEffect(() => {
        const isSubOptionIncludedIn = (subOptionName) => {
            if (
                getInTarifsState(`${subOptionName}.includedIn`)
                && getInTarifsState(`${subOptionName}.includedIn`)[hektorType]
            ) {
                return getInTarifsState(`${subOptionName}.includedIn`)[hektorType];
            }
        }

        if (!includedIn) {
            setIncludedIn(
                subOptions.map(subOption => isSubOptionIncludedIn(subOption.value))
            );
        }
    }, [subOptions, includedIn]);

    // Callback when user toggle a subOption
    const handleToggleSubOption = useCallback((subOptionIndex) => {
        setToggled(
            toggled.map((value, index) =>
                index === subOptionIndex ? !value : (radio ? false : value)
            )
        );
    }, [radio, toggled]);

    // useEffect whenever toggled hook change
    useEffect(() => {
        if (toggled) {
            toggled.forEach((toggle, index) =>
                setInDataState(`${pathInDataState}.${subOptions[index].value}`, toggle)
            );
        }
    }, [pathInDataState, subOptions, toggled]);
    return (
        toggled && (
            <Container checked={toggled.some((item => true === item))}>
                <ImageOption
                    src={getImage(props.image)}
                    alt='#'
                />
                <NameStructureOption>
                    <NameOption>
                        {getLabel(props.label)}
                    </NameOption>
                </NameStructureOption>
                {
                    subOptions.map((subOption, index) => (
                        <MinorOption
                            key={index}
                            index={index}
                            total={subOptions.length}
                            toggle={toggled[index]}
                            pathInDataState={pathInDataState}
                            name={subOption.value}
                            tarifsName={subOption.value}
                            onToggle={handleToggleSubOption}
                            isIncludedIn={includedIn[index]}
                            counterDependsOn={props.counterDependsOn}
                            excludedOptionsFromCounter={props.excludedOptionsFromCounter}
                            pathInDataStateQuantity={props.pathInDataStateQuantity ?? (props.basePathInDataStateQuantity ? `${props.basePathInDataStateQuantity}.${subOption.value}` : undefined)}
                            toggable
                            withoutCounter={subOption.withoutCounter ? subOption.withoutCounter : false}
                            dependsOn={hektorOffer}
                        />
                    ))
                }
                <CheckmarkContainer
                    checked={toggled.some((item => true === item))}
                    notBottomCheckmark
                />
            </Container>
        )
    );
};

export default WithCounterChoiceOption;
