import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDataState } from '../Store/selectors';
import styled from 'styled-components';
import { borderRadius, borderGrey, green, grey, mediumGrey, darkOrange } from '../Utils/styleConstants';
import { getLabel } from '../Utils/labels';
import {
    getInDataState,
    multipleClearInDataState,
    setInDataState,
    setFinalPriceInTarifsState,
    multipleClearFinalPriceOfTarifs
} from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${props => props.margin ?? '80px 0'};
    pointer-events: ${props => props.clickable ? 'inital' : 'none'};
`;

const Choice = styled.p`
    text-transform: uppercase;
    text-align: center;
    min-width: 25%;
    border: 1px solid ${borderGrey};
    padding: 10px 30px;
    cursor: pointer;
    transition: all .2s linear;
    font-size: 0.8em;
    letter-spacing: 2px;
    font-weight: bold;
    background: ${props => !props.selected ? 'white' : props.orange ? darkOrange : green};
    color: ${props => props.selected ? 'white' : props.clickable ? grey : mediumGrey };
  
    &:hover {
        background: ${props => props.orange ? darkOrange : green};
        color: white;
    };

    &:first-child {
        border-radius: ${borderRadius} 0 0 ${borderRadius};
        border-right: 0.5px solid ${borderGrey};
    };

    &:last-child {
        border-radius: 0 ${borderRadius} ${borderRadius} 0;
        border-left: 0.5px solid ${borderGrey};
    };
`;

const BiChoice = props => {
    // Use of hook
    const [selected, setSelected] = useState(null);

    // Use of redux selector
    const dataState = useSelector(selectDataState);

    // useEffect when component is mounting
    useEffect(() => {
        Object.entries(props.options).forEach(([key, option], index) => {
            index === props.byDefault && undefined === getInDataState(props.pathInDataState) &&
                // Set the value of the option in data state as we click on it
                setInDataState(props.pathInDataState, option);
        });
    }, [props])

    // useEffect whenever dataState changes
    useEffect(() => {
        const tmpSelected = [];

        // This setup an array of boolean which determine which option is selected
        Object.entries(props.options).forEach(([key, option]) => tmpSelected.push(option === getInDataState(props.pathInDataState)));

        setSelected(tmpSelected);
    }, [props.options, props.pathInDataState, dataState]);

    // Callback used when user select an option
    const handleClickOption = useCallback((choiceName, choiceIndex, choiceIsChecked) => {
        if (choiceName === 'vente' || choiceName === 'location') {
            // Reset/Clear finalPrices for domain-names
            setFinalPriceInTarifsState('domain-names', null);
            // Change price for hebergement
            setFinalPriceInTarifsState('hebergement', null);
        }

        // We can't deselect, so we only do action when the choice switch from OFF to ON
        if (false === choiceIsChecked) {
            // Update dataState
            setInDataState(props.pathInDataState, choiceName);

            // Either select the option or not by modifying the hook array of booleans
            setSelected(selected.map((_, index) => index === choiceIndex));

            // Clear some data (if clearOnChangeInDataState paths indicated in json file)
            multipleClearInDataState(props.clearOnChangeInDataState);

            // Clear some finalPrice of tarifs (if clearFinalPrice is indicated in json file)
            multipleClearFinalPriceOfTarifs(props.clearFinalPrice);
        }

        // If it is a prospect we will have the choice of the website offer
        if (choiceName === 'hektor-ika-le-pack-prospect') {
            setInDataState('produits','site',true);
        }
    }, [props.pathInDataState, props.clearOnChangeInDataState, selected, props.clearFinalPrice]);

    return (
        selected && (
            <Container 
                margin={props.margin} 
                clickable={!props.notClickable}
            >
                {
                     Object.entries(props.options).map(([key, option], index) => (
                        <Choice
                            key={index}
                            selected={selected[index]}
                            onClick={() => handleClickOption(option, key, selected[index])}
                            clickable={!props.notClickable}
                            orange={props.orange}
                        >
                            {getLabel(key.length > 1 ? key : option)}
                        </Choice>
                    ))
                }
            </Container>
        )
    );
};

export default BiChoice;
