import React from 'react'
import styled from 'styled-components'
import { easeOutBack, borderGrey, borderGreen, darkGreen } from '../../Utils/styleConstants'

const Plus = styled.path`
    fill: ${darkGreen};
    transform: scale(0.3);
    transform-origin: center center;
    transition: all .5s ${easeOutBack};
`

const BorderCircle = styled.circle`
    fill: transparent;
    stroke: ${borderGreen};
    stroke-width: 20px;
    transition: all .5s ${easeOutBack};
`

const FillCircle = styled.circle`
    fill: ${borderGrey};
    transform: scale(0);
    transform-origin: center center;
    transition: all .5s ${easeOutBack};
`

const Svg = styled.svg`
    width: 30px;
    height: 30px;
    cursor: pointer;

    &:hover {
        ${FillCircle} {
            transform: scale(1);
        }
        ${Plus} {
            transform: scale(0.3) rotate(180deg);
        }
    }
`

class Add extends React.Component {
    render () {
        return (
            <Svg viewBox='0 0 492 492' onClick={this.props.handleClick}>
                        <FillCircle cx='246' cy='246' r='206' />
                        <BorderCircle cx='246' cy='246' r='206' />
                        <Plus d="M465.064,207.566l0.028,0H284.436V27.25c0-14.84-12.016-27.248-26.856-27.248h-23.116
			c-14.836,0-26.904,12.408-26.904,27.248v180.316H26.908c-14.832,0-26.908,12-26.908,26.844v23.248
			c0,14.832,12.072,26.78,26.908,26.78h180.656v180.968c0,14.832,12.064,26.592,26.904,26.592h23.116
			c14.84,0,26.856-11.764,26.856-26.592V284.438h180.624c14.84,0,26.936-11.952,26.936-26.78V234.41
			C492,219.566,479.904,207.566,465.064,207.566z"/>
                    </Svg>
        )
    }
}

export default Add
