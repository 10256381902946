import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UPDATE_DATA } from '../Store/actions';
import styled from 'styled-components';
import { getImage } from '../Utils/jsonInterpreter';

const ImgContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: ${props => props.height !== undefined ? props.height : 'auto'};
`;

const Img = styled.img`
    max-width: ${props => props.maxWidth ?? '100%'};
    flex: 1;
`;

const Label = styled.p`
    font-size: ${props => props.fontSize ?? '0.8em'};
    font-weight: ${props => props.fontWeight ?? 'bold'};
    text-decoration: ${props => props.underline ? 'underline' : 'none'};
    align-self: flex-start;
    padding: ${props => props.padding ?? '0.5em'};
`;

const Illustration = props => {
    // Use dispatch from redux
    const dispatch = useDispatch();

    // useEffect on mounting
    useEffect(() => {
        props.setInStore
            && dispatch({
                type: UPDATE_DATA,
                value: { [props.masterId]: props.setInStore }
            });
    }, [props.masterId, props.setInStore, dispatch])

    return (
        <ImgContainer height={props.height}>
            {
                props.label && (
                    <Label
                        fontSize={props.labelFontSize}
                        fontWeight={props.labelFontWeight}
                        padding={props.labelPadding}
                        underline={props.labelUnderline}
                    >
                        {props.label}
                    </Label>
                )
            }
            <Img {...props} src={getImage(props.src)} alt='' />
        </ImgContainer>
    )
};

export default Illustration;
