import React from 'react'
import Labeled from './Labeled'
import Counter from './Counter'

class CounterLabeled extends React.Component {
  render () {
    const fitIfInverted = this.props.inverted || this.props.forceFit ? {fitContent:true} : {}
    return (
      <Labeled {...this.props} inverted={this.props.inverted} isToggle={true} {...fitIfInverted}>
        <Counter {...this.props} />
      </Labeled>
    )
  }
}

export default CounterLabeled
