import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectDataState } from '../Store/selectors';
import { getLabel } from '../Utils/labels';
import { borderGrey, green, lightGrey, grey } from '../Utils/styleConstants';
import { getInDataState, setInDataState } from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    font-size: 0.7em;
    background: ${lightGrey};
    overflow: hidden;
    border-radius: 5px;
    margin: 10px;
`;

const Label = styled.div`
    background: ${green};
    color: white;
    text-transform: uppercase;
    padding: 20px 35px;
    font-weight: bold;
`;

const OptionsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0 20px;
    color: ${grey};
    letter-spacing: 0.5px;
`;

const Inner = styled.div`
    width: 10px;
    height: 10px;
    border-radius: 50%;
`;

const StyledCheck = styled.div`
    width: 20px;
    height: 20px;
    border: 2px solid ${props => props.checked ? green : borderGrey};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
    ${Inner} {
        background: ${props => props.checked ? green : 'white'};
    }
`;

const StyledOption = styled.div`
    display: flex;
    margin-left: 35px;
    align-items: center;
`;

const Option = props => (
    <StyledOption>
        {getLabel(props.value)}
        <StyledCheck
            onClick={props.onClick}
            checked={props.checked}
        >
            <Inner />
        </StyledCheck>
    </StyledOption>
);

const RadioBand = (props) => {
    // Use of hooks
    const [options, setOptions] = useState(null);

    // Use of redux
    const dataState = useSelector(selectDataState);

    // useEffect on mount
    useEffect(() => {
        let optionsToStore = props.options;
        const produits = getInDataState('produits');

        if (!produits.includes('hektor')) {
            optionsToStore = props.options.filter(option => option.value !== 'livraison-hektor');
        }
        if (!produits.includes('site')) {
            optionsToStore = props.options.filter(option => option.value !== 'livraison-site');
        }
        if (!produits.includes('hektor') && !produits.includes('site')) {
            setOptions([ { value: 'despossible' }]);
            setInDataState(props.pathInDataState, 'despossible');
        } else {
            setOptions(optionsToStore);
        }
    }, [props])

    // Callback used when user click on an option
    const handleClickOption = useCallback((optionValue) => {
        1 !== options.length &&
            setInDataState(props.pathInDataState, optionValue);
    }, [options, props.pathInDataState])

    return (
        null !== options && dataState && (
            <Container>
                <Label>{getLabel(props.labelName)}</Label>
                <OptionsContainer>
                    {
                        options.map(option => (
                            <Option
                                key={`${props.name}/${option.value}`}
                                onClick={() => handleClickOption(option.value)}
                                checked={getInDataState(props.pathInDataState) === option.value}
                                {...option}
                            />
                        ))
                    }
                </OptionsContainer>
            </Container>
        )
    );
};

export default RadioBand;
