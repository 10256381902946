import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {borderGrey, grey} from "../../Utils/styleConstants";

const CounterInnerContainer = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: fit-content;
    border: 1px solid ${borderGrey};
    border-radius: ${props => props.fontSize ? `calc(${props.fontSize} + 10px)` : '25px'};
    margin: ${props => props.margin ?? 'auto'};
    ${props => props.width && `width: ${props.width}`};
    background-color: white;

    & > p {
        font-size: ${props => props.fontSize ? props.fontSize : '15px'};
    }
`;

const Count = styled.p`
    font-family: 'Roboto Mono', monospace;
    color: ${grey};
    user-select: none;
`;

const Control = styled(Count)`
    cursor: pointer;
    padding: 5px 10px;
`;

const Counter = ({ min, max, step = 1, initialValue = null, onCountChange }) => {
    const innerMax = max ?? 999;
    const innerMin = min ?? 0;

    const [count, setCount] = useState(initialValue ?? innerMin);

    useEffect(() => {
        if (initialValue === null) {
            setCount(innerMin);
            onCountChange?.(innerMin);
        }
    }, [onCountChange, initialValue, innerMin, setCount]);

    const handleIncrement = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (count < innerMax) {
            setCount(count + step);
            onCountChange?.(count + step, event);
        }
    }

    const handleDecrement = (event) => {
        event.preventDefault();
        event.stopPropagation();

        if (count > innerMin) {
            const newCount = count > innerMin ? count - step : innerMin;

            if (newCount !== count) {
                setCount(newCount);
                onCountChange?.(newCount, event);
            }
        }
    }

    return (
        <CounterInnerContainer margin='0' fontSize='18px'>
            <Control onClick={(event) => handleDecrement(event)}>-</Control>
            <Count>{count}</Count>
            <Control onClick={(event) => handleIncrement(event)}>+</Control>
        </CounterInnerContainer>
    )
}

export default Counter;
