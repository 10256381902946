import React from 'react'
import styled from 'styled-components'

const SvgElement = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${props => props.color === undefined ? 'white' : props.color};
`

class Checkmark extends React.Component {
  render () {
    return (
      <SvgElement {...this.props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z' /></SvgElement>
    )
  }
}

export default Checkmark
