import styled from "styled-components";

const Container = styled.div`
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

const BigNumber = styled.span`
    font-size: 200%;
    font-weight: bold;
`

/**
 * @typedef WarrantyDurationProps
 * @property {number} duration
 */

/**
 *
 * @param {WarrantyDurationProps} props
 * @return {JSX.Element}
 */
const WarrantyDuration = ({ duration }) => {
    return (
        <Container>
            <p>Durée de la garantie : <BigNumber>{duration}</BigNumber> mois</p>
        </Container>
    )
}

export default WarrantyDuration;
