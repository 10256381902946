import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import Counter from './Counter';
import CheckmarkContainer from './Icons/CheckmarkContainer';
import { StyledCheckbox } from '../Utils/styleConstants';
import { getAgencyName } from '../Utils/labels';
import {
    deleteInDataState,
    deleteFinalPriceInStore,
    setFinalPriceInTarifsState,
    getInDataState,
    resetFinalPriceHektorOptions
} from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 33.33%;
    padding: 0 1.5%;
`;

const Checkbox = styled(StyledCheckbox)`
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 230px;
    padding: 40px 0;
`;

const Type = styled.p`
    font-size: 2em;
    font-weight: bold;
`;

const CounterStructure = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Agency = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px 0 0;

    &:last-child {
        padding: 20px 0;
    }
`;

const Bold = styled.span`
    font-weight: bold;
`;

const AgencyConfiguration = props => {
    // Use of hooks
    const [checked, setChecked] = useState(null);
    const [agencies, setAgencies] = useState(null);

    // Definition of masterId
    const masterId = `${props.pathInDataState}.${props.dataKey}`;

    // useEffect when component is mounting
    useEffect(() => {
        if (getInDataState(masterId)) {
            const totalAgencies = Object.keys(getInDataState(masterId)).length;

            // Array of values 0...N (where N is totalAgencies - 1)
            null === agencies && setAgencies([ ...Array(totalAgencies).keys() ]);
            null === checked && setChecked(totalAgencies > 0);
        } else {
            null === checked && setChecked(false);
            null === agencies && setAgencies([]);
        }
    }, [checked, agencies, masterId])

    // Callback used when user change the agency counter value
    const handleAgencyCounterChange = useCallback((value, event, inc) => {
        setChecked(value > 0);

        if (inc) {
            setAgencies([ ...agencies, value ]);
        } else {
            const totalAgencies = agencies.length;

            // Remove the last agency from hook
            setAgencies(agencies.filter((agency, index) => index !== totalAgencies - 1));
            // Remove the last agency in redux state
            totalAgencies > 0 && deleteInDataState(`${masterId}.agency${totalAgencies}`);
        }

        // In any cases, we also reset commissioning finalPrice
        setFinalPriceInTarifsState('commissioning-hektor', null, undefined, props.hektorOffer);
        // We also reset the final price of all hektor options
        resetFinalPriceHektorOptions(false, props.hektorOffer);
    }, [agencies, masterId, props.hektorOffer])

    // Callback used when user change the agency users counter value
    const handleUserCounterChange = useCallback((value, event, inc, index) => {
        const agencyName = getAgencyName(`agency${index + 1}`);

        deleteFinalPriceInStore(props.tarifsKey, agencyName);
        deleteFinalPriceInStore(`${props.tarifsKey}-promotion`, agencyName);
    }, [props.tarifsKey]);

    return (
        null !== checked && null !== agencies && (
            <Container>
                <Checkbox checked={checked}>
                    <Type>{props.type}</Type>
                    <CounterStructure>
                        <p>Nombre d'agence(s)</p>
                        <Counter
                            dontStandAlone
                            min={0}
                            initialValue={agencies.length}
                            fontSize='20px'
                            margin='2vh 0 0 0'
                            callback={handleAgencyCounterChange}
                        />
                    </CounterStructure>
                    <CheckmarkContainer checked={checked} />
                </Checkbox>
                {
                    agencies.map((value, index) => (
                        <Agency key={index}>
                            <p><Bold>{`Agence ${index + 1}`}</Bold> - Utilisateur(s)</p>
                            <Counter
                                masterId={masterId}
                                name={`agency${index + 1}`}
                                min={1}
                                initialValue={(getInDataState(masterId) && getInDataState(masterId)[`agency${index + 1}`]) ?? 1}
                                fontSize='20px'
                                margin='0'
                                index={index}
                                callback={handleUserCounterChange}
                            />
                        </Agency>
                    ))
                }
            </Container>
        )
    );
};

export default AgencyConfiguration;
