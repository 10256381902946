import React from 'react'
import Labeled from './Labeled'
import InputItem from './InputItem'

class InputText extends React.Component {
  render () {
    return (
      <Labeled {...this.props}>
        <InputItem key={this.props.name} {...this.props} />
      </Labeled>
    )
  }
}

export default InputText
