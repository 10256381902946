import React from 'react';
import styled from 'styled-components';
import Parent from './Parent';
import ChildrenGroup from './ChildrenGroup';
import Notes from '../Notes';

const Container = styled.div`
    padding: 0 0 4em;
`;

const Node = props => {
    return (
        <Container>
            <Parent {...props.parent}/>
            {
                props.groups.map((group, index) => (
                    <ChildrenGroup
                        key={index}
                        parentId={props.parent.id}
                        {...group}
                    />
                ))
            }
            <Notes
                pathInDataState={props.parent.pathInDataStateForNotes}
                padding='2em 20px 0 20px'
                noLabel
            />
        </Container>
    );
};

export default Node;
