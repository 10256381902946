import React from 'react';
import styled from 'styled-components';
import Child from './Child';
import { getLabel } from '../../Utils/labels';
import { lightGrey } from '../../Utils/styleConstants';

const Container = styled.div`
    padding: 1em;
`;

const Label = styled.p`
    padding: 10px 0 10px 20px;
    font-weight: bold;
    font-size: 0.9em;
    text-transform: uppercase;
    background-color: ${lightGrey};
`;

const ChildListContainer = styled.div`
    padding: 0 20px;
`;

const Footer = styled.div`
    padding: 1em;
    font-size: 1em;
    color: #9d9d9d;
    text-align: center;
    font-style: italic;
`;

const ChildList = ({ parentId, items }) => {
    return (
        <>
            {
                items
                    .filter((child) => child?.display ?? true)
                    .map((child, index) => (
                        <Child
                            key={index}
                            parentId={parentId}
                            {...child}
                        />
                    ))
            }
        </>
    )
}

const ChildrenGroup = props => {
    const footerLabel = getLabel(`${props.id}-footer`);

    return (
        <Container>
            <Label>{getLabel(props.id)}</Label>
            <ChildListContainer>
               <ChildList parentId={props.parentId} items={props.children}/>
            </ChildListContainer>
            {undefined !== footerLabel && '' !== footerLabel && <Footer>{footerLabel}</Footer>}
        </Container>
    );
};

export default ChildrenGroup;
