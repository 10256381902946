import React from 'react';
import styled from 'styled-components';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import PriceElement from '../PriceElement';
import { StyledCheckbox } from '../../Utils/styleConstants';
import { getImage } from '../../Utils/jsonInterpreter';

const Image = styled.img`
    width: ${props => props.withTarif ? '80%' : '100%'};
    
`;

const TarifsStructure = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
`;

const CheckableImagedOption = props => {
    return (
        <StyledCheckbox
            checked={props.checked}
            onClick={() => props.onClick(props.index, props.option)}
        >
            <Image withTarif={props.option.tarifsName} src={getImage(props.option.img)} alt='#' />
            {
                props.option.tarifsName && (
                    <TarifsStructure>
                        <PriceElement tarifsName={props.option.tarifsName} />
                    </TarifsStructure>
                )
            }
            <CheckmarkContainer checked={props.checked} />
        </StyledCheckbox>
    );
};

export default CheckableImagedOption;