import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import TvaUpdater from './TvaUpdater';
import { green } from '../../Utils/styleConstants';
import Price from "./Price";
import {getInDataState} from '../../Utils/storeHelpers';
import PriceDiscounted from "./PriceDiscounted";

moment.locale('fr');

const Container = styled.div`
    background: ${green};
  margin-top: 10px;
`;

const TopRow = styled.div`
    display: grid;
    grid-template-columns: 3fr${({ columns }) => ' 1fr'.repeat(columns)};
    grid-auto-rows: minmax(100px, auto);
`;

const BottomRow = styled.div`
    display: grid;
    grid-template-columns: 3fr${({ columns }) => ' 1fr'.repeat(columns)};
    grid-auto-rows: minmax(100px, auto);
`;

const ContainerTitle = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 30px;
    border-right: 1px solid white;
    background: rgb(255 255 255 / 30%);
`;

const Title = styled.p`
    font-size: 2em;
    font-weight: bold;
    color: white;
`;

const MensualitesTitle = styled.p`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    background: rgb(255 255 255 / 30%);

    &:not(:last-child) {
        border-right: 1px solid white;
    }
`;

const Label = styled.span`
    ${props => props.fontColor && `color: ${props.fontColor};`}
`;

const SubLabel = styled.span`
    ${props => props.fontColor && `color: ${props.fontColor};`}
    font-size: 0.8em;
`;

const LabelContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    padding: 0 30px 0 0;
    border-right: 1px solid white;
`;

const LineElement = styled.div`
    display: flex;
    margin: 6px 0;

    ${props => props.fontColor && `color: ${props.fontColor};`}
    & > span {
        ${props => props.fontColor && `color: ${props.fontColor};`}
    }
`;

const MonthDate = styled.span`
    font-weight: bold;
    text-transform: capitalize;
`;

const getDate = prelevement => {
    const month = prelevement['date-month'] - 1;
    const year = prelevement['date-year'];

    return moment().month(month).year(year).format('MMMM YYYY');
};

const Total = props => {
    const [nbrColumns, setNbrColumns] = useState(null);
    const lbiMedia = getInDataState('offre-lbi-media')

    const prelevement = getInDataState('prelevement');

    useEffect(() => {
        setNbrColumns(props.total.length);
    }, [props.total.length])

    useEffect(() => {
        setNbrColumns(props.totalDiscount.length);
    }, [props.totalDiscount.length])

    return (
        null !== nbrColumns && (
            <div>
                <Container>
                    <TopRow columns={nbrColumns}>
                        <ContainerTitle>
                            <Title>Total</Title>
                            {
                                prelevement ? (
                                    (prelevement?.display ?? true) &&
                                    <p>Mensualité à partir de <MonthDate>{getDate(prelevement)}</MonthDate></p>
                                ) : (
                                    <p>À partir du <MonthDate>{moment().format('MMMM YYYY')}</MonthDate></p>
                                )
                            }
                        </ContainerTitle>
                        {
                            props.total.map((value, key) => {
                                const color = key === props.total.length - 1 ? 'white' : undefined;

                                return (
                                    <MensualitesTitle key={key}>
                                        <Label fontColor={color}>{value.label}</Label>
                                        {value.subLabel && (<SubLabel fontColor={color}>{value.subLabel}</SubLabel>)}
                                    </MensualitesTitle>
                                );
                            })
                        }
                    </TopRow>
                    <BottomRow columns={nbrColumns}>
                        <LabelContainer>
                            <LineElement fontColor='white'>Montant HT </LineElement>
                            <LineElement fontColor='white'>TVA <TvaUpdater />%</LineElement>
                            <LineElement fontColor='white'>Montant TTC</LineElement>
                        </LabelContainer>
                        <Price total={props.total}/>
                    </BottomRow>
                </Container>
                {lbiMedia && (
                    <Container>
                        <TopRow columns={nbrColumns}>
                            <ContainerTitle>
                                <Title>Total remisé</Title>
                                {
                                    prelevement ? (
                                        (prelevement?.display ?? true) &&
                                        <p>Mensualité à partir de <MonthDate>{getDate(prelevement)}</MonthDate></p>
                                    ) : (
                                        <p>À partir du <MonthDate>{moment().format('MMMM YYYY')}</MonthDate></p>
                                    )
                                }
                            </ContainerTitle>
                            {
                                props.totalDiscount.map((value, key) => {
                                    const color = key === props.total.length - 1 ? 'white' : undefined;
                                    return (
                                        <MensualitesTitle key={key}>
                                            <Label fontColor={color}>{value.label}</Label>
                                            {value.subLabel && (<SubLabel fontColor={color}>{value.subLabel}</SubLabel>)}
                                        </MensualitesTitle>
                                    );
                                })
                            }
                        </TopRow>
                        <BottomRow columns={nbrColumns}>
                            <LabelContainer>
                                <LineElement fontColor='white'>Montant HT</LineElement>
                                <LineElement fontColor='white'>TVA <TvaUpdater />%</LineElement>
                                <LineElement fontColor='white'>Montant TTC</LineElement>
                            </LabelContainer>
                            <PriceDiscounted totalDiscount={props.totalDiscount} handleDiscountChange={props.handleDiscountChange} />
                        </BottomRow>
                    </Container>
                )}
            </div>
        )
    );
};

export default Total;
