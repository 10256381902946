import ProductInformation from "./Led/ProductInformation";
import CheckmarkContainer from "../Icons/CheckmarkContainer";
import styled from "styled-components";
import {SimpleCheckbox} from "../../Utils/styleConstants";
import Price from "./Price";
import Checkbox from "../Checkbox/Checkbox";

const CheckableContainer = styled(SimpleCheckbox)`
    display: flex;
    max-height: 300px;
    min-height: 100px;
`;

const InformationContainer = styled.div`
    flex: 0 0 15%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SubTitle = styled.div`
    flex: 0 0 40%;
    display: flex;
    align-items: center;
    border-left: 1px solid #E1E1E1;
`;

const SubTitleText = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
`;

const CheckboxContainer = styled.div`
    flex: 0 0 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #E1E1E1;
`;

const PriceContainer = styled.div`
    flex: 1 0 15%;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 10px;
    padding-bottom: 10px;
`;

const CheckboxOption = ({ title, imageSrc, price = null, isSelected, setSelected }) => {
    return (
        <CheckableContainer checked={isSelected}>
            <InformationContainer>
                <ProductInformation imageSrc={imageSrc}/>
            </InformationContainer>
            <SubTitle>
                <SubTitleText>{title}</SubTitleText>
            </SubTitle>
            <CheckboxContainer>
                <Checkbox isSelected={isSelected} onChange={setSelected}/>
            </CheckboxContainer>
            {price &&
                <PriceContainer>
                    <Price value={price} />
                </PriceContainer>
            }
            <CheckmarkContainer checked={isSelected} notBottomCheckmark={true}/>
        </CheckableContainer>
    )
}

export default CheckboxOption;
