import { connect } from 'react-redux'
import Menu from '../Components/Menu'
import { grey } from '../Utils/styleConstants'
import { mapStateToPropsAddable } from '../Utils/mapToProps'

function getBloc(extended, id) {
    const normal = {
        titre: {
            type: 'InputTextContainer',
            name: `${id}/titre`,
            label: 'titre',
            labelTitle: true
        },
        dest: {
            type: 'SelectContainer',
            name: `${id}/dest`,
            label: 'dest',
            options: ['menu-redac', 'menu-form', 'menu-listing']
        }
    }
    const extendedObj = {
        label: {
            type: 'LabelAdd',
            label: 'textexpl',
            color: `${grey}`,
            fontWeight: 'normal',
            fontSize: 'initial'
        },
        texte: {
            type: 'TextAreaContainer',
            name: `${id}/texte`,
            placeholder: 'Votre texte explicatif ...'
        }
    }
    return extended === true ? {...normal, ...extendedObj} : normal
}

const getSpans = extended => extended === true ? ['label', 'texte'] : []

const mapStateToProps = (state, ownProps) => ({
    ...mapStateToPropsAddable(state, ownProps),
    gridElements: ['titre', 'dest'],
    spanElements: getSpans(ownProps.extended),
    max: ownProps.max,
    min: ownProps.min,
    refs: id => getBloc(ownProps.extended, id)
})

export default connect(
    mapStateToProps
)(Menu)

