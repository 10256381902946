import CheckboxGroup from "../Checkbox/CheckboxGroup";
import styled from "styled-components";
import ProductInformation from "./Led/ProductInformation";
import CheckmarkContainer from "../Icons/CheckmarkContainer";
import {SimpleCheckbox} from "../../Utils/styleConstants";
import CheckboxCounter from "../Checkbox/CheckboxCounter";
import Price from "./Price";

const CheckableContainer = styled(SimpleCheckbox)`
    display: flex;
  
`;

const InformationContainer = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 10%;
`;

const SubTitle = styled.div`
    display: flex;
    flex: 0 0 15%;
    align-items: center;
    border-left: 1px solid #E1E1E1;
`;

const SubTitleText = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
`;

const GroupContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    flex: ${({ $size }) => $size ? `1 0 ${$size}%` : "1 0 33.333%" };
    padding: 10px;
    gap: 15px;
`

const useCountableOptions = (selectedOptions = [], setSelectedOptions) => {
    const selected = Object.keys(selectedOptions);

    const handleSelected = (options) => {
        if (!setSelectedOptions) {
            return;
        }

        const initialSelected = Object.keys(selectedOptions);

        if (options.length > initialSelected.length) {
            const selectedOption = options.filter(option => !initialSelected.includes(option))[0];
            setSelectedOptions({ ...selectedOptions, [selectedOption]: 1 })
            return;
        }

        setSelectedOptions(
            Object.fromEntries(
                Object.entries(selectedOptions).filter(([key, _]) => options.includes(key))
            )
        )
    }

    const handleCountChange = (option, counterValue) => {
        if (!setSelectedOptions) {
            return;
        }

        setSelectedOptions({ ...selectedOptions, [option]: counterValue });
    }

    return { selected, handleSelected, handleCountChange };
}

const CounterOptionsGrid = ({
    title,
    imageSrc,
    selectableOptions,
    selectedOptions,
    setSelectedOptions,
    $gridDivision
}) => {
    const {
        selected,
        handleSelected,
        handleCountChange
    } = useCountableOptions(selectedOptions, setSelectedOptions);

    const isOptionChecked = selected.length >= 1;

    return (
        <CheckableContainer checked={isOptionChecked}>
            <InformationContainer>
                <ProductInformation imageSrc={imageSrc}/>
            </InformationContainer>
            <SubTitle>
                <SubTitleText>{title}</SubTitleText>
            </SubTitle>
            <CheckboxGroup value={selected} onValueChange={handleSelected} GroupContainer={GroupContainer}>
                {selectableOptions.map((option) =>
                    <Box key={option.value} $size={100/ ($gridDivision ?? 3)}>
                        <label>{option.title}</label>
                        <CheckboxCounter
                            checkboxProps={{value:option.value}}
                            counterProps={{
                                onCountChange: (count) => handleCountChange(option.value, count),
                                initialValue: selectedOptions[option.value] ?? 1,
                                min: 1,
                            }}
                        />
                        <Price value={option.price} />
                    </Box>
                )}
            </CheckboxGroup>
            <CheckmarkContainer checked={isOptionChecked} notBottomCheckmark={true}/>
        </CheckableContainer>
    )
}

export default CounterOptionsGrid;
