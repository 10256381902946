import styled from "styled-components";
import ScreenChoice from "./ScreenChoice";
import {setInDataState} from "../../../Utils/storeHelpers";
import {useSelector} from "react-redux";
import {get} from "../../../Utils/object";

const Container = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    padding: 10px;
`;

const ITEMS_PATH = 'offre-lbi-media.screen.items';

const options = [
    {
        title: "Simple",
        type: "simple",
        imageSrc: "/static/img/lbi-media/screen/pied-simple.png"
    },
    {
        title: "Prestige",
        type: "prestige",
        imageSrc: "/static/img/lbi-media/screen/pied-prestige.png"
    }
]

const ScreenChoices = () => {
    const items = useSelector(({ data }) => get(data, ITEMS_PATH, []));

    const handleCountChange = (type, value) => {
        const itemsOfType = items.filter(x => x.type === type);

        if (value > itemsOfType.length) {
            setInDataState(ITEMS_PATH, [...items, { type: type, orientation: 'portrait', inch: 'inch-46', cpl: false, configuration: true }]);
        } else {
            const draftState = [...items];
            const index = draftState.findLastIndex(x => x.type === type);

            if (index > -1) {
                draftState.splice(index, 1);
            }

            setInDataState(ITEMS_PATH, draftState);
        }
    }

    return (
        <Container>
            {options.map((option, _) =>
                <ScreenChoice
                    counterInitialValue={items.filter(x => x.type === option.type).length ?? 0}
                    key={option.title}
                    type={option.type}
                    title={option.title}
                    imageSrc={option.imageSrc}
                    onCountChange={handleCountChange}
                />
            )}
        </Container>
    )
}

export default ScreenChoices;
