import styled from "styled-components";
import React, { useCallback, useEffect, useState } from "react";
import { FakeInputPrice } from "../../Utils/styleConstants";

const InputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
`;

const PriceDiscountedUpdater = (props) => {
    const WAIT_INTERVAL = 2000

    const [price, setPrice] = useState(props.value.price);
    const [timer, setTimer] = useState(undefined);

    useEffect(() => {
        setPrice(props.value.price);
    }, [props.value.price]);

    const handleChangeValueHT = useCallback((newValue) => {
        const parsedNewValue = parseFloat(newValue);

        if (!parsedNewValue || isNaN(parsedNewValue)) {
            return;
        }

        clearTimeout(timer);
        setPrice(parsedNewValue);

        setTimer(
            setTimeout( async () => {
                await props.handleDiscountChange(props.value.label, props.value?.subLabel, parsedNewValue);
            }, WAIT_INTERVAL)
        );
    }, [props.value, timer]);

    return (
        <InputContainer>
            <FakeInputPrice
                type="text"
                value={price}
                cursor="pointer"
                width={
                    0 === price.toString().length
                        ? "1ch"
                        : `${price.toString().length}ch`
                }
                onChange={(event) => handleChangeValueHT(event.target.value)}
            />
        </InputContainer>
    );
};

export default PriceDiscountedUpdater;
