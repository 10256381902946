import React, { useEffect } from 'react';
import styled from 'styled-components';
import PriceElement from './PriceElement';
import AgencyConfiguration from './AgencyConfiguration';
import PromoTag from './PromoTag';
import PromoFnaimTag from './PromoFnaimTag';
import personnalisationHektor from '../Images/HK_offert.png';
import logoHektor from '../Images/logo-hektor.png';
import { getLabel } from '../Utils/labels';
import { manualDependsOnTarifsInfo } from '../Utils/jsonInterpreter';
import {
    SubscriptionContainer,
    SubscriptionStructure,
    SubscriptionInsteadOf,
    SubscriptionLogo,
    SubscriptionOfferName,
    SubscriptionCommissioning,
    SubscriptionAdditionalUser
} from '../Utils/styleConstants';
import {
    setInDataState,
    getHektorTypeFromHektorOffer,
    getInTarifsState
} from '../Utils/storeHelpers';

const ImageContainer = styled.div`
  padding: 0 5% 0 0;
`

const Image = styled.img`
  object-fit: fill;
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - 518.88px);
`;

const LogoStructure = styled.div`
    display: flex;
    flex-direction: column;
`;

const LabelInStructure = styled.p`
    order: -1;
    white-space: nowrap;
`;

const AgenciesConfiguration = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1em 1.5%;
`;

const AbonnementComplex = props => {
    // Use of redux
    const hektorType = getHektorTypeFromHektorOffer(props.hektorOffer);
    const tarifsHektorType = getInTarifsState(hektorType);
    const tarifsInfo = manualDependsOnTarifsInfo(tarifsHektorType, props.hektorOffer);

    // useEffect when component is mounting
    useEffect(() => {
        // Set commissioning in dataState
        props.commissioningName && setInDataState(`${props.pathInDataState}.${props.commissioningName}`, true);
    }, [props.pathInDataState ,props.commissioningName])

    return (
        <SubscriptionContainer>
            <ImageContainer>
                <Image src={personnalisationHektor} alt='Personnalisation offerte' />
            </ImageContainer>
            <Content>
                <LogoStructure>
                    <SubscriptionLogo src={logoHektor} alt="Logo Hektor" />
                    <SubscriptionOfferName>{props.offerName}</SubscriptionOfferName>
                </LogoStructure>
                {
                    tarifsInfo && (
                        <SubscriptionStructure>
                            <PriceElement
                                tarifsName={hektorType}
                                dependsOn={props.hektorOffer}
                                fontSize='6em'
                            />
                            {
                                tarifsInfo.insteadOf && typeof tarifsInfo.insteadOf !== 'object' && (
                                    <SubscriptionInsteadOf>{`Au lieu de ${tarifsInfo.insteadOf} € ${tarifsInfo.priceUnit}`}</SubscriptionInsteadOf>
                                )
                            }
                            {
                                tarifsInfo.nbrOfUsers && typeof tarifsInfo.nbrOfUsers !== 'object' && (
                                    <p>{`Compte Agence pour ${tarifsInfo.nbrOfUsers} utilisateurs`}</p>
                                )
                            }
                        </SubscriptionStructure>
                    )
                }
                {
                    props.additionalUsers && (
                        <SubscriptionAdditionalUser>
                            <LabelInStructure>Utilisateur(s) supplémentaire(s)</LabelInStructure>
                            <PriceElement
                                tarifsName={props.additionalUsers}
                                dependsOn={hektorType}
                            />
                        </SubscriptionAdditionalUser>
                    )
                }
                {
                    props.commissioningName && (
                        <SubscriptionCommissioning>
                            <LabelInStructure>{getLabel(props.commissioningName)}</LabelInStructure>
                            <PriceElement
                                tarifsName={props.commissioningName}
                                dependsOn={props.hektorOffer}
                            />
                        </SubscriptionCommissioning>
                    )
                }
            </Content>
            <AgenciesConfiguration>
                <AgencyConfiguration
                    type='Succursale'
                    pathInDataState={props.pathInDataState}
                    hektorOffer={props.hektorOffer}
                    dataKey='agencies-succursale'
                    tarifsKey='hektor-agencies-succursale'
                />
                <AgencyConfiguration
                    type='Indépendant'
                    pathInDataState={props.pathInDataState}
                    hektorOffer={props.hektorOffer}
                    dataKey='agencies-independant'
                    tarifsKey='hektor-agencies-independant'
                />
                <AgencyConfiguration
                    type='Franchise'
                    pathInDataState={props.pathInDataState}
                    hektorOffer={props.hektorOffer}
                    dataKey='agencies-franchise'
                    tarifsKey='hektor-agencies-franchise'
                />
            </AgenciesConfiguration>
            {
                props.promoTag && (
                    <PromoTag
                        tarifsName={`${hektorType}-promotion`}
                        promotionId={props.hektorOffer}
                        {...props.promoTag}
                    />
                )
            }
            {
                props.promoFnaimTag && (
                    <PromoFnaimTag {...props.promoFnaimTag} />
                )
            }
        </SubscriptionContainer>
    );
};

export default AbonnementComplex;
