import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Toggle from '../Toggle';
import Counter from '../Counter';
import { UPDATE_DATA_VALUE } from '../../Store/actions';
import { getLabel } from '../../Utils/labels';
import { FinalPriceOption, FinalPriceOptionSpecial, borderGrey } from '../../Utils/styleConstants';
import { deleteInDataState, getInDataState, setFinalPriceInTarifsState } from '../../Utils/storeHelpers';
import { formatPrice } from '../../Utils/price';
import { getTarifsInfoByTarifsName, manualDependsOnTarifsInfo } from '../../Utils/jsonInterpreter';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${props => props.width};
    padding: 2% 0;
    border-left: 1px solid ${borderGrey};
`;

const ToggleStructure = styled.div`
    padding: 15px 0;
`;

const ModeloSuppOptionHektor = props => {
    // Use of hook
    const [toggle, setToggle] = useState(null);

    // Retrieve info about tarifs
    let tarifsInfo = getTarifsInfoByTarifsName(props.tarifsName);
    tarifsInfo = manualDependsOnTarifsInfo(tarifsInfo, props.dependsOn);

    // useEffect when component is mounting
    useEffect(() => {
        // The toggle of the option depends on existance of a dataState property
        if (null === toggle) {
            true === tarifsInfo.includedIn ?
                setToggle(true)
                : setToggle(undefined !== getInDataState(`${props.pathInDataState}.${props.name}`));
        }
    }, [props.pathInDataState, props.name, toggle, tarifsInfo, props.tarifsName])

    // useEffect whenever toggable props changes and the option is toggled
    useEffect(() => {
        if (false === props.toggable && true === toggle) {
            // Means that "parent" has been toggled off so we also need to toggle off this option
            // First we delete the property key in dataState
            deleteInDataState(`${props.pathInDataState}.${props.name}`);
            // And toogle hook to false
            setToggle(false);
        }
    }, [props.pathInDataState, props.name, props.toggable, toggle])

    // Callback used when user change the toggle of the option
    const handleToggle = useCallback((value) => {
        // Only work if the option is toggable
        if (props.toggable) {
            // Delete the property key in dataState if toggle is off
            false === value && deleteInDataState(`${props.pathInDataState}.${props.name}`);
            // On each cases we change the toggle
            setToggle(value);
        }
    }, [props.pathInDataState, props.name, props.toggable])

    // useEffect whenever toggle hook changes and it's not null
    useEffect(() => {
        false === toggle && setFinalPriceInTarifsState(props.name.replace(props.optionSuffix, ''), null, undefined, props.partOfId);
    }, [props.name, props.partOfId, props.optionSuffix, toggle]);

    // Callback used when the counter change
    const handleCounterChange = useCallback(() => {
        // Whenever the user change the counter, we reset the finalPrice
        setFinalPriceInTarifsState(props.name.replace(props.optionSuffix, ''), null, undefined, props.partOfId);
    }, [props.name, props.partOfId, props.optionSuffix]);

    return (
        null !== toggle && (
            <Container width={props.width}>
                <p>{getLabel(props.name)}</p>
                <ToggleStructure>
                    <Toggle
                        action={UPDATE_DATA_VALUE}
                        masterId={props.pathInDataState}
                        name={props.name}
                        value={toggle}
                        callback={() => handleToggle(!toggle)}
                    />
                </ToggleStructure>
                {
                    isNaN(tarifsInfo.price) ? (
                        <FinalPriceOptionSpecial>
                            {tarifsInfo.price}
                        </FinalPriceOptionSpecial>
                    ) : (
                        <FinalPriceOption>
                            {`${formatPrice(tarifsInfo.price)} ${tarifsInfo.priceUnit.toUpperCase()}`}
                        </FinalPriceOption>
                    )
                }
                {
                    toggle && (
                        <Counter
                            masterId={props.pathInDataState}
                            name={props.name}
                            min={1}
                            initialValue={getInDataState(`${props.pathInDataState}.${props.name}`) ?? 1}
                            margin='2vh 0 0 0'
                            callback={handleCounterChange}
                        />
                    )
                }
            </Container>
        )
    );
};

export default ModeloSuppOptionHektor;
