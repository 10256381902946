import styled from "styled-components";
import {borderGrey, SimpleCheckbox } from "../../../Utils/styleConstants";
import {getImage} from "../../../Utils/jsonInterpreter";
import CheckmarkContainer from "../../Icons/CheckmarkContainer";
import React from "react";
import Switch from "../../Switch/Switch";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    gap: 20px;
`

const Header = styled.div`
    display: flex;
    width: 100%;
    font-size: 13px;
    align-items: center;
`

const HeaderContent = styled.div`
    flex: 1 0 30%;
`

const HeaderOptions = styled.div`
    display: flex;
    flex: 1 0 70%;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
`

const OptionsContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    height: 90%;
    width: 100%;
    padding: 10px;
`;

const OptionContainer = styled.div`
    display: flex;
    flex-flow: column;
    width: 45%;
    height: 250px;
    justify-content: flex-end;
`

const CheckableContainer = styled(SimpleCheckbox)`
    display: inline-flex;
    border: 1px solid ${borderGrey};
    border-radius: 3px;
    max-width: 100%;
    max-height: 80%;
    justify-content: center;  
    padding: 10px;
`

const Image = styled.img`
    max-width: 100%;
`

const Title = styled.p`
    height: 20%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Option = ({ onClick, checked = false, imgSrc }) => {
    return (
        <CheckableContainer onClick={onClick} checked={checked}>
            <Image src={getImage(imgSrc)}/>
            <CheckmarkContainer checked={checked}/>
        </CheckableContainer>
    )
}

const options = [
    {
        value: 'portrait',
        imgSrc: '/static/img/lbi-media/screen/portrait.png',
        title: 'Portait'
    },
    {
        value: 'paysage',
        imgSrc: '/static/img/lbi-media/screen/paysage.png',
        title: 'Paysage'
    },
]

const ScreenOrientation = ({ onPlayerSelected, playerSelected, onSettingsSelected, settingSelected, onClick, selected = null}) => {
    return (
        <Container>
            <Header>
                <HeaderContent>Orientation de l'écran : </HeaderContent>
                <HeaderOptions>
                    {onPlayerSelected &&
                        <>
                            <p>Player</p>
                            <Switch onChange={onPlayerSelected} checked={playerSelected}/>
                        </>
                    }
                    {onSettingsSelected &&
                        <>
                            <p>Installation et paramétrage</p>
                            <Switch onChange={onSettingsSelected} checked={settingSelected}/>
                        </>
                    }
                </HeaderOptions>
            </Header>
            <OptionsContainer>
                {options.map((option, index) =>
                    <OptionContainer key={option.value}>
                        <Option checked={option.value === selected} onClick={() =>  onClick?.(option.value, index)} imgSrc={option.imgSrc} />
                        <Title>{option.title}</Title>
                    </OptionContainer>
                )}
            </OptionsContainer>
        </Container>
    )
}

export default ScreenOrientation;
