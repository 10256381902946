import { connect } from 'react-redux'
import Menu from '../Components/Menu'
import { mapStateToPropsAddable } from '../Utils/mapToProps'

const mapStateToProps = (state, ownProps) => ({
    ...mapStateToPropsAddable(state, ownProps),
    gridElements: ['label','blank','picto', 'titre'],
    spanElements: [],
    min: 2,
    refs: id => ({
        label: {
            type: 'LabelAdd',
            label: 'picto-order'
        },
        blank: {
            type: 'Blank'
        },
        picto: {
            type: 'InputTextContainer',
            name: `${id}/picto`,
            label: 'picto'
        },
        titre: {
            type: 'InputTextContainer',
            name: `${id}/titre`,
            label: 'titre'
        }
    })
})

export default connect(
    mapStateToProps
)(Menu)

