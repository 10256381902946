import React, {useEffect} from 'react';
import moment from 'moment';
import styled from 'styled-components';
import Currency from './Currency';
import PriceDiscountedUpdater from "./PriceDiscountedUpdater";

moment.locale('fr');

const PriceContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-weight: bold;

    &:not(:last-child) {
        border-right: 1px solid white;
    }
`;

const LineElement = styled.div`
    display: flex;
    margin: 6px 0;

    ${props => props.fontColor && `color: ${props.fontColor};`}
    & > span {
        ${props => props.fontColor && `color: ${props.fontColor};`}
    }
`;

const BlankLine = styled.span`
    height: 19px
`;

const PriceDiscounted = props => {

    const isDeposit = (label) => {
        return 'acompte' === label;
    };

    return (
        props.totalDiscount.map((value, key) => {
            const color = key === props.totalDiscount.length - 1 ? 'white' : undefined;

            return (
                <PriceContainer key={key}>
                    <LineElement fontColor={color}>
                        {!isDeposit(value.label) ? <PriceDiscountedUpdater value={value} handleDiscountChange={props.handleDiscountChange} /> : <BlankLine/> }
                        €
                    </LineElement>
                    <LineElement fontColor={color}>
                        {!isDeposit(value.label) ? <Currency value={value.tva}/> : <BlankLine/>}
                    </LineElement>
                    <LineElement fontColor={color}>
                        <Currency value={ value.price + value.tva}/>
                    </LineElement>
                </PriceContainer>
            );
        })
    );
};

export default PriceDiscounted;
