import React from 'react';
import styled from 'styled-components';
import { getTarifsInfoByTarifsName } from '../Utils/jsonInterpreter';
import { getInDataState } from '../Utils/storeHelpers';

const Information = styled.p`
    padding: 0.5em 0;
    font-size: 0.9em;
    text-align: center;
`;

const Bold = styled.span`
    padding: 0.5em 0 1em;
    font-weight: bold;
    font-size: 0.9em;
    text-align: center;
`;

const TraductionsInformation = props => {
    const tarifsInfo = getTarifsInfoByTarifsName(props.tarifsName);
    const siteMode = getInDataState('offre-site.mode');
    const offreSiteName = getInDataState(`offre-site.offre-site-${siteMode}`).replace('-location','').replace('-vente','');
    const nbLangIncluded = tarifsInfo.freeLimit[offreSiteName];

    return (
        null !== tarifsInfo && (
            <>
                <Information>Il s'agit de traductions automatiques fournies par Google. Les résultats obtenus peuvent être approximatifs.</Information>
                <Information>Pour toute autre langue, veuillez en faire la demande, celle-ci sera étudiée par le service technique.</Information>
                <Bold>{`${nbLangIncluded > 1 ? nbLangIncluded + ' langues incluses' : nbLangIncluded + ' langue incluse'} puis ${tarifsInfo.price}€ HT ${tarifsInfo.priceUnit} par langue supplémentaire.`}</Bold>
            </>
        )
    );
};

export default TraductionsInformation;
