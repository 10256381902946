import React from "react";
import { beautifulGetLabel } from "../Utils/labels";
import styled from "styled-components";
import { green } from "../Utils/styleConstants";

const Title = styled.p`
    color: ${props => (props.color ?? green)};
    padding: ${props => (props.bottomer ? " 0 0 0.7em 0" : "0.7em")};
    font-weight: ${props => (props.fontWeight ?? "bold")};
    font-size: ${props => (props.fontSize ?? "1.2em")};
`;

const LabelAdd = (props) => (
    <Title {...props}>
        {beautifulGetLabel(props)}
    </Title>
);

export default LabelAdd;
