import React from "react";
import styled from "styled-components";
import {BackgroundContainer, borderGrey } from "../../../Utils/styleConstants";
import {getImage} from "../../../Utils/jsonInterpreter";
import Counter from "../Counter";

const Container = styled(BackgroundContainer)`
    display: flex;
    flex-flow: row;
    width: 35%;
    height: 250px;
    border: 1px solid ${borderGrey};
    border-radius: 3px;
    margin: 0 20px;
`

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
`

const ScreenCountContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`

const LeftContainer = styled.div`
    display: flex;
    justify-content: center;
`

const Image = styled.img`
    display: block;
    margin: auto;
    height: 80%;
    transform: scaleX(-1);
`;

const TitleBold = styled.p`
    font-size: 30px;
    font-weight: bold;
`;

const ScreenChoice = ({ type, counterInitialValue, onCountChange, title, imageSrc }) => {
    return (
        <Container>
            <LeftContainer>
                <Image src={getImage(imageSrc)}/>
            </LeftContainer>
            <RightContainer>
                <TitleBold>
                    {title}
                </TitleBold>
                <ScreenCountContainer>
                    <p> Nombre d'écran(s) </p>
                    <Counter initialValue={counterInitialValue} onCountChange={(v) => onCountChange(type, v)}/>
                </ScreenCountContainer>
            </RightContainer>
        </Container>
    )
}

export default ScreenChoice;
