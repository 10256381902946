import initialState from '../initialState';
import { getPriceFromNdd, isValidNdd, sortFinalPrices } from '../../Utils/price';
import { beautifulGetLabel, getAgencyName } from '../../Utils/labels';
import {
    UPDATE_TARIFS,
    CHANGE_PRICE,
    LOAD_DOMAIN_NAMES,
    LOAD_GATEWAYS,
    LOAD_TRADUCTIONS,
    LOAD_AGENCIES,
    DELETE_FINALPRICE,
    ADD_IN_FINALPRICES,
    LOAD_HEBERGEMENT,
    RESET_FINALPRICE,
    LOAD_EMAILS,
    LOAD_DOMAINS,
    LOAD_CONTENT_PAGE,
    LOAD_CRO_CONTENT_PAGE,
    ADD_FINANCING_PRICE,
    RESET_FINALPRICES
} from '../actions';
import {isFnaimOffer} from "../../Utils/storeHelpers";
import computePrice from "../../Utils/financing";

const tarifs = (state = initialState.tarifs, action) => {
    const newState = { ...state };

    switch (action.type) {
        case UPDATE_TARIFS:
            return {
                ...state,
                ...action.value,
            };

        case ADD_IN_FINALPRICES: {
            const tarifsName = action.payload.tarifsName;
            const item = action.payload.item;
            const currentTarif = { ...state[tarifsName] };
            const isForcedTarif = undefined !== item.isForcedTarif && true === item.isForcedTarif;

            if (!currentTarif.finalPrices.some(value => value.id === item.id)) {
                currentTarif.finalPrices = [ ...currentTarif.finalPrices, item ];
            }

            if (isForcedTarif) {
                currentTarif.finalPrices[0] = item;
            }

            newState[tarifsName] = currentTarif;

            return newState;
        }

        case ADD_FINANCING_PRICE: {
            const tariffName = action.payload.tariffName;
            const finalPriceName = action.payload.name;
            const price = action.payload.price;
            const financingDuration = action.payload.financingDuration;
            const financingName = action.payload.financingName;
            const id = action.payload.id;
            const displayWhenParent = action.payload.displayWhenParent;
            const floor = action.payload.floor ?? false;

            const currentTariff = { ...state[tariffName] };
            currentTariff.hasFinancing = true;

            const financingTariffName = `${tariffName}-financing`;

            if (!(financingTariffName in newState)) {
                const financingTariff = { ...state[tariffName] };
                financingTariff.finalPrices = [];
                financingTariff.id = financingTariffName;
                financingTariff.priceUnit = ` / Mois`;
                financingTariff.periodicities = [ "leasing" ];
                financingTariff.display = action.payload.display;
                financingTariff.displayInOrder = action.payload.displayInOrder;

                newState[financingTariffName] = financingTariff;
            }

            const financingTariff = { ...newState[financingTariffName] };
            const finalPriceTariffId = `${id}-financing`;
            const financingFinalPrices = financingTariff.finalPrices;
    
            if (!financingFinalPrices.some(value => value.id === finalPriceTariffId)) {
                financingTariff.finalPrices =  [ ...financingFinalPrices, {
                    id: finalPriceTariffId,
                    name: financingName ?? `${finalPriceName} - mensualité de ${financingDuration} mois`,
                    price: price === 'inclus' 
                        ? 'inclus'
                        : (floor ? Math.floor(computePrice(price, financingDuration)) : computePrice(price, financingDuration)),
                    floor: floor,
                    financingDuration: financingDuration,
                    display: action.payload.display,
                    displayInOrder: action.payload.displayInOrder,
                    displayWhenParent
                }];
            } else {
                let index = financingFinalPrices.findIndex(value => value.id === finalPriceTariffId);

                financingTariff.finalPrices[index] = {
                    ...financingFinalPrices[index],
                    name: financingName ?? `${finalPriceName} - mensualité de ${financingDuration} mois`,
                    price: price === 'inclus' 
                        ? 'inclus'
                        : (floor ? Math.floor(computePrice(price, financingDuration)) : computePrice(price, financingDuration)),
                    floor: floor,
                    financingDuration: financingDuration,
                    display: action.payload.display,
                    displayInOrder: action.payload.displayInOrder,
                    displayWhenParent
                }
            }

            const currentFinalPrices = currentTariff.finalPrices;

            if (!currentFinalPrices.some(value => value.id === id)) {
                currentTariff.finalPrices =  [ ...currentFinalPrices, {
                    id: id,
                    name: finalPriceName,
                    price: price,
                    notInTotal: true,
                    financingTarif: financingTariffName,
                    displayWhenParent,
                }];
            }

            newState[financingTariffName] = financingTariff;
            newState[tariffName] = currentTariff;

            return newState;
        }

        case CHANGE_PRICE: {
            const tarifsName = action.payload.tarifsName;
            const value = action.payload.value;
            const index = action.payload.index;
            const partOfId = action.payload.partOfId;
            const onlyIfNull = action.payload.onlyIfNull;
            const priceUnitInRecap = action.payload.priceUnitInRecap;
            const discount = action.payload.discount;

            const currentPrice = { ...state[tarifsName] };

            if (currentPrice.hasOwnProperty('finalPrices')) {
                if (null === value) {
                    if (undefined !== index) {
                        const finalPrice = currentPrice.finalPrices[index];

                        currentPrice.finalPrices = currentPrice.finalPrices
                            .filter((_, itemIndex) => itemIndex !== index);

                        if (finalPrice.financingTarif) {
                            const financingTariff = newState[`${tarifsName}-financing`];
                            financingTariff.finalPrices = financingTariff.finalPrices
                                .filter((_, itemIndex) => itemIndex !== index);
                        }
                    } else {
                        currentPrice.finalPrices = undefined !== partOfId ?
                             currentPrice.finalPrices.filter((item) => {
                                 return !item.id.includes(partOfId);
                             })
                            : [];
                    }
                } else if (undefined !== index) {
                    const finalPrice = currentPrice.finalPrices[index];

                    if (finalPrice.financingTarif) {
                        const financingTariff = newState[`${tarifsName}-financing`];
                        const financingFinalPrice = financingTariff.finalPrices[index];
                        const floor = financingFinalPrice.floor ?? false;

                        financingTariff.finalPrices[index] = {
                            ...financingFinalPrice,
                            price: floor 
                                ? Math.floor(computePrice(value, financingFinalPrice.financingDuration))
                                : computePrice(value, financingFinalPrice.financingDuration)
                        }
                    }

                    currentPrice.finalPrices[index].price = value;
                }
            }

            if (currentPrice.hasOwnProperty('finalPrice')) {
                if (true === onlyIfNull && null === currentPrice.finalPrice) {
                    currentPrice.finalPrice = value;
                } else if (!onlyIfNull) {
                    currentPrice.finalPrice = value;
                }
            }
            
            if (priceUnitInRecap && currentPrice.hasOwnProperty('priceUnitInRecap')) {
                currentPrice.priceUnitInRecap = priceUnitInRecap;
            }

            if (discount) {
                currentPrice.discount = discount;
            }

            newState[tarifsName] = currentPrice;

            return newState;
        }

        case LOAD_TRADUCTIONS: {
            const key = action.payload.key;
            const listOfTraductions = action.payload.list;
            const mode = action.payload.mode;
            const siteName = action.payload.siteName;
            const price = state[key].price[mode];
            const finalPrices = state[key].finalPrices;
            const freeLimit = state[key].freeLimit[siteName];

            /* For each traduction on my data list
            * Check if the traduction already exists on my tarifs
            * If not, add it with price
            */
            listOfTraductions.forEach(traduction => {
                if (!finalPrices.some(finalPrice => finalPrice.name === traduction)) {
                    let newTraduction = {};

                    newTraduction.label = traduction;
                    newTraduction.name = traduction;
                    newTraduction.price = newState[key].finalPrices.length >= freeLimit ? price : 'inclus';

                    newState[key].finalPrices = [ ...newState[key].finalPrices, newTraduction ];
                }
            });
            /* *********************************************** */

            /* For each traduction on my tarifs
            * Check if the traduction still exists on my data list of traduction
            * If not remove it from tarifs
            */
            finalPrices.forEach((finalePrice, index) =>
                !listOfTraductions.some(traduction => traduction === finalePrice.name) &&
                    newState[key].finalPrices.splice(index, 1)
            );
            /* *********************************************** */

            // Considering the priceUnitInRecap and periodicities depends on siteMode
            // We specify them here
            newState[key].priceUnitInRecap = 'location' === mode ? '/ Mois' : '';

            return newState;
        }

        case LOAD_DOMAIN_NAMES: {
            const key = action.payload.key;
            const listOfNdd = action.payload.list;
            const mode = action.payload.mode;
            const prices = state[key].price;
            const finalPrices = state[key].finalPrices;

            /* For each ndd on my data list
            * Check if the ndd already exists on my tarifs
            * If not, add it with price
            */
            listOfNdd.forEach(domainName => {
                if (
                    !finalPrices.some(finalPrice => finalPrice.name === domainName.name)
                    && isValidNdd(prices[mode], domainName.name)
                    && 'non-gere-ext' !== domainName.action.value
                ) {
                    let newDomainName = {};

                    newDomainName = { ...domainName };
                    newDomainName.price = getPriceFromNdd(prices[mode], domainName.name);

                    newState[key].finalPrices = [ ...newState[key].finalPrices, newDomainName ];
                }
            });
            /* *********************************************** */

            /* For each ndd on my tarifs
            * Check if the ndd still exists on my data list of ndd
            * If not remove it from tarifs
            */
            finalPrices.forEach((finalePrice, index) => {
                if (
                    !listOfNdd.some(domainName => domainName.name === finalePrice.name) || !listOfNdd.some(domainName => domainName.action.value === finalePrice.action.value)
                ) {
                    newState[key].finalPrices.splice(index, 1);
                }
            });
            /* *********************************************** */

            // Considering the priceUnitInRecap and periodicities depends on siteMode
            // We specify them here
            newState[key].priceUnitInRecap = 'location' === mode ? '/ Mois' : '/ An';
        
            return newState;
        }

        case LOAD_GATEWAYS: {
            const key = action.payload.key;
            const listOfGateways = action.payload.list;
            const price = state[key].price;
            const finalPrices = state[key].finalPrices;

            /* For each gateways on my data list
            * Check if the gateway already exists on my tarifs
            * If not, add it with base price
            */
            listOfGateways.forEach(gateway => {
                if (!finalPrices.some(finalPrice => finalPrice.name === gateway) && gateway !== '') {
                    let newGateway = {};

                    newGateway.name = gateway;
                    newGateway.price = price;

                    newState[key].finalPrices = [ ...newState[key].finalPrices, newGateway ];
                }
            });
            /* *********************************************** */

            /* For each gateways on my tarifs
            * Check if the gateway still exists on my data list of gateways
            * If not remove it from tarifs
            */
            finalPrices.forEach((finalePrice, index) =>
                !listOfGateways.some(gateway => gateway === finalePrice.name) &&
                    newState[key].finalPrices.splice(index, 1)
            );
            /* *********************************************** */

            return newState;
        }

        case LOAD_EMAILS: {
            const key = action.payload.key;
            const listOfEmails = action.payload.list;
            const price = state[key].price;
            const finalPrices = state[key].finalPrices;

            /* For each emails on my data list
            * Check if the email already exists on my tarifs
            * If not, add it with base price
            */
           listOfEmails.forEach(email => {
                if (!finalPrices.some(finalPrice => finalPrice.name === email) && email !== '') {
                    let newEmail = {};

                    newEmail.name = email;
                    newEmail.price = price;

                    newState[key].finalPrices = [ ...newState[key].finalPrices, newEmail ];
                }
            });
            /* *********************************************** */

            /* For each emails on my tarifs
            * Check if the email still exists on my data list of emails
            * If not remove it from tarifs
            */
            finalPrices.forEach((finalePrice, index) =>
                !listOfEmails.some(email => email === finalePrice.name) &&
                    newState[key].finalPrices.splice(index, 1)
            );
            /* *********************************************** */

            return newState;
        }

        case LOAD_AGENCIES: {
            const key = action.payload.key;
            const listOfAgencies = action.payload.list;
            const hektorType = action.payload.type;
            const hektorOffer = action.payload.offer;
            const additionalUserPrice = action.payload.additionalUserPrice;
            const price = state[hektorType].price[hektorOffer];
            const addUsersKey = isFnaimOffer(hektorOffer) ? 'hektor-additional-users-fnaim' : 'hektor-additional-users';
            const priceAddUsers = state[addUsersKey].price[hektorType];
            const nbrOfUsers = state[hektorType].nbrOfUsers[hektorOffer];
            const finalPrices = state[key].finalPrices;

            /* For each agencies on my data list
            * Check if the agency already exists on my tarifs
            * If not, add it with related price
            */
            Object.keys(listOfAgencies).forEach(agency => {
                const agencyName = getAgencyName(agency);
                const exists = finalPrices.some(finalPrice => finalPrice.name === agencyName);
                const agencyUsers = listOfAgencies[agency];
                const additonalUsers = Math.max(0, agencyUsers - nbrOfUsers);

                if (!exists) {
                    const priceUsers = (priceAddUsers + additionalUserPrice) * additonalUsers;
                    let newAgency = {};

                    newAgency.id = `${agency}|parent-${hektorOffer}`;
                    newAgency.name = agencyName;
                    newAgency.data = agencyUsers > 1 ? `${agencyUsers} utilisateurs` : `${agencyUsers} utilisateur`;
                    newAgency.price = price + priceUsers;
                    newAgency.priceUsers = priceUsers;
                    newAgency.displayWhenParent = `parent-${hektorOffer}`;

                    newState[key].finalPrices = [ ...newState[key].finalPrices, newAgency ];
                }
            });
            /* *********************************************** */

            /* For each agencies on my tarifs
            * Check if the agency still exists on my data list of agencies
            * If not remove it from tarifs
            */
            finalPrices.forEach((finalePrice, index) => {
                const exists = Object.keys(listOfAgencies).some(agency =>
                    `${getAgencyName(agency)}` === finalePrice.name
                );

                if (!exists) {
                    newState[key].finalPrices.splice(index, 1);
                }
            });
            /* *********************************************** */

            // We sort the array of finalPrices by agency name ASC
            newState[key].finalPrices = sortFinalPrices(newState[key].finalPrices);

            return newState;
        }

        case LOAD_CONTENT_PAGE: {
            const { key, mode, list, quantities } = action.payload;
            const finalPrices = state[key].finalPrices;

            list.forEach(element => {
                if (!finalPrices.some(finalPrice => finalPrice.name === element)) {
                    const elementTarifs = state[element];
                    const elementPrice = elementTarifs ? (elementTarifs.finalPrice ?? elementTarifs.price[mode]) : 'inclus';

                    let newElement = {};

                    newElement.name = element;
                    newElement.label = beautifulGetLabel(element);
                    newElement.price = quantities && quantities[element] && elementPrice !== 'inclus' ? elementPrice * quantities[element] : elementPrice;

                    newState[key].finalPrices = [ ...newState[key].finalPrices, newElement ];
                }
            });

            /* *********************************************** */
            finalPrices.forEach((finalePrice, index) => {
                if (!finalePrice.hasOwnProperty('independant') && !list.some(element => element === finalePrice.name)) {
                    delete newState[key].finalPrices[index];
                }
            });

            const newFinalPrices = [];
            newState[key].finalPrices.forEach((finalPrice) => {
                newFinalPrices.push(finalPrice);
            });
            /* *********************************************** */

            newState[key].finalPrices = newFinalPrices;

            return newState;
        }

        case LOAD_CRO_CONTENT_PAGE: {
            const { key, list, config } = action.payload;
            const finalPrices = state[key].finalPrices;
            
            Object.entries(list).forEach(([element, value]) => {
                if (true === config[element] && !finalPrices.some(finalPrice => finalPrice.name === element)) {
                    const croTarifs = state[key];
                    let newElement = {};
    
                    newElement.name = element;
                    newElement.label = beautifulGetLabel(element);
                    newElement.price = croTarifs.price * value;
    
                    newState[key].finalPrices = [ ...newState[key].finalPrices, newElement ];
                }
            });

            finalPrices.forEach((finalePrice, index) =>
                !Object.entries(list).some(([element]) => element === finalePrice.name) &&
                    newState[key].finalPrices.splice(index, 1)
            );

            return newState;
        }

        case LOAD_HEBERGEMENT: {
            const key = action.payload.key;
            const mode = action.payload.mode;

            if (null === newState[key].finalPrice) {
                newState[key].finalPrice = newState[key].price[mode];
            }
            newState[key].priceUnitInRecap = newState[key].priceUnit[mode];

            return newState;
        }

        case LOAD_DOMAINS: {
            const key = action.payload.key;
            const domain = action.payload.domain;
            const type = action.payload.type;
            const produits = action.payload.produits;
            const price = state[key].price;
            const finalPrices = state[key].finalPrices;
            
            finalPrices.forEach((finalePrice, index) =>
                domain === finalePrice.name &&
                    newState[key].finalPrices.splice(index, 1)
            );

            if ('' !== domain && 'non-gere-ext' !== type && !produits.includes('site')) {
                if (finalPrices.length > 0) {
                    newState[key].finalPrices[0].name = domain;
                } else {
                    let newDomain = {};

                    newDomain.name = domain;
                    newDomain.price = price;

                    newState[key].finalPrices = [ ...newState[key].finalPrices, newDomain ];
                }
            }

            return newState;
        }

        case DELETE_FINALPRICE: {
            const id = action.payload.id;
            const name = action.payload.name;

            newState[id].finalPrices = newState[id].finalPrices.filter(value => !value.name.includes(name));

            return newState;
        }

        case RESET_FINALPRICE: {
            const tarifsName = action.payload.tarifsName;
            const basePrice = newState[tarifsName].price;

            newState[tarifsName].finalPrice = basePrice;

            return newState;
        }

        case RESET_FINALPRICES: {
            const tariffName = action.payload.name;

            const tariff = { ...state[tariffName] };
            tariff.finalPrices = [];

            if (tariff.hasFinancing) {
                const financingTariffName = `${tariffName}-financing`;

                if (financingTariffName in state) {
                    const financingTariff = { ...state[financingTariffName] };
                    financingTariff.finalPrices = [];

                    newState[financingTariffName] = financingTariff;
                }
            }

            newState[tariffName] = tariff

            return newState;
        }

        default:
            return state;
    }
};

export default tarifs;
