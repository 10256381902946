import React from 'react';
import styled from 'styled-components';
import { easeOutBack, green } from '../../Utils/styleConstants';
import Checkmark from './Checkmark';

const Container = styled.div`
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    padding: 3px;
    background: ${green};
    border-radius: 50%;
    opacity: ${props => props.checked ? 1 : 0};
    transform: translateY(${props => props.checked ? 0 : '40px'});
    inset: auto auto -10px 50%;
    margin-left: -8px;
    margin-right: auto;
    margin-top: auto;
    transition: opacity 0.2s ease-in, transform 0.5s ${easeOutBack};
`;

const CheckmarkContainerSocialNetworks = props => {
    return (
        <Container
            checked={props.checked}
            notBottomCheckmark={props.notBottomCheckmark}
        >
            <Checkmark />
        </Container>
    )
}

export default CheckmarkContainerSocialNetworks;
