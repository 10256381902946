import React from 'react';
import styled from 'styled-components';
import logoFnaim from '../Images/fnaim.svg';

const Container = styled.div`
    position: absolute;
    top: ${props => props.top};
    right: ${props => props.right};
    display: flex;
    transform: rotate(5deg);
`;

const SpecialOfferStructure = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 125px;
    padding: 10px 0;
    background-color: #343434;
`;

const OfferSpecial = styled.p`
    font-size: 13px;
    text-transform: uppercase;
    color: white;
    font-weight: bolder;
`;

const Adherent = styled.p`
    font-size: 15px;
    letter-spacing: 3.5px;
    text-transform: uppercase;
    color: ${props => props.color ?? 'red'};
    font-weight: 500;
`;

const Logo = styled.img`
    width: 110px;
`;

const PromotionStructure = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 170px;
    background-color: ${props => props.color ?? '#343434'};
`;

const Promo = styled.p`
    font-size: 35px;
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 1px 1px #C9BE26;
`;

const SubPromo = styled.p`
    font-size: 18px;
    text-transform: uppercase;
    text-shadow: 1px 1px #C9BE26;
`;

const PromoFnaimTag = props => {
    return (
        <Container
            top={props.top}
            right={props.right}
        >
            <SpecialOfferStructure>
                <OfferSpecial>offre spéciale</OfferSpecial>
                <Adherent color={props.color}>adhèrent</Adherent>
                <Logo src={logoFnaim} alt="Logo Hektor" />
            </SpecialOfferStructure>
            <PromotionStructure color={props.color}>
                <Promo>{props.period}</Promo>
                <SubPromo>d'abonnement</SubPromo>
                <Promo>offerts</Promo>
            </PromotionStructure>
        </Container>
    );
};

export default PromoFnaimTag;
