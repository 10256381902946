import React, { useCallback, useEffect, useState } from 'react';
import { borderGrey } from '../Utils/styleConstants';
import styled from 'styled-components';
import Add from './Addable/Add';
import Delete from './Addable/Delete';
import { deleteInDataState, getInDataState, setInDataState } from '../Utils/storeHelpers';

const Container = styled.div`
    border: 1px solid ${borderGrey};
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5em;
    margin: 0 0.5em;
    align-self: flex-start;
`;

const Title = styled.div`
    font-weight: bold;
    font-size: 0.8em;
`;

const ColorContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
`;

const FakeInputColor = styled.label`
    display: flex;
`;

const InputColor = styled.input`
    width: 0;
    height: 0;
    overflow: hidden;
`;

const ColorElement = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: 0 5px 0 0;
    cursor: not-allowed;
`;

const Color = props => {
    // Use of hooks
    const [colors, setColors] = useState(null);
    const [currentColor, setCurrentColor] = useState('#000000');

    // useEffect on mounting
    useEffect(() => {
        null === colors && setColors(getInDataState('logo.colors') ?? []);
    }, [colors])

    // Callback use to delete a color
    const handleDeleteColor = useCallback((deletedColor) => {
        const newColors = colors.filter(color => color !== deletedColor);

        setColors(newColors);
        deleteInDataState(`${props.masterId}.${props.name}`, deletedColor);
    }, [props, colors])

    // Callback use to add a color
    const handleAddColor = useCallback(() => {
        const newColors = [ ...colors, currentColor ];

        if (!colors.includes(currentColor)) {
            setColors(newColors);
            setInDataState(`${props.masterId}.${props.name}`, currentColor, true);
        }
    }, [props, colors, currentColor])

    return (
        <Container>
            <Title>Code couleur hexadecimal</Title>
            <ColorContainer>
                {
                    colors && colors.map((color, index) => (
                        <ColorElement
                            key={index}
                            onClick={() => handleDeleteColor(color)}
                            color={color}
                        >
                                <Delete white={true} color={color} />
                        </ColorElement>
                    ))
                }
                <FakeInputColor htmlFor='logoColors'>
                    <Add />
                </FakeInputColor>
                <InputColor
                    id='logoColors'
                    type='color'
                    value={currentColor}
                    onBlur={() => handleAddColor()}
                    onChange={(event) => setCurrentColor(event.target.value)}
                />
            </ColorContainer>
        </Container>
    )
}

export default Color;
