import { legacy_createStore as createStore, combineReducers} from 'redux'
import generic from './Reducers/generic';
import data from './Reducers/data';
import tarifs from './Reducers/tarifs';

export const store = createStore(
    combineReducers({
        generic,
        data,
        tarifs
    }),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export const dispatch = store.dispatch;
