import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { setFinalPriceInTarifsState } from '../../Utils/storeHelpers';
import { Checkbox, FakeInputContainer, FakeInputPrice, PriceUnit } from '../../Utils/styleConstants';

const FakeInputDevisStructure = styled.div`
    display: flex;
    flex-direction: column;
`;

const DevisCheckboxStructure = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0 0;
`;

const DevisCheckboxLabel = styled.p`
    padding: 0 0 0 10px;
`;

const PriceUpdaterDevis = props => {
    const WAIT_INTERVAL = 1500;

    //props.tarifs
    const [checked, setChecked] = useState(null);
    const [choosenPrice, setChoosenPrice] = useState(null);
    const [timer, setTimer] = useState(undefined);

    // useEffect when component is mounting
    useEffect(() => {
        if (null === choosenPrice) {
            undefined !== props.index && props.tarifs.hasOwnProperty('finalPrices') &&
                setChoosenPrice('sur devis' === props.tarifs.finalPrices[props.index].price ? '' : props.tarifs.finalPrices[props.index].price);
            
            props.tarifs.hasOwnProperty('finalPrice') && 
                setChoosenPrice('sur devis' === props.tarifs.finalPrice ? '' : props.tarifs.finalPrice);
        }
    }, [choosenPrice, props.tarifs, props.index])

    // useEffect when choosenPrice hook value changes
    useEffect(() => {
        null !== choosenPrice && setChecked('' === choosenPrice);
    }, [choosenPrice, props.tarifs]);

    // Callback used when user change input value
    const handleInputChange = useCallback((newPrice) => {
        clearTimeout(timer);

        const priceForHook = '' === newPrice ?
            '' : parseFloat(newPrice);
        
        const priceForState = '' === newPrice ?
            'sur devis' : parseFloat(newPrice);

        // Change hook value
        setChoosenPrice(priceForHook);

        setTimer(setTimeout(() => {
            // Change finalPrice in redux tarifs state
            setFinalPriceInTarifsState(props.tarifs.id, priceForState, props.index);
        }, WAIT_INTERVAL));
    }, [timer, props.tarifs.id, props.index]);

    // Callback used when user click on checkbox
    const handleCheckboxClick = useCallback(() => {
        if ('' !== choosenPrice) {
            // Change hook value
            setChoosenPrice('');

            // Change finalPrice in redux tarifs state
            setFinalPriceInTarifsState(props.tarifs.id, 'sur devis', props.index);
        }
    }, [choosenPrice, props.tarifs.id, props.index]);

    return (
        null !== checked && null !== choosenPrice && (
            <FakeInputDevisStructure>
                <FakeInputContainer>
                    <FakeInputPrice
                        type='text'
                        parent={props.parent}
                        value={choosenPrice}
                        width={0 === choosenPrice.toString().length ? '1ch' : `${choosenPrice.toString().length}ch`}
                        onChange={(event) => handleInputChange(event.target.value)}
                    />
                    <PriceUnit>
                        {`€ ${props.priceUnit}`}
                    </PriceUnit>
                </FakeInputContainer>
                <DevisCheckboxStructure>
                    <Checkbox
                        className={checked ? 'checked' : ''}
                        checked={checked}
                        size={18}
                        onClick={() => handleCheckboxClick()}
                    />
                    <DevisCheckboxLabel>Sur devis</DevisCheckboxLabel>
                </DevisCheckboxStructure>
            </FakeInputDevisStructure>
        )
    );
};

export default PriceUpdaterDevis;
