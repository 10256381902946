const computeTarifForThresholdDependentMandataires = (mandataireThreshold, numberOfMandataires, price, name,priceForMandataireSupp) => {

    if (numberOfMandataires < mandataireThreshold) {
        return price;
    }
    else {
        return price + priceForMandataireSupp * ( numberOfMandataires - mandataireThreshold)
    }
}

export default computeTarifForThresholdDependentMandataires;