import checkmarkContainerSocialNetworks from "../Components/Icons/CheckmarkContainerSocialNetworks";

export const TVA_DEFAULT = 20;

export const getPrice = ({ tarifs, ...props }) => {
    const name = findPricingName(props)
    if (tarifs[name] !== undefined) {
        return tarifs[name]
    }
    return {
        prix: 0
    }
};

const findPricingName = ({pricingName, name, value, pricingId, ...props}) => {
    if (pricingId !== undefined) {
        return pricingId
    }
    if (pricingName !== undefined) {
        return [name, props[pricingName].name, value[pricingName]].join('-')
    }
    return name
};

export const nddRegExp = /^[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-zA-Z]{2,}$/g;

export const isValidNdd = (prices, ndd) => {
    let isValid = true;
    const match = ndd.match(nddRegExp);
    const extension = ndd.substring(ndd.lastIndexOf('.') + 1, ndd.length);

    if (!match || '' === ndd || !prices[extension]) {
        isValid = false;
    }

    return isValid;
};

export const getPriceFromNdd = (prices, ndd) => {
    const extension = ndd.substring(ndd.lastIndexOf('.') + 1, ndd.length);
    
    return prices[extension];
};

export const formatPrice = (price) => new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(price);

export function sortFinalPrices(finalPrices) {
    return finalPrices.sort(function (a, b) {
        let x = a.name;
        let y = b.name;

        return x.localeCompare(y, undefined, { numeric: true, sensitivity: 'base' });
    });
};

export function getPriceDecimals(price, toFixed) {
    let priceDecimals = '00';

    if (typeof price === 'number') {
        priceDecimals = (price % 1).toFixed(toFixed).substring(2);
    }

    return priceDecimals;
};

export const isValidInputRecap = (value) => {
    return '' === value || value.match(/^\d{1,3}(\.\d{0,3})?$/);
}
