import * as _ from "./object";

export function setInState(state, path, value, isArray, asKey, forArray) {
    // Retrieve an array of keys from path
    const arrayOfKeys = path.split('.');
    const shiftedKey = arrayOfKeys.shift();

    if (arrayOfKeys.length === 0) {
        if (isArray) {
            if (value === '') {
                return;
            }

            if (state[shiftedKey] === undefined) {
                state[shiftedKey] = [];
            }

            if (!state[shiftedKey].includes(value)) {
                state[shiftedKey].push(value);
            }

            return;
        }

        if (value === '') {
            delete state[shiftedKey];
            return;
        }

        if (asKey === true) {
            if (state[shiftedKey] === undefined) {
                state[shiftedKey] = {};
            }

            state[shiftedKey][value] = forArray ? [] : {};
        } else {
            state[shiftedKey] = value;
        }
    } else {
        if (arrayOfKeys.length === 1) {
            if (state[shiftedKey] !== undefined && _.isPureObject(state[shiftedKey])) {
                state[shiftedKey] = { ...state[shiftedKey] }
            }
        }

        if (state[shiftedKey] === undefined) {
            state[shiftedKey] = {};
        }

        setInState(state[shiftedKey], arrayOfKeys.join('.'), value, isArray, asKey, forArray);
    }
}

export function deleteInState(state, path, value, asKey, deleteArray) {
    // Retrieve an array of keys from path
    const arrayOfKeys = path.split('.');
    const shiftedKey = arrayOfKeys.shift();

    // Stop the recursion because we are at the last key of our initial path
    if (0 === arrayOfKeys.length) {
        if (Array.isArray(state[shiftedKey])) {
            state[shiftedKey] = state[shiftedKey].filter(item => item !== value);
            // Delete completly if array length is 0 OR deleteArray param is true
            if (0 === state[shiftedKey].length || true === deleteArray) {
                delete state[shiftedKey];
            }
        } else {
            if (true === asKey) {
                delete state[shiftedKey][value];
            } else {
                delete state[shiftedKey];
            }
        }

    // We are not at the end of our path so we need to be able to continue
    } else {
        if (undefined !== state[shiftedKey]) {
            // Continue the recursion by passing the state but one step deeper
            deleteInState(state[shiftedKey], arrayOfKeys.join('.'), value, asKey, deleteArray);
        }
    }
}
