import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Checkbox } from '../Utils/styleConstants';
import { getInDataState, setInDataState } from '../Utils/storeHelpers';
import { getTarifsInfoByTarifsName } from '../Utils/jsonInterpreter';

const Container = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    ::after{
        position: absolute;
        top: 50%;
        left: 0;
        z-index: -1;
        content: '';
        width: 100%;
        border-bottom: 1px dotted lightgray;
    }
`;

const CheckboxStructure = styled.div`
    display: flex;
    align-items: center;
    padding: 0 25px 0 0;
    background-color: white;
`;

const Label = styled.label`
    color: ${props => props.color ?? 'initital'};
    font-size: ${props => props.fontSize ?? '1em'};
    font-weight: ${props => props.fontWeight ?? 'normal'};
    padding: ${props => props.padding ?? '0'};
    cursor: pointer;
`;

const Price = styled.p`
    color: ${props => props.color ?? 'initital'};
    font-size: ${props => props.fontSize ?? '1em'};
    font-weight: ${props => props.fontWeight ?? 'normal'};
    background-color: white;
    padding: 0 0 0 25px;
    cursor: default;
`;

const SimpleCheckBox = (props) => {
    // Use of hooks
    const [checked, setChecked] = useState(null);

    // Constante about tarifs
    const tarifsInfo = getTarifsInfoByTarifsName(props.tarifsName);

    // useEffect when component is mounting
    useEffect(() => {
        null === checked && setChecked(getInDataState(props.pathInDataState) ?? false);
    }, [checked, props.pathInDataState])

    // Callback on click on checkbox
    const handleClick = useCallback(() => {
        setChecked(!checked);
        setInDataState(props.pathInDataState, !checked);
    }, [props, checked])

    return (
        <Container
            onClick={handleClick}
        >
            <CheckboxStructure>
                <Checkbox
                    className={checked ? 'checked' : ''}
                    checked={checked}
                />
                <Label
                    color={props.labelColor}
                    fontSize={props.labelFontSize}
                    fontWeight={props.labelFontWeight}
                    padding={props.labelPadding}
                >
                    {props.label}
                </Label>
            </CheckboxStructure>
            {
                null !== tarifsInfo && (
                    <Price
                        color={props.priceColor}
                        fontSize={props.priceFontSize}
                        fontWeight={props.priceFontWeight}
                        padding={props.pricePadding}
                    >
                        {`${tarifsInfo.price} € HT`}
                    </Price>
                )
            }
        </Container>
    );
};

export default SimpleCheckBox;
