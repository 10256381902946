const specials = [
    'multiply',
    'counter',
    'addable'
]

const getInfosPrice = getStoreInfoData => key => element => value => {
    const info = {name: key, value: true, ...element}
    const storeInfo = getStoreInfoData(info.id)(info.name)
    let thisPrice
    if (info.type === 'addable') {
        thisPrice = getAddablePrice(getStoreInfoData)(value.prix)(info.id)(info.name)(info)
    } else {
        thisPrice = getPrice(storeInfo)(info.type)(info.value)(value.prix)(info)   
    }
    return thisPrice
}

const getPrice = storeValue => typeCompare => compareValue => price => more => {
    switch (typeCompare) {
        case 'multiply': {
            const multiplier = storeValue === undefined ? 0 : parseInt(storeValue)
            return multiplier * price
        }
        case 'counter': {
            let counter = Array.isArray(storeValue) && storeValue.length ? storeValue.length : 0
            if (more.freetier) {
                counter = Math.max(0, counter - more.freetier)
            }
            return counter * price
        }
        default:
            return (storeValue === compareValue) ? parseInt(price) : 0
    }
}

const getAddablePrice = getStoreInfoData => price => id => name => more => {
    const orderLength = getAddableValueForInfos(getStoreInfoData)({id, name}).length
    const counter = more.freetier ? Math.max(0, orderLength - more.freetier) : orderLength
    return counter * price
}

const getAddableValueForInfos = getStoreInfoData => ({ id, name }) => {
    const orderName = [id, name].join('|')
    const orderValue = getStoreInfoData('_order')(orderName)
    if (orderValue) {
        return orderValue
        .map(uid => getStoreInfoData(id)(`${name}.${uid}`))
        .filter(v => v !== '' && v !== undefined)
    }
    return []
}

const getChildInfos = entries => parent => getStoreData => {
    const groups = {}
    for (let [key, value] of entries) {
        if (Array.isArray(value.parent) && value.parent.indexOf(parent) > -1) {
            value.elements.forEach(element => {
                const child = getInfos(getStoreData)(key)(element)(value)
                if (child !== null) {
                    if (groups[element.id] === undefined) {
                        groups[element.id] = []
                    }
                    if (groups[element.id].filter(el => child.key === el.key).length === 0) {
                        groups[element.id].push(child)
                    }
                }
            })
        }
    }
    return groups
}

const getParentInfos = getStoreData => key => value =>  {
    if (value.parent === true) {
        return value.elements.reduce((acc, element) => {
            if (acc === null) {
                return getInfos(getStoreData)(key)(element)(value)
            }
            return acc
        }, null)
    }
    return null
}

const shouldBeIn = storeValue => compareValue => priceType => {
    return (
        storeValue !== undefined && 
        (specials.indexOf(priceType) > -1
        || storeValue === compareValue
        || (
            Array.isArray(storeValue)
            && storeValue.indexOf(compareValue) > -1
        ))
    )
}
const getInfos = getStoreInfoData => key => element => value => {
    const info = {name: key, value: true, ...element}
    const storeValue = element.type === 'addable' ? getAddableValueForInfos(getStoreInfoData)(info) : getStoreInfoData(info.id)(info.name)
    const compareValue = info.value
    if (shouldBeIn(storeValue)(compareValue)(element.type)) {
        const valueForInfo = element.type === 'addable' ? getAddableValueForInfos(getStoreInfoData)(element) : (element.value && element.value !== true ? element.value : storeValue)
        const labelName = value.labelName ? value.labelName : key
        return {
            key,
            name: key,
            labelName,
            unitPrice: value.prix,
            price: getInfosPrice(getStoreInfoData)(key)(element)(value),
            value: Array.isArray(valueForInfo) ? getSubElements(value.prix)(element.freetier)(valueForInfo) : valueForInfo,
            infos: value
        }
    }
    return null
}

const getSubElements = unitPrice => freetier => tab => tab.map((value, index) => {
    const nbElement = index + 1
    const price = inFreetier(freetier)(nbElement) ? 0 : unitPrice
    const o = {
        value,
        price
    }
    return o
})

const inFreetier = freetier => nieme => {
    if (freetier !== undefined) {
        return nieme <= freetier
    }
    return false
}

const getStoreInfo = data => id => name => (data[id] !== undefined && data[id][name] !== undefined) ? data[id][name] : undefined

const getTva = taux => price => parseFloat(price * taux/100)

const isMatchedPeriode = periode => element => {
    if (periode === 'year') {
        return element.an === true
    }
    if (periode === 'month') {
        return element.mois === true
    }
    return element.mois !== true && element.an !== true
}

const getAcceptedTarifs = tarifs => tarif => produits => {
    if (produits === undefined) {
        return true
    }
    const produitsOfTarifs = getProduitsOfTarifs(tarifs)(tarif)
    return produitsOfTarifs ? produitsOfTarifs.reduce((acc, produit) => acc === false ? produits.indexOf(produit) > -1 : acc, false) : true
}

const getProduitsOfTarifs = tarifs => tarif => {
    if (tarif.produits) {
        return tarif.produits
    }
    if (Array.isArray(tarif.parent)) {
        return tarif.parent.reduce((acc, parent) => {
            if (tarifs[parent] && tarifs[parent].produits) {
                if (!Array.isArray(acc)) {
                    acc = []
                }
                return [...acc, ...tarifs[parent].produits]
            }
            return acc
        }, false)
    }
    return false
}

const price = {
    getTva20: getTva(20),
    createTree: storeData => tarifs => {
        const parents = []
        const getStoreInfoData = getStoreInfo(storeData)
        const entries = Object.entries(tarifs)
        const isAcceptedTarif = getAcceptedTarifs(tarifs)
        for (let [key, value] of entries) {
            if (value.elements !== undefined && isAcceptedTarif(value)(getStoreInfoData('produits')('type'))) {
                const parent = getParentInfos(getStoreInfoData)(key)(value)
                if (parent !== null) {
                    parents.push(parent)
                }
            }
        }
        const tree = parents.map(parent => ({
            parentInfos: parent,
            childsInfos: getChildInfos(entries)(parent.key)(getStoreInfoData)
        }))
        return tree
    },
    getPriceWithoutCharges: periode => storeData => tarifs => {
        let total = 0
        const getStoreInfoData = getStoreInfo(storeData)
        const isThisPeriode = isMatchedPeriode(periode)
        const isAcceptedTarif = getAcceptedTarifs(tarifs)
        for (let [key, value] of Object.entries(tarifs)) {
            if (value.elements !== undefined && isThisPeriode(value) && isAcceptedTarif(value)(getStoreInfoData('produits')('type'))) {
                total = value.elements.reduce(
                    (acc, element) => acc + getInfosPrice(getStoreInfoData)(key)(element)(value)
                , total)
            }
        }
        return parseFloat(total)
    }
};

export default price;
