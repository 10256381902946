import React from 'react';
import styled from "styled-components";
import CheckableOption from '../Option/CheckableOption';
import AdvancedOption from "../Option/AdvancedOption";
import WithCounterChoiceOption from "./WithCounterChoiceOption";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const CheckableTypeOptions = ({ options, pricesDependOn, pathInDataState }) => {
    return (
        <Container>
            {
                options.map((option, index) => (
                    <React.Fragment key={index}>
                        {
                            'checkable' === option.type && (
                                <CheckableOption
                                    dependsOn={pricesDependOn ?? option.pricesDependOn}
                                    pathInDataState={pathInDataState}
                                    pathInDataStateQuantity={option.pathInDataStateQuantity}
                                    name={option.name}
                                    image={option.image}
                                    label={option.label}
                                    tarifsName={option.name}
                                    counterLabel={option.counterLabel}
                                    counterDependsOn={option.counterDependsOn}
                                    full={option.full}
                                    fullOptions={option.fullOptions}
                                    setItselfFalse={option.setItselfFalse}
                                    setInDataState={option.setInDataState}
                                    default={option.default}
                                    disabled={option.disabled}
                                />
                            )
                        }
                        {
                            'advanced' === option.type && (
                                <AdvancedOption
                                    dependsOn={pricesDependOn ?? option.pricesDependOn}
                                    pathInDataState={pathInDataState}
                                    {...option}
                                />
                            )
                        }
                        {
                            'withChoice' === option.type && (
                                <WithCounterChoiceOption
                                    dependsOn={pricesDependOn ?? option.pricesDependOn}
                                    hektorType={option.hektorType}
                                    pathInDataState={pathInDataState}
                                    {...option}
                                />
                            )
                        }
                    </React.Fragment>
                ))
            }
        </Container>
    );
}

export default CheckableTypeOptions;
