import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import { getInTarifsState, setFinalPriceInTarifsState } from '../Utils/storeHelpers';
import { borderGrey, grey } from '../Utils/styleConstants';

const Container = styled.div`
    display: flex;
    align-items: center;
    padding: 0.5em 0;
`;

const InputInner = styled.input`
    width: 80px;
    border: 1px solid ${props => props.error ? 'red' : `${borderGrey}`};
    border-radius: 4px;
    padding: 0.5em;
    font-size: 1em;
    font-family: Roboto, sans-serif;
    text-align: center;
    font-weight: bold;
    color: ${grey};
`;

const By = styled.p`
    text-transform: capitalize;
    font-weight: bold;
    padding: 0.5em;
    color: ${grey};
`;

const Error = styled.p`
    color: red;
    font-size: 0.8em;
    font-weight: bold;
    font-style: italic;
`;

const PriceAdwordsEditor = props => {
    // Use of hooks
    const [displayError, setDisplayError] = useState(false);
    const [finalPrice, setFinalPrice] = useState(null);

    // Use of redux
    const tarifs = getInTarifsState(props.tarifsName);

    // useEffect when component is mounting
    useEffect(() => {
        if (undefined !== tarifs && null === finalPrice) {
            null === tarifs.finalPrice ?
                setFinalPrice(props.formulaMinMax.min)
                : setFinalPrice(tarifs.finalPrice);
        }
    }, [props.formulaMinMax, finalPrice, tarifs])

    // Callback when user change price
    const handleChange = useCallback((event) => {
        let newPrice = event.target.value.replace(/[^0-9]/g, '');
        newPrice = newPrice === '' ? 0 : parseInt(newPrice);

        if (newPrice > props.formulaMinMax.max) {
            newPrice = props.formulaMinMax.max;
        }

        setDisplayError(newPrice < props.formulaMinMax.min);
        setFinalPrice(newPrice);
    }, [props.formulaMinMax])

    // Callback when user leave the input
    const handleBlur = useCallback((event) => {
        let newPrice = event.target.value.replace(/[^0-9]/g, '');
        newPrice = newPrice === '' ? 0 : parseInt(newPrice);

        if (newPrice < props.formulaMinMax.min) {
            newPrice = props.formulaMinMax.min;
        }

        setDisplayError(false);
        setFinalPrice(newPrice);
    }, [props.formulaMinMax])

    // useEffect when finalPrice hook value changes
    useEffect(() => {
        if (null !== finalPrice) {
            setDisplayError(finalPrice < props.formulaMinMax.min || finalPrice > props.formulaMinMax.max);
            setFinalPriceInTarifsState(props.tarifsName, finalPrice);
        }
    }, [finalPrice, props.tarifsName, props.formulaMinMax])

    return (
        null !== finalPrice && (
            <>
                <Container>
                    <InputInner
                        type='text'
                        value={finalPrice}
                        onChange={(event) => handleChange(event)}
                        onBlur={(event) => handleBlur(event)}
                        error={displayError}
                    />
                    <By> € / mois</By>
                </Container>
                {
                    displayError && (
                        <Error>Veuillez renseigner un prix valide</Error>
                    )
                }
            </>
        )
    );
};

export default PriceAdwordsEditor;