import styled from "styled-components";
import {getImage} from "../Utils/jsonInterpreter";

const Container = styled.div`
    position: absolute;
    top: ${props => props.top};
    right: ${props => props.right};
    display: flex;
    transform: rotate(8deg);
    box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
`;

const Logo = styled.img`
  width: 300px;
`

const ImmosignPromoTag = () => {
    return (
        <Container top="9.75%" right="5%">
            <Logo src={getImage("/static/img/pub-banner-immosign.png")}  alt={"coucou"}/>
        </Container>
    )
}

export default ImmosignPromoTag;