import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Cookies from 'universal-cookie';
import Spinner from '../Spinner';
import {getInDataState, getInGenericState} from '../../Utils/storeHelpers';
import fetchDistant from '../../Utils/fetch';
import { Waiting, WaitingContainer } from '../../Utils/styleConstants';

const Link = styled.a`
    font-size: 45px;
`;

const PdfDownloadPage = props => {
    // Use of hooks
    const [token, setToken] = useState(null);
    const [filename, setFilename] = useState(null);
    const [link, setLink] = useState(null);
    const [apiError, setApiError] = useState(false);

    // Use of redux
    const idDocument = getInGenericState('idDocument');
    const contractNum = getInDataState('contrat.numero');

    // useEffect when component is mounting
    useEffect(() => {
        null === token && setToken((new Cookies()).get('thetoken'));
    }, [token]);

    // useEffect when component is mounting
    useEffect(() => {
        null !== token &&
            fetchDistant(`/document/create?id=${idDocument}&contractNum=${contractNum}`)
                .then(result => setFilename(result.data.filename))
                .catch(() => {
                    console.log('Impossible to call our API to generate PDFs');
                    setApiError(true);
                });
    }, [token, idDocument, contractNum]);

    // Callback waiting server pdfs
    const waitReadyToDownload = useCallback(() => {
        fetchDistant(`/document/state?fn=${filename}`)
            .then(result =>
                result.data.ready ?
                    setLink(`${process.env.REACT_APP_SERVER_URI}/document/get?fn=${filename}&access_token=${token}`)
                    : setTimeout(() => waitReadyToDownload(), 5000)
            )
            .catch(() => {
                console.log('Impossible to retrieve PDFs');
                setApiError(true);
            });
    }, [filename, token]);

    // useEffect when component is mounting
    useEffect(() => {
        null !== token && null !== filename && waitReadyToDownload();
    }, [token, filename, waitReadyToDownload]);

    return (
        link ? (
            <WaitingContainer>
                <Waiting>Cliquez sur le lien suivant pour télécharger vos documents</Waiting>
                <Link href={link}>Documents Kontractor</Link>
            </WaitingContainer>
        ) : (
            <WaitingContainer>
                {
                    apiError ? (
                        <>
                            <Waiting>Impossible de générer les PDFs</Waiting>
                            <Waiting>:(</Waiting>
                        </>
                    ) : (
                        <>
                            <Waiting>Veuillez patienter pendant la création des documents</Waiting>
                            <Spinner size={150} />
                        </>
                    )
                }
            </WaitingContainer>
        )
    );
};

export default PdfDownloadPage;
