import {CheckboxGroupContext} from "./CheckboxGroupContext";

const useCheckboxGroup = (props) => {
    const { onValueChange, groupState } = props;

    const handleValueChange  = (value, isSelected) => {
        onValueChange(isSelected ? [...groupState, value] : groupState.filter(item => item !== value), value);
    }

    return { handleValueChange };
}

const CheckboxGroup = (props) => {
    const { value: groupState, onValueChange, children, GroupContainer } = props;
    const { handleValueChange } = useCheckboxGroup({ groupState, onValueChange});

    const Container = GroupContainer ?? 'div';

    return (
        <Container>
            <CheckboxGroupContext.Provider value={{ groupState, onChange: handleValueChange }}>
                {children}
            </CheckboxGroupContext.Provider>
        </Container>
    )
}

export default CheckboxGroup;
