import React, { useCallback } from 'react';
import styled from 'styled-components';
import Toggle from '../Toggle';
import {UPDATE_DATA_VALUE} from '../../Store/actions';
import {getLabel} from '../../Utils/labels';
import {formatPrice} from '../../Utils/price';
import {getTarifsInfoByTarifsName, manualDependsOnTarifsInfo} from '../../Utils/jsonInterpreter';
import {
    FinalPriceOption,
    FinalPriceOptionSpecial,
    borderGrey
} from '../../Utils/styleConstants';

const StyleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({ width, totalWidth }) => width ?? `calc((100% - 90px - 15%) / ${totalWidth})`};
    padding: 2% 0;
    border-left: 1px solid ${borderGrey};
    border-bottom : ${({ borderBottom }) => borderBottom && `1px solid ${borderGrey}`};
    border-right :${({ borderRight }) => borderRight && `1px solid ${borderGrey}`};
`;

const ToggleStyleContainer = styled.div`
    padding: 15px 0;
`;

const getPriceComponent = (tarifsInfo) => () => {
    if (!tarifsInfo) return null;

    if (isNaN(tarifsInfo.price)) {
        return (
            <FinalPriceOptionSpecial>
                {tarifsInfo.price}
            </FinalPriceOptionSpecial>
        )
    }

    const price = formatPrice(tarifsInfo.price);
    const priceUnit = tarifsInfo.priceUnit.toUpperCase();

    return (
        <FinalPriceOption>
            {`${price} HT ${priceUnit}`}
        </FinalPriceOption>
    );
}

const LePackMinorOption = props => {
    let tarifsInfo = getTarifsInfoByTarifsName(props.tarifsName);
    tarifsInfo = manualDependsOnTarifsInfo(tarifsInfo, props.dependsOn);

    const Price = getPriceComponent(tarifsInfo);

    const handleToggle = useCallback(() => {
        if (props.toggable) {
            props.onToggle(props.index);
        }
    }, [props]);

    return (
        <StyleContainer
            width={props.width}
            totalWidth={props.totalWidth}
            borderBottom={props.borderBottom}
            borderRight={props.borderRight}
        >
            <p>{getLabel(props.label)}</p>
            <ToggleStyleContainer>
                <Toggle
                    action={UPDATE_DATA_VALUE}
                    pathInDataState={props.pathInDataState}
                    name={props.name}
                    value={props.toggle}
                    callback={handleToggle}
                />
            </ToggleStyleContainer>
            <Price />
        </StyleContainer>
    );
};

export default LePackMinorOption;
