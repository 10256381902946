import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {selectDataState, selectGenericState, selectTarifsState} from '../../Store/selectors';
import Recap from './Recap';
import Total from './Total';
import fetchDistant from '../../Utils/fetch';
import { preRenderRecap } from '../../Utils/recapHelpers';
import {TVA_DEFAULT} from "../../Utils/price";
import {get} from "../../Utils/object";
import {getInDataState, setInDataState} from "../../Utils/storeHelpers";

async function fetchTree(idDocument, tarifs) {
    return await fetchDistant(`/tree?id=${idDocument}`, {tarifs: tarifs});
}

async function fetchTotal(idDocument, tarifs, tva) {
    return await fetchDistant(`/total?id=${idDocument}`, {tarifs: tarifs, tva: tva});
}

async function fetchTotalDiscount(idDocument, priceDiscount,tva) {
    return await fetchDistant(`/discount?id=${idDocument}`, { priceDiscount: priceDiscount, tva:  tva });
}

const Summary = props => {
    const totalDiscount = useSelector(({data}) => get(data, "totalDiscount", null));

    const [total, setTotal] = useState([]);
    const [tree, setTree] = useState([]);

    const [recapReady, setRecapReady] = useState(false);

    // Use of redux
    const dataState = useSelector(selectDataState);
    const tarifsState = useSelector(selectTarifsState);
    const genericState = useSelector(selectGenericState);

    // useEffect when component is mounting
    useEffect(() => {
        preRenderRecap();

        setRecapReady(true);
    }, []);

    const handleDiscountChange = async (label, subLabel, newValue) => {
        const totalDiscountFromRaw = getInDataState('totalDiscount');

        const key = subLabel ? label + subLabel : label;
        const index = totalDiscountFromRaw.findIndex(x => {
            const itemKey = x.subLabel ? x.label + x.subLabel : x.label;
            return itemKey === key;
        });

        if (index >= 0) {
            const newTotalDiscount = [...totalDiscountFromRaw];
            const item = newTotalDiscount[index];
            const result = await fetchTotalDiscount(
                genericState.idDocument,
                item.subLabel ? { price: newValue, subLabel: subLabel, label: label} : { price: newValue, label: label }
                , dataState.tva ?? TVA_DEFAULT
            );

            newTotalDiscount[index] = result.data;

            setInDataState('totalDiscount', newTotalDiscount);
        }
    }

    useEffect(() => {
        if (recapReady) {
            const tva = dataState.tva ?? TVA_DEFAULT;

            Promise.all([fetchTree(genericState.idDocument, tarifsState), fetchTotal(genericState.idDocument, tarifsState, tva)])
                .then((values) => {
                    const [tree, total] = values;

                    setTree(tree.data);
                    setTotal(total.data);
                    setInDataState('totalDiscount', total.data);
                })
        }
    }, [recapReady, tarifsState, dataState.tva, genericState.idDocument])

    return (
        recapReady && total && total.length > 0 && (
            <>
                <Recap tree={tree} />
                <Total total={total} totalDiscount={totalDiscount} handleDiscountChange={handleDiscountChange}/>
            </>
        )
    );
};

export default Summary;
