import { connect } from 'react-redux'
import Menu from '../Components/Menu'
import { mapStateToPropsAddable } from '../Utils/mapToProps'

const mapStateToProps = (state, ownProps) => ({
    ...mapStateToPropsAddable(state, ownProps),
    gridElements: ['commercialName', 'address', 'postalCode', 'email', 'websiteUrl', 'phoneNumber', 'businessAndServices', 'unionOrOrganization', 'additionalUsers'],
    spanElements: [],
    add: 'un point de vente',
    min: ownProps.min,
    max: ownProps.max ? ownProps.max : 5,
    refs: id => ({
        commercialName: {
            type: 'InputTextContainer',
            name: `${id}/commercialName`,
            label: 'commercial-name'
        },
        address: {
            type: 'InputTextContainer',
            name: `${id}/address`,
            label: 'adresse',
            required: true
        },
        postalCode: {
            type: 'InputTextContainer',
            name: `${id}/postalCode`,
            label: 'cp',
            required: true
        },
        email: {
            type: 'InputTextContainer',
            name: `${id}/email`,
            label: 'email',
            required: true
        },
        websiteUrl: {
            type: 'InputTextContainer',
            name: `${id}/websiteUrl`,
            label: 'site-web'
        },
        phoneNumber: {
            type: 'InputTextContainer',
            name: `${id}/phoneNumber`,
            label: 'telephone',
            required: true
        },
        businessAndServices: {
            type: 'SelectContainer',
            name: `${id}/businessAndServices`,
            label: 'business-and-services',
            options: ['transaction', 'location', 'gestion-locative', 'syndic', 'immo-entrep', 'Autre'],
            isMulti: true
        },
        unionOrOrganization: {
            type: 'SelectContainer',
            name: `${id}/unionOrOrganization`,
            label: 'union-or-organization',
            options: ['fnaim', 'unis', 'snpi', 'galian', 'Autre'],
            isMulti: true
        },
        additionalUsers: {
            type: 'CounterLabeledContainer',
            name: `${id}/additionalUsers`,
            label: "mooveo-users",
            fitContent: true,
        }
    })
})

export default connect(
    mapStateToProps
)(Menu)
