import { connect } from 'react-redux'
import ConditionnalComponent from '../Components/ConditionnalComponent'
import condition from '../Utils/condition'

const mapStateToProps = (_, ownProps) => {
  return {
    condition: condition(ownProps)
  }
}

export default connect(
  mapStateToProps
)(ConditionnalComponent)
