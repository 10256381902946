import React from 'react';
import Addable from './Addable';
import styled from 'styled-components';
import Builder from '../Utils/builder';
import { grey } from '../Utils/styleConstants';
import { v4 as uuid } from 'uuid';

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

const Row = styled.div`
    flex: 1;
    margin: .5em 0;
`;

const BtnContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Btn = styled.div`
    background: ${grey};
    color: white;
    margin: 2em;
    padding: 1em;
    border-radius: 5px;
    cursor: pointer;
`;

const BottomMention = styled.div`
    font-style: italic;
    color: ${grey};
`;

/**
 *
 * @param {Object} override
 * @return {(function(*, *): (*))|*}
 */
function resolveStyleOverride(override) {
    return (id, order) => {
        if (override) {
            if (typeof override === 'function') {
                return override(id, order);
            }

            return override;
        }

        return {};
    }
}

const getAddLabel = ({ max, add }, ids) => {
    if (max && ids.length < max) {
        const addLabel = add ? add : 'un titre';
        return `Ajouter ${addLabel} (${max} maximum)`;
    }

    if (add && !max) {
        return `Ajouter ${add}`;
    }

    return false;
};

class Menu extends Addable {
    addToTree (tree) {
        const addLabel = getAddLabel(this.props, this.state.ids)
        if (addLabel) {
            tree.push(<BtnContainer key={uuid()}><Btn onClick={this.handleAdd}>{addLabel}</Btn></BtnContainer>)
        }
        if (this.props.bottomMention) {
            tree.push(<BottomMention key={uuid()} dangerouslySetInnerHTML={{__html: this.props.bottomMention}} />)
        }
        this.setState({ tree })
    }
    getFirstRow (props) {
        return this.getRow({...props, first: true})
    }
    getElementProps (id, order) {
        const styleOverride = this.props.styleOverride
            ? resolveStyleOverride(this.props.styleOverride.menuElement)(id, order)
            : {}
        ;

        return {
            ...this.props,
            priced: false,
            type: 'MenuElement',
            masterId: this.props.masterId,
            id,
            handleDelete: this.handleDelete(id, this.props.masterId),
            name: `${this.props.name}|${id}`,
            order,
            styleOverride: styleOverride
        }
    }
    getRow (props) {
        const TitleIfPresent = React.memo(function Title({ title = null }) {
            if (title) {
                const value = title(props.id, props.order);
                return <h1>{value}</h1>
            }

            return null;
        });

        const MenuElement = () => Builder.create(this.props.masterId, props)

        const containerStyle = this.props.styleOverride
            ? resolveStyleOverride(this.props.styleOverride.containerMenuElement)(props.id, props.order)
            : {}
        ;

        return (
            <div style={containerStyle}>
                <TitleIfPresent title={this.props.menuTitle} />
                <RowContainer key={uuid()}>
                    <Row>
                        <MenuElement />
                    </Row>
                </RowContainer>
            </div>
        )
    }
}

export default Menu;
