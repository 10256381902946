import ScreenOrientation from "./ScreenOrientation";
import styled from "styled-components";
import {borderGrey} from "../../../Utils/styleConstants";
import ScreenInch from "./ScreenInch";

const Container = styled.div`
    display: flex;
    width: 100%;
    box-shadow: rgba(99, 99, 99, 0.3) 0 0 4px 0;
    margin: 30px 0;
    padding: 20px;
`

const LeftContainer = styled.div`
    width: 50%;
    padding-right: 2%;
    border-right: 1px solid ${borderGrey};
`

const RightContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding-left: 2%;
`
const ScreenConfiguration = ({
    playerSelected,
    onPlayerSelected,
    settingSelected,
    onSettingsSelected,
    inchSelected,
    orientationSelected,
    onInchClick,
    onOrientationClick,
    type
}) => {
    return (
        <Container>
            <LeftContainer>
               <ScreenOrientation
                   onPlayerSelected={onPlayerSelected}
                   playerSelected={playerSelected}
                   onSettingsSelected={onSettingsSelected}
                   settingSelected={settingSelected}
                   onClick={onOrientationClick}
                   selected={orientationSelected}
               />
            </LeftContainer>
            <RightContainer>
                <ScreenInch
                    selected={inchSelected}
                    onClick={onInchClick}
                    orientation={orientationSelected}
                    type={type}
                />
            </RightContainer>
        </Container>
    )
}

export default ScreenConfiguration;
