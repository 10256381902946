import {getLabel} from "../../../../Utils/labels";

export const posterHolderSizeOptions = [
    {
        title: getLabel('format-a4'),
        value: 'format-a4',
        price: 80
    },
    {
        title: getLabel('format-a3'),
        value: 'format-a3',
        price: 100
    },
    {
        title: getLabel('format-a2'),
        value: 'format-a2',
        price: 280
    },
    {
        title: getLabel('round'),
        value: 'round',
        price: 100
    },
    {
        title: getLabel('square-diamond'),
        value: 'square-diamond',
        price: 100
    },
    {
        title: getLabel('others'),
        value: 'others',
        price: 100
    }
];

export const screenOrientationOptions = [
    {
        title: getLabel('portrait'),
        value: 'portrait',
        options: [
            { title: getLabel('size-a4'), value: 'size-a4' },
            { title: getLabel('size-a3'), value: 'size-a3' },
            { title: getLabel('size-a2'), value: 'size-a2' }
        ]
    },
    {
        title: getLabel('landscape'),
        value: 'landscape',
        options: [
            { title: getLabel('size-a4'), value: 'size-a4' },
            { title: getLabel('size-a3'), value: 'size-a3' },
            { title: getLabel('size-a2'), value: 'size-a2' }
        ]
    }
];

export const edgeTypeOptions = [
    {
        title: getLabel('rounded'),
        value: 'rounded',
        options: [
            { title: getLabel('size-a4'), value: 'size-a4' },
            { title: getLabel('size-a3'), value: 'size-a3' },
            { title: getLabel('size-a2'), value: 'size-a2' }
        ]
    },
    {
        title: getLabel('squares'),
        value: 'squares',
        options: [
            { title: getLabel('size-a4'), value: 'size-a4' },
            { title: getLabel('size-a3'), value: 'size-a3' },
            { title: getLabel('size-a2'), value: 'size-a2' }
        ]
    }
];

export const colorisOptions = [
    {
        title: getLabel('black'),
        value: 'black',
        options: [
            { title: getLabel('size-a4'), value: 'size-a4' },
            { title: getLabel('size-a3'), value: 'size-a3' },
            { title: getLabel('size-a2'), value: 'size-a2' }
        ]
    },
    {
        title: getLabel('grey'),
        value: 'grey',
        options: [
            { title: getLabel('size-a4'), value: 'size-a4' },
            { title: getLabel('size-a3'), value: 'size-a3' },
            { title: getLabel('size-a2'), value: 'size-a2' }
        ]
    }
];

export const railOptions = [
    {
        title: getLabel('size-1m'),
        value: 'size-1m',
        price: 50
    },
    {
        title: getLabel('size-1m5'),
        value: 'size-1m5',
        price: 65
    }
];

export const engravingOptions = [
    {
        value: 'engraving',
        price: 10,
        options: [
            { title: getLabel('size-a4'), value: "size-a4" },
            { title: getLabel('size-a3'), value: "size-a3" },
            { title: getLabel('size-a2'), value: "size-a2" }
        ]
    }
];

export const blackLightPaperOptions = [
    {
        title: getLabel('ream-format-a4'),
        value: 'ream-format-a4',
        price: 20
    },
    {
        title: getLabel('ream-format-a3'),
        value: 'ream-format-a3',
        price: 30
    },
];
