import React from 'react'
import { connect } from 'react-redux'
import { dispatch } from '../Store/store'
import { fulfilMandatories } from '../Utils/jsonInterpreter'
import {
  CHANGE_PAGE,
  MANDATORY_MISSING,
  NOT_CONNECTED,
  KO_NETWORK,
  OK_NETWORK,
  DELETE_BULK_DATA,
  DELETE_MANDATORY_PREDICATES
} from '../Store/actions'
import { NET_DOWN } from '../Store/network-state'
import Link from '../Components/Link'
import styled from 'styled-components'
import { CenteredContainer, grey } from '../Utils/styleConstants'
import server from '../Utils/get-server'
import condition from '../Utils/condition'
import {getDataState} from "../Utils/storeHelpers";

const Footer = styled.div`
  padding: 6em 0 2em;
  margin-top: -120px;
  background: ${grey};
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 350px;
`

const Container = styled(CenteredContainer)`
  display: flex;
  justify-content: center;
`

const Column = styled.div`
  display: flex;
  flex: 0 0 50%;
  justify-content: center;
  align-items: center;
`

const getFieldsNames = e => e !== undefined && e.fields !== undefined ? e.fields.filter(field => field.name !== undefined).map(({ name }) => name) : []

class Pager extends React.Component {
  constructor (props) {
    super(props)
    this.handlePage = this.handlePage.bind(this)
    this.handleNextPage = this.handleNextPage.bind(this)
    this.handlePreviousPage = this.handlePreviousPage.bind(this)
    this.nextPage = this.nextPage.bind(this)
    this.previousPage = this.previousPage.bind(this)
    this.checkMandatory = this.checkMandatory.bind(this)
    this.handleEndPage = this.handleEndPage.bind(this)
    this.saveDocument = this.saveDocument.bind(this)
    this.getNextPageNumber = this.getNextPageNumber.bind(this)
    this.getPreviousPageNumber = this.getPreviousPageNumber.bind(this)
    this.testPage = this.testPage.bind(this)
    this.getDataToClean = this.getDataToClean.bind(this)
    this.cleanData = this.cleanData.bind(this)
    this.getRawPageNumber = this.getRawPageNumber.bind(this)
    this.maxPage = this.props.doc.pages !== undefined ? this.props.doc.pages.length - 1 : 0
  }

  checkMandatory () {
    if (!process.env.REACT_APP_TRUE_SERVER) {
      return true;
    }

    let missing = false;
    const currentPage = this.props.doc.pages[this.props.page];

    // Mandatories field in json page
    if (currentPage && currentPage.mandatories) {
      if (currentPage.mandatories.predicates) {
        const predicates = currentPage.mandatories.predicates;
        const data = getDataState();

        const messages = [];
        for (const predicate of predicates) {
          const { fn: predicateFn, message } = predicate;

          if (!predicateFn) {
            continue;
          }

          if (predicateFn.apply(null, [data]) === false) {
            missing = true;
            messages.push(message);
          }
        }

        if (missing) {
          dispatch({ type: MANDATORY_MISSING, payload : { messages: messages } });
          return !missing;
        }
      }

      const fulfil = fulfilMandatories(currentPage.mandatories);

      if (true === !fulfil) {
        missing = !fulfil;
        dispatch({ type: MANDATORY_MISSING });
      }
    }

    return !missing;
  }

  saveDocument (cb) {
    const data = { ...this.props.dataState, _tarifs: this.props.tarifsState }
    return server.saveDocument({
      id: this.props.idDocument,
      user: this.props.user,
      data
    }).then(token => {
      dispatch({ type: OK_NETWORK })
      if (cb) {
        cb({
          id: this.props.idDocument,
          token
        })
      }
      return true
    }).catch(e => {
      if(e.message.indexOf('403') > -1) {
        dispatch({ type: NOT_CONNECTED })
      } else {
        dispatch({ type: KO_NETWORK })
      }
      return false
    })
  }

  getNextPageNumber () {
    return this.getRawPageNumber(this.props.page, n => n + 1, n => n < this.maxPage)
  }

  getPreviousPageNumber () {
    return this.getRawPageNumber(this.props.page, n => n - 1, n => n > 0)
  }

  getRawPageNumber (currentPageNumber, walkFn, whileFn) {
    do {
      currentPageNumber = walkFn(currentPageNumber)
      if (this.testPage(currentPageNumber)) {
        return currentPageNumber
      }
    } while (whileFn(currentPageNumber))

    return false
  }

  testPage (numberPage) {
    const pageToTest = this.props.doc.pages[numberPage];
    let conditionFulfil = false;

    if (pageToTest !== undefined) {
      conditionFulfil = condition(pageToTest);
    }
    
    return conditionFulfil;
  }

  getDataToClean (numberPage) {
    const pageToClean = this.props.doc.pages[numberPage]
    if (pageToClean) {
      return pageToClean.groups.map(({ id, head, body }) => {
        const row = [...getFieldsNames(head), ...getFieldsNames(body)]
        return {id, row}
      }).filter(o => o.row.length > 0)
    }
    return []
  }

  cleanData () {
    const allDataToClean = [...Array(this.maxPage).keys()].map( pageNumber => {
      if (!this.testPage(pageNumber)) {
        return this.getDataToClean(pageNumber)
      }
      return []
    }).reduce((acc, val) => [...acc, ...val])
    dispatch({
      type: DELETE_BULK_DATA,
      value: allDataToClean
    })
  }

  cleanMandatories() {
    dispatch({ type: DELETE_MANDATORY_PREDICATES });
  }

  handleNextPage (page) {
    if (this.checkMandatory()) {
      this.handlePage(page, 'inc')
    }
  }

  handlePreviousPage (page) {
    this.handlePage(page, 'dec')
  }

  handlePage (newPage, stepUpdate) {
    this.cleanMandatories();
    this.cleanData()
    this.saveDocument().then(() => {
        if (NET_DOWN !== this.props.networkState) {
          dispatch({
            type: CHANGE_PAGE,
            payload: {
              page: newPage,
              stepUpdate: stepUpdate
            }
          })
        }
      })
  }

  handleEndPage () {
    this.cleanMandatories();
    this.cleanData()
    server.saveDocument({
      id: this.props.idDocument,
      data: { ...this.props.dataState, _tarifs: this.props.tarifsState },
      user: this.props.user
    }).then(() => {
      if (NET_DOWN !== this.props.networkState) {
        dispatch({
          type: CHANGE_PAGE,
          payload: {
            page: -1,
            stepUpdate: 'inc'
          }
        })
      }
    })
  }

  nextPage () {
    const nextPage = this.getNextPageNumber()
    if (nextPage === false) {
      return <Column><Link label="Générer le document" green handle={() => this.handleEndPage()} /></Column>
    }
    return <Column><Link label="étape suivante" handle={() => this.handleNextPage(nextPage)} arrowNext={true} /></Column>
  }

  previousPage () {
    const prevPage = this.getPreviousPageNumber()
    if (prevPage === false) {
      return null
    }
    return <Column><Link label="étape precedente" handle={() => this.handlePreviousPage(prevPage)} arrowPrev={true} /></Column>
  }

  render () {
    return (
      <Footer>
        <Container>
          {this.previousPage()}
          {this.nextPage()}
        </Container>
      </Footer>
    )
  }
}

const mapStateToProps = state => ({
  page: state.generic.page,
  doc: state.generic.document,
  idDocument: state.generic.idDocument,
  networkState: state.generic.networkState,
  user: state.generic.user,
  dataState: state.data,
  tarifsState: state.tarifs,
})

export default connect(mapStateToProps)(Pager)
