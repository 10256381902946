import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Counter from './Counter';
import PriceElement from './PriceElement';
import CheckmarkContainer from './Icons/CheckmarkContainer';
import HektorCro from '../Data/hk_cro.svg';
import { StyledCheckbox } from '../Utils/styleConstants';
import { deleteInDataState, getInDataState, setFinalPriceInTarifsState } from '../Utils/storeHelpers';

const Container = styled(StyledCheckbox)`
    justify-content: space-evenly;
    padding: 0.5em;
    cursor: pointer;
`;

const Image = styled.img`
    max-height: 300px;
`;

const Cro = styled.p`
    display: flex;
    align-items: flex-end;
    text-transform: uppercase;
    font-size: 30px;
    padding: 2em 0;
`;

const Maj = styled.span`
    font-size: 60px;
    font-weight: bold;
    line-height: 0.95;

    &:not(:first-child) {
        padding: 0 0 0 10px;
    }
`;

const PriceElementStructure = styled.div`
    padding: 0 0 20px;
`;

const CroSelector = props => {
    // Use of hooks
    const [checked, setChecked] = useState(null);

    // useEffect when component is mounting
    useEffect(() => {
        null === checked && setChecked(getInDataState(props.pathInDataState) ?? false);
    }, [checked, props.pathInDataState])

    // Callback when user click on container
    const handleClickContainer = useCallback(() => {
        // Invert checked hook value
        setChecked(!checked);

        // If user uncheck
        if (false === !checked) {
            // Reset dataState
            deleteInDataState(props.pathInDataState);
            // Reset finalPrice
            setFinalPriceInTarifsState(props.tarifsName, null);
        }
    }, [checked, props.pathInDataState, props.tarifsName])

    // Callback when user change cro counter
    const handleCroCounter = useCallback((_, event) => {
        event.stopPropagation();

        // Reset finalPrice
        setFinalPriceInTarifsState(props.tarifsName, null);
    }, [props.tarifsName])

    return (
        null !== checked && (
            <Container
                checked={checked}
                onClick={handleClickContainer}
            >
                <Image src={HektorCro} alt='#' />
                <div>
                    <Cro><Maj>C</Maj>ontenu <Maj>R</Maj>édactionnel <Maj>O</Maj>ptimisé</Cro>
                    <PriceElementStructure>
                        <PriceElement
                            tarifsName={props.tarifsName}
                            fontSize='4em'
                        />
                    </PriceElementStructure>
                    {
                        checked && (
                            <Counter
                                min={1}
                                margin='0'
                                fontSize='20px'
                                masterId={props.pathInDataState}
                                initialValue={getInDataState(props.pathInDataState) ?? 1}
                                callback={handleCroCounter}
                            />
                        )
                    }
                </div>
                <CheckmarkContainer checked={checked} />
            </Container>
        )
    );
};

export default CroSelector;
