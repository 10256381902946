import React from 'react';
import PuffLoader from 'react-spinners/PuffLoader';
import { green } from '../Utils/styleConstants';

const Spinner = props => {
    return (
        <PuffLoader
            loading={props.loading ?? true}
            color={green}
            size={props.size}
        />
    );
};

export default Spinner;
