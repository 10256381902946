import React from 'react';
import styled from 'styled-components';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import { getImage } from '../../Utils/jsonInterpreter';
import { StyledCheckbox } from '../../Utils/styleConstants';

const Container = styled(StyledCheckbox)`
    flex-wrap: wrap;
    height: 257.13px;
    width: 23%;
    ${props => props.centerOptions && 'justify-content: center;'}
    align-items: center;
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
    padding: 25px;
    width: ${({ width }) => width ?? 'initial' };
    height: ${({ height }) => height ?? 'initial' };
`;

const CheckableImage = props => {
    return (
        <Container
            checked={props.checked}
            onClick={() => props.onClick(props.index, props.option)}
            centerOptions={props.option.centerOptions}
        >
            <Image width={props.option.width} height={props.option.height} src={getImage(props.option.img)} alt='#' />
            <CheckmarkContainer checked={props.checked} />
        </Container>
    )
}

export default CheckableImage;
