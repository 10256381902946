import Cookies from 'universal-cookie';
import axios from 'axios';
import parse from 'url-parse';
import labels from '../Fake/labels.json';
import tarifs from '../Fake/tarifs.json';
import price from '../Fake/price';

const reloadUrl = () => {
    const url = parse(window.location, true);

    document.location.href = `${url.protocol}//${url.host}${url.pathname}`;

    return false;
};

const fetch = async (uri, postData, storeData) => {
    if (process.env.REACT_APP_TRUE_SERVER || (process.env.REACT_APP_TRUE_AUTH && uri.indexOf('logged') > -1)) {
        const cookies = new Cookies()
        const cookieToken = cookies.get('thetoken')
        if (cookieToken) {
            const config = {
                headers: {
                    'Authorization': `Bearer ${cookieToken}`,
                    'Access-Control-Allow-Origin': '*'
                }
            };

            if (postData) {
                return await axios.post(
                    `${process.env.REACT_APP_SERVER_URI}${uri}`,
                    postData,
                    config
                );
            }

            return await axios.get(`${process.env.REACT_APP_SERVER_URI}${uri}`, config);
        }
        return reloadUrl();
    } else {
        if (uri.indexOf('labels') > -1) {
            return {data: labels};
        }

        if (uri.indexOf('tarifs') > -1) {
            return {data: tarifs};
        }

        if (uri.indexOf('price') > -1) {
            const yearPrice = price.getPriceWithoutCharges('year')(storeData)(postData.tarifs);
            const mensPrice = price.getPriceWithoutCharges('month')(storeData)(postData.tarifs);
            const depositPrice = price.getPriceWithoutCharges('deposit')(storeData)(postData.tarifs);
            const buyPrice = price.getPriceWithoutCharges()(storeData)(postData.tarifs);
            return {
                data: {
                    loc: {
                        price: mensPrice,
                        tva: price.getTva20(mensPrice)
                    },
                    buy: {
                        price: buyPrice,
                        tva: price.getTva20(buyPrice)
                    },
                    year: {
                        price: yearPrice,
                        tva: price.getTva20(yearPrice)
                    },
                    deposit: {
                        price: depositPrice,
                        tva: price.getTva20(depositPrice)
                    },
                    tree: price.createTree(storeData)(postData.tarifs)
                }
            };
        }
        if (uri.indexOf('social') > -1) {
            const social = [
                {name: 'facebook', color: '#1A76D2'},
                {name: 'twitter', color: '#55acee'}
            ];

            return {data: social.map(el => ({...el, icon: `/${el.name}.svg`}))};
        }
    }
    return reloadUrl();
};

export default fetch;
