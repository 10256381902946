import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import DomainName from './DomainName';
import { replaceDataInStore } from '../Utils/storeHelpers';
import { SimpleAddButton } from '../Utils/styleConstants';

const DomainNamesContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const AddDomainButton = styled(SimpleAddButton)`
    margin: 0 0 2em;
`;

const Italic = styled.p`
    font-style: italic;
`;

const BoldItalic = styled.p`
    font-style: italic;
    font-weight: bold;
`;

const DomainNamesList = (props) => {
    // Use of hooks
    const [mounted, setMounted] = useState(false);

    // useEffect of component mounting
    useEffect(() => {
        if (!mounted) {
            setMounted(true);
            0 === props.domainNames.length && props.dispatch({
                type: props.action,
                value: {
                    id: props.masterId,
                    name: props.name,
                    index: 0,
                    value: props.emptyDomainName
                }
            });
        } 
    }, [props, mounted])

    // Callback to add a domain name
    const handleAddDomainClick = useCallback(() => {
        props.dispatch({
            type: props.action,
            value: {
                id: props.masterId,
                name: props.name,
                index: props.domainNames.length,
                value: props.emptyDomainName
            }
        })
    }, [props])

    // Callback to delete a domain name
    const handleDeleteDomainClick = useCallback((deleteIndex) => {
        replaceDataInStore(props.masterId, props.name, props.domainNames.filter((item, index) => index !== deleteIndex));
    }, [props])

    return (
        mounted && (
            <DomainNamesContainer>
                {
                    props.domainNames.map((item, index) => (
                        <DomainName
                            key={index}
                            index={index}
                            domainName={props.domainNames[index]}
                            isDeletable={index > 0}
                            deleteCallback={handleDeleteDomainClick}
                            {...props}
                        />
                    ))
                }
                <AddDomainButton onClick={handleAddDomainClick}>
                    Ajouter un nom de domaine
                </AddDomainButton>
                <Italic>Le prestataire actuel devra réaliser une opération technique afin que nous puissions lancer l'opération de transfert.</Italic>
                <BoldItalic>Les noms de domaines à transférer doivent être disponibles à la même date que le NDD principal.</BoldItalic>
            </DomainNamesContainer>
        )
    );
};

export default DomainNamesList;
