import { connect } from 'react-redux'
import Menu from '../Components/Menu'
import { mapStateToPropsAddable } from '../Utils/mapToProps'

const mapStateToProps = (state, ownProps) => ({
    ...mapStateToPropsAddable(state, ownProps),
    gridElements: ['label', 'type', 'secteur', 'codePostal', 'specificite'],
    spanElements: [],
    add: 'un univers',
    min: ownProps.min,
    max: ownProps.max ? ownProps.max : 5,
    bottomMention: `<center>Une seule spécificité par univers, exemple : vue mer, centre ville, montagne...</center>`,
    refs: id => ({
        label: {
            type: 'LabelAdd',
            label: 'univers-order'
        },
        type: {
            type: 'SelectContainer',
            name: `${id}/type`,
            isLabeled: false,
            options: ['constructeur', 'estimation', 'expertise', 'gestion-admin', 'immo-entrep', 'location', 'loc-saison', 'prog-neuf', 'recrut-mandataire',
                'syndic', 'syndic-copro', 'transaction', 'viager']
        },
        secteur: {
            type: 'InputTextContainer',
            name: `${id}/secteur`,
            label: 'secteur'
        },
        specificite: {
            type: 'InputTextContainer',
            name: `${id}/specificite`,
            label: 'specificite'
        },
        codePostal: {
            type: 'InputTextContainer',
            name: `${id}/codePostal`,
            label: 'cp'
        }
    })
})

export default connect(
    mapStateToProps
)(Menu)
