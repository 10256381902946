import React from 'react'
import Labeled from './Labeled'
import InputItem from './InputItem'

class InputNumber extends React.Component {
    render () {
        return (
            <Labeled {...this.props}>
                <InputItem key={this.props.name} {...this.props} innerType={'number'}/>
            </Labeled>
        )
    }
}

export default InputNumber
