import {getInDataState, getInTarifsState} from "../Utils/storeHelpers";

const RANGE_1 = 1;
const RANGE_2 = 25;
const RANGE_3 = 100;
const RANGE_4 = 200;
const RANGE_5 = 500;

const computeOskarPrice = (numberOfLot) => {
    const oskarData = getInDataState('offre-oskar.type');
    const offre = oskarData[0];
    const numberOflotAdditional = numberOfLot ;

    function isBetween(value, rangeStart, rangeEnd) {
        return value >= rangeStart && value <= rangeEnd;
    }

    let price = 0;
    if (numberOflotAdditional >= 0 && numberOflotAdditional !== null) {
        if (isBetween(numberOflotAdditional, RANGE_1 , RANGE_2)) {
            price += numberOflotAdditional * getInTarifsState('oskar-value-range-0-display').price[offre];
        }
        else if (numberOflotAdditional > RANGE_2) {
            price += RANGE_2 * getInTarifsState('oskar-value-range-0-display').price[offre];
        }
        if (isBetween(numberOflotAdditional, RANGE_2 , RANGE_3)) {
            price += (numberOflotAdditional - RANGE_2) * getInTarifsState('oskar-value-range-1-display').price[offre];
        }
        else if (numberOflotAdditional > RANGE_3) {
            price += (RANGE_3 - RANGE_2) * getInTarifsState('oskar-value-range-1-display').price[offre];
        }
        if (isBetween(numberOflotAdditional, RANGE_3 , RANGE_4)) {
            price += (numberOflotAdditional - RANGE_3) * getInTarifsState('oskar-value-range-2-display').price[offre];
        }
        else if (numberOflotAdditional > RANGE_4) {
            price += (RANGE_4 - RANGE_3) * getInTarifsState('oskar-value-range-2-display').price[offre];
        }
        if (isBetween(numberOflotAdditional, RANGE_4 , RANGE_5)) {
            price += (numberOflotAdditional - RANGE_4) * getInTarifsState('oskar-value-range-3-display').price[offre];
        }
        else if (numberOflotAdditional > RANGE_5) {
            price += (RANGE_5 - RANGE_4) * getInTarifsState('oskar-value-range-3-display').price[offre];
            price += (numberOflotAdditional - RANGE_5) * getInTarifsState('oskar-value-range-4-display').price[offre];
        }
    }
    return price;
}

export default computeOskarPrice;