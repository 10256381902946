import {
    addFinancingPrice,
    addInFinalPrices,
    getInDataState,
    getInTarifsState,
    resetFinalPrices
} from "../../storeHelpers";
import viceVersaPrices from "../../../Components/LbiMedia/Led/ViceVersaConfiguration/prices";
import regularPrices from "../../../Components/LbiMedia/Led/RegularConfiguration/prices";

import {getLabel} from "../../labels";
import {PaymentType} from "../../../types/payment";

let prices = regularPrices;

export const loadLedOffer = (state) => {
    const quantities = state?.led?.count ?? {};

    Object.entries(quantities).forEach(([type, quantity]) => {
        resetAllPrices(type);

        if (quantity <= 0) {
            return;
        }

        switch (type) {
            case 'vice-versa':
                loadViceVersa();
                break;
            case 'crystal':
                loadRegular('crystal');
                break;
            case 'sublime':
                loadRegular('sublime');
                break;
            default:
                throw new Error("Le type d'écran n'est pas reconnu");
        }
    })
}

const loadRegular = (configurationName) => {
    prices = regularPrices;

    const configuration = getInDataState(`offre-lbi-media.led.configurations.${configurationName}`);
    const tarifPlaceholder = getInTarifsState(`lbi-media-led-placeholder-${configurationName}`);

    loadChoices(tarifPlaceholder, configuration, 'poster-holder-size');
    loadChoicesGroup(tarifPlaceholder, configuration, 'screen-orientation');
    loadChoices(tarifPlaceholder, configuration, 'rail');
    loadSimpleChoice(tarifPlaceholder, configuration, 'cable-mounting-kit');
    loadSimpleChoice(tarifPlaceholder, configuration, 'inter-rail-connection');
    loadSimpleChoice(tarifPlaceholder, configuration, '12v-power-supply');
    loadSimpleChoice(tarifPlaceholder, configuration, 'power-cable');
    loadSimpleChoice(tarifPlaceholder, configuration, 'white-power-supply-cover');
    loadChoicesGroup(tarifPlaceholder, configuration, 'engraving');
    loadChoices(tarifPlaceholder, configuration, 'blacklight-paper');
    loadChoicesGroup(tarifPlaceholder, configuration, 'coloris');
    loadChoicesGroup(tarifPlaceholder, configuration, 'edge-types');
    loadNumberOfColumns(tarifPlaceholder, configuration);
    loadSimpleChoice(tarifPlaceholder, configuration, 'order-preparation-and-delivery');
}

const loadViceVersa = () => {
    prices = viceVersaPrices;

    const configuration = getInDataState('offre-lbi-media.led.configurations.vice-versa');
    const tarifPlaceholder = getInTarifsState('lbi-media-led-placeholder-vice-versa');

    loadChoices(tarifPlaceholder, configuration, 'poster-holder-size');
    loadChoices(tarifPlaceholder, configuration, 'coloris');
    loadChoices(tarifPlaceholder, configuration, 'rail');
    loadSimpleChoice(tarifPlaceholder, configuration, 'inter-rails-rigid-connector');
    loadSimpleChoice(tarifPlaceholder, configuration, 'power-cable');
    loadSimpleChoice(tarifPlaceholder, configuration, 'piston-suspension-kit-two-packs');
    loadChoices(tarifPlaceholder, configuration, 'intermediate-cable-kit');
    loadSimpleChoice(tarifPlaceholder, configuration, 'tilt-kit'); 
    loadChoices(tarifPlaceholder, configuration, 'transformer');
    loadSimpleChoice(tarifPlaceholder, configuration, 'transformer-cover');
    loadChoices(tarifPlaceholder, configuration, 'blacklight-paper');
    loadChoices(tarifPlaceholder, configuration, 'impressions');
    loadNumberOfColumns(tarifPlaceholder, configuration);
    loadSimpleChoice(tarifPlaceholder, configuration, 'order-preparation-and-delivery');
}

const loadNumberOfColumns = (cart, configuration) => {
    const OPTION_NAME = 'number-of-columns';
    const payment = getInDataState('offre-lbi-media.led.payment');
    const count = configuration[OPTION_NAME] ?? 0;

    if (count > 0) {
        const price = prices[OPTION_NAME][count] ?? 0;
        const label = getRecapLabelOrDefault(OPTION_NAME);

        if (payment.type === PaymentType.FINANCEMENT) {
            addFinancingPrice(cart.id, {
                id: `${cart.id}-${OPTION_NAME}`,
                name: `${count} ${label}`,
                price: price === 0 ? 'inclus' : price,
                financingDuration: payment.financing.duration,
                displayWhenParent: 'parent-offre-lbi-media-led'
            });
        } else {
            addInFinalPrices(cart.id, {
                id: `${cart.id}-${OPTION_NAME}`,
                name: `${count} ${label}`,
                price: price === 0 ? 'inclus' : price,
                displayWhenParent: 'parent-offre-lbi-media-led',
            })
        }
    }
}

/**
 * 
 * @param {Object} cart 
 * @param {Object} configuration 
 * @param {string} optionName 
 */
const loadChoicesGroup = (cart, configuration, optionName) => {
    const payment = getInDataState('offre-lbi-media.led.payment');

    const option = configuration[optionName] ?? {};
    const optionPrice = prices[optionName] ?? {};
    const optionLabel = getRecapLabelOrDefault(optionName);

    const entries = Object.entries(option);

    entries.forEach(([name, choices]) => {
        const groupName = getRecapLabelOrDefault(name) ;

        choices.forEach((subName) => {
            const choiceName = getRecapLabelOrDefault(subName);
            const price = optionPrice[name][subName] ?? 0;

            if (payment.type === PaymentType.FINANCEMENT) {
                addFinancingPrice(cart.id, {
                    id: `${cart.id}-${name}-${subName}`,
                    name: `1 ${optionLabel} : ${groupName} ${choiceName}`,
                    price: price === 0 ? 'inclus' : price,
                    financingDuration: payment.financing.duration,
                    displayWhenParent: 'parent-offre-lbi-media-led'
                });
            } else {
                addInFinalPrices(cart.id, {
                    id: `${cart.id}-${name}-${subName}`,
                    name: `1 ${optionLabel} : ${groupName} ${choiceName}`,
                    price: price === 0 ? 'inclus' : price,
                    displayWhenParent: 'parent-offre-lbi-media-led'
                })
            }
        });
    });
}

/**
 * 
 * @param {Object} cart 
 * @param {Object} configuration 
 * @param {string} optionName 
 */
const loadChoices = (cart, configuration, optionName) => {
    const payment = getInDataState('offre-lbi-media.led.payment');

    const option = configuration[optionName] ?? {};
    const optionPrice = prices[optionName] ?? {};

    const optionLabel = getRecapLabelOrDefault(optionName);

    Object.entries(option).forEach(([name, count]) => {
        const price = optionPrice[name] ?? 0;
        const label = getRecapLabelOrDefault(name);

        if (payment.type === PaymentType.FINANCEMENT) {
            addFinancingPrice(cart.id, {
                id: `${cart.id}-${optionName}-${name}`,
                name: `${count} ${optionLabel} : ${label}`,
                price: price === 0 ? 'inclus' : price * count,
                financingDuration: payment.financing.duration,
                displayWhenParent: 'parent-offre-lbi-media-led'
            });
        } else {
            addInFinalPrices(cart.id, {
                id: `${cart.id}-${optionName}-${name}`,
                name: `${count} ${optionLabel} : ${label}`,
                price: price === 0 ? 'inclus' : price * count,
                displayWhenParent: 'parent-offre-lbi-media-led'
            })
        }
    });
}

/**
 * 
 * @param {Object} cart 
 * @param {Object} configuration 
 * @param {string} optionName 
 */
const loadSimpleChoice = (cart, configuration, optionName) => {
    const payment = getInDataState('offre-lbi-media.led.payment');
    const count = configuration[optionName] ?? 0;

    if (count > 0) {
        const price = prices[optionName] ?? 0;
        const label = getRecapLabelOrDefault(optionName);

        if (payment.type === PaymentType.FINANCEMENT) {
            addFinancingPrice(cart.id, {
                id: `${cart.id}-${optionName}`,
                name: `${count} ${label}`,
                price: price === 0 ? 'inclus' : price * count,
                financingDuration: payment.financing.duration,
                displayWhenParent: 'parent-offre-lbi-media-led'
            });
        } else {
            addInFinalPrices(cart.id, {
                id:`${cart.id}-${optionName}`,
                name: `${count} ${label}`,
                price: price === 0 ? 'inclus' : price * count,
                displayWhenParent: 'parent-offre-lbi-media-led',
            })
        }

    }
}

/**
 * 
 * @param {string} name 
 * @returns {string}
 */
const getRecapLabelOrDefault = (name) => {
    const result = getLabel(name, { prefix: 'recap' });

    return result !== '' ? result : getLabel(name);
}

/**
 * 
 * @param {string} type 
 */
const resetAllPrices = (type) => {
    resetFinalPrices(getInTarifsState(`lbi-media-led-placeholder-${type}`).id);
}

export default loadLedOffer;
