import {dispatch, store} from '../Store/store';
import {
    ADD_FINANCING_PRICE,
    ADD_IN_FINALPRICES,
    CHANGE_PRICE,
    DELETE_DATA,
    DELETE_FINALPRICE,
    RESET_FINALPRICE,
    RESET_FINALPRICES,
    SET_DATA,
    UPDATE_DATA
} from '../Store/actions';

export function getState(name) {
    const state = store.getState();

    return name ? state[name] : state;
}

export function getDataState() {
    return getState('data');
}

export function getTarifsState() {
    return getState('tarifs');
}

export function getGenericState() {
    return getState('generic');
}

export function filterPath(path) {
    let finalPath = null;

    // If path does not start or contain an undefined key (which is not the final key)
    if (undefined !== path && !path.includes('undefined.')) {
        // We remove the undefined final key if necessary
        finalPath = path.replace('.undefined', '');
    }

    // Return either filteredPath or null;
    return finalPath;
}

export function safelyGetInState(state, path) {
    // Retrieve an array of keys from path
    const arrayOfKeys = path.split('.');
    const shiftedKey = arrayOfKeys.shift();

    // Stop the recursion because we are at the last key of our initial path
    if (0 === arrayOfKeys.length) {
        return state[shiftedKey];

        // We are not at the end of our path so we need to be able to continue
    } else {
        return undefined !== state[shiftedKey] ?
            // Continue the recursion by passing the state but one step deeper
            safelyGetInState(state[shiftedKey], arrayOfKeys.join('.'))
            : undefined;
    }
}

export function getInState(state, path) {
    const filteredPath = filterPath(path);

    return filteredPath ?
        safelyGetInState(state, filteredPath)
        : undefined;
}

export function getInDataState(path) {
    const state = getDataState();
    const filteredPath = filterPath(path);

    return filteredPath ?
        safelyGetInState(state, filteredPath)
        : undefined;
}

export function getInTarifsState(path) {
    const state = getTarifsState();
    const filteredPath = filterPath(path);

    return filteredPath ?
        safelyGetInState(state, filteredPath)
        : undefined;
}

export function getInGenericState(path) {
    const state = getGenericState();
    const filteredPath = filterPath(path);

    return filteredPath ?
        safelyGetInState(state, filteredPath)
        : undefined;
}

export function getSiteMode() {
    return getInDataState('offre-site.mode');
}

export function getHektorOffer() {
    return getInDataState('offre-hektor.offre');
}

export function getHektorOffers() {
    const hektorData = getInDataState('offre-hektor');
    let hektorOffers = null;

    if (hektorData) {
        hektorOffers = Object.keys(hektorData).filter(key => key !== 'mode' && key !== 'offre');
    }

    return hektorOffers;
}

export function getOffers(offerType, keys = null) {
    let offers = null;
    keys = keys ? keys : ['mode', 'offre', 'type-contract'];
    const data = getInDataState(offerType);

    if (data) {
        offers = Object.keys(data).filter(key => !keys.includes(key));
    }

    return offers;
}

export function getHektorContext() {
    const currentPage = getInGenericState('page');
    const currentPageName = getInGenericState('document.pagesReferences')[currentPage];
    let context = [];

    if (currentPageName.includes('simple')) {
        context.push('simple');
    }
    if (currentPageName.includes('one')) {
        context.push('one');
    }
    if (currentPageName.includes('fnaim')) {
        context.push('fnaim');
    }
    if (currentPageName.includes('agences')) {
        context.push('agences');
    }
    if (currentPageName.includes('mandataires')) {
        context.push('mandataires');
    }

    return context;
}

export function getHektorTypeFromHektorOffer(hektorOffer) {
    return getInDataState(`offre-hektor.${hektorOffer}.type`);
}

export function getPrevisiteTypeFromOffer(offer) {
    const offerInfo = getInDataState(`offre-previsite.${offer}`);
    if (undefined === offerInfo) {
        return undefined;
    }

    const type = getInDataState(`offre-previsite.${offer}.type`);

    return undefined !== type ? type.replace('previsite-', '') : undefined;
}

export function getFullAccessTypeOffer() {
    const offerFullAccess = getInDataState("offre-previsite.type");

    if (offerFullAccess && offerFullAccess.length > 0) {
        return offerFullAccess[0];
    }

    return null;
}

export function setInStore({action, masterId, name}, value) {
    dispatch({
        type: action,
        value: {
            id: masterId,
            name: name,
            value: value
        }
    });
}

export function setInDataState(path, value, isArray, asKey, forArray) {
    const filteredPath = filterPath(path);

    filteredPath && dispatch({
        type: SET_DATA,
        payload: {
            path: filteredPath,
            value: value,
            isArray: isArray,
            asKey: asKey,
            forArray: forArray
        }
    });
}

export function multipleSetInDataState(rows) {
    rows && rows.forEach(row => setInDataState(row.path, row.value));
}

export function multipleClearInDataState(paths) {
    if (paths) {
        paths.forEach(item => {
            if ('object' === typeof item) {
                deleteInDataState(item.path, item.value)
            } else {
                deleteInDataState(item, undefined, undefined, true)
            }
        })
    }
}

export function multipleUnsetInDataState(rows) {
    rows && rows.forEach(row => deleteInDataState(row.path, undefined, undefined, true));
}

export function multipleClearArrayInDataState(rows) {
    rows && rows.forEach(row => deleteInDataState(row.path, row.value, undefined, false));
}

export function createKeysInDataState(keysToCreate) {
    keysToCreate && keysToCreate.forEach(keyToCreate => setInDataState(keyToCreate.path, keyToCreate.key, false, true));
}

export function deleteInDataState(path, value, asKey, deleteArray) {
    dispatch({
        type: DELETE_DATA,
        payload: {
            path: path,
            value: value,
            asKey: asKey,
            deleteArray: deleteArray
        }
    });
}

export function replaceDataInStore(id, name, value) {
    name ?
        dispatch({
            type: UPDATE_DATA,
            value: {
                [id]: {
                    [name]: value
                },
            }
        })
        : dispatch({
            type: UPDATE_DATA,
            value: {
                [id]: value
            }
        });
}

export function addInFinalPrices(tarifsName, item) {
    dispatch({
        type: ADD_IN_FINALPRICES,
        payload: {
            tarifsName: tarifsName,
            item: item
        }
    });
}

/**
 * @function
 * @param {string} name
 * @param {Object} item
 */
export function addFinancingPrice(name, item) {
    dispatch({
        type: ADD_FINANCING_PRICE,
        payload: {
            tariffName: name,
            name: item.name,
            id : item.id,
            price: item.price,
            financingDuration: item.financingDuration,
            financingName: item.financingName,
            displayWhenParent: item.displayWhenParent,
            display: item?.display ?? false,
            displayInOrder: item?.displayInOrder ?? false,
            floor : item?.floor ?? false,
        }
    });
}

export function deleteFinalPriceInStore(id, name) {
    dispatch({
        type: DELETE_FINALPRICE,
        payload: {
            id: id,
            name: name
        }
    })
}

export function multipleResetFinalPriceOfTarifs(tarifsNames) {
    tarifsNames && tarifsNames.forEach(tarifsName => resetFinalPriceOfTarifs(tarifsName));
}

export function resetFinalPriceOfTarifs(tarifsName) {
    tarifsName && dispatch({
        type: RESET_FINALPRICE,
        payload: {
            tarifsName: tarifsName
        }
    })
}

export function resetFinalPrices(tariffName) {
    tariffName && dispatch({
        type: RESET_FINALPRICES,
        payload: {
            name: tariffName
        }
    });
}

export function multipleClearFinalPriceOfTarifs(tarifsNames, index, partOfId) {
    tarifsNames && tarifsNames.forEach(tarifsName => {
        if ('options-add' === tarifsName) {
            resetFinalPriceHektorOptions(false, partOfId);
        } else if ('hektor-agencies' === tarifsName) {
            resetFinalPriceAgencies();
        } else if ('hektor-types' === tarifsName) {
            resetFinalPriceHektorTypes(partOfId);
        } else if ('modules' === tarifsName) {
            resetFinalPriceModules();
        } else if ('pages' === tarifsName) {
            resetFinalPricePages();
        } else if ('blocksSite' === tarifsName) {
            resetFinalPriceBlocksSite();
        } else {
            setFinalPriceInTarifsState(tarifsName, null, index, partOfId);
        }
    });
}

export function setFinalPriceInTarifsState(tarifsName, value, index, partOfId, onlyIfNull, priceUnitInRecap,version) {
    dispatch({
        type: CHANGE_PRICE,
        payload: {
            tarifsName: tarifsName,
            index: index,
            partOfId: partOfId,
            value: value,
            onlyIfNull: onlyIfNull,
            priceUnitInRecap: priceUnitInRecap,
        }
    });
}

/**
 * Dispatch the tarif state mutation with 'CHANGE_PRICE' action by giving a config object
 *
 * @param {Object} config
 */
export function setFinalPrice(config) {
    if (!('name' in config) || config.name === null) {
        throw new Error("name property fog config object cannot be null or undefined");
    }

    dispatch({
        type: CHANGE_PRICE,
        payload: {
            tarifsName: config.name,
            value: config?.value,
            index: config?.index,
            partOfId: config?.partOfId,
            onlyIfNull: config?.onlyIfNull,
            priceUnitInRecap: config?.priceUnitInRecap,
            discount: config?.discount ?? false
        }
    });
}

export function optionWithCounter(offer, option, offerType = 'offre-hektor') {
    let counter;
    counter = getInDataState(`${offerType}.${offer}.quantities.${option}`);

    return counter;
}

export function inHektorOfferWithAgencies(hektorOffer) {
    const targetedOffer = ['hektor-reseau-agences'];

    return hektorOffer ? targetedOffer.includes(hektorOffer) : false;
}

export function inHektorOfferWithMandataires(hektorOffer) {
    const targetedOffer = [
        'hektor-reseau-mandataires',
        'hektor-fnaim-reseau-mandataires',
        'hektor-fnaim-creation-reseau-mandataires'
    ];

    return hektorOffer ? targetedOffer.includes(hektorOffer) : false;
}

export function hektorDependOfNumberOfMandataire(hektorOffer) {
    const tarif = getInTarifsState(hektorOffer);
    return tarif.tarifDependOfMandataire;
}

export function inHektorOfferMixte(givenOffer) {
    const hektorOffer = givenOffer ?? getHektorOffer();

    return [
        'hektor-reseau-mixte',
        'hektor-fnaim-reseau-mixte',
        'hektor-fnaim-creation-reseau-mixte'
    ].includes(hektorOffer);
}

export function getNumberOfHektorAgencies(hektorOffer) {
    let totalAgencies = 0;

    Object.keys(getInDataState(`offre-hektor.${hektorOffer}`)).forEach(key => {
        if (key.startsWith('agencies-')) {
            totalAgencies += Object.keys(getInDataState(`offre-hektor.${hektorOffer}.${key}`)).length
        }
    });

    return totalAgencies;
}

export function getNumberOfHektorMandataires(hektorOffer) {
    const supKeyFnaim = isFnaimOffer(hektorOffer) ? '-fnaim' : '';
    const defaultMandataire = 0;
    const additionalMandataires = getInDataState(`offre-hektor.${hektorOffer}.hektor-additional-mandataires` + supKeyFnaim) ?? 1;

    return defaultMandataire + additionalMandataires;
}

export function getNumberOfHektorAdmins(hektorOffer) {
    const supKeyFnaim = isFnaimOffer(hektorOffer) ? '-fnaim' : '';
    const additionalAdmins = getInDataState(`offre-hektor.${hektorOffer}.additional-admins` + supKeyFnaim) ?? 0;
    const defaultAdmin = getInDataState(`offre-hektor.${hektorOffer}.admin-account` + supKeyFnaim) ?? 0;
    const superAdmin = 1;

    return superAdmin + defaultAdmin + additionalAdmins;
}

export function resetFinalPriceHektorOptions(onlyOptionAdd, partOfId) {
    const tarifsState = getTarifsState();

    Object.entries(tarifsState).forEach(([key, value]) => {
        if (value.hasOwnProperty('group')) {
            'options-hektor-additionnelles' === value.group &&
            setFinalPriceInTarifsState(key, null, undefined, partOfId);

            'options-hektor-incluses' === value.group &&
            setFinalPriceInTarifsState(key, null, undefined, undefined);

            !onlyOptionAdd && 'options-hektor' === value.group &&
            setFinalPriceInTarifsState(key, null, undefined, partOfId);
        }
    });
}

export function resetFinalPriceAgencies() {
    const tarifsState = getTarifsState();

    Object.entries(tarifsState).forEach(([key, value]) => {
        if (value.hasOwnProperty('group') && value.group.includes('hektor-agencies') && value.id.includes('promotion')) {
            setFinalPriceInTarifsState(key, null);
        }
    });
}

export function resetFinalPriceHektorTypes(partOfId) {
    const tarifsState = getTarifsState();

    Object.entries(tarifsState).forEach(([key, value]) => {
        if (value.hasOwnProperty('group') && value.group.includes('offre-hektor')) {
            setFinalPriceInTarifsState(key, null, undefined, partOfId);
        }
    });
}

export function resetFinalPriceModules() {
    const tarifsState = getTarifsState();

    Object.keys(tarifsState).forEach(key => {
        if (key.startsWith('module-')) {
            setFinalPriceInTarifsState(key, null);
        }
    });
}

export function resetFinalPricePages() {
    const tarifsState = getTarifsState();

    Object.keys(tarifsState).forEach(key => {
        if (key.startsWith('page-')) {
            setFinalPriceInTarifsState(key, null);
        }
    });
}

export function resetFinalPriceBlocksSite() {
    const listBlocks = [
        "block-redac1-portail",
        "block-redac2-portail",
        "block-redac3-portail",
        "block-redac4-portail",
        "page-and-listing-agent",
        "page-and-listing-agency"
    ];

    listBlocks.forEach(value => {
        setFinalPriceInTarifsState(value, null);
    })
}

export function getConfigKey(key) {
    let configKey = key;

    if (['page-agency', 'page-agents'].includes(key)) {
        configKey = 'page-mixed';
    } else if (key === 'page-panoramic-property') {
        configKey = 'page-property';
    } else if (['module-agency', 'module-agents'].includes(key)) {
        configKey = 'module-mixed';
    }

    return configKey;
}

// Allows you to return the delivery date according to the parameter
export function getConfigDeliveryDate(value) {
    let deliveryDate;
    switch (value) {
        case 'zephyr':
        case 'mistral':
        case 'sirocco':
            // For the mistral and sirocco offer the delivery date is 8 weeks
            deliveryDate = 8 * 7;
            break;
        default:
            // By default the delivery date is 6 weeks
            deliveryDate = 6 * 7;
    }
    return deliveryDate;
}

export const getOffer = (isSpecialCase, pathForOffer) => {
    return pathForOffer && isSpecialCase ? getInDataState(`${pathForOffer}.offre`) : '';
};

export const getDynamicHektorOffer = (isSpecialCase, pathForOffer, hektorOffer) => {
    return pathForOffer && isSpecialCase ? getInDataState(`${pathForOffer}.offre`) : hektorOffer;
};

export const getDynamicPathInDataState = (isSpecialCase, pathInDataState, hektorOffer) => {
    return pathInDataState && isSpecialCase ? pathInDataState.replace('%specialCase%', hektorOffer) : pathInDataState;
};

export const getDynamicPathToDependsOn = (isSpecialCase, pathToDependsOn, hektorOffer) => {
    return pathToDependsOn && isSpecialCase ? pathToDependsOn.replace('%specialCase%', hektorOffer) : pathToDependsOn;
};

export const isIkaLePackType = (type) => {
    return type && ("hektor-ika-le-pack-prospect" === type || "hektor-ika-le-pack-client" === type);
};

export const isFnaimOffer = (offer) => {
    return offer.includes('fnaim');
};

export const dynamicPriceCalculation = (tarifsInfo, counterValue, hektorOffer = null) => {
    const nbrOfUsers = tarifsInfo.nbrOfUsers;
    const dynamicPrice = null !== hektorOffer ? tarifsInfo.dynamicPrice[hektorOffer] : tarifsInfo.dynamicPrice;
    const additionalUserPrice = null !== hektorOffer ? tarifsInfo.additionalUserPrice[hektorOffer] : tarifsInfo.additionalUserPrice;

    let price = dynamicPrice[counterValue] ?? null;

    if (null === price && counterValue > nbrOfUsers) {
        price = dynamicPrice[nbrOfUsers] + ((counterValue - nbrOfUsers) * additionalUserPrice);
    }

    return price;
};
