import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styled from 'styled-components';
import Toggle from '../Toggle';
import {UPDATE_DATA_VALUE} from '../../Store/actions';
import {getLabel} from '../../Utils/labels';
import {formatPrice} from '../../Utils/price';
import {deleteInDataState, getInDataState, setFinalPriceInTarifsState, setInDataState} from '../../Utils/storeHelpers';
import {getTarifsInfoByTarifsName, manualDependsOnTarifsInfo} from '../../Utils/jsonInterpreter';
import {
    FinalPriceOption,
    BasePriceOption,
    FinalPriceOptionSpecial,
    borderGrey
} from '../../Utils/styleConstants';
import BaseCounter from "../Counter";
import initialState from "../../Store/initialState";
import tarifs from "../../Store/Reducers/tarifs";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${props => props.width ?? `calc((100% - 90px - 15%) / ${props.total})`};
  padding: 2% 0;
  border-left: 1px solid ${borderGrey};
  ${props => props.borderBottom && `border-bottom: 1px solid ${borderGrey};`}
  ${props => props.borderRight && `border-right: 1px solid ${borderGrey};`}
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 80%;

`;
const LabelToggleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 70px;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const ToggleStructure = styled.div`
    padding: 15px 0;
`;

/**
 * @param {Object} props
 * @param {Object} props.tarifsInfo
 *
 * @return {JSX.Element|null}
 */
const InsteadOfPrice = ({ tarifsInfo }) => {
    const content = isNaN(tarifsInfo.insteadOf) && tarifsInfo.insteadOf !== null
        ? tarifsInfo.insteadOf
        : `au lieu de ${formatPrice(tarifsInfo.insteadOf)} ${tarifsInfo.priceUnit.toUpperCase()}`

    if (tarifsInfo.insteadOf) {
        return (
            <BasePriceOption>
                {content}
            </BasePriceOption>
        )
    }

    return null;
}

/**
 * @param {Object} props
 * @param {boolean} props.isIncluded
 * @param {Object} props.tarifsInfo
 *
 * @return {JSX.Element}
 */
const Price = ({ isIncluded, tarifsInfo }) => {
    const BasePrice = useMemo(() => () => {
        return !isNaN(tarifsInfo.price)
            ? (
                <FinalPriceOption>
                    {`${formatPrice(tarifsInfo.price)} ${tarifsInfo.priceUnit.toUpperCase()}`}
                </FinalPriceOption>
            )
            : (
                <FinalPriceOptionSpecial>
                    {`${formatPrice(tarifsInfo.price)} ${tarifsInfo.priceUnit.toUpperCase()}`}
                </FinalPriceOptionSpecial>
            )
    }, [tarifsInfo.price, tarifsInfo.priceUnit])

    if (isIncluded || tarifsInfo.price === 'inclus') {
        return (
            <FinalPriceOptionSpecial>
                INCLUS
            </FinalPriceOptionSpecial>
        );
    }

    return (
        <>
            {tarifsInfo.insteadOf ? (
                <>
                    <BasePrice />
                    <InsteadOfPrice tarifsInfo={tarifsInfo} />
                </>
            ) : (
                <BasePrice />
            )}
        </>
    );

}

/**
 * @param {Object} props
 * @param {string} props.name
 *
 * @return {JSX.Element}
 */
const Label = ({ name }) => {
    const splitLabel = getLabel(name).split('*');

    if (splitLabel.length > 1) {
        return  (
            <p>
                {splitLabel[0]}
                <Bold>{splitLabel[1]}</Bold>
                {splitLabel[2]}
            </p>
        )
    }

    return (
        <p> {splitLabel[0]} </p>
    )
}

/**
 * @param {Object} props
 * @param {string} props.name
 *
 * @return {null|JSX.Element}
 */
const LabelSup = ({ name }) => {
    const labelSup = getLabel(`${name}-$labelSup`);

    return (
        labelSup && <p> {labelSup} </p>
    )
}

/**
 * @param {Object} props
 * @param {boolean} show
 * @param {Object} counterProps
 *
 * @return {JSX.Element|null}
 */
const Counter = ({ show, ...counterProps }) => {
    return (
        show && <BaseCounter {...counterProps} />
    )
}

const MinorOption = props => {
    const withoutCounter = props.withoutCounter;
    const [counterValue, setCounterValue] = useState(getInDataState(props.pathInDataStateQuantity) ?? null);
    // Retrieve info about tarifs
    let tarifsInfo = getTarifsInfoByTarifsName(props.tarifsName);

    tarifsInfo = manualDependsOnTarifsInfo(tarifsInfo, props.dependsOn);

    // Callback used when user use the toggle
    const handleToggle = useCallback(() => {
        if (props.toggable) {
            props.onToggle(props.index);
        }

        if (props.pathInDataStateQuantity) {
            !props.toggle
                ? setInDataState(props.pathInDataStateQuantity, 1)
                : deleteInDataState(props.pathInDataStateQuantity);

            setCounterValue(1);
        }
    }, [props]);

    // Reset final price of toggle is turned off
    useEffect(() => {
        if (!props.toggle) {
            setFinalPriceInTarifsState(props.name, null, undefined, undefined);
        }
    }, [props.toggle, props.name, props.pathInDataStateQuantity]);

    // Callback when user change counter
    const handleCounter = useCallback((value, event) => {
        event?.stopPropagation();

        setCounterValue(value);
    }, [setCounterValue]);

    useEffect(() => {
        if (counterValue !== null) {
            setInDataState(props.pathInDataStateQuantity, counterValue);
            setFinalPriceInTarifsState(props.tarifsName, null, undefined, props.dependsOn);
        }
    }, [counterValue, props]);

    return (
        <Container
            width={props.width}
            total={props.total}
            borderBottom={props.borderBottom}
            borderRight={props.borderRight}
        >
            <LabelContainer>
                <LabelToggleWrapper>
                    <Label name={props.name} />
                </LabelToggleWrapper>
            </LabelContainer>
            <LabelSup name={props.name} />
            <ToggleStructure>
                <Toggle
                    action={UPDATE_DATA_VALUE}
                    pathInDataState={props.pathInDataState}
                    name={props.name}
                    value={props.toggle}
                    callback={handleToggle}
                />
            </ToggleStructure>
            <Price isIncluded={props.isIncludedIn} tarifsInfo={tarifsInfo} />
            { !withoutCounter && (
                <Counter
                    show={props.pathInDataStateQuantity && props.toggle}
                    dontStandAlone
                    min={1}
                    pathInDataStateQuantity={props.pathInDataStateQuantity}
                    counterDependsOn={props.counterDependsOn}
                    margin='5px 0 0 0'
                    fontSize='18px'
                    masterId={props.pathInDataStateQuantity}
                    initialValue={getInDataState(props.pathInDataStateQuantity) ?? 1}
                    callback={handleCounter}
                    excludedOptionsFromCounter={props.excludedOptionsFromCounter}
                    name={props.name}
                />
            )}
        </Container>
    );
};

export default MinorOption;
