import getLabel, { beautifulGetLabel } from './labels';
import urljoin from 'url-join';
import { getInDataState, getInTarifsState } from './storeHelpers';

import * as _ from './object';

export const getComboName = ({ name, ...item }) => type => {
    const toReturn = name ? [name] : [];

    item[type] !== undefined && toReturn.push(item[type].name)

    return toReturn.join('/');
};

export const createOptions = ({ options }) => {
    return options.map(value => {
        const option = {value};

        return {
            ...option,
            label: getLabel(option),
            key: [...options, value].join('-')
        };
    });
};

export function createOptionsFromArray(optionNames) {
    let options = [];

    optionNames.forEach(optionName => {
        let option = {
            value: optionName,
            label: beautifulGetLabel(optionName)
        };

        options.push(option);
    });

    return options;
};

export function getImage (img) {
    return img.indexOf('http') === 0 ? img : urljoin(process.env.REACT_APP_SERVER_URI, img)
};

export function fulfilMandatories(pageMandatories) {
    let fixedPaths = true;
    let conditionalPaths = true;
    let dynamicPaths = true;
    let conditionalDynamicPaths = true;

    // Verification of all fixedPaths
    fixedPaths = fulfilFixedPaths(pageMandatories.fixedPaths);

    // Verification of all conditionalPaths
    conditionalPaths = fulfilConditionalPaths(pageMandatories.conditionalPaths);

    // Verification of all dynamicPaths
    dynamicPaths = fulfilDynamicPaths(pageMandatories.dynamicPaths);

    // Verification of all conditionalDynamicPaths
    conditionalDynamicPaths = fulfilConditionalDynamicPaths(pageMandatories.conditionalDynamicPaths);

    return fixedPaths && conditionalPaths && dynamicPaths && conditionalDynamicPaths;
};

function fulfilFixedPaths(paths) {
    let fulfil = true;

    paths && paths.forEach(fixedPath => {
        if (!fulfilPath(fixedPath)) {
            fulfil = false;
        }
    });

    return fulfil;
};

function fulfilConditionalPaths(paths) {
    let fulfil = true;

    paths && paths.forEach(conditionalPath => {
        if (fulfilFixedPaths(conditionalPath.conditionPaths)) {
            if (!fulfilFixedPaths(conditionalPath.pathsToCheck)) {
                fulfil = false;
            }
        }
    });

    return fulfil;
};

function fulfilConditionalDynamicPaths(paths) {
    let fulfil = true;
    paths && paths.forEach(conditionalPath => {
        if (fulfilFixedPaths(conditionalPath.conditionPaths)) {
            if (!fulfilDynamicPaths(conditionalPath.pathsToCheck)) {
                fulfil = false;
            }
        }
    });

    return fulfil;
};

function fulfilDynamicPaths(paths) {
    let fulfil = true;

    paths && paths.forEach(dynamicPath => {
        // Define constante for idsPath
        const idsPath = dynamicPath.idsPath;
        if (idsPath) {
            // Retrieve all ids from path (these ids generate a dynamic key)
            const ids = getInDataState(idsPath);
            // Retrieve the start of fixedPath from idsPath
            const fixedPathStart = idsPath.replace('_order.', '').replace('|', '.');

            ids && ids.forEach(id => {
                dynamicPath.suffixes.forEach(suffix => {
                    const completeFixedPath = { path: fixedPathStart + `|${id}/${suffix}` };

                    if (!fulfilPath(completeFixedPath)) {
                        fulfil = false;
                    }
                })
            })
        }
    });

    return fulfil;
}

function fulfilPath(path) {
    let fulfil = true;
    const valueAtPath = getInDataState(path.path);

    if (undefined === valueAtPath || ('object' === typeof valueAtPath && Object.keys(valueAtPath).length === 0)) {
        fulfil = false;
    }
    if (undefined !== valueAtPath && false === compareValueWithCriteria(valueAtPath, path.criteria, path.value)) {
        fulfil = false;
    }

    return fulfil;
};

function compareValueWithCriteria(value, criteria, target) {
    let result = null;

    switch (criteria) {
        case 'number':
            result = !isNaN(value);

            break;
        case 'hasOwnProperty':
            result = value.hasOwnProperty(target);

            break;
        case 'includes':
            result = value.includes(target);

            break;
        default:
            result = undefined !== target ?
                value === target
                : undefined !== value;

            break;
    }

    return result;
};

function getTarifsSpecificInfo(tarifs, infoName, fallback) {
    let tarifsInfo = fallback;

    // Sometimes, info can depends on a value in dataState
    // So, we need to get this value and the info associated to it
    if (tarifs.hasOwnProperty(`${infoName}DependsOn`) && tarifs.hasOwnProperty(infoName)) {
        const targetInfoKey = getInDataState(tarifs[`${infoName}DependsOn`]);

        if (targetInfoKey && tarifs[infoName].hasOwnProperty(targetInfoKey)) {
            tarifsInfo = tarifs[infoName][targetInfoKey];
        }
    } else if (!tarifs.hasOwnProperty(`${infoName}DependsOn`) && tarifs.hasOwnProperty(infoName)) {
        tarifsInfo = tarifs[infoName];
    }

    return tarifsInfo;
};

function getTarifsPrice(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'price', 'inclus');
};

function getTarifsPeriodicities(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'periodicities', []);
};

export function getTarifsPriceUnit(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'priceUnit', '');
};

export function getTarifsPriceUnitInRecap(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'priceUnitInRecap', undefined);
};

function getTarifsInsteadOf(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'insteadOf', null);
};

function getTarifsNbrOfUsers(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'nbrOfUsers', null);
};

export function getTarifsIncludedIn(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'includedIn', undefined);
};

function getTarifsApartir(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'apartir', false);
};

function getTarifsFreeLimit(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'freeLimit', undefined);
};

function getTarifsDynamicPrice(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'dynamicPrice', undefined);
};

function getTarifsFromPrice(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'fromPrice', undefined);
};

function getTarifsAdditionalUserPrice(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'additionalUserPrice', undefined);
};

function getTarifsWithFloor(tarifs) {
    return getTarifsSpecificInfo(tarifs, 'withFloor', undefined);
}

function getTarifsInfo(tarifs) {
    return undefined !== tarifs ? {
        price: getTarifsPrice(tarifs),
        priceUnit: getTarifsPriceUnit(tarifs),
        priceUnitInRecap: getTarifsPriceUnitInRecap(tarifs),
        periodicities: getTarifsPeriodicities(tarifs),
        insteadOf: getTarifsInsteadOf(tarifs),
        nbrOfUsers: getTarifsNbrOfUsers(tarifs),
        includedIn: getTarifsIncludedIn(tarifs),
        apartir: getTarifsApartir(tarifs),
        freeLimit: getTarifsFreeLimit(tarifs),
        dynamicPrice: getTarifsDynamicPrice(tarifs),
        fromPrice: getTarifsFromPrice(tarifs),
        additionalUserPrice: getTarifsAdditionalUserPrice(tarifs),
        withFloor: getTarifsWithFloor(tarifs),
        startingAt: getTarifsSpecificInfo(tarifs, 'startingAt', undefined)
    } : null;
};

export function getTarifsInfoByTarifsName(tarifsName) {
    const tarifs = getInTarifsState(tarifsName);

    return getTarifsInfo(tarifs);
};

export function manualDependsOnTarifsInfo(tarifsInfo, dependsOn, additionalDependsOn) {
    let newTarifsInfo = tarifsInfo ? { ...tarifsInfo } : null;

    if (newTarifsInfo && dependsOn) {
        // price
        if (_.has(newTarifsInfo.price, dependsOn)) {
            newTarifsInfo.price = _.get(newTarifsInfo.price, dependsOn);
        }
        if (_.has(newTarifsInfo.price, additionalDependsOn)) {
            newTarifsInfo.price = _.get(newTarifsInfo.price, additionalDependsOn);
        }
        // priceUnit
        if (_.has(newTarifsInfo.priceUnit, dependsOn)) {
            newTarifsInfo.priceUnit = _.get(newTarifsInfo.priceUnit, dependsOn);
        }
        if (_.has(newTarifsInfo.priceUnit, additionalDependsOn)) {
            newTarifsInfo.priceUnit = _.get(newTarifsInfo.priceUnit, additionalDependsOn);
        }
        // insteadOf
        if (newTarifsInfo.insteadOf) {
            if (_.has(newTarifsInfo.insteadOf, dependsOn)) {
                newTarifsInfo.insteadOf = _.get(newTarifsInfo.insteadOf, dependsOn);
            } else if (_.has(newTarifsInfo.insteadOf, additionalDependsOn)) {
                newTarifsInfo.insteadOf = _.get(newTarifsInfo.insteadOf, additionalDependsOn);
            }
        }
        // nbrOfUsers
        if (newTarifsInfo.nbrOfUsers) {
            if (_.has(newTarifsInfo.nbrOfUsers, dependsOn)) {
                newTarifsInfo.nbrOfUsers = _.get(newTarifsInfo.nbrOfUsers, dependsOn);
            } else if (_.has(newTarifsInfo.nbrOfUsers, additionalDependsOn)) {
                newTarifsInfo.nbrOfUsers = _.get(newTarifsInfo.nbrOfUsers, additionalDependsOn);
            }
        }
        // includedIn
        if (newTarifsInfo.includedIn) {
            if (_.has(newTarifsInfo.includedIn, dependsOn)) {
                newTarifsInfo.includedIn = _.get(newTarifsInfo.includedIn, dependsOn);
            } else if (_.has(newTarifsInfo.includedIn, additionalDependsOn)) {
                newTarifsInfo.includedIn = _.get(newTarifsInfo.includedIn, additionalDependsOn);
            }
        }
        // dynamicPrice
        if (newTarifsInfo.dynamicPrice) {
            if (_.has(newTarifsInfo.dynamicPrice, dependsOn)) {
                newTarifsInfo.dynamicPrice = _.get(newTarifsInfo.dynamicPrice, dependsOn);
            }
            if (_.get(newTarifsInfo.dynamicPrice, additionalDependsOn)) {
                newTarifsInfo.dynamicPrice = _.get(newTarifsInfo.dynamicPrice, additionalDependsOn);
            }
        }
        // fromPrice
        if (newTarifsInfo.fromPrice) {
            if (_.has(newTarifsInfo.fromPrice, dependsOn)) {
                newTarifsInfo.fromPrice = _.get(newTarifsInfo.fromPrice, dependsOn);
            }
            if (_.has(newTarifsInfo.fromPrice, additionalDependsOn)) {
                newTarifsInfo.fromPrice = _.get(newTarifsInfo.fromPrice, additionalDependsOn);
            }
        }
        // additionalUserPrice
        if (newTarifsInfo.additionalUserPrice) {
            if (_.has(newTarifsInfo.additionalUserPrice, dependsOn)) {
                newTarifsInfo.additionalUserPrice = _.get(newTarifsInfo.additionalUserPrice, dependsOn);
            }
            if (_.has(newTarifsInfo.additionalUserPrice, additionalDependsOn)) {
                newTarifsInfo.additionalUserPrice = _.get(newTarifsInfo.additionalUserPrice, additionalDependsOn);
            }
        }
    }

    return newTarifsInfo;
};

export function getIdsOfFinalPrices(tarifsName) {
    const ids = [];
    const finalPrices = getInTarifsState(`${tarifsName}.finalPrices`);

    finalPrices &&
        finalPrices.forEach(finalPrice => ids.push(finalPrice.id));

    return ids;
};

export function sameArrays(array1, array2) {
    let same = true;

    // Compare lengths
    // Can save a lot of time 
    if (array1.length !== array2.length) {
        same = false;
    }

    array1.forEach((value, index) => {
        if (value !== array2[index]) {
            same = false;   
        }
    });
    
    return same;
};
