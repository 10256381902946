/**
 * Capitalize the first letter of a string
 *
 * @param {string} string
 * @return {string}
 */
function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export default capitalize;
