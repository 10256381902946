import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { store } from './Store/store';
import Spinner from './Components/Spinner';
import AuthenticatedApp from './AuthenticatedApp';
import isAuth from './Utils/auth';
import fetchDistant from './Utils/fetch';
import { Waiting, WaitingContainer } from './Utils/styleConstants';

const App = () => {
    // Use of hooks
    const [authData, setAuthData] = useState(null);
    const [labelsRef, setLabelsRef] = useState(false);
    const [apiError, setApiError] = useState(false);

    // useEffect when App is mounting
    useEffect(() => {
        isAuth()
            .then(authData => setAuthData(authData))
            .catch(() => {
                console.error('Unable to auth');
                setApiError(true);
            });
    }, [])

    // useEffect after previous one finished succesfully
    useEffect(() => {
        if (null !== authData) {
            fetchDistant('/labels_ref')
                .then(labels => {
                    localStorage.setItem('labels', JSON.stringify(labels.data));
                    setLabelsRef(true);
                })
                .catch(() => {
                    console.log('Can not reach server');
                    setApiError(true);
                });
        } 
    }, [authData]);

    return (
        (authData && authData.logged && labelsRef) ? (
            <Provider store={store}>
                <AuthenticatedApp { ...authData.token } />
            </Provider>
        ) : (
            <WaitingContainer>
                {
                    apiError ? (
                        <>
                            <Waiting>Oups, impossible de se connecter.</Waiting>
                            <Waiting>:(</Waiting>
                        </>
                    ) : (
                        <>
                            <Waiting>Connexion en cours, patientez ...</Waiting>
                            <Spinner size={150} />
                        </>
                    )
                }
            </WaitingContainer>
        )
    );
};

export default App;
