import React from 'react';
import styled from 'styled-components';
import widgets from '../Containers/Widgets';
import PricedContainer from '../Containers/PricedContainer';
import { v4 } from 'uuid';

const Wrap = styled.div`
    ${props => props.styles ? props.styles : null}
`;

function create(groupId, ref, groupFields, groupRef) {
    // Try to math type with component
    const Component = ref ? widgets[ref.type] : undefined;

    // Component type is wrap
    if (ref && 'wrap' === ref.type) {
        return (
            <Wrap key={`${v4()}-wrap`} styles={ref.style}>
                {build(groupId, ref.fields, groupRef, groupFields)}
            </Wrap>
        );
    }

    // If component is priced
    if (ref && ref.priced !== undefined && ref.priced) {
        return (
            <PricedContainer {...ref} key={`${v4()}-priced`} masterId={groupId}>
                <Component {...ref} masterId={groupId} groupRef={groupRef}/>
            </PricedContainer>
        );
    }

    // Default
    return undefined !== Component && (
        <Component {...ref} key={`${v4()}-component`} masterId={groupId} groupFields={groupFields} groupRef={groupRef} />
    );
};

function build(groupId, fields, groupRef, groupFields) {
    const groups = [];
    undefined !== fields &&
        fields.forEach(field => groups.push(create(groupId, field, groupFields, groupRef)));

    return groups;
};

function document(name) {
    const document = require(`../Data/refs/documents/${name}.json`);

    document.pages = document.pagesReferences.map(pageName => {
        const page = require(`../Data/refs/pages/${pageName}.json`);

        page.groups = page.groupsReferences.map(groupName => require(`../Data/refs/groups/${groupName}.json`));

        return page;
    });

    return document;
};

const builder = { build, create, document };

export default builder;
