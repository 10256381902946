import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Toggle from './Toggle';
import PriceElement from './PriceElement';
import Notes from './Notes';
import { UPDATE_DATA_VALUE } from '../Store/actions';
import { getLabel } from '../Utils/labels';
import { borderGrey } from '../Utils/styleConstants';
import {
    getInDataState,
    setInDataState,
    setFinalPriceInTarifsState, getDynamicHektorOffer, getDynamicPathInDataState
} from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const ModuleStructure = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    padding: 3vh 0;
    border-bottom: 1px solid ${borderGrey};

    &:first-child {
        padding: 0 0 3vh;
    }

    &::after {
        order: -1;
        content: '';
        flex-grow: 1;
        margin: 0 30px 0 0;
        border-bottom: 1px dotted grey;
    }
`;

const ToggleStructure = styled.div`
    display: flex;
    align-items: center;
    min-width: 35%;
    order: -1;
`;

const OptionName = styled.p`
    padding: 0 0 0 10%;
`;

const OptionHektor = props => {
    const hektorOffer = getDynamicHektorOffer(props.isSpecialCase, props.pathForOffer, props.hektorOffer);
    const pathInDataState = getDynamicPathInDataState(props.isSpecialCase, props.pathInDataState, hektorOffer);
    const options = props.options;

    // Use of hooks
    const [toggles, setToggles] = useState(null);

    // useEffect when component is mounting
    useEffect(() => {
        if (!toggles) {
            let tmpToggles = [];

            options.forEach(option => {
                // Try to recover module in dataState
                const inDataState = getInDataState(`${pathInDataState}.${option.name}`);

                // Toggle state either depend on the recovered module or the value defined in json
                tmpToggles = [ ...tmpToggles, inDataState ];
            });

            setToggles(tmpToggles);
        }
    }, [toggles, pathInDataState, options]);

    // Callback when toggle change
    const handleToggleChange = useCallback((optionValue, optionIndex, optionName) => {
        // Either set the option value in state or delete it
        setInDataState(`${pathInDataState}.${optionName}`, optionValue);

        // If toggle is turned off, we reset the final price
        !optionValue && setFinalPriceInTarifsState(optionName, null, undefined, hektorOffer);

        // Only switch or not the clicked toggle
        setToggles(
            toggles.map((value, index) =>
                index === optionIndex ?
                    !value
                    : value
            )
        );
    }, [pathInDataState, hektorOffer, toggles]);

    return (
        toggles && (
            <Container>
                {
                    options.map((option, index) => (
                        <ModuleStructure key={index}>
                            <ToggleStructure>
                                <Toggle
                                    index={index}
                                    action={UPDATE_DATA_VALUE}
                                    masterId={props.masterId}
                                    name={option.name}
                                    value={toggles[index]}
                                    callback={handleToggleChange}
                                />
                                <OptionName>{getLabel(option.name)}</OptionName>
                            </ToggleStructure>
                            <PriceElement
                                tarifsName={option.name}
                                dependsOn={hektorOffer}
                            />
                        </ModuleStructure>
                    ))
                }
                {
                    props.withNotes && (
                        <Notes
                            pathInDataState={`${pathInDataState}.notes`}
                            name='notes'
                            padding='30px 0 0 0'
                        />
                    )
                }
            </Container>
        )
    );
};

export default OptionHektor;
