import React from 'react';
import styled from 'styled-components';
import { green, grey, lightGrey } from '../Utils/styleConstants';
import { getInDataState, getInTarifsState } from '../Utils/storeHelpers';

const StyledContainer = styled.div`
  padding: 1em;

  * {
    color: inherit;
  }
`;

const StyledTarifsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 24%) 0 3px 8px;
  overflow: hidden;
`;

const TarifContainer = styled.div`
  display: flex;
  letter-spacing: 0.03em;
  background-color: ${({ backgroundColor }) => backgroundColor || lightGrey};
  color: ${({ color }) => color || grey};
  font-size: ${({ fontSize }) => fontSize || '20px'};
  font-weight: bold;
  text-shadow: 0.5px 0 currentcolor;

  &:not(:last-child) {
    border-bottom: 1px solid #e1e1e1;
  }

  & > div {
    padding: 1.5em 2em;
  }
`;

const TitleContainer = styled(TarifContainer)`
  background: #ececec;
  justify-content: center;
`;

const LabelContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  width: 45%;
  border-right: solid;
  border-width: 0.25ex;
  background: #ececec;
`;

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.3em;
  text-align: end;
  width: 55%;
`;

const Label = styled.div`
  text-transform: uppercase;
  font-weight: normal;
`;

const Price = styled.div`
  font-size: 23px;
  text-transform: uppercase;
`;

const Devise = styled.span`
  color: ${({ color }) => color || 'inherit'};
`;

const Value = styled.span`
  position: relative;
`;

const Unit = styled.span`
  font-size: 70%;
`;

const OskarRecap = () => {
    const oskarData = getInDataState('offre-oskar.type');
    const offre = oskarData[0];

    const tarifsData = [
        { label: 'De 1 à 25 lots', tarif: getInTarifsState('oskar-value-range-0-display') },
        { label: 'De 26 à 100 lots', tarif: getInTarifsState('oskar-value-range-1-display') },
        { label: 'De 101 à 200 lots', tarif: getInTarifsState('oskar-value-range-2-display') },
        { label: 'De 201 à 500 lots', tarif: getInTarifsState('oskar-value-range-3-display') },
        { label: 'De 500 lots à plus', tarif: getInTarifsState('oskar-value-range-4-display') },
    ];

    return (
        <StyledContainer>
            <StyledTarifsContainer>
                <TitleContainer>
                    <div>Frais par lot</div>
                </TitleContainer>
                {tarifsData.map((item, index) => (
                    <TarifContainer key={index}>
                        <LabelContainer>
                            <Label>{item.label}</Label>
                        </LabelContainer>
                        <PriceContainer>
                            <Price>
                                <Devise color={green}>
                                    <Value>
                                        { item.tarif.price[offre] === 0
                                            ? 'inclus'
                                            : item.tarif.price[offre].toFixed(2)  }
                                    </Value>
                                </Devise>
                                { item.tarif.price[offre] === 0
                                    ? null
                                    : <Unit> HT {item.tarif.priceUnit}</Unit>
                                }
                            </Price>
                        </PriceContainer>
                    </TarifContainer>
                ))}
            </StyledTarifsContainer>
        </StyledContainer>
    );
};

export default OskarRecap;
