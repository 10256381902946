import React from 'react';
import { deleteInDataState } from '../Utils/storeHelpers';
import condition from '../Utils/condition'
import Builder from '../Utils/builder';

class ConditionnalComponent extends React.Component {
    componentDidMount () {
        if (!this.props.condition && !this.props.notAlone) {
            deleteInDataState(`${this.props.masterId}.${this.props.name}`);
        }

        this.displayManagement();
    }

    componentDidUpdate (prevProps) {
        if (prevProps.condition !== this.props.condition && !this.props.condition) {
            deleteInDataState(`${this.props.masterId}.${this.props.name}`);
        }

        this.displayManagement();
    }
    render () {
        return (
            this.props.condition && Builder.create(this.props.masterId, {...this.props, type: this.props.component}, this.props.groupFields, this.props.groupRef)
        )
    }

    displayManagement(){
        // Group display management
        if(undefined !== this.props.groupRef && null !== this.props.groupRef.current && undefined !== this.props.groupFields) {
            let display = false;
            
            // See if at least one element must be displayed in the group
            this.props.groupFields.forEach(field => {
                display = condition(field) ? true : display
            });

            // Show or hide the group on the page
            this.props.groupRef.current.style = display ? "display: block" : "display: none";
        }
    }
}

export default ConditionnalComponent;
