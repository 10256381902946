import React, { useCallback } from 'react';
import styled from 'styled-components';
import HiddenCheckbox from './HiddenCheckbox';
import Checkmark from './Icons/Checkmark';
import Cross from './Icons/Cross';
import { easeOutBack, green, borderGrey, grey } from '../Utils/styleConstants';
import { setInDataState } from '../Utils/storeHelpers';

const StyledCheckbox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.checked ? green : borderGrey};
    border-radius: 36px;
    width: 68px;
    height: 36px;
    padding: 13px 9px;
    transition: all .5s ${easeOutBack};
    cursor: pointer;
`;

const Container = styled.div`
    position: relative;
`;

const Mask = styled.svg`
    height: 20px;
    width: 20px;
    fill: white;
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: ${props => props.checked ? '37px' : '9px'};
    transition: all .2s ${easeOutBack};
    cursor: pointer;
`;

const Checkbox = ({ checked, sublabel, label, globalName, ...props }) => (
  <Container>
    <Mask checked={checked} viewBox='0 0 100 100'><circle cx='50' cy='50' r='50' /></Mask>
    <HiddenCheckbox checked={checked} name={globalName} {...props} />
    <StyledCheckbox checked={checked}>
      <Checkmark color={grey} />
      <Cross color={grey} />
    </StyledCheckbox>
  </Container>
);

const isChecked = (uniq, value) => undefined !== uniq ? uniq === value : value === true;

const Toggle = props => {
    // Callback when checkbox value changes
    const handleChange = useCallback((event) => {
        const actualValue = undefined !== props.uniqValue ? props.uniqValue : event.target.checked;

        if (props.callback) {
            props.callback(actualValue, props.index, props.name);
        } else if (props.hasOwnProperty('masterId') && props.hasOwnProperty('name')) {
            setInDataState(`${props.masterId}.${props.name}`, actualValue);
        } else if (props.hasOwnProperty('pathInDataState')) {
            setInDataState(props.pathInDataState, actualValue);
        }
    }, [props])

    return (
        <label>
            <Checkbox
                checked={isChecked(props.uniqValue, props.value)}
                onChange={(event) => handleChange(event)}
                {...props} 
            />
        </label>
    );
};

export default Toggle;
