import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Delete from './Addable/Delete';
import { getInDataState, setInDataState } from '../Utils/storeHelpers';
import { green, SimpleInput, SimpleAddButton } from '../Utils/styleConstants';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1% 2%;
    margin: 0 2% 2%;
`;

const Line = styled.div`
    display: flex;
    align-items: center;
    width: ${props => `calc((100% - (2% * ${props.perLine ? props.perLine : 1})) / ${props.perLine ? props.perLine : 1})`};
    padding: 1% 2%;
`;

const InputStructure = styled.div`
    display: ${props => props.top ? 'block' : 'flex'};
    justify-content: space-between;
    align-items: center;
    width: 97%;
    padding: 0 3% 0 0;
`;

const TitleLabel = styled.p`
    color: ${green};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1em;
    text-align: ${props => props.center ? 'center' : 'left'};
    padding: ${props => props.center ? '0 0 1em 0' : '0'};
`;

const Input = styled(SimpleInput)`
    width: ${props => props.full ? '100%' : '80%'};
`;


const AddElementButton = styled(SimpleAddButton)`
    margin: 1em 0;
    padding: 10px 20px;
`;

const ConfigTitles = props => {
    // Use of hooks
    const [titles, setTitles] = useState(null);

    // useEffect when component is mounting
    useEffect(() => {
        if (null === titles) {
            const currentTitles = getInDataState(props.pathInDataState);
            let newTitles = [''];
            if (props.perLine) {
                for(let i = 0; i < props.perLine; i++) {
                    newTitles[i] = '';
                }
            }

            undefined === currentTitles || 0 === currentTitles.length ?
                setTitles(newTitles)
                : setTitles(currentTitles);
        }
    }, [titles, props.pathInDataState, props.perLine])

    // Callback when user add an element
    const handleAddElement = useCallback(() => {
        setTitles([ ...titles, '' ]);
    }, [titles])

    // useEffect each time titles hook value changes
    useEffect(() => {
        null !== titles && setInDataState(props.pathInDataState, titles);
    }, [titles, props.pathInDataState])

    // Callback when user change one title
    const handleChangeTitle = useCallback((event, titleIndex) => {
        setTitles(titles.map((title, index) => index === titleIndex ? event.target.value : title));
    }, [titles])

    // Callback when user delete an element
    const handleDeleteElement = useCallback(titleIndex => {
        setTitles(titles.filter((_, index) => index !== titleIndex));
    }, [titles])

    return (
        null !== titles && (
            <Container>
                {
                    titles.map((title, index) => (
                        <Line key={index}  perLine={props.perLine}>
                            <InputStructure top={props.perLine ? true : false}>
                                <TitleLabel center={props.perLine ? true : false}>{`titre ${titles.length > 1 ? index + 1 : ''}`}</TitleLabel>
                                <Input
                                    type='text'
                                    value={title}
                                    onChange={(event) => handleChangeTitle(event, index)}
                                    full={props.perLine ? true : false}
                                />
                            </InputStructure>
                            {
                                props.addable && 0 < index && (
                                    <Delete handleClick={() => handleDeleteElement(index)}/>
                                )
                            }
                        </Line>
                    ))
                }
                {
                    props.addable && (
                        <AddElementButton onClick={() => handleAddElement()}>
                            Ajouter un élement
                        </AddElementButton>
                    )
                }
            </Container>
        )
    );
};

export default ConfigTitles;
