/**
 * @param {Object} obj
 * @param {Array|string} path
 * @param {any} [defaultValue]
 * @returns {undefined|*}
 */
function get(obj, path, defaultValue) {
    if (!path) return undefined

    /** @type {Array} */
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g)

    const result = pathArray.reduce(
        (prevObj, key) => prevObj && prevObj[key],
        obj
    )

    return result === undefined ? defaultValue : result
}

export default get;
