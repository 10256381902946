import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr'
import calendar from '../Images/calendar.svg';
import { borderGrey } from '../Utils/styleConstants';
import { getInDataState, setInDataState, getConfigDeliveryDate } from '../Utils/storeHelpers';
require('react-datepicker/dist/react-datepicker.css');

registerLocale('fr', fr);

const DatePickerInput = styled.div`
    position: relative;
    width: 160px;
    ${props => `margin : ${props.margin ?? ''}`};
`;

const Calendar = styled.img`
    position: absolute;
    height: 100%;
    right: 10px;
    padding: 10px;
`;

const StyledDatePicker = styled(ReactDatePicker)`
    padding: 10px 20px;
    width: 100%;
    font-size: 1em;
    font-family: Roboto,sans-serif;
    border: 1px solid ${borderGrey};
    border-radius: 25px;
    background-color: transparent;
    cursor: pointer;
`;

const DatePicker = props => {
    // Use of hooks
    const [date, setDate] = useState(null);

    // useEffect when component is mounting
    useEffect(() => {
        if (null === date) {
            if (props.checkPathInDataState) {
                const prelevementInDataState = getInDataState(props.checkPathInDataState);
                if (typeof prelevementInDataState !== 'object') {
                    setInDataState(props.checkPathInDataState, '');
                }
            }

            if (props.defaultDate) {
                const ISODefaultDate = (new Date(props.defaultDate)).toISOString();

                setDate(ISODefaultDate);
                setInDataState(props.pathInDataState, ISODefaultDate);
            } else if (props.pathToDeliveryDate) {
                const fallbackDate = new Date();
                const offreSite = getInDataState(props.pathToDeliveryDate);
                const modeleSite = getInDataState(`offre-modele.modele-${offreSite['offre-site-'+offreSite.mode]}`);
                const deliveryDate = getConfigDeliveryDate(modeleSite);

                fallbackDate.setDate(fallbackDate.getDate() + deliveryDate);
                const ISOToday = fallbackDate.toISOString();

                setDate(ISOToday);
                setInDataState(props.pathInDataState, ISOToday);
            } else {
                const fallbackDate = new Date();
                const ISOToday = fallbackDate.toISOString();

                setDate(ISOToday);
                setInDataState(props.pathInDataState, ISOToday);
            }
        }
    }, [date, props]);

    // Callback on change datepicker
    const handleDatePickerChange = useCallback((date, event) => {
        // Prevent default to not trigger parent events
        event.preventDefault();

        const ISODate = date.toISOString();
 
        setDate(ISODate);
        setInDataState(props.pathInDataState, ISODate);
    }, [props.pathInDataState]);

    return (
        null !== date && (
            <DatePickerInput margin={props.margin}>
                <Calendar src={calendar} alt='#' />
                <StyledDatePicker
                    locale='fr'
                    dateFormat='dd/MM/yyyy'
                    minDate={
                        undefined !== props.minDate ?
                            'today' === props.minDate ? new Date() : new Date(props.minDate)
                            : undefined
                    }
                    maxDate={
                        undefined !== props.maxDate ?
                            'today' === props.maxDate ? new Date() : new Date(props.maxDate)
                            : undefined
                    }
                    selected={new Date(date)}
                    onChange={(date, event) => handleDatePickerChange(date, event)}
                />
            </DatePickerInput>
        )
    );
};

export default DatePicker;
