import {
    CHANGE_PAGE,
    UPDATE_DOCUMENT,
    MANDATORY_MISSING,
    MANDATORY_NOT_MISSING,
    SET_ID_DOCUMENT,
    SET_CREATE_DATE,
    SET_UPDATE_DATE,
    SET_CONTRACT_NUM,
    SET_USER_EMAIL,
    NOT_CONNECTED,
    KO_NETWORK,
    OK_NETWORK,
    WAIT_NETWORK,
    SET_USER_AVATAR,
    SET_MANDATORY_PREDICATE, DELETE_MANDATORIES, DELETE_MANDATORY_PREDICATES
} from '../actions';
import { NET_UP, NET_WAIT, NET_DOWN } from '../network-state';
import initialState from '../initialState';
import {produce} from "immer";
const generic = (state = initialState.generic, action) => {
    switch (action.type) {
        case CHANGE_PAGE:
            const { page, stepUpdate } = action.payload;
            let currentStep = state.step;

            if ('inc' === stepUpdate) {
                ++currentStep;
            } else if ('dec' === stepUpdate) {
                --currentStep;
            }

            return {
                ...state,
                page: page,
                step: currentStep
            };

        case UPDATE_DOCUMENT:
            return {
                ...state,
                document: action.value
            };

        case SET_MANDATORY_PREDICATE:
            return produce(state, draftState => {
                const currentPageIndex = draftState.page;
                const page = draftState.document.pages[currentPageIndex];

                if (!page.mandatories) {
                    page.mandatories = { predicates : [] };
                }

                if (!page.mandatories.predicates) {
                    page.mandatories.predicates = [];
                }

                const { predicates } = page.mandatories;
                predicates.push(action.payload.predicate);

                page.mandatories.predicates = predicates;
            });
        case DELETE_MANDATORY_PREDICATES:
            return produce(state, draftState => {
                const currentPageIndex = draftState.page;
                const page = draftState.document.pages[currentPageIndex];

                if (page.mandatories && page.mandatories.predicates) {
                    page.mandatories.predicates = [];
                }
            });
        case MANDATORY_NOT_MISSING:
            return produce(state, draftState => {
                const currentPageIndex = draftState.page;
                const page = draftState.document.pages[currentPageIndex];

                if (page.mandatories) {
                    draftState.document.pages[currentPageIndex] = page;
                }

                draftState.missingMandatory = false;
            });
        case MANDATORY_MISSING:
            return {
                ...state,
                missingMandatory: true
            };
        case NOT_CONNECTED:
            return {
                ...state,
                notConnected: true
            };

        case KO_NETWORK:
            return state.networkState === NET_UP ?
                {
                    ...state,
                    networkState: NET_DOWN
                } : state;

        case WAIT_NETWORK:
            return {
                ...state,
                networkState: NET_WAIT
            };

        case OK_NETWORK:
            return {
                ...state,
                networkState: NET_UP
            };

        case SET_ID_DOCUMENT:
            return {
                ...state,
                idDocument: action.value
            };

        case SET_CREATE_DATE:
            return {
                ...state,
                createDate: action.value
            };

        case SET_UPDATE_DATE:
            return {
                ...state,
                updateDate: action.value
            };

        case SET_CONTRACT_NUM:
            return {
                ...state,
                contractNum: action.value
            };

        case SET_USER_EMAIL:
            return {
                ...state,
                user: action.value
            };

        case SET_USER_AVATAR:
            return {
                ...state,
                avatar: action.value
            };

        default:
            return state;
    }
};

export default generic;
