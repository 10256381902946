import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDataState } from '../../Store/selectors';
import styled from 'styled-components';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import CheckmarkContainerLittle from '../Icons/CheckmarkContainerLittle';
import Counter from '../Counter';
import {beautifulGetLabel} from '../../Utils/labels';
import { getImage, getTarifsInfoByTarifsName, manualDependsOnTarifsInfo } from '../../Utils/jsonInterpreter';
import { formatPrice } from '../../Utils/price';
import {
    setInDataState,
    deleteInDataState,
    setFinalPriceInTarifsState,
    multipleSetInDataState,
    multipleClearInDataState,
    multipleUnsetInDataState,
    multipleClearFinalPriceOfTarifs,
    multipleClearArrayInDataState,
    getInDataState, dynamicPriceCalculation
} from '../../Utils/storeHelpers';
import {
    ContainerOption,
    NameOption,
    FinalPriceOption,
    BasePriceOption,
    FinalPriceOptionSpecial
} from '../../Utils/styleConstants';

const Container = styled(ContainerOption)`
  width: ${props => props.full ? '100%' : '48%'};
  height: ${props => props.height ?? 'initial' };
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  ${props => props.disabled && 'opacity: 0.5;'}
  ${props => !props.isImage && 'padding: 20px 0;'}
`;

const FinalPriceOptionStructure = styled.div`
    display: flex;
`;

const Prices = styled.div`
    position: absolute;
    bottom: ${props => props.little ? '5px' : '8px'};
    right: ${props => props.little ? '10px' : '13px'};
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const NameStructureOption = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 15%;
    font-weight: bold;
    text-transform : uppercase;
  ${props => props.isImage && 'border-left: 1px solid #E1E1E1;'}
    ${props => !props.isImage && 'font-weight: 900;'}
`;

const ImageOptionLbiMedia = styled.img`
  height: 100%;
  padding: 5px;
`;


const FullOptionContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 32%);
    grid-auto-flow: dense;
    gap: 2% 10px;
    width: 100%;
    font-size: 12px;
    padding: 15px 0;
`;

const LineFullOption = styled.div`
    display: flex;
    align-items: center;
`;

const TextFullOption = styled.span`
    padding-left: 0.5em;
`;

const CheckableOptionLbiMedia = props => {
    // Use of hooks
    const [checked, setChecked] = useState(null);
    const [counterValue, setCounterValue] = useState(getInDataState(props.pathInDataStateQuantity) ?? null);
    const [fromPrice, setFromPrice] = useState(null);
    const [dynamicPrice, setDynamicPrice] = useState(null);

    // Use of redux selector
    const dataState = useSelector(selectDataState);

    // Retrieve info about tarifs
    let tarifsInfo = getTarifsInfoByTarifsName(props.tarifsName);
    tarifsInfo = manualDependsOnTarifsInfo(tarifsInfo, props.dependsOn, props.additionalDependsOn);

    const isIncludedIn = tarifsInfo && true === tarifsInfo.includedIn;

    useEffect(() => {
        if (null !== tarifsInfo && Array.isArray(tarifsInfo.dynamicPrice)) {
            const fromVal = tarifsInfo.fromPrice ?? null;
            setFromPrice(fromVal);

            if (null !== counterValue) {
                const dynVal = dynamicPriceCalculation(tarifsInfo, counterValue);

                setDynamicPrice(dynVal);
            }
        }
    }, [counterValue, props.dependsOn, tarifsInfo]);

    // useEffect when component is mounting
    useEffect(() => {
        if (null === checked) {
            if (!props.isArrayInDataState) {
                const completePath = `${props.pathInDataState}.${props.name}`;
                const valueInDataState = getInDataState(completePath);

                // Option can be checked thanks to its tarifs configuration
                if (isIncludedIn) {
                    setInDataState(completePath, true);
                    // If option state was never specified & setItselfFalse config is true
                } else if (undefined === valueInDataState && true === props.setItselfFalse) {
                    setInDataState(completePath, false);
                    // If option state was never specified
                } else if (undefined === valueInDataState) {
                    // Option is checked by default ?
                    true === props.default ?
                        setInDataState(completePath, true)
                        : setInDataState(completePath, undefined);
                }
            }
        }
    }, [props, isIncludedIn, checked])

    // useEffect whenever dataState changes
    useEffect(() => {
        const dataState = getInDataState(`${props.pathInDataState}`);
        // See if the option is checked in the datastate
        const isCheck = props.isArrayInDataState ?
            (dataState && dataState.includes(props.name)) ?? false
            : getInDataState(`${props.pathInDataState}.${props.name}`) ?? false;
        setChecked(isCheck);
    }, [props, dataState])

    // Callback when user click on the option
    const handleClickOption = useCallback(() => {
        if (!props.disabled) {
            // Check or not the option by changing dataState
            if (props.isArrayInDataState) {
                !checked ?
                    setInDataState(props.pathInDataState, props.name, props.isArrayInDataState)
                    : deleteInDataState(props.pathInDataState, props.name);
            } else {
                setInDataState(`${props.pathInDataState}.${props.name}`, !checked);
                if (props.pathInDataStateQuantity) {
                    !checked ?
                        setInDataState(props.pathInDataStateQuantity, 1)
                        : deleteInDataState(props.pathInDataStateQuantity);

                    setCounterValue(1);
                }
            }

            // Clear some data (if clearOnChangeInDataState paths indicated in json file)
            multipleClearInDataState(props.clearOnChangeInDataState);

            // Set in dataState paths & values indicated in json file
            multipleSetInDataState(props.setInDataState);

            // Delete some data (if clearOnChangeInDataState paths indicated in json file)
            multipleClearArrayInDataState(props.clearArrayOnChangeInDataState)

            // Clear some finalPrice of tarifs (if clearFinalPrice is indicated in json file)
            multipleClearFinalPriceOfTarifs(props.clearFinalPrice, undefined, props.partOfId);

            if (false === !checked) {
                // If uncheck reset option final price
                setFinalPriceInTarifsState(props.tarifsName, null, undefined, props.partOfId);

                // If uncheck reset data set in dataState
                multipleUnsetInDataState(props.setInDataState);
            }

        }
    }, [checked, props])

    // Callback when user change counter
    const handleCounter = useCallback((value, event) => {
        event?.stopPropagation();

        setCounterValue(value);
    }, [])

    useEffect(() => {
        if (null !== counterValue) {
            setInDataState(props.pathInDataStateQuantity, counterValue);
            setFinalPriceInTarifsState(props.tarifsName, null, undefined, props.dependsOn);
        }
    }, [counterValue, props])

    return (
        <Container
            checked={checked}
            onClick={handleClickOption}
            disabled={props.disabled}
            full={props.full}
            isImage={!!props.image}
            height={props.height}
            key={props.key}
        >
            {
                props.image && (
                    <ImageOptionLbiMedia
                        fullImage={props.fullImage}
                        src={getImage(props.image)}
                        alt='#'
                    />
                )
            }
            <NameStructureOption isImage={!!props.image}>
                <NameOption>
                    {beautifulGetLabel(props.label ?? props.name)}
                </NameOption>
            </NameStructureOption>
            {
                props.full && props.fullOptions && (
                    <FullOptionContainer>
                        {
                            props.fullOptions && props.fullOptions.map((option, index) => (
                                <LineFullOption key={index}>
                                    <CheckmarkContainerLittle />
                                    <TextFullOption>
                                        {option}
                                    </TextFullOption>
                                </LineFullOption>
                            ))
                        }
                    </FullOptionContainer>
                )
            }
            {
                props.pathInDataStateQuantity && checked && (
                    <Counter
                        dontStandAlone
                        min={1}
                        pathInDataStateQuantity={props.pathInDataStateQuantity}
                        counterDependsOn={props.counterDependsOn}
                        margin='0 0 0 25px'
                        fontSize='18px'
                        masterId={props.pathInDataStateQuantity}
                        initialValue={getInDataState(props.pathInDataStateQuantity) ?? 1}
                        callback={handleCounter}
                    />
                )
            }
            {
                null !== tarifsInfo && (
                    <Prices little={props.little}>
                        {
                            isIncludedIn ? (
                                    <FinalPriceOptionSpecial little={props.little}>
                                        INCLUS
                                    </FinalPriceOptionSpecial>
                                ) :
                                <>
                                    {
                                        isNaN(tarifsInfo.price) && (
                                            <FinalPriceOptionSpecial little={props.little}>
                                                {tarifsInfo.price}
                                            </FinalPriceOptionSpecial>
                                        )
                                    }
                                    {

                                        (Number.isInteger(fromPrice) || (null !== dynamicPrice && counterValue > 1)) && (
                                            <FinalPriceOption little={props.little}>
                                                {`${null === counterValue || 1 === counterValue ? formatPrice(fromPrice) : formatPrice(dynamicPrice)} HT ${tarifsInfo.priceUnit.toUpperCase()}`}
                                            </FinalPriceOption>
                                        )
                                    }
                                    {

                                        !Array.isArray(tarifsInfo.dynamicPrice) && Number.isInteger(tarifsInfo.price) && (
                                            <FinalPriceOptionStructure>
                                                {tarifsInfo.freeLimit &&
                                                    <FinalPriceOptionSpecial little={props.little}>
                                                        {`${tarifsInfo.freeLimit} inclus -`}&nbsp;
                                                    </FinalPriceOptionSpecial>
                                                }
                                                <FinalPriceOption little={props.little}>
                                                    {`${tarifsInfo.startingAt ? 'à partir de ' : ''} ${formatPrice(tarifsInfo.price)} HT ${tarifsInfo.priceUnit.toUpperCase()}`}
                                                </FinalPriceOption>
                                            </FinalPriceOptionStructure>
                                        )
                                    }
                                    {
                                        null !== tarifsInfo.insteadOf && (
                                            <BasePriceOption>
                                                {
                                                    isNaN(tarifsInfo.insteadOf) ?
                                                        tarifsInfo.insteadOf
                                                        : `au lieu de ${formatPrice(tarifsInfo.insteadOf)} HT ${tarifsInfo.priceUnit.toUpperCase()}`
                                                }
                                            </BasePriceOption>
                                        )
                                    }
                                </>
                        }
                    </Prices>
                )
            }
            <CheckmarkContainer
                checked={checked}
                notBottomCheckmark
            />
        </Container>
    );
};

export default CheckableOptionLbiMedia;
