import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDataState } from '../Store/selectors';
import moment from 'moment';
import styled from 'styled-components';
import { getInDataState, setInDataState } from '../Utils/storeHelpers';
import { SimpleSelect } from '../Utils/styleConstants';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const SelectContainer = styled(SimpleSelect)`
    width: 100px;
    margin: 10px;
`;

const MonthSelect = props => {
    // Use of hooks
    const [monthValue, setMonthValue] = useState(null);
    const [yearValue, setYearValue] = useState(null);

    // Use of redux
    const dataState = useSelector(selectDataState);

    // useEffect to manage monthValue hook
    useEffect(() => {
        const currentMonthValueInState = getInDataState(props.pathInDataState[0]);

        if (!currentMonthValueInState) {
            const newMonthValue = parseInt(moment().format('M'));
            setInDataState(props.pathInDataState[0], newMonthValue);
            setMonthValue(newMonthValue);
        } else {
            setMonthValue(currentMonthValueInState);
        }
    }, [props.pathInDataState, monthValue, dataState])

    // useEffect to manage yearValue hook
    useEffect(() => {
        const currentYearValueInState = getInDataState(props.pathInDataState[1]);

        if (!currentYearValueInState) {
            const newYearValue = parseInt(moment().format('YYYY'));
            setInDataState(props.pathInDataState[1], newYearValue);
            setYearValue(newYearValue);
        } else {
            setYearValue(currentYearValueInState);
        }
    }, [props.pathInDataState, yearValue, dataState])

    // Callback used to create month options
    const createMonthOptions = useCallback(() => {
        const monthNumber = [ ...Array(13).keys() ].splice(1);
        const monthOptions = [];

        monthNumber.forEach(value => {
            let monthOption = {};

            monthOption.label = value.toString();
            monthOption.value = value;
            monthOptions.push(monthOption);
        })

        return monthOptions;
    }, [])

    // Callback used to create year options
    const createYearOptions = useCallback(interval => {
        const intervalArray = [ ...Array(interval).keys() ];
        const yearOptions = [];

        intervalArray.forEach(value => {
            let yearOption = {};
            const year = moment().add(value, 'years').format('YYYY');

            yearOption.label = year;
            yearOption.value = parseInt(year);
            yearOptions.push(yearOption);
        });

        return yearOptions;
    }, [])

    // Definition of the select options
    const monthOptions = createMonthOptions();
    const yearOptions = createYearOptions(props.yearInterval);

    return (
        <Container>
            <SelectContainer
                options={monthOptions}
                onChange={(event) => setInDataState(props.pathInDataState[0], event.value)}
                placeholder={''}
                value={monthOptions.filter(option => option.value === monthValue)}
                menuPlacement={'top'}
            />
            <p>/</p>
            <SelectContainer
                options={yearOptions}
                onChange={(event) => setInDataState(props.pathInDataState[1], event.value)}
                placeholder={''}
                value={yearOptions.filter(option => option.value === yearValue)}
                menuPlacement={'top'}
            />
        </Container>
    );
};

export default MonthSelect;
