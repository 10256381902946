import React from 'react';
import styled from 'styled-components';
import { green } from '../Utils/styleConstants';
import { getTarifsInfoByTarifsName, manualDependsOnTarifsInfo } from '../Utils/jsonInterpreter';
import { getPriceDecimals } from '../Utils/price';

const Container = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;

    & > p {
        color: ${props => props.white ? 'white' : 'inherit'};
    }
    ${props => props.justifyCenter && 'justify-content: center;'}
`;

const Price = styled.p`
    font-weight: bold;
    font-size: ${props => props.fontSize ?? '2.3em'};
`;

const AfterPrice = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0 0 5px;

    & > p {
        color: ${props => props.white ? 'white' : 'inherit'};
    }
`;

const Devise = styled.p`
    font-weight: bold;
    font-size: ${props => props.fontSize ? `calc(${props.fontSize} / 2.5)}` : '1.2em'};
`;

const Unit = styled.p`
    font-size: ${props => props.fontSize ? `calc(${props.fontSize} / 3.5)}` : '0.8em'};
`;

const Apartir = styled.p`
    ${props => props.white && 'color: white;'}
    font-size: 0.9em;
`;

const PriceString = styled.p`
    color: ${props => props.white ? 'white' : green};
    font-weight: bold;
    text-transform: uppercase;
`;

const PriceElement = props => {
    // Retrieve info about tarifs
    let tarifsInfo = getTarifsInfoByTarifsName(props.tarifsName);
    tarifsInfo = manualDependsOnTarifsInfo(tarifsInfo, props.dependsOn);

    return (
        null !== tarifsInfo && (
            isNaN(tarifsInfo.price) ? (
                <PriceString white={props.white}>
                    {tarifsInfo.price}
                </PriceString>
            ) : (
                <>
                    {
                        true === tarifsInfo.apartir && (
                            <Apartir white={props.white}>
                                À partir de :
                            </Apartir>
                        )
                    }
                    <Container white={props.white} justifyCenter={props.justifyCenter}>
                        <Price fontSize={props.fontSize}>
                            {tarifsInfo.withFloor ? Math.floor(tarifsInfo.price) : tarifsInfo.price}
                        </Price>
                        <AfterPrice white={props.white}>
                            <Devise fontSize={props.fontSize}>
                                {`€ ${getPriceDecimals(tarifsInfo.price, 2)}`}
                            </Devise>
                            <Unit fontSize={props.fontSize}>
                                {'' === tarifsInfo.priceUnit ? 'HT' : `HT ${tarifsInfo.priceUnit}`}
                            </Unit>
                        </AfterPrice>
                    </Container>
                </>
            )
        )
    );
};

export default PriceElement;
