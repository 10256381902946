import React from 'react'
import styled from 'styled-components'
import PriceElement from './PriceElement'
import { borderGrey } from '../Utils/styleConstants'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 20px;
  border-bottom: 1px solid ${borderGrey};
  border-top: 1px solid transparent;
`

const Data = styled.div`
  flex: 0 0 50%;
`

const PriceContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-position: center center;
  background-size: 5px 5px;
`

class Priced extends React.Component {
  render () {
    return (
      <Container>
        <Data>{this.props.children}</Data>
        <PriceContainer>
          <PriceElement tarifsName={this.props.tarifsName} />
        </PriceContainer>
      </Container>
    )
  }
}

export default Priced
