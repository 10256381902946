import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Delete from './Addable/Delete';
import { createOptionsFromArray } from '../Utils/jsonInterpreter';
import { getInDataState, setInDataState } from '../Utils/storeHelpers';
import { borderGrey, green, SimpleInput, SimpleSelect, SimpleAddButton } from '../Utils/styleConstants';
import ToggleLabeled from "./ToggleLabeled";

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 1% 2%;
    margin: 0 2% 2%;
    border: 1px solid ${borderGrey};
`;

const Line = styled.div`
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    width: 100%;
    padding: 1% 2%;
`;

const InputStructure = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 48%;
    padding: 0 3% 0 0;
`;

const ToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
`;

const TitleLabel = styled.p`
    color: ${green};
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1em;
`;

const PageLabel = styled.p`
    font-size: 1em;
`;

const Input = styled(SimpleInput)`
    width: 70%;
`;

const Select = styled(SimpleSelect)`
    width: 40%;
`;

const AddElementButton = styled(SimpleAddButton)`
    margin: 1em 0;
    padding: 10px 20px;
`;

const ConfigTitlesPages = props => {
    // Use of hooks
    const [lines, setLines] = useState(null);

    // useEffect when component is mounting
    useEffect(() => {
        if (null === lines) {
            const currentLines = getInDataState(props.pathInDataState);

            undefined === currentLines || 0 === currentLines.length ?
                setLines([ { title: '', page: '' } ])
                : setLines(currentLines);
        }
    }, [lines, props.pathInDataState])

    // Callback when user add an element
    const handleAddElement = useCallback(() => {
        setLines([ ...lines, { title: '', page: '' } ]);
    }, [lines])

    // useEffect each time lines hook value changes
    useEffect(() => {
        null !== lines && setInDataState(props.pathInDataState, lines);
    }, [lines, props.pathInDataState])

    // Callback when user change line title
    const handleChangeTitle = useCallback((event, lineIndex) => {
        setLines(lines.map((line, index) => index === lineIndex ? { ...line, title: event.target.value } : line));
    }, [lines])

    // Callback when user change page
    const handleChangePage = useCallback((event, lineIndex) => {
        setLines(lines.map((line, index) => index === lineIndex ? { ...line, page: event } : line));
    }, [lines])

    // Callback when user delete an element
    const handleDeleteElement = useCallback(lineIndex => {
        setLines(lines.filter((_, index) => index !== lineIndex));
    }, [lines])

    const handleToggleButton = useCallback((event, lineIndex) => {
        setLines(lines.map((line, index) => index === lineIndex ? { ...line, toggle: event.target.checked } : line));
    }, [lines]);

    return (
        null !== lines && (
            <Container>
                {lines.map((line, index) => (
                    <Line key={index}>
                            <ToggleWrapper >
                                {props.toggleableDeepPage && (
                                    <ToggleLabeled
                                        label="redirection-page-profonde"
                                        default={false}
                                        checked={line.toggle}
                                        onChange={(event) => handleToggleButton(event, index)}
                                    />
                                )}
                            </ToggleWrapper>
                            <Wrapper>
                                <InputStructure>
                                    <TitleLabel>{`titre ${index + 1}`}</TitleLabel>
                                    <Input
                                        type='text'
                                        value={line.title}
                                        onChange={(event) => handleChangeTitle(event, index)}
                                    />
                                </InputStructure>
                                <InputStructure>
                                    <PageLabel>Contenu de la page de destination</PageLabel>
                                    <Select
                                        options={createOptionsFromArray(props.options)}
                                        placeholder=''
                                        value={line.page}
                                        onChange={(event) => handleChangePage(event, index)}
                                    />
                                </InputStructure>
                            </Wrapper>
                            {
                                props.addable && 0 < index && (
                                    <Delete handleClick={() => handleDeleteElement(index)}/>
                                )
                            }
                        </Line>
                    ))
                }

                {
                    props.addable && (
                        <AddElementButton onClick={() => handleAddElement()}>
                            Ajouter un élement
                        </AddElementButton>
                    )
                }
            </Container>
        )
    );
};

export default ConfigTitlesPages;
