import React from 'react';
import CheckableTypeOptions from "./Option/CheckableTypeOptions";

const SmartPix = ({ options, ...props}) => {
    const { pricesDependOn, pathInDataState } = props;

    return (
        <CheckableTypeOptions options={options} pricesDependOn={pricesDependOn} pathInDataState={pathInDataState} />
    );
}

export default SmartPix;
