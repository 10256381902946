import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { createOptions, getImage } from '../Utils/jsonInterpreter';
import { getInDataState, setInDataState } from '../Utils/storeHelpers';
import { SimpleSelect } from '../Utils/styleConstants';

const Image = styled.img`
    width: 100%;
`;

const Separator = styled.hr`
    height: 1px;
    width: 100%;
    background-color: lightgray;
    margin: 3em 0;
    border: none;
`;

const SliderCube = (props) => {
    // Use of hooks
    const [options, setOptions] = useState(null);
    const [mounted, setMounted] = useState(false);
    const [sliderSelectedOption, setSliderSelectedOption] = useState(null);
    const [cubeSelectedOption, setCubeSelectedOption] = useState(null);

    // useEffect on mount
    useEffect(() => {
        setOptions(createOptions(props));
    }, [props])

    // useEffect once options is not null and each times the component is mounted
    useEffect(() => {
        if (options !== null && !mounted) {
            setMounted(true);
            // Load from store data
            getInDataState(`${props.pathInDataState}.slider`) ?
                setSliderSelectedOption(options.find(option => option.value === getInDataState(`${props.pathInDataState}.slider`)))
                : setSliderSelectedOption(options[0]);
            getInDataState(`${props.pathInDataState}.cube`) ?
                setCubeSelectedOption(options.filter(option => option.value === getInDataState(`${props.pathInDataState}.cube`)))
                : setCubeSelectedOption(options[0]);
            // or set the default option
        }
    }, [props, options, mounted])

    // Callback on select change
    const handleChange = useCallback((selectedOption, selectName) => {
        const twoOptions = options.slice(1);
        const notSelectedOption = twoOptions.filter(option => option.value !== selectedOption.value)[0];

        if ('slider' === selectName) {
            // Change hook of component
            setSliderSelectedOption(selectedOption);
            
            if ('no-choice' !== selectedOption.value && options[0].value !== cubeSelectedOption.value) {
                // If we did not reset to default, the other select takes the other value
                setCubeSelectedOption(notSelectedOption);
                // Change value in redux state
                setInDataState(`${props.pathInDataState}.cube`, notSelectedOption.value);
            } 
        }
        if (selectName === 'cube') {
            // Change hook of component
            setCubeSelectedOption(selectedOption);

            if ('no-choice' !== selectedOption.value && options[0].value !== sliderSelectedOption.value) {
                // If we did not reset to default, the other select takes the other value
                setSliderSelectedOption(notSelectedOption);
                // Change value in redux state
                setInDataState(`${props.pathInDataState}.slider`, notSelectedOption.value);
            }
        }

        // Change value in redux state
        setInDataState(`${props.pathInDataState}.${selectName}`, selectedOption.value);
    }, [props, sliderSelectedOption, cubeSelectedOption, options])

    return (
        options !== null && (
            <>
                <Image src={getImage(props.sliderImg)} alt="slider-tokyo"/>
                <SimpleSelect
                    options={options}
                    placeholder=''
                    onChange={(selectedOption) => handleChange(selectedOption, 'slider')}
                    value={sliderSelectedOption}
                />
                <Separator />
                <Image src={getImage(props.cubeImg)} alt="module-cube-tokyo" />
                <SimpleSelect
                    options={options}
                    placeholder=''
                    onChange={(selectedOption) => handleChange(selectedOption, 'cube')}
                    value={cubeSelectedOption}
                />
            </>
        )
    );
};

export default SliderCube;
