const server = {
    getDocument: async () => {
        return {
            id: 1,
            data: {}
        };
    },
    saveDocument: async () => {
        return true;
    },
    getAuth: () => ({
        logged: true,
        token: {
            clientRef: 1234,
            walletId: 4321,
            forcenew: false,
            typeof: "hektor",
            user: "jk@la-boite-immo.com"
        }
    })
};

export default server
