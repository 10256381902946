/**
 * Takes an array of T elements and a grouping function and returns a Map
 * of the array grouped by the grouping function.
 *
 * @template T
 * @template K
 * @param {T[]} list
 * @param {function(T): K} keySelector
 *
 * @returns {Map<K, T[]>}
 */
function groupBy(list, keySelector) {
    /** @type {Map<K, T[]>} */
    const map = new Map();

    for (const item of list) {
        const key = keySelector(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    }

    return map;
}

export default groupBy;
