import React, { useCallback, useState, useEffect } from 'react';
import styled from 'styled-components';
import CheckmarkContainerSocialNetworks from './Icons/CheckmarkContainerSocialNetworks';
import { borderGrey, socialNetworksData, SimpleCheckbox } from '../Utils/styleConstants';
import { deleteInDataState, setInDataState, getInDataState } from '../Utils/storeHelpers';
import urljoin from 'url-join';

const Panel = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;


const SocialNetwork = styled(SimpleCheckbox)`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => props.little ? '80px' : '100px'};
    height: ${props => props.little ? '45px' : '48px'};
    margin: 0 5px;
    border-radius: 4px;
    background-color: ${props => props.color ?? 'transparent'};
    background-image: url(${props => props.icon ? urljoin(process.env.REACT_APP_SERVER_URI, props.icon) :  'none'});
    background-size: 20px 20px;
    background-position: center center;
    background-repeat: no-repeat;
    cursor: ${props => props.pointer ? 'pointer' : 'default'};
`;

const Grid = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
`;

const ChoiceStructure = styled.div`
    display: flex;
    align-items: center;
    width: 48%;
    padding: 2em 0 0;
`;

const DeletableSocialNetwork = styled.div`
    position: relative;
    cursor: default;
`;

const Delete = styled.p`
    position: absolute;
    top: -10px;
    right: -10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    border: 4px solid white;
    font-size: 12px;
    font-weight: bold;
    background-color: ${borderGrey};
    cursor: pointer;
`;

const Input = styled.input`
    width: 100%;
    margin: 0 0 0 20px;
    color: initial;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    padding: 0.5em;
    font-size: 1em;
    font-family: Roboto,sans-serif;
`;

const ReseauxSociaux = props => {
    // Use of hooks
    const [socialNetworks, setSocialNetworks] = useState(null);
    const [choices, setChoices] = useState(null);

    // useEffect when component is mounting
    useEffect(() => {
        if (null === socialNetworks) {
            setSocialNetworks(socialNetworksData);
        }
    }, [socialNetworks, props.pathInDataState])

    // useEffect when component is mounting
    useEffect(() => {
        if (null !== socialNetworks && null === choices) {
            const choicesInState = getInDataState(props.pathInDataState);
            if (choicesInState) {
                let tabChoice = [];
                socialNetworks.forEach((item, index) => {
                    item.checked = false;
                    item.value = '';
                    if(choicesInState.hasOwnProperty(item.name)){
                        item.value = choicesInState[item.name];
                        item.checked = true;
                        tabChoice = [ ...tabChoice, item ];
                    }
                });
                setChoices(tabChoice);
            } else {
                socialNetworks.forEach((item) => {
                    item.checked = false;
                    item.value = '';
                });
                setChoices([]);
            }
        }
    }, [choices, socialNetworks, props.pathInDataState])

    // Callback if user want to add social network
    const handleAdd = useCallback((item) => {
        if (!choices.some(choice => choice.name === item.name)) {
            item.checked = true;
            setChoices([ ...choices, item ]);
        } else {
            const fullPath = `${props.pathInDataState}.${item.name}`;

            item.value = '';
            item.checked = false;
            setChoices(choices.filter(choice=> choice.name !== item.name));
            deleteInDataState(fullPath);
        }
    }, [choices, props.pathInDataState])

    // Callback if user want to delete social network
    const handleDelete = useCallback((choiceIndex) => {
        const fullPath = `${props.pathInDataState}.${choices[choiceIndex].name}`;

        socialNetworks.forEach((item, index) => {
            if(item.name === choices[choiceIndex].name){
                item.checked = false;
                item.value = '';
            }
        });

        setChoices(choices.filter((_, index) => index !== choiceIndex));
        deleteInDataState(fullPath);
    }, [choices, socialNetworks, props.pathInDataState])

    // Callback when user change an social network input
    const handleInputChange = useCallback((event, choiceIndex) => {
        const value = event.target.value;
        const fullPath = `${props.pathInDataState}.${choices[choiceIndex].name}`;

        setChoices(choices.map((choice, index) => index === choiceIndex ? { ...choice, value: value } : choice));

        '' !== value ?
            setInDataState(fullPath, value)
            : deleteInDataState(fullPath)
    }, [choices, props.pathInDataState])

    return (
        <>
            <Panel>
                {
                    socialNetworks && choices && socialNetworks.map((item, index) => (
                        <SocialNetwork
                            pointer
                            key={index}
                            color={item.color}
                            icon={item.icon}
                            onClick={() => handleAdd(item)}
                            checked={item.checked}
                        >
                            <CheckmarkContainerSocialNetworks checked={item.checked} />
                        </SocialNetwork>
                    ))
                }
            </Panel>
            <Grid>
                {
                    socialNetworks && choices && choices.map((choice, index) => (
                        <ChoiceStructure
                            key={index}
                        >
                            <DeletableSocialNetwork>
                                <SocialNetwork
                                    little
                                    color={choice.color}
                                    icon={choice.icon}
                                />
                                <Delete onClick={() => handleDelete(index)}>x</Delete>
                            </DeletableSocialNetwork>
                            <Input
                                type='text'
                                value={choice.value ?? ''}
                                onChange={(event) => handleInputChange(event, index)}
                            />
                        </ChoiceStructure>
                    ))
                }
            </Grid>
        </>
    )
};

export default ReseauxSociaux;
