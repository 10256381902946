import React from 'react'
import styled from 'styled-components'

const SvgElement = styled.svg`
  width: 100%;
  height: 100%;
  fill: ${props => props.color === undefined ? 'white' : props.color};
`

class Checkmark extends React.Component {
  render () {
    return (
      <SvgElement {...this.props} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'><path d='M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z' /></SvgElement>
    )
  }
}

export default Checkmark
