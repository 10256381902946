import React from 'react';
import styled from 'styled-components';
import { grey } from '../Utils/styleConstants';

const Container = styled.div`
    color: ${grey};
    font-size: 0.8em;
`;

const Html = props => {
    return (
        <Container
            fontSize={props.fontSize}
            dangerouslySetInnerHTML={{__html: props.html}}
        />
    );
};

export default Html;
