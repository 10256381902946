import { deleteInState, setInState } from '../../Utils/reducerHelpers';
import {
    UPDATE_DATA,
    DELETE_DATA_WITH_TEMPLATE,
    SWITCH_DATA_VALUE,
    UPDATE_ORDER_ADDABLE,
    DELETE_BULK_DATA,
    UPDATE_DATA_NDD,
    SET_DATA,
    DELETE_DATA
} from '../actions';
import initialState from '../initialState';

const data = (state = initialState.data, action) => {
    const newState = { ...state };
    
    switch (action.type) {
        case SET_DATA:
            setInState(
                newState,
                action.payload.path,
                action.payload.value,
                action.payload.isArray,
                action.payload.asKey,
                action.payload.forArray
            );

            return newState;
        
        case DELETE_DATA:
            deleteInState(newState, action.payload.path, action.payload.value, action.payload.asKey, action.payload.deleteArray);

            return newState;

        case UPDATE_DATA_NDD:
            if (newState[action.value.id] === undefined) {
                newState[action.value.id] = {};
            }
            if (newState[action.value.id][action.value.name] === undefined) {
                newState[action.value.id][action.value.name] = [];
            }

            // If the action modify the primary field
            action.value.primary &&
                newState[action.value.id][action.value.name].map((item, index) =>
                    //Set all ndd with primary => false
                    newState[action.value.id][action.value.name][index].primary = false
                );

            newState[action.value.id][action.value.name][action.value.index] = action.value.value;

            return newState;

        case SWITCH_DATA_VALUE:
            if (newState[action.value.id] === undefined) {
                newState[action.value.id] = {};
            }

            newState[action.value.id][action.value.name] =
                newState[action.value.id][action.value.name] !== undefined ?
                    !newState[action.value.id][action.value.name]
                    : true;

            return newState;

        case UPDATE_DATA:
            return {
                ...state,
                ...action.value,
            };

        case DELETE_DATA_WITH_TEMPLATE:
            if (newState[action.value.group] !== undefined) {
                Object.entries(newState[action.value.group]).forEach(([key]) => {
                    if (key.indexOf(action.value.template) > -1) {
                        delete newState[action.value.group][key];
                    }
                });
            }

            return newState;

        case DELETE_BULK_DATA:
            action.value.forEach(({ id, row }) => {
                if (newState[id] !== undefined) {
                    row.forEach((name) => {
                        // If it's not undefined
                        // Either we overwrite with the initialState ...
                        // Or we don't touch the data and we continue
                        if (newState[id] !== undefined && newState[id][name] !== undefined) {
                            if (initialState.data[id] !== undefined && initialState.data[id][name] !== undefined) {
                                newState[id][name] = initialState.data[id][name];
                            }
                        // Else, if it's undefined, we delete it
                        } else {
                            delete newState[id][name];
                        }
                    });

                    if (newState[id].constructor === Object && Object.keys(newState[id]).length === 0) {
                        delete newState[id];
                    }
                }
            });

            Object.keys(newState).forEach(key => {
                const obj = newState[key];

                if (obj.constructor === Object && Object.keys(obj).length === 0 ) {
                    delete newState[key];
                }
            });

            return newState;

        case UPDATE_ORDER_ADDABLE:
            const { addname, order } = action.value;

            newState['_order'][addname] = order;

            return newState;

        default:
            return state;
    }
};

export default data;
