import parse from 'url-parse';
import Cookies from 'universal-cookie';
import server from './get-server';
import fetch from './fetch';

const askLogged = async () => {
    try {
        const result = await fetch('/is-logged');

        if (result && result.status === 200 && result.data !== undefined && result.data.logged !== undefined) {
            return result.data;
        }

        return {
            logged: false,
            token: {}
        };
    } catch (e) {
        return {
            logged: false,
            token: {}
        };
    }
};

const auth = async () => {
    if (process.env.REACT_APP_TRUE_AUTH) {
        const cookies = new Cookies();
        const url = parse(window.location, true);

        if (url.query.token !== undefined) {
            cookies.set('thetoken', url.query.token);
            document.location.href = `${url.protocol}//${url.host}${url.pathname}`;

            return false;
        }

        return await askLogged();
    }

    return server.getAuth();
};

export default auth;
