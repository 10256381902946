import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Toggle from './Toggle';
import PriceElement from './PriceElement';
import DatePicker from './DatePicker';
import {borderGrey, GenericTextInfo, InputNdd, SimpleSelect} from '../Utils/styleConstants';
import { nddRegExp } from '../Utils/price';
import { createOptionsFromArray } from '../Utils/jsonInterpreter';
import { beautifulGetLabel } from '../Utils/labels';
import {
    deleteInDataState,
    setInDataState,
    getInDataState,
    setFinalPriceInTarifsState,
    inHektorOfferMixte,
    getHektorContext, getDynamicHektorOffer, getDynamicPathInDataState
} from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Line = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    padding: 2.5em 0;

    &:not(:last-child) {
        border-bottom: 1px solid ${borderGrey};
    }
`;

const ToggleStructure = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 350px;
`;

const Label = styled.p`
    padding: 0 2em 0 1em;
    user-select: none;
`;

const SelectWithPriceStructure = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 350px);
`;

const Select = styled(SimpleSelect)`
    width: 40%;
`;

const DatePickerStructure = styled.div`
    display: flex;
    align-items: center;
    padding: 1em 0 0;
`;

const DatePickerLabel = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 350px;
`;

const InputWithErrorStructure = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 350px);
`;

const InputDomainStructure = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
`;

const InputLabel = styled.label`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 37px;
    padding: 0 15px;
    color: #A5A5AA;
    background-color: ${borderGrey};
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
`;

const Error = styled.p`
    color: red;
    padding: 1em 0 0;
`;

const TextInfo = styled.div`
    padding: 2em 0;
`;

const RecupDonnees = props => {
    const hektorOffer = getDynamicHektorOffer(props.isSpecialCase, props.pathForOffer, props.hektorOffer);
    const pathInDataState = getDynamicPathInDataState(props.isSpecialCase, props.pathInDataState, hektorOffer);

    // Use of redux
    const hektorMixte = inHektorOfferMixte();
    const hektorContext = getHektorContext();

    // Use of hooks
    const [recuperation, setRecuperation] = useState(null);
    const [aspiration, setAspiration] = useState(null);
    const [option, setOption] = useState(null);
    const [url, setUrl] = useState(null);

    // useEffect on mounting
    useEffect(() => {
        null === recuperation &&
            setRecuperation('logiciel' === getInDataState(`${pathInDataState}.use`));

        null === aspiration &&
            setAspiration('aspi' === getInDataState(`${pathInDataState}.use`));

        if (null === option) {
            const logiciel = getInDataState(`${pathInDataState}.logiciel`);

            logiciel ? setOption({ value: logiciel, label: beautifulGetLabel(logiciel) }) : setOption(null);
        }

        null === url &&
            setUrl(getInDataState(`${pathInDataState}.url`) ?? '');
    }, [aspiration, option, recuperation, url, pathInDataState, props.tarifsPrefix])

    // Callback on change toggle recuperation
    const handleToggleRecuperation = useCallback((value) => {
        setRecuperation(value);
        setFinalPriceInTarifsState(props.tarifsName, null, undefined, hektorOffer);

        if (value) {
            aspiration && setAspiration(false);
            setUrl('');
            setInDataState(`${pathInDataState}.date`, new Date().toISOString());
            setInDataState(`${pathInDataState}.logiciel`, '');
            setInDataState(`${pathInDataState}.use`, 'logiciel');
        } else {
            setOption(null);
            deleteInDataState(pathInDataState);
        }
    }, [pathInDataState, aspiration, hektorOffer, props.tarifsName])

    // Callback on change toggle aspiration
    const handleToggleAspiration = useCallback((value) => {
        setAspiration(value);

        if (value) {
            recuperation && setRecuperation(false);
            setInDataState(`${pathInDataState}.logiciel`, '');
            setOption(null);
            setInDataState(`${pathInDataState}.url`, '');
            setInDataState(`${pathInDataState}.use`, 'aspi');
        } else {
            setUrl('');
            deleteInDataState(pathInDataState);
        }
    }, [pathInDataState, recuperation])

    // Callback on software select change
    const handleSoftwareChange = useCallback((event) => {
        setOption(event);
        setRecuperation(true);
        setAspiration(false);
        setUrl('');
        setInDataState(`${pathInDataState}.logiciel`, event.value);
        setInDataState(`${pathInDataState}.use`, 'logiciel');
        setFinalPriceInTarifsState(props.tarifsName, null, undefined, hektorOffer);
    }, [pathInDataState, hektorOffer, props.tarifsName])

    // Callback on change aspiration url
    const handleAspirationUrlChange = useCallback((event) => {
        setOption(null);
        setAspiration(true);
        setRecuperation(false);
        setUrl(event.target.value);
    }, [])

    // useEffect each time url hook value changes
    useEffect(() => {
        null !== url && nddRegExp.test(url) ?
            setInDataState(`${pathInDataState}.url`, url)
            : deleteInDataState(`${pathInDataState}.url`);
    }, [url, pathInDataState])

    return (
        hektorContext.includes('agences') && hektorMixte ? (
            <TextInfo>
                <GenericTextInfo>La récupération des données sera définie lors du choix des options pour votre réseau mandataires (étape 6).</GenericTextInfo>
            </TextInfo>
            ) :
            null !== recuperation && null !== aspiration && null !== url && (
                <Container>
                    <Line>
                        <ToggleStructure>
                            <Toggle
                                value={recuperation}
                                callback={handleToggleRecuperation}
                            />
                            <Label>Récupération de données</Label>
                        </ToggleStructure>
                        <SelectWithPriceStructure>
                            <Select
                                options={createOptionsFromArray(props.options)}
                                placeholder='Choisir un logiciel'
                                value={option}
                                error={recuperation && !option}
                                onChange={(event) => handleSoftwareChange(event)}
                            />
                            {
                                null !== option && (
                                    <PriceElement
                                        tarifsName={props.tarifsName}
                                        dependsOn={option.value.toLowerCase()}
                                    />
                                )
                            }
                        </SelectWithPriceStructure>
                        {
                            recuperation && (
                                <DatePickerStructure>
                                    <DatePickerLabel>
                                        <Label>Coupure du logiciel le</Label>
                                    </DatePickerLabel>
                                    <DatePicker
                                        pathInDataState={`${pathInDataState}.date`}
                                        minDate='today'
                                    />
                                </DatePickerStructure>
                            )
                        }
                    </Line>
                    <Line>
                        <ToggleStructure>
                            <Toggle
                                value={aspiration}
                                callback={handleToggleAspiration}
                            />
                            <Label>Aspiration de données</Label>
                        </ToggleStructure>
                        {
                            aspiration && (
                                <InputWithErrorStructure>
                                    <InputDomainStructure>
                                        <InputLabel htmlFor='aspi-ndd'>
                                            {'http://'}
                                        </InputLabel>
                                        <InputNdd
                                            id='aspi-ndd'
                                            type='text'
                                            placeholder='...'
                                            autoComplete='off'
                                            value={url}
                                            error={!url.match(nddRegExp)}
                                            onChange={(event) => handleAspirationUrlChange(event)}
                                        />
                                    </InputDomainStructure>
                                    {
                                        !url.match(nddRegExp) && (
                                            <Error>Votre nom de domaine est invalide</Error>
                                        )
                                    }
                                </InputWithErrorStructure>
                            )
                        }
                    </Line>
                </Container>
            )
    );
};

export default RecupDonnees;
