import React from 'react';
import styled from 'styled-components';
import PriceUpdater from './PriceUpdater';
import {beautifulGetLabel, getCounterInfo, getLabel} from '../../Utils/labels';
import { borderGrey } from '../../Utils/styleConstants';

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    border-bottom: 1px solid ${borderGrey};
    white-space: pre-line;
`;

const getFinalLabel = (parentId, label, name) => {
    let finalLabel = label ? beautifulGetLabel(label, parentId) : name;
    const labelSup = getLabel(`${label ? label : name}-$labelSup`);

    finalLabel += labelSup ? ` - ${labelSup}` : '';

    return finalLabel;
};

const Child = props => {
    return (
        <>
            {
                props.hasOwnProperty('finalPrices') && props.finalPrices.length > 0 && (
                    props.finalPrices.map((finalPrice, index) => {
                        const displayWhenParent = finalPrice.displayWhenParent;
        
                        // Some finalPrice, only have to be display under specific parent
                        // For example, it is the case for hektor additional options
                        return (undefined === displayWhenParent || displayWhenParent.includes(props.parentId)) && (
                            <Container key={index}>
                                <p>
                                    {getCounterInfo('prefix', finalPrice, props.parentId)}
                                    {getFinalLabel(props.parentId, finalPrice.label, finalPrice.name)}
                                    {getCounterInfo('suffix', finalPrice, props.parentId)}
                                </p>
                                <PriceUpdater
                                    tarifs={props}
                                    index={index}
                                />
                            </Container>
                        );
                    })
                )
            }
            {
                props.hasOwnProperty('finalPrice') && (
                    <Container>
                        <p>
                            {getCounterInfo('prefix', props, props.parentId)}
                            {getFinalLabel(props.parentId, props.label, props.name)}
                            {getCounterInfo('suffix', props, props.parentId)}
                        </p>
                        <PriceUpdater tarifs={props} />
                    </Container>
                )
            }
        </>
    );
};

export default Child;
