import React, { useRef } from 'react';
import styled from 'styled-components';
import GroupHeader from './GroupHeader';
import GroupBody from './GroupBody';
import GroupFooter from './GroupFooter';
import condition from '../../Utils/condition'
import { grey } from '../../Utils/styleConstants';
import { transparentize } from 'polished';

const Container = styled.section`
    background: white;
    margin: 2em 0;
    box-shadow: 0 0 10px ${transparentize(0.8, grey)};

    &:first-child {
        margin-top: 0;
    }
`;

const Group = props => {

    // Allows to set in parameter the reference of the group
    const containerRef = useRef(null);

    // Group display management
    let display = false;
    // See if at least one element must be displayed in the group
    props.body.fields.forEach(field => {
        display = condition(field) ? true : display
    });

    return (
        <Container ref={containerRef} style={{ display: display ? "block" : "none" }}>
            <GroupHeader
                groupId={props.id}
                fields={props.hasOwnProperty('header') ? props.header.fields : null}
            />
            <GroupBody
                groupId={props.id}
                columns={props.body.columns}
                padding={props.body.padding}
                fields={props.body.fields}
                groupRef={containerRef}
                groupFields={props.body.fields}
            />
            {
                props.hasOwnProperty('footer') && (
                    <GroupFooter
                        padding={props.footer.padding}
                        fields={props.footer.fields}
                    />
                )
            }
        </Container>
    );
};

export default Group;
