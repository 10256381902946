import React from 'react';
import Builder from '../Utils/builder';
import Labeled from './Labeled';
import styled from 'styled-components';
import { getComboName } from '../Utils/jsonInterpreter';
import { beautifulGetLabel } from '../Utils/labels';
import { v4 as uuid } from 'uuid';

const refs = {
    input: 'InputTextContainer',
    select: 'SelectContainer',
    toggle: 'ToggleLabeled',
    conditionnal: 'ConditionnalComponentContainer',
    counter: 'CounterContainer'
};

const Container = styled.div`
    display: flex;
    width: 100%;
`;

const Wrapper = styled.div`
    flex: 0 0 ${props => props.size}%;
    padding: 0 5px;

    &:first-child {
        padding-left: 0;
    }

    &:last-child {
        padding-right: 0;
    }
`;

const generateTree  = (props) => {
    return props.combo.map(type => {
        const ref = {
            ...props,
            type: refs[type],
            combo: false,
            ...props[type],
            name: getComboName(props)(type),
            isLabeled: false,
            priced: false
        }

        return (
            <Wrapper key={uuid()} {...props[type]}>
                {Builder.create(props.masterId, ref)}
            </Wrapper>
        )
    })
};

class Combo extends React.PureComponent {
    constructor (props) {
        super (props)
        this.state = {
            tree: generateTree(props)
        }
    }

    render () {
        return (
            <Labeled
                {...this.props}
                label={this.props.label ?? beautifulGetLabel(this.props.name)}
            >
                <Container>
                    {this.state.tree}
                </Container>
            </Labeled>
        )
    }
}

export default Combo;
