import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
    UPDATE_DOCUMENT,
    UPDATE_TARIFS,
    UPDATE_DATA,
    SET_ID_DOCUMENT,
    SET_USER_EMAIL,
    SET_USER_AVATAR,
    SET_CREATE_DATE,
    SET_UPDATE_DATE,
    SET_CONTRACT_NUM
} from './Store/actions';
import AppLayout from './AppLayout';
import Spinner from './Components/Spinner';
import Builder from './Utils/builder';
import server from './Utils/get-server';
import fetchDistant from './Utils/fetch';
import { Waiting, WaitingContainer } from './Utils/styleConstants';
import 'moment/locale/fr';

const AuthenticatedApp = props => {
    // Use of hooks
    const [document, setDocument] = useState(null);
    const [prices, setPrices] = useState(null);
    const [apiError, setApiError] = useState(false);
    const [deprecated, setDeprecated] = useState(null);
    const [lastTarifsRefId, setLastTarifsRefId] = useState(null);

    // Use of redux
    const dispatch = useDispatch();

    // useEffect when component is mounting
    useEffect(() => {
        const data = Builder.document('generic');

        dispatch({
            type: UPDATE_DATA,
            value: data.initialData
        });

        dispatch({
            type: UPDATE_DOCUMENT,
            value: data
        });

        dispatch({
            type: SET_USER_AVATAR,
            value: props.avatar
        });

        dispatch({
            type: SET_USER_EMAIL,
            value: props.user
        });
    }, [props, dispatch]);

    // useEffect when component is mounting
    useEffect(() => {
        server.getDocument({ ...props, typeOfDocument: 'generic' })
            .then(document => {
                dispatch({
                    type: SET_ID_DOCUMENT,
                    value: document.id
                });

                dispatch({
                    type: SET_CREATE_DATE,
                    value: document.createDate
                });

                dispatch({
                    type: SET_UPDATE_DATE,
                    value: document.updateDate
                });

                dispatch({
                    type: SET_CONTRACT_NUM,
                    value: document.contractNum
                });

                dispatch({
                    type: UPDATE_DATA,
                    value: document.data
                });

                // Save document in hook
                setDocument(document);
            })
            .catch(() => {
                console.log('Cannot retrieve dataState');
                setApiError(true);
            });
    }, [props, dispatch])

    // useEffect when document hook value changes & not null
    useEffect(() => {
        null !== document &&
            fetchDistant(`/tarifs_ref?id=${document.data.tarifsRefId}`)
                .then(prices => {
                    dispatch({
                        type: UPDATE_TARIFS,
                        value: prices.data
                    });

                    // Store prices in hook
                    setPrices(prices.data);
                })
                .catch(() => {
                    console.log('Cannot retrieve tarifsRef');
                    setApiError(true);
                });
    }, [document, dispatch]);

    // useEffect when both document/prices hooks value change & not null
    useEffect(() => {
        null !== document && null !== prices &&
            // Create new version of tarifsRef if needed (server-side)
            fetchDistant(`/version`)
                .then(() =>
                    // Then check if client tarifsRef is deprecated
                    fetchDistant(`/deprecated?tarifsRefId=${document.data.tarifsRefId}`)
                        .then(result => {
                            setDeprecated(result.data.isDeprecated);
                            setLastTarifsRefId(result.data.lastTarifsRefId);
                        })
                )
                .catch(() => {
                    console.log('Cannot check for tarifsRef new version');
                    setApiError(true);
                });
    }, [document, prices]);

    return (
        (document && prices && null !== deprecated) ? (
            <AppLayout deprecated={deprecated} lastTarifsRefId={lastTarifsRefId}/>
        ) : (
            <WaitingContainer>
                {
                    apiError ? (
                        <>
                            <Waiting>Impossible de récupérer vos données.</Waiting>
                            <Waiting>:(</Waiting>
                        </>
                    ) : (
                        <>
                            <Waiting>Récupération de vos données ...</Waiting>
                            <Spinner size={150} />
                        </>
                    )
                }
            </WaitingContainer>
        )
    );
};

export default AuthenticatedApp;
