import React from "react";
import CounterOptionsGrid from "../../CounterOptionsGrid";
import CheckboxOptionsGroup from "../../CheckboxOptionsGroup";
import CounterOption from "../../CounterOption";
import styled from "styled-components";
import {
    blackLightPaperOptions,
    colorisOptions,
    edgeTypeOptions,
    engravingOptions, posterHolderSizeOptions,
    railOptions, screenOrientationOptions
} from "./optionsData";
import {getLabel} from "../../../../Utils/labels";
import CheckboxOption from "../../CheckboxOption";

const LargeRowsContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const GridContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  
  > div {
    flex: 1 0 45%  
  }
`

const RegularConfiguration = ({ configuration = {}, setConfiguration }) => {
    const handleChange = (property, value) => {
        setConfiguration({...configuration, [property]: value});
    }

    return (
        <>
            <LargeRowsContainer>
                <CounterOptionsGrid
                    title={getLabel('poster-holder-size')}
                    imageSrc={"/static/img/lbi-media/led/taille-porte-affiches.svg"}
                    selectableOptions={posterHolderSizeOptions}
                    selectedOptions={configuration["poster-holder-size"] ?? []}
                    setSelectedOptions={(options) => handleChange("poster-holder-size", options)}
                />
                <CheckboxOptionsGroup
                    title={getLabel('screen-orientation')}
                    imageSrc={"/static/img/lbi-media/led/orientation.svg"}
                    required={true}
                    selectableOptionsGroup={screenOrientationOptions}
                    selectedOptions={configuration["screen-orientation"] ?? []}
                    setSelectedOptions={(options) => handleChange("screen-orientation", options)}
                />
                <CheckboxOptionsGroup
                    title={getLabel('edge-types')}
                    imageSrc={"/static/img/lbi-media/led/type-bords.svg"}
                    required={true}
                    selectableOptionsGroup={edgeTypeOptions}
                    selectedOptions={configuration["edge-types"] ?? []}
                    setSelectedOptions={(options) => handleChange("edge-types", options)}
                />
                <CheckboxOptionsGroup
                    title={getLabel('coloris')}
                    imageSrc={"/static/img/lbi-media/led/coloris.svg"}
                    required={true}
                    selectableOptionsGroup={colorisOptions}
                    selectedOptions={configuration["coloris"] ?? []}
                    setSelectedOptions={(options) => handleChange("coloris", options)}
                />
                <CounterOptionsGrid
                    title={getLabel('blacklight-paper')}
                    imageSrc={"/static/img/lbi-media/led/papier-backlight.svg"}
                    selectableOptions={blackLightPaperOptions}
                    selectedOptions={configuration["blacklight-paper"] ?? []}
                    setSelectedOptions={(options) => handleChange("blacklight-paper", options)}
                />
                <CounterOptionsGrid
                    title={getLabel('rail')}
                    imageSrc={"/static/img/lbi-media/led/rail.svg"}
                    selectableOptions={railOptions}
                    selectedOptions={configuration["rail"] ?? []}
                    setSelectedOptions={(options) => handleChange("rail", options)}
                />
                <CheckboxOptionsGroup
                    title={getLabel('engraving')}
                    imageSrc={"/static/img/lbi-media/led/gravure.svg"}
                    selectableOptionsGroup={engravingOptions}
                    selectedOptions={configuration['engraving'] ?? [] }
                    setSelectedOptions={(options) => handleChange("engraving", options)}
                />
            </LargeRowsContainer>
            <GridContainer>
                <CounterOption
                    title={getLabel('white-power-supply-cover')}
                    imageSrc={"/static/img/lbi-media/led/cache-alimentation.svg"}
                    price={20}
                    counter={configuration["white-power-supply-cover"]}
                    setCounter={(counter) => handleChange("white-power-supply-cover", counter)}
                />
                <CounterOption
                    title={getLabel('12v-power-supply')}
                    imageSrc={"/static/img/lbi-media/led/alimentation.svg"}
                    price={55}
                    counter={configuration["12v-power-supply"]}
                    setCounter={(counter) => handleChange("12v-power-supply", counter)}
                />
                <CounterOption
                    title={getLabel('power-cable')}
                    imageSrc={"/static/img/lbi-media/led/cable-alimentation.svg"}
                    price={20}
                    counter={configuration["power-cable"]}
                    setCounter={(counter) => handleChange("power-cable", counter)}
                />
                <CounterOption
                    title={getLabel('number-of-columns')}
                    imageSrc={"/static/img/lbi-media/led/colonnes.svg"}
                    counter={configuration["number-of-columns"]}
                    setCounter={(counter) => handleChange("number-of-columns", counter)}
                />
                <CounterOption
                    title={getLabel('cable-mounting-kit')}
                    imageSrc={"/static/img/lbi-media/led/fixation.svg"}
                    price={38}
                    counter={configuration["cable-mounting-kit"]}
                    setCounter={(counter) => handleChange("cable-mounting-kit", counter)}
                />
                <CounterOption
                    title={getLabel('inter-rail-connection')}
                    imageSrc={"/static/img/lbi-media/led/inter-rail.svg"}
                    price={10}
                    counter={configuration["inter-rail-connection"]}
                    setCounter={(counter) => handleChange("inter-rail-connection", counter)}
                />
                <CheckboxOption
                    title={"Préparation de commande & Livraison sur site"}
                    imageSrc={"/static/img/lbi-media/led/livraison-rapide.svg"}
                    isSelected={configuration["order-preparation-and-delivery"]}
                    setSelected={(value) => handleChange("order-preparation-and-delivery", value ? 1 : 0)}
                    price={50}
                />
            </GridContainer>
        </>
    )
}

const Crystal = RegularConfiguration;
const Sublime = RegularConfiguration;

export { Crystal, Sublime };
