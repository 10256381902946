import styled from 'styled-components';
import RefNatAboutCurrentPrestation from "./RefNatAboutCurrentPrestation";

const Container = styled.div`
    padding:  0 0 1em;
`;

const Bold = styled.p`
    text-align: center;
    font-weight: bold;
    font-size: 0.8em;
`;

const Text = styled.p`
    text-align: center;
    font-size: 0.8em;
`;


const RefNatInfo = () => {
    return (
        <>
            <RefNatAboutCurrentPrestation />
            <Container>
                <Bold>Nous travaillons un seul secteur géographique par univers sémantique.</Bold>
                <br/>
                <Text>Pour travailler deux secteurs géographiques différents, il faut opter pour deux univers
                    sémantiques.
                </Text>
                <Bold>Univers supplémentaire : 50 € HT / mois par univers pour l'offre adhésion - 70 € HT / mois par univers
                    pour l'offre conquête.
                </Bold>
                <br/>
            </Container>
        </>
    )
}

export default RefNatInfo;
