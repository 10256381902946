import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDataState } from '../Store/selectors';
import Labeled from './Labeled';
import Toggle from './Toggle';
import { beautifulGetLabel } from '../Utils/labels';
import {  getInDataState, setInDataState } from '../Utils/storeHelpers';

/**
 * Properties received by the ToggleLabeled component
 *
 * @typedef {Object} ToggleLabeledProps
 * @property {bool} [default]
 * @property {string} [pathInDataState]
 * @property {bool} [label]
 * @property {bool} [inverted]
 * @property {bool} [forceFit]
 */

/**
 * @param {ToggleLabeledProps} props
 */
function ToggleLabeled(props) {
    // Use of redux selector
    const dataState = useSelector(selectDataState);

     // Use of hooks
     const [isChecked, setIsChecked] = useState(null);

    useEffect(() => {
        if (null === isChecked) {
            props.default && undefined === getInDataState(props.pathInDataState) && setInDataState(props.pathInDataState, true);
        }
    }, [isChecked, props])

    // useEffect whenever dataState changes
    useEffect(() => {
        let check = getInDataState(props.pathInDataState);
        check = undefined === check && props.default ? true : check;
        setIsChecked(check);
    }, [props, dataState])

    return (
        null !== isChecked && (
            <Labeled
                isToggle
                { ...props }
                label={beautifulGetLabel(props.label)}
                inverted={props.inverted}
                fitContent={props.inverted || props.forceFit}
            >
                <Toggle
                    {...props}
                    value={isChecked}
                />
            </Labeled>
        )
    );
}

export default ToggleLabeled;
