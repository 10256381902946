import InputTextContainer from './InputTextContainer'
import InputNumberContainer from './InputNumberContainer'
import SelectContainer from './SelectContainer'
import Select from './JustSelectContainer'
import GroupCheck from '../Components/GroupCheck'
import CheckableGroup from '../Components/CheckableGroup'
import ConditionnalComponentContainer from './ConditionnalComponentContainer'
import DatePicker from '../Components/DatePicker'
import ToggleLabeled from '../Components/ToggleLabeled'
import Combo from '../Components/Combo'
import Memory from '../Components/Memory'
import BiChoice from '../Components/BiChoice'
import CounterContainer from './CounterContainer'
import CounterLabeledContainer from './CounterLabeledContainer'
import Abonnement from '../Components/Abonnement'
import AbonnementSimple from '../Components/AbonnementSimple'
import AbonnementComplex from '../Components/AbonnementComplex'
import AbonnementIkaLePack from '../Components/AbonnementIkaLePack'
import Prelevement from '../Components/Prelevement'
import Summary from '../Components/Summary/Summary'
import Addable from './AddableContainer'
import ReseauxSociaux from '../Components/ReseauxSociaux'
import Menu from './MenuContainer'
import MenuSimple from './MenuContainerSimple'
import DomainNamesContainer from './DomainNamesContainer'
import AgenceSupContainer from './AgenceSupContainer'
import DomaineGsuiteContainer from './DomaineGsuiteContainer'
import DomaineExchangeContainer from './DomaineExchangeContainer'
import MenuElement from '../Components/MenuElement'
import Html from '../Components/Html'
import Illustration from '../Components/Illustration'
import TextAreaContainer from './TextAreaContainer'
import SubSectionTitle from '../Components/SubSectionTitle'
import Color from './ColorContainer'
import OptionSelector from '../Components/OptionSelector'
import LineAddable from '../Components/LineAddable'
import PricePop from '../Components/PricePop'
import Adwords from './AdwordsContainer'
import Blank from '../Components/Blank'
import LabelAdd from '../Components/LabelAdd'
import UniversSemantique from './UniversSemantique'
import PriceLine from '../Components/PriceLine'
import Styler from '../Components/Styler'
import RadioBand from '../Components/RadioBand'
import Livraison from '../Components/Livraison'
import Picto from './Picto'
import PictoSimple from './PictoSimple'
import BlocRedac from './BlocRedac'
import TextExpl from './TextExpl'
import TextExplSimple from './TextExplSimple'
import PriceContainer from './PriceContainer'
import RequiredFields from '../Components/RequiredFields'
import RefNatInfo from '../Components/Referencement/RefNatInfo'
import RefNatNdd from '../Components/Referencement/RefNatNdd'
import Notes from '../Components/Notes'
import HebergementMaintenance from '../Components/HebergementMaintenance'
import SliderCube from '../Components/SliderCube'
import SimpleCheckBox from '../Components/SimpleCheckBox'
import TraductionsInformation from '../Components/TraductionsInformation'
import RecupDonnees from '../Components/RecupDonnees'
import OptionHektor from '../Components/OptionHektor'
import OptionHektorAdd from '../Components/OptionHektorAdd'
import PasserelleImport from '../Components/PasserelleImport'
import HektorAccounts from '../Components/HektorAccounts'
import ConfigTitlesPages from '../Components/ConfigTitlesPages'
import ConfigTitles from '../Components/ConfigTitles'
import CroSelector from '../Components/CroSelector'
import OptionPrevisiteAdd from "../Components/OptionPrevisiteAdd";
import AbonnementPrevisite from "../Components/AbonnementPrevisite";

import RecupData from "../Components/RecupData";

import {
  LePackOptionsAdd,
  LePackOptions,
  LePackSubscription,
  LePackOptionGroup,
  LePackRefNatInfo,
  LePackSalesOutletSubscription,
  LePackRecap,
} from '../Components/LePack';

import SalesOutlet from "./SalesOutlet";
import RefNatAutorisation from "../Components/Referencement/RefNatAutorisation";
import AkademieOptions from "../Components/Akademie/AkademieOptions";
import AdvancedOption from "../Components/Option/AdvancedOption";
import MinorOption from "../Components/Option/MinorOption";
import CheckableOption from "../Components/Option/CheckableOption";
import Options from "../Components/Options";
import PigimmoPostalCodes from "./PigimmoPostalCodes";
import CallToAction from "./CallToAction";
import PaymentAndWarranty from "../Components/LbiMedia/PaymentAndWarranty";
import CheckableOptionLbiMedia from "../Components/LbiMedia/CheckableOptionLbiMedia";
import ScreenOrientations from "../Components/LbiMedia/ScreenOrientations";
import ScreenCountOptions from "../Components/LbiMedia/ScreenCountOptions";
import ScreenChoices from "../Components/LbiMedia/Screen/ScreenChoices";
import ScreenConfiguration from "../Components/LbiMedia/Screen/ScreenConfiguration";
import ScreenConfigurations from "../Components/LbiMedia/Screen/ScreenConfigurations";
import LedChoices from "../Components/LbiMedia/Led/LedChoices";

import CustomersInformations from "../Components/LbiMedia/CustomersInformations";
import ProductInformation from "../Components/LbiMedia/Led/ProductInformation";
import LedConfigurations from "../Components/LbiMedia/Led/LedConfigurations";
import ImmosignPromoTag from "../Components/ImmosignPromoTag";
import OskarRecap from "../Components/OskarRecap";
import CheckableTypeOptions from "../Components/Option/CheckableTypeOptions";
import SmartPix from "../Components/SmartPix";
import WithCounterChoiceOption from "../Components/Option/WithCounterChoiceOption";

const Widgets = {
  RadioBand,
  InputTextContainer,
  InputNumberContainer,
  SelectContainer,
  Select,
  Prelevement,
  ConditionnalComponentContainer,
  DatePicker,
  ToggleLabeled,
  Combo,
  Memory,
  BiChoice,
  CounterContainer,
  CounterLabeledContainer,
  Abonnement,
  AbonnementSimple,
  AbonnementComplex,
  AbonnementIkaLePack,
  Summary,
  Addable,
  ReseauxSociaux,
  Menu,
  MenuSimple,
  MenuElement,
  Html,
  Illustration,
  TextAreaContainer,
  GroupCheck,
  CheckableGroup,
  SubSectionTitle,
  Color,
  OptionSelector,
  DomainNamesContainer,
  DomaineGsuiteContainer,
  DomaineExchangeContainer,
  AgenceSupContainer,
  LineAddable,
  PricePop,
  Adwords,
  Blank,
  LabelAdd,
  UniversSemantique,
  PriceLine,
  Styler,
  Livraison,
  Picto,
  TextExpl,
  BlocRedac,
  PictoSimple,
  TextExplSimple,
  PriceContainer,
  RequiredFields,
  RefNatAutorisation,
  RefNatInfo,
  RefNatNdd,
  Notes,
  HebergementMaintenance,
  SliderCube,
  SimpleCheckBox,
  PasserelleImport,
  TraductionsInformation,
  RecupDonnees,
  RecupData,
  OptionHektor,
  OptionHektorAdd,
  HektorAccounts,
  ConfigTitlesPages,
  ConfigTitles,
  CroSelector,

  OptionPrevisiteAdd,
  AbonnementPrevisite,

  Options,

  LePackOptionsAdd,
  LePackOptions,
  LePackSubscription,
  LePackOptionGroup,
  LePackRefNatInfo,
  LePackSalesOutletSubscription,
  LePackRecap,
  SalesOutlet,
  PigimmoPostalCodes,

  GeneralCheckableCounterOption: CheckableTypeOptions,
  SmartPix,
  AkademieOptions,
  CounterChoiceOption: WithCounterChoiceOption,

  AdvancedOption,
  CheckableOption,
  MinorOption,
  ImmosignPromoTag,

  CallToAction,

  ScreenChoices,
  ScreenConfiguration,
  ScreenConfigurations,
  PaymentAndWarranty,
  CheckableOptionLbiMedia,
  ScreenOrientation,
  ScreenOrientations,
  ScreenCountOptions,
  LedChoices,
  CustomersInformations,
  ProductInformation,
  LedConfigurations,
  OskarRecap
};

export default Widgets;
