import {useSelector} from "react-redux";
import {get} from "../../../Utils/object";
import styled from "styled-components";
import {setInDataState} from "../../../Utils/storeHelpers";
import {Crystal, Sublime} from "./RegularConfiguration/RegularConfiguration";
import ViceVersa from "./ViceVersaConfiguration/ViceVersaConfiguration";
import {produce} from "immer";
import {getLabel} from "../../../Utils/labels";
import {useEffect} from "react";
import {addMandatory} from "../../../Utils/mandatory/addMandatory";

const LED_CONFIGURATION_PATH = 'offre-lbi-media.led.configurations';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    margin: 30px 30px;
`;

const ConfigurationContainer = styled.div`
    border: 2px solid #E1E1E1;
    border-radius: 15px;
    padding: 15px;
    margin: 20px 0 40px 0;
    box-shadow: rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
`

const Title = styled.div`
    font-size: 18px;
    font-weight: bold;
`;

const components = {
    'crystal': Crystal,
    'sublime': Sublime,
    'vice-versa': ViceVersa
}

const LedConfiguration = ({ type, setConfiguration, configuration }) => {
    const Component = components[type];

    return (
        <Component setConfiguration={setConfiguration} configuration={configuration} />
    );
}

/**
 *
 * @param {Object} options
 * @return {boolean}
 */
const hasSelectedAtLeastOneOption = (options) => {
    if (Object.entries(options).length > 0) {
        const values = Object.values(options);
        for (const value of values) {
            if (value.length > 0) {
                return true;
            }
        }

        return false;
    }

    return false;
}

const LedConfigurations = () => {
    const counts = useSelector(({ data }) => get(data, 'offre-lbi-media.led.count', 0));
    const configurations = useSelector(({ data }) => get(data, LED_CONFIGURATION_PATH, {}));

    const handleConfigurationChange = (ledType, value) => {
        setInDataState(`${LED_CONFIGURATION_PATH}`, produce(
            configurations,
            draftState => {
                draftState[ledType] = value;
            }
        ));
    };

    useEffect(() => {
        addMandatory((data) => {
           const configurations = get(data, `${LED_CONFIGURATION_PATH}`, {});

           return Object.entries(configurations).length > 0;
        })

        addMandatory((data) => {
            const crystal = get(data, `${LED_CONFIGURATION_PATH}.crystal`, null);

            if (crystal) {
                return hasSelectedAtLeastOneOption(
                    get(crystal, 'coloris', {})
                ) && hasSelectedAtLeastOneOption(
                    get(crystal, 'edge-types', {})
                ) && hasSelectedAtLeastOneOption(
                    get(crystal, 'screen-orientation', {})
                );
            }

            return true;
        })

        addMandatory((data) => {
            const sublime = get(data, `${LED_CONFIGURATION_PATH}.sublime`, null);

            if (sublime) {
                return hasSelectedAtLeastOneOption(
                    get(sublime, 'coloris', {})
                ) && hasSelectedAtLeastOneOption(
                    get(sublime, 'edge-types', {})
                ) && hasSelectedAtLeastOneOption(
                    get(sublime, 'screen-orientation', {})
                );
            }

            return true;
        })
    }, []);

    return (
        <Container>
            {
                Object.entries(configurations)
                    .map(([ledType, configuration]) => (
                        <div>
                            <Title>{counts[ledType]} {getLabel(ledType)}</Title>
                            <ConfigurationContainer>
                                <LedConfiguration
                                    type={ledType}
                                    setConfiguration={(value) => handleConfigurationChange(ledType, value)}
                                    configuration={configuration ?? {}}
                                />
                            </ConfigurationContainer>
                        </div>
                    )
                )
            }
        </Container>
    )
}

export default LedConfigurations;
