import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { borderGrey, grey, lightGrey } from '../Utils/styleConstants';
import { deleteInDataState, getInDataState, setInDataState } from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    ${props => props.padding && `padding: ${props.padding}`};
`;

const Title = styled.p`
    text-transform: uppercase;
    font-weight: bold;
    padding: 0 0 10px;
    font-size: 0.8em;
`;

const Area = styled.textarea`
    padding: 20px;
    color: ${grey};
    font-size: 0.9em;
    font-style: italic;
    font-family: Roboto, sans-serif;
    border: 1px solid ${borderGrey};
    border-radius: 4px;
    background-color: ${lightGrey};
    resize: none;

    ::placeholder {
        color: ${borderGrey};
    }
`;

const Notes = props => {
    // Use of hooks
    const [notes, setNotes] = useState(null);

    // useEffect when component is mounting
    useEffect(() => {
        null === notes && setNotes(getInDataState(props.pathInDataState) ?? '');
    }, [notes, props.pathInDataState])

    // Callback used when user change the textarea
    const handleChange = useCallback((value) => {
        setNotes(value);

        '' !== value ?
            setInDataState(props.pathInDataState, value)
            : deleteInDataState(props.pathInDataState);
    }, [props.pathInDataState])

    return (
        null !== notes && (
            <Container padding={props.padding}>
                {
                    !props.noLabel && (
                        <Title>Notes</Title>
                    )
                }
                <Area
                    rows={5}
                    onChange={(event) => handleChange(event.target.value)}
                    value={notes}
                    placeholder='Ajouter une note ...'
                />
            </Container>
        )
    );
};

export default Notes;
