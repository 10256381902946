import { connect } from 'react-redux'
import Menu from '../Components/Menu'
import { mapStateToPropsAddable } from '../Utils/mapToProps'

const mapStateToProps = (state, ownProps) => ({
    ...mapStateToPropsAddable(state, ownProps),
    gridElements: ['titre', 'dest', 'note', 'sousmenu', 'typesousmenu'],
    spanElements: ['sousmenuel'],
    refs: id => ({
        titre: {
            type: 'InputTextContainer',
            name: `${id}/titre`,
            label: 'title'
        },
        dest: {
            type: 'SelectContainer',
            name: `${id}/dest`,
            label: 'dest',
            options: ['menu-home', 'menu-redac', 'menu-form', 'menu-listing']
        },
        note: {
            type: 'InputTextContainer',
            name: `${id}/note`,
            label: 'note'
        }
    })
})

export default connect(
    mapStateToProps
)(Menu)
