import React from 'react';
import styled from 'styled-components';
import Builder from '../../Utils/builder';
import { defaultFooterPadding } from '../../Utils/styleConstants';

const Footer = styled.div`
    padding: ${props => props.padding ?? defaultFooterPadding};
`;

const GroupFooter = props => {
    return (
        props.fields && (
            <Footer padding={props.padding}>
                {Builder.build(props.groupId, props.fields)}
            </Footer>
        )
    );
};

export default GroupFooter;
