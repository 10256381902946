import React from 'react'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { green, grey, borderGrey } from '../Utils/styleConstants'

const Container = styled.div`
    background: ${transparentize(0.8, green)};
    display: flex;
    justify-content: space-between;
    color: ${grey};
    border: 1px solid ${borderGrey};
    border-left: 0;
    border-right: 0;
    padding: 1.05em;
    margin: 1em 0;
`

const Title = styled.div`
  font-size: 1em;
  font-weight: bold;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 50px;
    height: 2px;
    background: ${green};
    position: absolute;
    bottom: -1.05em;
  }
`

class SubSectionTitle extends React.Component {
    render () {
        return (
            <Container>
                <Title>{this.props.title}</Title>
            </Container>
        )
    }
}

export default SubSectionTitle
