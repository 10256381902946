import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PriceUpdater from './PriceUpdater';
import { green } from '../../Utils/styleConstants';
import { beautifulGetLabel } from '../../Utils/labels';
import { getInDataState, getInTarifsState } from '../../Utils/storeHelpers';

const Container = styled.div`
    background: ${green};
    padding: 1em 2em;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.div`
    font-size: 1.5em;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
`;

const Parent = props => {
    // Use of hooks
    const [tarifs, setTarifs] = useState(null);
    const [indexFinalPrices, setIndexFinalPrices] = useState(undefined);

    // Use of redux
    const tarifsName = getInDataState(props.pathToTarifsName);

    // Defined the tarifs to used for the PriceUpdater
    useEffect(() => {
        null === tarifs && tarifsName &&
            setTarifs(getInTarifsState(tarifsName));
    }, [tarifs, tarifsName])

    // Now that we know which tarifs will be used,
    // We need to specify the finalPrice to display
    useEffect(() => {
        if (null !== tarifs && undefined === indexFinalPrices && tarifs.hasOwnProperty('finalPrices')) {
            // We specify it by giving its index
            tarifs.finalPrices.forEach((item, key) => {
                // The item index which has displayWhenParent equals to props.id (parentId)
                item.displayWhenParent === props.id &&
                    setIndexFinalPrices(key);
            });
        }
    })

    return (
        <Container>
            <Title>{beautifulGetLabel(props.label)}</Title>
            {
                tarifs && (
                    <PriceUpdater
                        parent
                        tarifs={tarifs}
                        index={indexFinalPrices}
                    />
                )
            }
        </Container>
    );
};

export default Parent;
