import styled from "styled-components";
import ProductInformation from "./Led/ProductInformation";
import CheckboxGroup from "../Checkbox/CheckboxGroup";
import CheckmarkContainer from "../Icons/CheckmarkContainer";
import Checkbox from "../Checkbox/Checkbox";
import {SimpleCheckbox} from "../../Utils/styleConstants";
import Price from "./Price";

const CheckableContainer = styled(SimpleCheckbox)`
    display: flex;
    max-height: 300px;
`;

const InformationContainer = styled.div `
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 10%;
`;

const SubTitle = styled.div`
    display: flex;
    flex: 0 0 15%;
    align-items: center;
    border-left: 1px solid #E1E1E1;
`;

const SubTitleText = styled.div`
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
`;

const GroupContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;  
`

const GroupBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #E1E1E1;
    border-top: 1px solid #E1E1E1;
    flex: 1 0 33.3333%;
    padding: 10px;
    gap: 15px;
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
`

const PriceContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`

const Label = styled.label`
    padding: 15px 0;
`

const Required = styled.span`
    color: red;
`

const CheckboxOptionsGroup = ({
    title,
    imageSrc,
    required,
    selectableOptionsGroup = [],
    selectedOptions = {},
    setSelectedOptions
}) => {
    const isOptionChecked =
        Object.entries(selectedOptions).some(([key, group]) => group && group.length > 0)
    ;

    const handleSelected = (groupValue, options) => {
        if (!setSelectedOptions) {
            return;
        }

        setSelectedOptions({ ...selectedOptions, [groupValue]: options })
    }

    return (
        <CheckableContainer checked={isOptionChecked}>
            <InformationContainer>
                <ProductInformation imageSrc={imageSrc}/>
            </InformationContainer>
            <SubTitle>
                <SubTitleText>
                    <p>
                        {title}
                        {required && <Required> *</Required> }
                    </p>
                </SubTitleText>
            </SubTitle>
            {selectableOptionsGroup.map((group, i) =>
                <GroupBox key={i}>
                    { group.title && <Label>{group.title}</Label> }
                    <CheckboxGroup value={selectedOptions[group.value] ?? []} onValueChange={(options) => handleSelected(group.value, options)} GroupContainer={GroupContainer} >
                        {group.options.map((option, j) =>
                            <Box key={option.value}>
                                <label>{option.title}</label>
                                <Checkbox key={i * j} value={option.value} />
                            </Box>
                        )}
                    </CheckboxGroup>
                    <PriceContainer>
                        <Price value={group.price} />
                    </PriceContainer>
                </GroupBox>
            )}
            <CheckmarkContainer checked={isOptionChecked} notBottomCheckmark={true}/>
        </CheckableContainer>
    )
}

export default CheckboxOptionsGroup;
