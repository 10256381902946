import {RadioGroupContext} from "../RadioGroupContext";
import {useContext} from "react";

const useRadio = ({ value }) => {
    const context = useContext(RadioGroupContext);

    const isInContext = !!context;

    if (!isInContext) {
        console.warn('Radio is not in a RadioGroup. This is not allowed. Please use the RadioGroup component to wrap the Radio components.')
    }

    const isSelected = context?.value && context?.value === value;

    const handleChange = () => {
        if (!isSelected) {
            context.onChange(value);
        }
    }

    return { isSelected, handleChange };
}

export default useRadio;
