import React, { useCallback } from 'react';
import styled from 'styled-components';
import { borderGrey, green, grey } from '../Utils/styleConstants';
import {getImage} from "../Utils/jsonInterpreter";

const InnerLink = styled.p`
    z-index: 1;
    display: flex;
    align-items: center;
    align-self: center;
    padding: 10px 30px;
    font-size: 0.8em;
    color: ${grey};
    text-transform: uppercase;
    font-weight: bold;
    border: 1px solid ${borderGrey};
    border-radius: 20px;
    transition: all 0.5s ease-out;
    cursor: pointer;
    user-select: none;
    background-color: white;

    &:hover {
        background-color: ${green};
    }

    &::before {
        content: '';
        background: url(${getImage('/static/img/eye.svg')}) no-repeat center center;
        background-size: 20px;
        width: 20px;
        height: 20px;
        margin: 0 10px 0 0;
    }
`;

const LightboxButton = props => {
    // Callback when user click on button
    const triggerCallback = useCallback((event) => {
        // Prevent trigger parent onCLick
        event.stopPropagation();

        // Call parent component callback
        props.callbackOnClick();
    }, [props])

    return (
        <InnerLink onClick={(event) => triggerCallback(event)}>
            {props.text ?? 'agrandir'}
        </InnerLink>
    );
};

export default LightboxButton;
