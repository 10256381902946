import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
    text-align: center;
    font-style: italic;
    font-size: 0.9em;
    color: #9d9d9d;
`;

const Star = styled.span`
    color: red
`;

const RequiredFields = () => <Text><Star>*</Star> Champs obligatoires à remplir</Text>;

export default RequiredFields;
