import React from 'react';
import styled from 'styled-components';
import Builder from '../../Utils/builder';
import { beautifulGetLabel } from '../../Utils/labels';
import { borderGrey, green, grey } from '../../Utils/styleConstants';

const Head = styled.section`
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid ${borderGrey};
    padding: 0 30px;
    height: 62px;
`;

const Title = styled.div`
    font-size: 20px;
    font-weight: bold;
    position: relative;
    color: ${grey};

    &::before {
        content: '';
        display: block;
        width: 50px;
        height: 1px;
        background: ${green};
        position: absolute;
        bottom: -18px;
    }
`;

const RightBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.8em;

    & > * {
        border-left: 1px solid ${borderGrey};
        padding-left: 1em;
    }

    & *:first-child {
        border-left: 0;
    }
`;

const GroupHeader = props => {
    return (
        <Head>
            <Title>
                {beautifulGetLabel(props.groupId)}
            </Title>
            {
                props.fields && (
                    <RightBox>
                        {Builder.build(props.groupId, props.fields)}
                    </RightBox>
                )
            }
        </Head>
    );
};

export default GroupHeader;
