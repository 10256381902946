import React from 'react';
import styled from 'styled-components';
import Builder from '../../Utils/builder';
import { defaultBodyPadding } from '../../Utils/styleConstants';

const Body = styled.div`
    display: grid;
    grid-template-columns: ${props => props.columns ? `repeat(${props.columns}, 1fr);` : '1fr'};
    padding: ${props => props.padding ?? defaultBodyPadding};
`;

const GroupBody = props => {
    return (
        props.fields && (
            <Body
                columns={props.columns}
                padding={props.padding}
                groupRef={props.groupRef}
            >
                {Builder.build(props.groupId, props.fields, props.groupRef, props.groupFields)}
            </Body>
        )
    );
};

export default GroupBody;
