import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDataState } from '../Store/selectors';
import styled from 'styled-components';
import Checkmark from './Icons/Checkmark';
import { green, grey } from '../Utils/styleConstants';
import Moment from 'react-moment';
import moment from 'moment';
import { getInDataState } from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const CheckBox = styled.div`
    border: 4px solid ${green};
    border-radius: 50%;
    width: 250px;
    height: 250px;
    padding: 60px;
`;

const Title = styled.div`
    font-size: 2.5em;
    font-weight: bold;
    text-align: center;
    color: ${grey};
    padding: 1em 0;
`;

const Pg = styled.p`
    text-align: center;
    color: ${grey};
    padding: 0 0 1.5em;
`;

const Stars = styled.p`
    text-align: center;
    color: ${grey};
    font-size: 0.9em;
    font-style: italic;
`;

const Livraison = ({ pathInDataState, product }) => {
    // Use of hooks
    const [livraison, setLivraison] = useState(null);
    const [nbWeek, setNbWeek] = useState(null);

    // Use of redux
    const dataState = useSelector(selectDataState);

    // useEffect when component is mounting
    useEffect(() => {
        const date = getInDataState(pathInDataState) ?? Date();
        const weeks = moment(date).diff(moment(), 'weeks')

        setLivraison(date);
        setNbWeek(0 === weeks ? 1 : weeks);
    }, [pathInDataState, livraison, nbWeek, dataState]);

    return (
        null !== livraison && null !== nbWeek && (
            <Container>
                <CheckBox>
                    <Checkmark color={green} />
                </CheckBox>
                <Title>Livraison prévue le <Moment locale='fr' format='dddd D MMMM YYYY' element='span'>{livraison}</Moment> *</Title>
                {
                    product === 'site' &&
                        <>
                            <Pg>Afin de respecter ce délai, l'agence s'engage à fournir les éléments nécessaire à la réalisation du projet :<br/>photos, logos sous format vectoriel, noms de domaines, données.</Pg>
                            <Pg>Les délais de livraison sont susceptibles d'être revus à la hausse suivant le temps nécessaire à l'obtention des éléments et des validations graphiques de la part du client.</Pg>
                            <Pg>Le client dégage La Boite Immo de toutes responsabilités liées notamment à la loi sur les droits d'auteurs et sur les éléments qu'ils nous seront fournis (photos, vidéos, contenu rédactionnel).</Pg>
                            <Stars>
                                <span>* Délai de minimum {nbWeek} {nbWeek > 1 ? 'semaines ouvrées' : 'semaine ouvrée'}, sous réserve de validation du dossier complet par le service administratif de La Boite Immo.</span>
                                <span>
                                * La date de livraison est une donnée indicative se basant sur un délai de validation des maquettes graphiques de votre part
                                de 5 jours ouvrés à réceptionde la première proposition de maquette. A compter de la validation des maquettes graphiques;
                                le délai de livraison moyen est de 3 semaines.
                            </span>
                            </Stars>
                        </>
                }
                {
                    product === 'hektor' &&
                        <>
                            <Pg>Les délais de livraison sont susceptibles d’être allongés suivant le temps d'obtention des éléments nécessaires à la réalisation du projet : logo, données.</Pg>
                            <Stars>
                                <span>* Délai de minimum {nbWeek} {nbWeek > 1 ? 'semaines ouvrées' : 'semaine ouvrée'}, sous réserve de validation du dossier complet par le service administratif de La Boite Immo.</span>
                            </Stars>
                        </>
                }
            </Container>
        )
    );
};

export default Livraison;
