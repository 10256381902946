import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import {FakeInputPrice} from '../../Utils/styleConstants';
import {getInDataState, setInDataState} from '../../Utils/storeHelpers';
import {isValidInputRecap, TVA_DEFAULT} from "../../Utils/price";

const InputContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
`;

const TvaUpdater = props => {
    const WAIT_INTERVAL = 2000;

    // Use of hook
    const [tva, setTva] = useState(null);
    const [timer, setTimer] = useState(undefined);

    // useEffect when component is mounting
    useEffect(() => {
        if (null === tva) {
            let tvaValue = getInDataState('tva');

            if (undefined === tvaValue) {
                tvaValue = TVA_DEFAULT;
                setInDataState('tva', tvaValue);
            }

            setTva(tvaValue);
        }

    }, [tva]);

    // Callback used when user change price
    const handleChangeTva = useCallback((newTva) => {
        clearTimeout(timer);

        // Float price
        const tvaValue = '' === newTva ? 0 : newTva;

        // Change hook value
        setTva(tvaValue);

        setTimer(setTimeout(() => setInDataState('tva', tvaValue), WAIT_INTERVAL));

    }, [timer]);

    return (
        null !== tva && (
            <InputContainer>
                <FakeInputPrice
                    type='text'
                    value={tva}
                    width={undefined === tva || 0 === tva.toString().length ? '1ch' : `${tva.toString().length}ch`}
                    cursor='pointer'
                    onChange={(event) => isValidInputRecap(event.target.value) && handleChangeTva(event.target.value)}
                />
            </InputContainer>
        )
    );
};

export default TvaUpdater;
