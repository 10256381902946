import React from 'react';
import styled from 'styled-components';
import { getLabel } from '../Utils/labels';
import { getInTarifsState } from '../Utils/storeHelpers';

const Container = styled.div`
    position: absolute;
    top: ${props => props.top};
    right: ${props => props.right};
    display: flex;
    transform: rotate(5deg);
`;

const SpecialOfferStructure = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 110px;
    background-color: #343434;
`;

const Offer = styled.p`
    font-size: 23px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: white;
    font-weight: bolder;
`;

const Special = styled.p`
    font-size: 15px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: white;
    font-weight: bolder;
`;

const OfferName = styled.p`
    font-size: 15px;
    letter-spacing: 1.5px;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.color ?? 'white'};
    font-weight: 500;
    padding: 5px 0 0;
`;

const PromotionStructure = styled.div`
    width: 170px;
    text-align: center;
    background-color: ${props => props.color ?? '#343434'};
    padding: 10px;
`;

const Percent = styled.p`
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 50px;
`;

const Value = styled.span`
    font-size: 60px;
    color: white;
    font-weight: bolder;
    text-shadow: 1px 3px 5px #343434;
`;

const Symbol = styled.span`
    font-size: 60px;
    color: #343434;
    font-weight: bolder;
    text-shadow: 0 0 2px #343434;
`;

const During = styled.p`
    font-size: 15px;
    color: #343434;
    text-transform: uppercase;
    padding: 5px 0 0;
`;

const SixFirstMonths = styled.p`
    font-size: 15px;
    color: white;
    font-weight: 900;
    text-transform: uppercase;
    text-shadow: 1px 3px 5px #343434;
`;

const PromoTag = props => {
    // Use of redux
    const tarifs = getInTarifsState(props.tarifsName);
    const promotion = tarifs.promotions.find(promotion => promotion.id === props.promotionId);

    return (
        tarifs && (
            <Container
                top={props.top}
                right={props.right}
            >
                <SpecialOfferStructure>
                    <Offer>offre</Offer>
                    <Special>spéciale</Special>
                    <OfferName color={props.color}>{props.offerName}</OfferName>
                </SpecialOfferStructure>
                <PromotionStructure color={props.color}>
                    {
                        promotion && (
                            <Percent>
                                <Value>{`-${promotion.percent}`}</Value>
                                <Symbol>%</Symbol>
                            </Percent>
                        )
                    }
                    <During>pendant vos</During>
                    <SixFirstMonths>
                        {
                            getLabel(tarifs.periodicities.find(
                                periodicity => periodicity.includes('Promo')
                            ))
                        }
                    </SixFirstMonths>
                </PromotionStructure>
            </Container>
        )
    );
};

export default PromoTag;
