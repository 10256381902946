import {SET_MANDATORY_PREDICATE} from "../../Store/actions";
import {dispatch} from "../../Store/store";

/**
 * Add a predicate that will be checked when we want to go to the next step.
 *
 * The data state is passed as argument of the fn predicate.
 *
 * @param {(Object) => boolean} fn
 * @param {string|null} message
 */
export function addMandatory(fn, message = null) {
    if (typeof fn !== 'function') {
        throw new Error('fn must be a function');
    }

    if (message && message === '') {
        throw new Error('message must be not empty when defined');
    }

    dispatch({ type: SET_MANDATORY_PREDICATE, payload: { predicate: { fn, message } } });
}
