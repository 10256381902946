import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import CheckElement from './CheckElement';
import HiddenCheckbox from './HiddenCheckbox'
import getLabel from '../Utils/labels';
import {
    deleteInDataState,
    getInDataState,
    multipleClearInDataState,
    setInDataState,
    multipleResetFinalPriceOfTarifs,
    multipleClearFinalPriceOfTarifs
} from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
`;

const Element = styled.div`
    padding: 10px;
    flex: 0 0;
    ${(props) =>
        props.grow ? `flex-grow: ${props.grow}` : `flex-basis: ${getSize(props)}`};
    ${(props) => (props.size === 'small' ? 'font-size: 0.7em' : '')};
`;

const getSize = ({ large, xlarge, size, forceSize }) => {
    if (forceSize) {
        return forceSize;
    }
    if (large) {
        return '50%';
    }
    if (xlarge) {
        return '100%';
    }
    switch (size) {
        case 'large':
            return '50%';
        case 'xlarge':
            return '100%';
        case 'small':
            return '12.5%';
        default:
            return '33.33%';
    }
};

const GroupCheck = props => {
    // Use of hooks
    const [checked, setChecked] = useState(null);

    // Callback to determine if the component is checked & logic if so
    const isComponentChecked = useCallback((value, checkedByDefault) => {
        const dataInState = getInDataState(props.pathInDataState);
        let isChecked = false;

        if (Array.isArray(dataInState)) {
            // Can be an array (ex: for 'produits')
            // So check if the array includes it
            isChecked = dataInState.includes(value);
        } else if (dataInState === value) {
            isChecked = true;
        } else if (checkedByDefault) {
            // Set the value of the option in data state as we click on it
            setInDataState(props.pathInDataState, value, props.isArrayInDataState);
            isChecked = true;
        }

        return isChecked;
    }, [props.pathInDataState, props.isArrayInDataState]);

    // useEffect when component is mounting
    useEffect(() => {
        if (!checked) {
            const tmpCheck = [];

            props.options.forEach(option => {
                const isChecked = isComponentChecked(option.value, option.checkedByDefault);

                tmpCheck.push(isChecked);
            });

            // This setup an array of boolean which determine if the options are checked or not (one by one)
            setChecked(tmpCheck);
        }
    }, [props.options, checked, isComponentChecked]);

    // Callback when user click on an option
    const handleCheckbox = useCallback((event, optionIndex, option) => {
        // Stop propagation
        event.stopPropagation();

        // Either set the option value in state or delete it
        !checked[optionIndex] ?
            setInDataState(props.pathInDataState, option.value, props.isArrayInDataState)
            : deleteInDataState(props.pathInDataState, option.value);

        // Delete in data state
        option.deleteFromPathInDataState && option.deleteFromPathInDataState.forEach(({index, value}) => {
            deleteInDataState(props.pathInDataState, value);
            checked[index] = false;
        });

        // Only check or not the clicked option (props.radio)
        // Either check the option or not by modifying the hook array of booleans (!props.radio)
        setChecked(
            checked.map((value, index) => 
                index === optionIndex ?
                    !value
                    : (props.radio ? false : value)
            )
        );

        // Clear some data (if clearOnChangeInDataState paths indicated in json file)
        multipleClearInDataState(props.clearOnChangeInDataState);

        // Reset to finalPrice for some tarifs (if resetFinalPrice tarifsName indicated in json file)
        multipleResetFinalPriceOfTarifs(props.resetFinalPrice);
        multipleResetFinalPriceOfTarifs(option.resetFinalPrice);

        // Clear a finalPrice
        multipleClearFinalPriceOfTarifs(option.clearFinalPrice);
    }, [props.radio, props.clearOnChangeInDataState, checked, props.pathInDataState, props.isArrayInDataState, props.resetFinalPrice])

    return (
        null !== checked && (
            <Container>
                {
                    props.options.map((option, index) => (
                        <Element
                            key={index}
                            forceSize={option.forceSize}
                            grow={option.grow}
                            {...props}
                        >
                            <CheckElement
                                masterId={props.masterId}
                                index={index}
                                isChecked={checked[index]}
                                sublabel={props.sublabel}
                                checkType={props.checkType}
                                label={getLabel(option)}
                                beautiful={props.beautiful}
                                {...option}
                            >
                                <HiddenCheckbox
                                    checked={checked[index]}
                                    onChange={(event) => handleCheckbox(event, index, option)}
                                />
                            </CheckElement>
                        </Element>
                    ))
                }
            </Container>
        )
    );
};

export default GroupCheck;
