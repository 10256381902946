import React from 'react';
import styled, { keyframes } from 'styled-components';
import { easeOutBack, green } from '../../Utils/styleConstants';

const FallDown = keyframes`
    from {
        transform: translateY(-50vh);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
`;

const BackWindow = styled.div`
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: rgb(0 0 0 / 80%);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0; left: 0;
`;

const Container = styled.div`
    padding: 30px;
    background: linear-gradient(white, white);
    background-size: contain;
    background-blend-mode: saturation;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    border-radius: 3px;
    width: 570px;
    animation: ${FallDown} 0.8s ${easeOutBack} forwards;
`;

const Title = styled.div`
    color: ${green};
    font-size: 32px;
    font-weight: bold;
`;

const Text = styled.div`
    font-size: 1em;
    margin: 1em 1em 40px;
`;

const CloseBtnContainer = styled.div`
    background: ${green};
    border: none;
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    width: 210px;
    height: 42px;
    display: flex;
    border-radius: 20px;
    letter-spacing: 2px;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    margin: auto;
`;

const BaseModal = props => {
    return (
        <BackWindow>
            <Container>
                <Title>{props.title}</Title>
                <Text>{props.text}</Text>
                <CloseBtnContainer onClick={() => props.onClose ? props.onClose() : window.location.reload()}>
                    Fermer
                </CloseBtnContainer>
            </Container>
        </BackWindow>
    );
};

export default BaseModal;
