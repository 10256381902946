import React, {useEffect, useState, useCallback, useMemo} from 'react';
import styled from 'styled-components';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import { getLabel } from '../../Utils/labels';
import { getImage } from '../../Utils/jsonInterpreter';
import {
    getInDataState, setFinalPriceInTarifsState,
    setInDataState
} from '../../Utils/storeHelpers';
import {
    ContainerOption,
    ImageOption,
    NameStructureOption,
    NameOption
} from '../../Utils/styleConstants';

import LePackMinorOption from "./LePackMinorOption";

const StyleContainer = styled(ContainerOption)`
    width: 98%;
`;

const createToggleGroupInitialState = (keys) => {
    return keys.reduce((current, key) => Object.assign(current, { [key]: true }), {});
}

const useToggleGroup = ({ initialKeys, radio }) => {
    const [toggled, setToggled] = useState(createToggleGroupInitialState(initialKeys));

    const offOthers = useCallback((previousState, key) => {
        const entries = Object.entries(previousState).map(([k, value]) => {
            return [k, `${key}` === k ? value : false]
        });

        return Object.fromEntries(entries);
    }, []);

    const toggle = useCallback((key) => {
        setToggled((previousState) => {
            const nextState = radio ? offOthers(previousState, key) : { ...previousState };

            if(!(key in nextState)) {
                nextState[key] = false;
            }

            nextState[key] = !nextState[key];

            return nextState;
        })
    }, [radio, offOthers, setToggled]);

    return { toggled, toggle };
}

const getToggledIndexesOrDefault = (pathInDataState, subOptions, def) => {
    const activatedSubOption = subOptions
        .map((subOption, key) => [subOption, key])
        .filter(([subOption, _]) => getInDataState(`${pathInDataState}.${subOption.name}`))
        .map(([_, key]) => key)

    if (activatedSubOption.length > 0) {
        return activatedSubOption;
    }

    if (def === 'none') {
        return [];
    }

    return [0];
}

const LePackOptionGroup = ({ subOptions, ...props }) => {
    const { pathInDataState, dependsOn, radio, 'default': def } = props;

    const { toggled , toggle } = useToggleGroup({
        initialKeys: getToggledIndexesOrDefault(pathInDataState, subOptions, def),
        radio: radio
    });

    const isOneToggled = useMemo(() => Object.values(toggled).includes(true), [toggled]);

    const handleToggleSubOption = useCallback((subOptionIndex) => toggle(subOptionIndex), [toggle]);

    useEffect(() => {
        subOptions.forEach((subOption) => {
            setInDataState(`${pathInDataState}.${subOption.name}`, false);
        })
    }, [pathInDataState, subOptions])

    // Handle toggled values change
    useEffect(() => {
        if (toggled) {
            const toggledEntries = Object.entries(toggled);

            toggledEntries.forEach(([key, checked]) => {
                const subOption = subOptions[key];

                setInDataState(`${pathInDataState}.${subOption.name}`, checked);

                if (!checked) {
                    setFinalPriceInTarifsState(subOption.name, null, undefined, undefined);
                }
            });
        }
    }, [pathInDataState, subOptions, toggled])

    return (
        toggled && (
            <StyleContainer checked={isOneToggled}>
                <ImageOption
                    src={getImage(props.image)}
                    alt='#'
                />
                <NameStructureOption>
                    <NameOption>
                        {getLabel(props.name)}
                    </NameOption>
                </NameStructureOption>
                {
                    subOptions.map((subOption, index) => (
                        <LePackMinorOption
                            key={index}
                            index={index}
                            name={subOption.name}
                            label={subOption.label}
                            pathInDataState={pathInDataState}
                            tarifsName={subOption.name}
                            dependsOn={dependsOn}
                            totalWidth={subOptions.length}
                            onToggle={handleToggleSubOption}
                            toggle={toggled[index]}
                            toggable
                        />
                    ))
                }
                <CheckmarkContainer
                    checked={isOneToggled}
                    notBottomCheckmark
                />
            </StyleContainer>
        )
    );
};

export default LePackOptionGroup;
