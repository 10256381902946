import React from "react";
import styled from "styled-components";
import {BackgroundContainer, borderGrey, SimpleCheckbox} from "../../../Utils/styleConstants";
import Counter from "../Counter";
import CheckmarkContainer from "../../Icons/CheckmarkContainer";

const LedChoiceContainer = styled(BackgroundContainer)`
    display: flex;
    flex-flow: row;
    border: 1px solid ${borderGrey};
    border-radius: 3px;
    width: 100%;
`;

const RightContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 100%;
`;

const ScreenCountContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
`;

const TitleBold = styled.p`
    font-size: 30px;
    font-weight: bold;
`;

const  SubTitle = styled.p`
    font-size: 15px;
`;

const CheckableContainer = styled(SimpleCheckbox)`
    display: inline-flex;
    justify-content: center;
    height: 250px;
    width: 28%
`;

const LedChoice = ({ checked, type, counterInitialValue, onCountChange, title, subTitle, onClick }) => {

    return (
            <CheckableContainer checked={checked}>
                <LedChoiceContainer>
                    <RightContainer>
                        <TitleBold>
                            {title}
                        </TitleBold>
                        <SubTitle>
                            { subTitle }
                        </SubTitle>
                        <ScreenCountContainer>
                            <Counter initialValue={counterInitialValue} onCountChange={(value) => onCountChange(type, value)}/>
                        </ScreenCountContainer>
                    </RightContainer>
                </LedChoiceContainer>
                <CheckmarkContainer checked={checked}/>
            </CheckableContainer>
    )
}

export default LedChoice;
