import React from 'react';
import styled from 'styled-components';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import { SimpleCheckbox } from '../../Utils/styleConstants';
import { getImage } from '../../Utils/jsonInterpreter';

const Container = styled(SimpleCheckbox)`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0 20px;
    min-width: 12.28%;
    cursor: pointer;
`;

const CheckableCountry = props => {
    return (
        <Container
            checked={props.checked}
            onClick={() => props.onClick(props.index, props.option)}
        >
            <img
                src={getImage(`/static/img/flags/${props.option.value}.png`)}
                alt={props.option.value}
            />
            <p>{props.option.name}</p>
            <CheckmarkContainer checked={props.checked} />
        </Container>
    );
};

export default CheckableCountry;
