import React from 'react';
import styled from 'styled-components';
import { green, grey } from '../Utils/styleConstants';
import PricePop from './PricePop';

const Info = styled.p`
    color: ${grey};
    font-size: 0.8em;
    font-weight: bold;
    text-align: center;
    padding: 0 0 1em;
`;

const Container = styled.div`
    display: flex;
    justify-content: space-around;
    height: 100%;
    width: 100%;
`;

const LineContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
`;

const Line = styled.div`
    display: flex;
    align-items: center;
`;

const Checkmark = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: ${green};
    margin: 0 1vh 0 0;
    color: white;
    font-weight: bold;
`;

const HebergementMaintenance = (props) => {
    const CheckmarkComponent = (<Checkmark>&#10004;</Checkmark>);

    const CustomContent = (
        <Container>
            <LineContainer>
                <Line>
                    {CheckmarkComponent}
                    <p>Bande passante 1 gbps</p>
                </Line>
                <Line>
                    {CheckmarkComponent}
                    <p>Stockage Emails 4 Go</p>
                </Line>
            </LineContainer>
            <LineContainer>
                <Line>
                    {CheckmarkComponent}
                    <p>Back-up automatique</p>
                </Line>
                <Line>
                    {CheckmarkComponent}
                    <p>Stockage de données 250 Go</p>
                </Line>
            </LineContainer>
        </Container>
    );

    return (
        <>
            {
                props.info && (
                    <Info>{props.info}</Info>
                )
            }
            <PricePop
                tarifsName={props.masterId}
                custom={CustomContent}
                isColorWith={false}
            />
        </>
    );
};

export default HebergementMaintenance;
