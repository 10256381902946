import React from 'react';
import styled from 'styled-components';
import background from '../Data/K.png';
import { getInGenericState } from '../Utils/storeHelpers';
import { CenteredContainer, grey, green } from '../Utils/styleConstants';

const Container = styled.div`
    background: ${grey};
    height: 250px;
`;

const BackCenteredContainer = styled(CenteredContainer)`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Title = styled.h1`
    font-size: 2em;
    font-weight: bold;
    color: ${green};
    margin: 0 0 10px;
`;

const SubTitle = styled.div`
    font-size: 1em;
    color: white;
`;

const Kmage = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 1em;
  margin-right: 2em;
  width: 20%;
  
  img {
    width: 50%;
  }
`;

const TopContent = props => {
    // Use of redux
    const pagesInfo = getInGenericState('document.pages');
    const currentStep = getInGenericState('step');

    return (
        <Container>
            <BackCenteredContainer>
                <div>
                    <Title>{props.documentName}</Title>
                    <SubTitle>Étape {currentStep} : {pagesInfo && pagesInfo[props.currentPageState].step.name}</SubTitle>
                </div>
                <Kmage><img src={background} alt='' /></Kmage>
            </BackCenteredContainer>
        </Container>
    );
};

export default TopContent;
