import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PriceElement from './PriceElement';
import Counter from './Counter';
import PromoTag from './PromoTag';
import PromoFnaimTag from './PromoFnaimTag';
import personnalisationHektor from '../Images/HK_offert.png';
import logoHektor from '../Images/logo-hektor.png';
import { getLabel } from '../Utils/labels';
import { manualDependsOnTarifsInfo } from '../Utils/jsonInterpreter';
import {
    SubscriptionContainer,
    SubscriptionStructure,
    SubscriptionInsteadOf,
    SubscriptionLogo,
    SubscriptionOfferName,
    SubscriptionCommissioning
} from '../Utils/styleConstants';
import {
    setInDataState,
    getInTarifsState,
    getHektorTypeFromHektorOffer,
    getInDataState,
    setFinalPriceInTarifsState,
    resetFinalPriceHektorOptions
} from '../Utils/storeHelpers';

const Image = styled.img`
    padding: 0 5% 0 3%;
    
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;
    padding: 3vh 0;
`;

const LogoStructure = styled.div`
    display: flex;
    flex-direction: column;
`;

const AdditionalElementStructure = styled.div`
    display: flex;
    align-items: center;
    padding: 2vh 0 0;
`;

const Commissioning = styled.p`
    order: -1;
    white-space: nowrap;
`;

const AbonnementIkaLePack = props => {
    // Use of redux
    const hektorOffer = getInDataState(`${props.pathInDataState}.offre`);
    const hektorType = getHektorTypeFromHektorOffer(hektorOffer);
    const tarifsHektorType = getInTarifsState(hektorType);
    const tarifsInfo = manualDependsOnTarifsInfo(tarifsHektorType, hektorOffer);

    // useEffect when component is mounting
    useEffect(() => {
        // Set commissioning in dataState
        props.commissioningName && setInDataState(`${props.pathInDataState}.${props.commissioningName}`, true);
    }, [props.pathInDataState, props.commissioningName]);

    // Callback used when the user counter change
    const handleUserCounterChange = useCallback(() => {
        // Whenever the user change the counter of additionalUsers, we reset its finalPrice
        setFinalPriceInTarifsState(props.additionalUsers, null, undefined, hektorOffer);
    }, [props.additionalUsers, hektorOffer]);

    // Callback used when the madataires counter change
    const handleMandCounterChange = useCallback(() => {
        // Whenever the user change the counter of additionalMandataires, we reset its finalPrice
        setFinalPriceInTarifsState(props.additionalMandataires, null, undefined, hektorOffer);
        // We also reset the finalPrice of hektor option add
        resetFinalPriceHektorOptions(true, hektorOffer);
    }, [props.additionalMandataires, hektorOffer]);

    return (
        <SubscriptionContainer>
            <Image src={personnalisationHektor} alt='Personnalisation offerte' />
            <Content>
                <LogoStructure>
                    <SubscriptionLogo src={logoHektor} alt="Logo Hektor" />
                    <SubscriptionOfferName>{props.offerName}</SubscriptionOfferName>
                </LogoStructure>
                {
                    hektorOffer && tarifsInfo && (
                        <SubscriptionStructure>
                            <PriceElement
                                tarifsName={hektorType}
                                dependsOn={hektorOffer}
                                fontSize='6em'
                            />
                            {
                                tarifsInfo.insteadOf && typeof tarifsInfo.insteadOf !== 'object' && (
                                    <SubscriptionInsteadOf>{`Au lieu de ${tarifsInfo.insteadOf} € ${tarifsInfo.priceUnit}`}</SubscriptionInsteadOf>
                                )
                            }
                            {
                                tarifsInfo.nbrOfUsers && typeof tarifsInfo.nbrOfUsers !== 'object' && (
                                    <p>{`Pour ${tarifsInfo.nbrOfUsers} utilisateurs`}</p>
                                )
                            }
                        </SubscriptionStructure>
                    )
                }
                {
                   props.additionalUsers && hektorType && (
                        <AdditionalElementStructure>
                            {
                                tarifsInfo.nbrOfUsers && typeof tarifsInfo.nbrOfUsers !== 'object' && (
                                    <>
                                        <p>Utilisateur(s) supplémentaire(s)</p>
                                        <Counter
                                            masterId={`${props.pathInDataState}.${hektorOffer}.${props.additionalUsers}`}
                                            initialValue={getInDataState(`${props.pathInDataState}.${hektorOffer}.${props.additionalUsers}`) ?? 0}
                                            margin='0 45px'
                                            callback={handleUserCounterChange}
                                        />
                                        <PriceElement
                                            tarifsName={props.additionalUsers}
                                            dependsOn={hektorType}
                                        />
                                    </>
                                )
                            }
                            
                        </AdditionalElementStructure>
                    )
                }
                {
                    props.additionalMandataires && hektorType && (
                        <AdditionalElementStructure>
                            <p>Nombre de mandataire(s)</p>
                            <Counter
                                masterId={`${props.pathInDataState}.${hektorOffer}.${props.additionalMandataires}`}
                                initialValue={getInDataState(`${props.pathInDataState}.${hektorOffer}.${props.additionalMandataires}`) ?? 0}
                                margin='0 45px'
                                callback={handleMandCounterChange}
                            />
                            <PriceElement
                                tarifsName={props.additionalMandataires}
                                dependsOn={hektorType}
                            />
                        </AdditionalElementStructure>
                    )
                }
                {
                    props.commissioningName && hektorType && (
                        <SubscriptionCommissioning>
                            <Commissioning>{getLabel(props.commissioningName)}</Commissioning>
                            <PriceElement
                                tarifsName={props.commissioningName}
                                dependsOn={hektorOffer}
                            />
                        </SubscriptionCommissioning>
                    )
                }
            </Content>
            {
                props.promoTag && (
                    <PromoTag
                        tarifsName={`${hektorType}-promotion`}
                        promotionId={hektorOffer}
                        {...props.promoTag}
                    />
                )
            }
            {
                props.promoFnaimTag && (
                    <PromoFnaimTag {...props.promoFnaimTag} />
                )
            }
        </SubscriptionContainer>
    );
};

export default AbonnementIkaLePack;
