import React from 'react';
import { useSelector } from 'react-redux';
import { selectGenericState } from '../../Store/selectors';
import BaseModal from './BaseModal';

const ModalConnection = () => {
    // Use of redux
    const genericState = useSelector(selectGenericState);

    return (
        genericState.notConnected && (
            <BaseModal
                title="Vous n'êtes plus authentifié !"
                text="La plupart du temps il s'agit d'une expiration du délai de votre token. 
                    Kontractor va maintenant se fermer, veuillez vous reconnecter. 
                    Toutes les données hormis celle de la page courante sont sauvées."
            />
        )
    );
};

export default ModalConnection;