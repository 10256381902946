import React from "react";
import useRadio from "./hooks/useRadio";
import Switch from "../Switch/Switch";
const Radio = (props) => {
    const { isSelected, handleChange } = useRadio({ value: props.value });

    return (
        <Switch checked={isSelected} onChange={handleChange} />
    )
}

export default Radio;
