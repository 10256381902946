import {RadioGroupContext} from "./RadioGroupContext";

const RadioGroup = (props) => {
    const { value: currentValue, onValueChange, children, GroupContainer } = props;

    const Container = GroupContainer ?? 'div';

    return (
        <Container>
            <RadioGroupContext.Provider value={{ value: currentValue, onChange: onValueChange }}>
                {children}
            </RadioGroupContext.Provider>
        </Container>
    )
}

export default RadioGroup;
