import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentPageState } from './Store/selectors';
import ModalConnection from './Components/Modals/ModalConnection';
import ModalNetwork from './Components/Modals/ModalNetwork';
import ModalMandatory from './Components/Modals/ModalMandatory';
import Page from './Components/AppTree/Page';
import Pager from './Containers/Pager';
import Header from './Components/Header';
import DeprecatedHeader from './Components/DeprecatedHeader';
import TopContent from './Components/TopContent';
import PdfDownloadPage from './Components/AppTree/PdfDownloadPage';
import { getDataState, getGenericState, getInDataState, getTarifsState } from './Utils/storeHelpers';

const AppLayout = props => {
    // Use of redux
    const currentPageState = useSelector(selectCurrentPageState);
    const genericState = getGenericState();
    const tarifsState = getTarifsState();
    const dataState = getDataState();

    // useEffect each time we change page
    useEffect(() => {
        // Scroll to top
        window.scrollTo(0, 0);

        //console.log(currentPageState);
        //console.log(dataState);
        //console.log(tarifsState);
    }, [currentPageState, dataState, tarifsState]);

    return (
        currentPageState === -1 ? (
            <PdfDownloadPage />
        ) : (
            <>
                {/* All modals (src/Components/Modals) */}
                <ModalConnection />
                <ModalNetwork />
                <ModalMandatory />
                {/* ********************************** */}
                <Header />
                <TopContent
                    documentName={genericState.document.documentName}
                    currentPageState={currentPageState}
                />
                {
                    props.deprecated && (
                        <DeprecatedHeader
                            id={genericState.idDocument}
                            createDate={genericState.createDate}
                            clientRef={getInDataState('agence.reference-client')}
                            walletId={getInDataState('agence.wallet-id')}
                            lastTarifsRefId={props.lastTarifsRefId}
                        />
                    )
                }
                <Page currentPageState={currentPageState} />
                <Pager/>
            </>
        )
    );
};

export default AppLayout;
