import React from 'react';
import { NumericFormat } from 'react-number-format';

const Currency = props => {
    return (
        <NumericFormat
            value={props.value}
            thousandSeparator=' '
            decimalSeparator=','
            decimalScale={2}
            suffix=' €'
            displayType='text'
            fixedDecimalScale
        />
    );
};

export default Currency;
