import React from 'react';
import styled from 'styled-components';
import CheckmarkLittle from './CheckmarkLittle';

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 13px;
    height: 25px;
    padding: 0;
    opacity: 1;
`;

const CheckmarkContainerLittle = props => {
    return (
        <Container>
            <CheckmarkLittle />
        </Container>
    )
}

export default CheckmarkContainerLittle;