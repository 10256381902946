import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import MinorOptionHektor from './MinorOptionHektor';
import ModeloSuppOptionHektor from './ModeloSuppOptionHektor';
import { getLabel } from '../../Utils/labels';
import { getImage, manualDependsOnTarifsInfo, getTarifsInfoByTarifsName } from '../../Utils/jsonInterpreter';
import { getInDataState, setInDataState } from '../../Utils/storeHelpers';
import {
    ContainerOption,
    ImageOption,
    NameStructureOption,
    NameOption
} from '../../Utils/styleConstants';

const Container = styled(ContainerOption)`
    width: 98%;
`;

const OptionsStructure = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: calc(100% - 90px - 15%);
`;

const ModeloOptionHektor = props => {
    // Use of hook
    const [toggled, setToggled] = useState(null);

    // Callback to determine if a toggle is on/off & logic if so
    const isSubOptionToggled = useCallback((subOptionName) => {
        let isToggled = false;
        let tarifsInfo = getTarifsInfoByTarifsName(subOptionName);
        tarifsInfo = manualDependsOnTarifsInfo(tarifsInfo, props.hektorOffer);

        // The state of the toggle will first depend on 'includedIn' property in tarifsState
        if (
            true === tarifsInfo.includedIn
            && !(false === getInDataState(`${props.pathInDataState}.${subOptionName}`))
        ) {
            // If tarifsState for this subOption has the property 'includedIn'
            // AND this property is set to true for this hektor offer
            // AND the user did not specified a choice for this subOption yet
            // We can toggle the subOption
            isToggled = true;
        } else {
            // If tarifsState for this subOption does not have the property 'includedIn'
            // We base the state of the subOption toggle on dataState
            isToggled = getInDataState(`${props.pathInDataState}.${subOptionName}`) ?? false;
        }

        return isToggled;
    }, [props.pathInDataState, props.hektorOffer]);

    // useEffect when component is mounting
    useEffect(() => {
        if (!toggled) {
            const tmpToggle = [];

            props.subOptions.forEach(subOption => {
                const isToggled = isSubOptionToggled(subOption);

                tmpToggle.push(isToggled);
            });

            // This setup an array of boolean which determine if the subOptions are selected or not (one by one)
            setToggled(tmpToggle);
        }
    }, [props.subOptions, toggled, isSubOptionToggled]);

    // Callback when user toggle a subOption
    const handleToggleSubOption = useCallback((subOptionIndex) => {
        0 === subOptionIndex ?
            setToggled(
                toggled.map((value, index) => index === subOptionIndex ? !value : false)
            )
            // Either toggle the subOption or not by modifying the hook array of booleans
            : setToggled(
                toggled.map((value, index) => index === subOptionIndex ? !value : value)
            );
    }, [toggled]);

    // useEffect whenever toggled hook change
    useEffect(() => {
        // Change dataState to match hook
        toggled && toggled.map((toggle, index) => 
            setInDataState(`${props.pathInDataState}.${props.subOptions[index]}`, toggle)
        );
    }, [props.pathInDataState, props.subOptions, toggled])

    return (
        toggled && (
            <Container checked={toggled.some((item => true === item))}>
                <ImageOption
                    src={getImage(props.image)}
                    alt='#'
                />
                <NameStructureOption>
                    <NameOption>
                        {getLabel(props.name)}
                    </NameOption>
                </NameStructureOption>
                <OptionsStructure>
                    {
                        props.subOptions.map((subOption, index) => (
                            <MinorOptionHektor
                                key={index}
                                index={index}
                                width='33.33%'
                                borderBottom
                                toggle={toggled[index]}
                                toggable={index === 0 ? true : toggled[0]}
                                pathInDataState={props.pathInDataState}
                                name={subOption}
                                tarifsName={subOption}
                                dependsOn={props.hektorOffer}
                                partOfId={props.hektorOffer}
                                onToggle={handleToggleSubOption}
                            />
                        ))
                    }
                    {
                        props.suffix && props.subOptions.map((subOption, index) => {
                            const suppOption = `${subOption}${props.suffix}`;

                            return (
                                <ModeloSuppOptionHektor
                                    key={index}
                                    width='33.33%'
                                    toggable={toggled[index]}
                                    pathInDataState={props.pathInDataState}
                                    name={suppOption}
                                    suffix={props.suffix}
                                    tarifsName={suppOption}
                                    dependsOn={props.hektorOffer}
                                    partOfId={props.hektorOffer}
                                />
                            );
                        })
                    }
                </OptionsStructure>
                <CheckmarkContainer
                    checked={toggled.some((item => true === item))}
                    notBottomCheckmark
                />
            </Container>
        )
    );
};

export default ModeloOptionHektor;
