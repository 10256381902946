import { NET_UP } from "./network-state";

const initialState = {
    generic: {
        page: 0,
        step: 1,
        document: {},
        missingMandatory: false,
        notConnected: false,
        networkState: NET_UP,
        idDocument: null,
        createDate: null,
        updateDate: null,
        contractNum: null,
        user: null
    },
    data: {
        _order: {},
        agence: {
            pays: "france"
        },
        "langues-devises": {
            langue: "francais",
            devise: "euro"
        },
        "listing-ag-mand": {
            mode: "agences"
        }
    },
    tarifs: {
        _previous: {}
    }
};

export default initialState;
