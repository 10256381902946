import React, { useEffect, useState, useCallback } from 'react';
import styled, { css } from 'styled-components';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import PriceElement from '../PriceElement';
import Counter from '../Counter';
import { getImage } from '../../Utils/jsonInterpreter';
import { StyledCheckbox } from '../../Utils/styleConstants';
import { getInDataState, setInDataState, setFinalPriceInTarifsState } from '../../Utils/storeHelpers';

const Container = styled(StyledCheckbox)`
    flex-direction: column;
    justify-content: space-between;
    height: 250px;
    width: ${props => `calc((100% - (2% * ${props.perLine})) / ${props.perLine})`};
    padding: 20px;
    opacity: ${({ disabled }) => disabled ? '0.5' : '1' };
`;

const TitleStructure = styled.div`
    max-width: 80%;
`;

const Title = styled.p`
    font-size: 30px;
    font-weight: 500;
`;

const TitleBold = styled.p`
    font-size: 30px;
    font-weight: bold;
`;

const Image = styled.img`
    position: absolute;
    right: 8%;
    bottom: ${({ bottom }) => bottom ?? '25%' };
    width : ${({ imgWidth }) => imgWidth ? `${imgWidth}px;` : '100px'};
    
    ${({ center }) => center && css`
        margin: auto;
        inset: 0;
    `};
`;

const PriceStructure = styled.div`
    display: flex;
    flex-direction: column;
`;

const ImgContainer = styled.div`
    height: 90px;
    width: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    margin-top: 50px;
    position: absolute;
    right: 0;
    top: 38px;
    ${props => props.contain ?
        (
            props.imgWidth && props.imgHeight ?
                `
                    background: white;
                    & img {
                        max-width: ${props.imgWidth}%;
                        max-height: ${props.imgHeight}%;
                    };
                ` 
                :
                `
                    background: white;
                    & img {
                        max-width: 70%;
                        max-height: 70%;
                    };
                `
        )
        : 
        `
            & img {
                width: 100%;
            };
        `
    }
`;

/**
 * @typedef {Object} Option
 * @property {number} pathInDataStateQuantity
 * @property {Object} displayIf
 * @property {string} tarifsName
 */

/**
 * Properties received by the CheckableImage component
 *
 * @typedef {Object} CheckableImageProps
 * @property {Option} option
 * @property {bool} [checked]
 */

/**
 * @param {CheckableImageProps} props
 */
const CheckableImage = props => {

    const [display, setDisplay] = useState(null);
    const [counterValue, setCounterValue] = useState(null);

    // useEffect when composant is mounting
    useEffect(() => {
        if (null === display) {
            if (props.option.pathInDataStateQuantity && props.checked && undefined === getInDataState(props.option.pathInDataStateQuantity)) {
                setInDataState(props.option.pathInDataStateQuantity, 1);
            }
            if (props.option.displayIf) {
                const conditions = props.option.displayIf.conditions;
                const correctValue = getInDataState(props.option.displayIf.path);
                const mode = props.option.displayIf.mode;
                let isDisplay = mode === 'or' ? false : true;

                conditions.forEach(value => {
                    if (mode === 'or') {
                        isDisplay = correctValue === value ? true : isDisplay;
                    } else {
                        isDisplay = correctValue !== value ? false : isDisplay;
                    }
                });

                setDisplay(isDisplay);
            } else {
                setDisplay(true);
            }
        }
    }, [props, display])

    // Callback when user change cro counter
    const handleCounter = useCallback((value, event) => {
        event.stopPropagation();
        props.option.tarifsName && setFinalPriceInTarifsState(props.option.tarifsName, null)
        setCounterValue(value);
    }, [props.option.tarifsName])

    useEffect(() => {
        null !== counterValue &&
            setInDataState(props.option.pathInDataStateQuantity, counterValue);
    }, [counterValue, props.option.pathInDataStateQuantity])

    return (
        display && (
            <Container
                checked={props.checked}
                perLine={props.perLine}
                onClick={() => props.onClick(props.index, props.option)}
                disabled={props.disabled}
            >
                <TitleStructure>
                    {props.option.topTitle && (<p>{props.option.topTitle}</p>)}
                    {props.option.title && (<Title>{props.option.title}</Title>)}
                    {props.option.titleBold && (<TitleBold>{props.option.titleBold}</TitleBold>)}
                </TitleStructure>
                {
                    props.option.pathInDataStateQuantity && props.checked && (
                        <Counter
                            dontStandAlone
                            min={1}
                            margin='0'
                            fontSize='18px'
                            inputCounter={props.option.inputCounter}
                            masterId={props.option.pathInDataStateQuantity}
                            initialValue={getInDataState(props.option.pathInDataStateQuantity) ?? 1}
                            callback={handleCounter}
                        />
                    )
                }
                <PriceStructure>
                    {
                        props.option.tarifsName && (
                            <PriceElement
                                tarifsName={props.option.tarifsName}
                                dependsOn={props.hektorOffer}
                            />
                        )
                    }
                </PriceStructure>
                {
                    props.option.contain ?
                    (
                        <ImgContainer contain={props.option.contain} imgWidth={props.option.imgWidth} imgHeight={props.option.imgHeight}>
                            <img src={getImage(props.option.img)} alt='' />
                        </ImgContainer>
                    )
                    :
                    (
                        <Image center={props.option.center} bottom={props.option.bottom} imgWidth={props.option.mainImgWidth} src={getImage(props.option.img)} alt='#' />
                    )
                }
                <CheckmarkContainer checked={props.checked} />
            </Container>
        )
        
    )
}

export default CheckableImage;
