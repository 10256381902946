import { connect } from 'react-redux'
import Menu from '../Components/Menu'
import { mapStateToPropsAddable } from '../Utils/mapToProps'

const mapStateToProps = (state, ownProps) => ({
    ...mapStateToPropsAddable(state, ownProps),
    gridElements: ['domain', 'hebergeur'],
    spanElements: [],
    gridRepeat: 1,
    bottomMention: `<center>Le prestataire actuel devra réaliser une opération technique afin que nous puissions lancer l'opération de transfert. <br/><b>La procédure vous sera envoyée par email.</b></center`,
    refs: id => ({
        domain: {
            type: 'Combo',
            combo: ['input', 'select'],
            isLabeled: false,
            priced: true,
            tarifsName: 'gsuite-domains',
            select: {
                name: `${id}/transfer`,
                options: ['a-transferer', 'a-acheter', 'non-gere-ext'],
                size: 30
            },
            input: {
                name: `${id}`,
                innerType: 'url'
            }
        },
        hebergeur: {
            type: 'InputTextContainer',
            name: `${id}/hebergeur`,
            label: 'ancienhebergeur'
        }
    })
})

export default connect(
    mapStateToProps
)(Menu)
