/**
 * @param {Object} obj
 * @param {Array|string} path
 * @returns {boolean}
 */
function has(obj, path) {
    if (!path) return false;

    /** @type {Array} */
    const pathArray = Array.isArray(path) ? path : path.match(/([^[.\]])+/g);

    return !!obj && (
        pathArray.length > 1
            ? has(obj[pathArray[0]], pathArray.slice(1))
            : Object.hasOwn(obj, pathArray[0])
    );
}

export default has;


