import { connect } from 'react-redux'
import DomainNamesList from '../Components/DomainNamesList'
import { UPDATE_DATA_NDD, UPDATE_DATA_VALUE } from '../Store/actions';
import { mapStateToProps, mapDispatchToProps } from '../Utils/mapToProps'

const additionalMapStateToProps = (state, ownProps) => {
    const emptyDomainName = {
        name: '',
        action: ownProps.options[0],
        oldHost: '',
        primary: false,
        lbi: false
    };

    return {
        ...mapStateToProps(state, ownProps),
        action: UPDATE_DATA_NDD,
        actionDelete: UPDATE_DATA_VALUE,
        options: ownProps.options,
        domainNames: state.data[ownProps.masterId] && state.data[ownProps.masterId][ownProps.name] ? state.data[ownProps.masterId][ownProps.name] : [],
        emptyDomainName: emptyDomainName,
        prices: state.tarifs[ownProps.masterId]['price'][state.data['offre-site'].mode]
    }
}

export default connect(
    additionalMapStateToProps,
    mapDispatchToProps
)(DomainNamesList)
