import React from 'react'
import styled from 'styled-components';
import {
    deleteInDataState, getInDataState,
    multipleClearFinalPriceOfTarifs,
    setInDataState
} from '../Utils/storeHelpers'
import { borderGrey, green } from '../Utils/styleConstants'
import { createOptions } from '../Utils/jsonInterpreter'
import Select, { components } from 'react-select'
import Checkmark from "./Icons/Checkmark";

const Control= styled(components.Control)`
  &.react-select__control {
    border: 1px solid ${borderGrey};
  }
`

const getCustomStyles = (props) => {
    return {
        container: (styles, _) => ({
            ...styles,
            width: props.width ? props.width : "100%",
            padding: props.padding
        }),
    }
}

const OptionComponent = (props) => {
    const Container = styled.div`
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        height: 20px;
    `

    const CheckmarkContainer = styled.div`
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        padding: 1px;
        background: ${({ isSelected }) => isSelected ? green : 'none'};
        border-radius: 25%;
    `

    const Label = styled.label`
        padding-left: 10px;
    `;

    const MultiOption = styled(components.Option)`
        &.react-select__option {
          background: ${({ isSelected }) => isSelected  ? green : 'normal'};
    
          &:active {
            background: ${green};
          }
    
          &:hover {
            filter: ${({ isSelected }) => isSelected ? 'brightness(.9)' : 'normal'};
          }
        }
    `;

    return (
        <MultiOption {...props} >
            <Container>
                <CheckmarkContainer isSelected={props.isSelected}>
                    { props.isSelected && <Checkmark /> }
                </CheckmarkContainer>
                <Label>{props.label}</Label>
            </Container>
        </MultiOption>
    );
};

class SelectItem extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.getDisabled = this.getDisabled.bind(this);
        this.getValue = this.getValue.bind(this);
        this.state = {
            disabled: this.getDisabled(props),
            defaultValue: this.props.defaultValue,
            customStyles: getCustomStyles(props),
            options: createOptions(props)
        }
    }

    componentDidMount() {
        const defaultValue = this.state.defaultValue;
        const valueInState = this.getFromDataState();

        if (!valueInState && defaultValue && defaultValue !== '') {
            const { options } = this.state;
            const valueExistInOptions = options.some(option => option.value === defaultValue);

            if (valueExistInOptions) {
                this.updateDataState(defaultValue);
            }
        }
    }

    getValue() {
        const { options } = this.state
        const { value, isMulti } = this.props;

        return isMulti
            ? options.filter(option => value.includes(option.value))
            : options.filter(option => option.value === value)
    }

    handleChange(event) {
        const { isMulti, clearFinalPrice } = this.props;

        if (isMulti) {
            const values = event.map(e => e.value);
            this.updateDataStateMulti(values);
        } else {
            this.updateDataState(event.value);
        }

        multipleClearFinalPriceOfTarifs(clearFinalPrice);
    }

    updateDataState(value) {
        const path = this.getPath();

        setInDataState(path, value)
    }

    getFromDataState() {
        const path = this.getPath();

        return getInDataState(path);
    }

    updateDataStateMulti(values) {
        const path = this.getPath();

        deleteInDataState(path, null, false, true);
        values.forEach(value => setInDataState(path, value, true));
    }

    getDisabled ({ disabled }) {
        return disabled !== undefined && disabled;
    }

    getPath() {
        const { masterId, name } = this.props;

        return `${masterId}.${name}`;
    }

    render () {
        return (
            <Select
                classNamePrefix="react-select"
                searchable
                options={this.state.options}
                onChange={this.handleChange}
                styles={this.state.customStyles}
                placeholder={''}
                isDisabled={this.state.disabled}
                isMulti={this.props.isMulti}
                closeMenuOnSelect={!this.props.isMulti}
                hideSelectedOptions={!this.props.isMulti}
                components={{
                    Option: this.props.isMulti ? OptionComponent : components.Option,
                    Control: Control
                }}
                value={this.getValue()}
            />
        )
    }
}

export default SelectItem
