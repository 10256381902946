import React from 'react'
import { getTarifsInfoByTarifsName, manualDependsOnTarifsInfo } from '../Utils/jsonInterpreter';
import { formatPrice } from '../Utils/price';

const PriceLine = props => {
    // Retrieve info about tarifs
    let tarifsInfo = getTarifsInfoByTarifsName(props.tarifsName);
    tarifsInfo = manualDependsOnTarifsInfo(tarifsInfo, props.dependsOn);

    return (
        null !== tarifsInfo && (
            isNaN(tarifsInfo.price) ? (
                <p>{tarifsInfo.price}</p>
            ) : (
                <p>
                    {`${formatPrice(tarifsInfo.price)} HT ${tarifsInfo.priceUnit}`}
                </p>
            )
        )
    );
};

export default PriceLine;
