import React from 'react';
import Builder from '../Utils/builder';
import { v4 as uuid } from 'uuid';
import styled from 'styled-components';
import Add from './Addable/Add';
import DeleteComponent from './Addable/Delete';
import short from 'short-uuid';
import { UPDATE_ORDER_ADDABLE } from '../Store/actions';
import {multipleClearInDataState, setInStore} from '../Utils/storeHelpers';

const RowContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    position: relative;
`;

const AddContainer = styled.div`
    flex: 0 0 50px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ absolute }) => absolute ? 'position: absolute' : null};
    right: 0;
`;

const isAbsolute = ({ type, combo }) => {
    if (type === 'SelectContainer') {
        return false;
    }

    if (type === 'Combo' && Array.isArray(combo) && [...combo].pop() === 'select') {
        return false;
    }

    return true;
};

const TreeContainer = styled.div`
    flex: 1;
`;

class Addable extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            tree: [],
            ids: []
        }
        this.getFirstRow = this.getFirstRow.bind(this)
        this.getOtherRow = this.getOtherRow.bind(this)
        this.getRow = this.getRow.bind(this)
        this.getElementProps = this.getElementProps.bind(this)
        this.addToTree = this.addToTree.bind(this)
        this.handleAdd = this.handleAdd.bind(this)
        this.handleDelete = this.handleDelete.bind(this)
        this.reloadTree = this.reloadTree.bind(this)
        this.buildTree = this.buildTree.bind(this)
        this.getElementName = this.getElementName.bind(this)
        this.createInitialData = this.createInitialData.bind(this)
    }

    getElementName () {
        return false
    }

    reloadTree (ids) {
        this.setState({ ids }, () => {
            this.props.dispatch({
                type: UPDATE_ORDER_ADDABLE,
                value: {
                    addname: [this.props.masterId, this.props.name].join('|'),
                    order: this.state.ids
                }
            })
            this.buildTree()
        })
    }

    handleAdd () {
        if (!this.props.max || this.state.ids.length < this.props.max) {
            const newId = short.generate()
            const ids = [...this.state.ids]
            if (this.props.addbottom) {
                ids.unshift(newId);
            } else {
                ids.push(newId);
            }
            this.reloadTree(ids);

            multipleClearInDataState(this.props.clearOnChangeInDataState);
        }
    }

    handleDelete (uid, masterId) {
        return () => {
            this.props.dispatch({
                type: this.props.deleteAction,
                value: {
                    group: masterId,
                    template: uid
                }
            })
            const idPos = this.state.ids.indexOf(uid);
            const ids = [...this.state.ids];
            ids.splice(idPos, 1);
            this.reloadTree(ids);

            multipleClearInDataState(this.props.clearOnChangeInDataState);
        }
    }

    getFirstRow (props) {
        if (props.labelName === undefined) {
            props.labelName = this.props.name
        }
        return this.getRow(props, <Add handleClick={this.handleAdd} />)
    }

    getOtherRow (props) {
        return (id) => {
            if (props.labelName === undefined) {
                props.labelName = this.props.name
            }
            return this.getRow(props, <DeleteComponent handleClick={this.handleDelete(id, this.props.masterId)} />)
        }
    }

    getRow (props, btn) {
        return (
            <RowContainer key={uuid()}>
                <TreeContainer>{Builder.create(this.props.masterId, props)}</TreeContainer>
                <AddContainer absolute={isAbsolute(props)}>{btn}</AddContainer>
            </RowContainer>
        )
    }

    getElementProps (id, order) {
        return {
            ...this.props.addcomponent,
            masterId: this.props.masterId,
            name: `${this.props.name}|${id}`,
            label: this.props.label,
            order
        }
    }

    buildTree () {
        let first = true
        let order = 0
        const tree = this.state.ids ?
            this.state.ids.map(id => {
                order++
                const elementProps = this.getElementProps(id, order)
                if (first) {
                    first = false
                    return this.getFirstRow(elementProps)
                }
                return this.getOtherRow(elementProps)(id)
            })
            : []
        this.addToTree(tree)
    }

    addToTree (tree) {
        this.setState({
            tree
        })
    }

    componentDidMount () {
        const ids = Array.isArray(this.props.ids) && this.props.ids.length === 0 && this.props.initialData
            ? this.createInitialData()
            : this.props.ids

        this.reloadTree(ids);
    }

    createInitialData () {
        return this.props.initialData.map(data => {
            const newId = short.generate()
            for (let [key, value] of Object.entries(data)) {
                const valueName = [[this.props.name, newId].join('|'), key].join('/')
                setInStore({...this.props, name: valueName}, value)
            }
            return newId
        })
    }

    render () {
        return (
            <div>
                {this.state.tree}
            </div>
        )
    }
}

export default Addable
