import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WAIT_NETWORK } from '../../Store/actions';
import { NET_DOWN } from '../../Store/network-state';
import { selectGenericState } from '../../Store/selectors';
import BaseModal from './BaseModal';

const ModalNetwork = () => {
    // Use of redux
    const dispatch = useDispatch();
    const genericState = useSelector(selectGenericState);

    return (
        NET_DOWN === genericState.networkState && (
            <BaseModal
                title="Vous n'êtes pas connecté au serveur !"
                text="Si vous pensez qu'il s'agit d'une panne de réseau temporaire, 
                    vous pouvez continuer de travailler et les données seront sauvegardées automatiquement à la reconnexion. 
                    Vous pouvez aussi quitter, toutes les données hormis celles de la page courante sont sauvées."
                onClose={() => dispatch({ type: WAIT_NETWORK })}
            />
        )
    );
};

export default ModalNetwork;