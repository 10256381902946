import {addFinancingPrice, addInFinalPrices, getInTarifsState, resetFinalPrices} from "../../storeHelpers";
import {getLabel} from "../../labels";
import {capitalize} from "../../string";
import {groupBy} from "../../array";
import {get} from "../../object";
import {PaymentType} from "../../../types/payment";

/**
 * @typedef {import('../types/lbiMedia.js').Screen} Screen
 * @typedef {import('../types/lbiMedia.js').ScreenOffer} ScreenOffer
 */

const TARIF_NAME_SCREEN_SIMPLE = 'lbi-media-screen-simple';
const TARIF_NAME_SCREEN_PRESTIGE = 'lbi-media-screen-prestige';
const TARIF_NAME_DYNAMIC_COMMUNICATION_SOFTWARE = 'lbi-media-dynamic-communication-software';
const TARIF_NAME_CONFIGURATION = 'lbi-media-screen-installation-settings';
const TARIF_NAME_GETTING_STARTED = 'lbi-media-screen-getting-started';
const TARIF_NAME_CPL = 'lbi-media-screen-cpl';

/**
 * @param {string} type
 * @return {Object}
 */
const getTariffFromScreenType = (type) => {
    if (type === 'simple') {
        return getInTarifsState(TARIF_NAME_SCREEN_SIMPLE);
    }

    return getInTarifsState(TARIF_NAME_SCREEN_PRESTIGE);
}

const loadDynamiqueCommunicationSoftwarePrice = () =>  {
    const tariff = getInTarifsState(TARIF_NAME_DYNAMIC_COMMUNICATION_SOFTWARE);

    addInFinalPrices(TARIF_NAME_DYNAMIC_COMMUNICATION_SOFTWARE, {
        price: tariff.price,
        name: getLabel(TARIF_NAME_DYNAMIC_COMMUNICATION_SOFTWARE),
        displayWhenParent: 'parent-offre-lbi-media-screen'
    });
}

const loadScreensPrices = (screenOffer) => {
    const screens = screenOffer.items ?? [];

    resetFinalPrices(TARIF_NAME_SCREEN_SIMPLE);
    resetFinalPrices(TARIF_NAME_SCREEN_PRESTIGE);

    const additionalLabel = getLabel('recap/lbi-media-screen-count-additional');

    /** @type {Map<string, Screen[]>} */
    const screensMap = groupBy(screens, screen => [screen.type, screen.orientation, screen.inch].join('-'));
    const screensGroup = Array.from(screensMap.values());

    if (screensGroup && screensGroup.length > 0) {
        screensGroup.map(group => {
            const screen = group[0];
            const count = group.length;

            return { ...screen, count };
        }).forEach((screen, index   ) => {
            /** @type {string} */
            const inch = getLabel(screen.inch);
            const type = capitalize(screen.type);
            const orientation = capitalize(screen.orientation);
            const name = `${screen.count} ${capitalize('écran')} ${type} ${inch} ${orientation} ${additionalLabel} `;

            const payment = screenOffer.payment;

            const tariff = getTariffFromScreenType(screen.type);

            /** @type {number} */
            const price = tariff.price[screen.type][screen.orientation][screen.inch] ?? 0;

            if (payment.type === PaymentType.FINANCEMENT) {
                addFinancingPrice(tariff.id, {
                    id: `${tariff.id}-${index}`,
                    name: name,
                    price: screen.count * price,
                    financingName: `Financement sur ${payment.financing.duration} mois`,
                    financingDuration: payment.financing.duration,
                    displayInOrder: true,
                    displayWhenParent: 'parent-offre-lbi-media-screen',
                    floor: true
                });
            } else {
                addInFinalPrices(tariff.id, {
                    id: `${tariff.id}-${index}`,
                    name: name,
                    price: screen.count * price,
                    displayWhenParent: 'parent-offre-lbi-media-screen'
                });
            }
        });
    }
}

const loadScreenOffer = (lbiMediaState) => {
    /** @type {ScreenOffer} */
    const screenOffer = get(lbiMediaState, 'screen', null);

    /** @type {string[]} */
    const offers = get(lbiMediaState, 'offre', []);

    if (screenOffer && offers.includes('screen')) {
        const items = screenOffer.items ?? [];
        const payment = screenOffer?.payment;

        const configurationQuantities = items.filter(screen => screen.configuration === true).length;
        const configurationTariff = getInTarifsState(TARIF_NAME_CONFIGURATION);
        resetFinalPrices(TARIF_NAME_CONFIGURATION);


        if (configurationQuantities > 0) {
            if (payment.type === PaymentType.FINANCEMENT) {
                addFinancingPrice(configurationTariff.id, {
                    id: configurationTariff.id,
                    name: `${configurationQuantities} ${getLabel(TARIF_NAME_CONFIGURATION)}`,
                    price: configurationQuantities * configurationTariff.price,
                    financingDuration: payment.financing.duration,
                    displayWhenParent: 'parent-offre-lbi-media-screen'
                });
            } else {
                addInFinalPrices(TARIF_NAME_CONFIGURATION, {
                    id: configurationTariff.id,
                    name: `${configurationQuantities} ${getLabel(TARIF_NAME_CONFIGURATION)}`,
                    price: configurationQuantities * configurationTariff.price,
                    displayWhenParent: 'parent-offre-lbi-media-screen'
                });
            }
        }
       

        const cplQuantities = items.filter(screen => screen.cpl === true).length;
        const cplTariff = getInTarifsState(TARIF_NAME_CPL);
        resetFinalPrices(TARIF_NAME_CPL);

        if (cplQuantities > 0) {
            if ((payment.type ===  PaymentType.FINANCEMENT)) {
                addFinancingPrice(cplTariff.id, {
                    id: cplTariff.id,
                    name: `${cplQuantities} ${getLabel(TARIF_NAME_CPL)}`,
                    price: cplQuantities * cplTariff.price,
                    financingDuration: payment.financing.duration,
                    displayWhenParent: 'parent-offre-lbi-media-screen'
                })
            } else {
                addInFinalPrices(cplTariff.id, {
                    id: cplTariff.id,
                    name: `${cplQuantities} ${getLabel(TARIF_NAME_CPL)}`,
                    price: cplQuantities * cplTariff.price,
                    displayWhenParent: 'parent-offre-lbi-media-screen'
                });
            }
        }
      

        const screenQuantities = items.length;
        const gettingStartedTariff = getInTarifsState(TARIF_NAME_GETTING_STARTED);
        resetFinalPrices(TARIF_NAME_GETTING_STARTED);

        if (screenQuantities > 0) {
            if (payment.type ===  PaymentType.FINANCEMENT) {
                addFinancingPrice(gettingStartedTariff.id, {
                    id: gettingStartedTariff.id,
                    name: `${screenQuantities} ${getLabel(TARIF_NAME_GETTING_STARTED)}`,
                    price: screenQuantities * gettingStartedTariff.price,
                    financingDuration: payment.financing.duration,
                    displayWhenParent: 'parent-offre-lbi-media-screen'
                });
            } else {
                addInFinalPrices(gettingStartedTariff.id, {
                    id: gettingStartedTariff.id,
                    name: `${screenQuantities} ${getLabel(TARIF_NAME_GETTING_STARTED)}`,
                    price: screenQuantities * gettingStartedTariff.price,
                    displayWhenParent: 'parent-offre-lbi-media-screen'
                });
            }    
        }
    
        const communityManagementTariff = getInTarifsState('lbi-media-community-management');
        const communityManagement = screenOffer['community-management'];
        resetFinalPrices('lbi-media-community-management');

        if (communityManagement) {
            addInFinalPrices(communityManagementTariff.id, {
                id: communityManagementTariff.id,
                name: getLabel('option-community-management'),
                price: communityManagementTariff.price,
                displayWhenParent: 'parent-offre-lbi-media-screen'
            });
        }

        loadDynamiqueCommunicationSoftwarePrice();
        loadScreensPrices(screenOffer);
    }
}

export default loadScreenOffer;
