const prices = {
    'poster-holder-size':  {
        'format-a4': 80,
        'format-a3': 100,
        'format-a2': 280,
        'round': 100,
        'square-diamond': 100,
        'others': 100
    },

    'rail': {
        'size-1m': 50,
        'size-1m5': 65,
    },

    'engraving': {
        'engraving': {
            'size-a4': 10,
            'size-a3': 10,
            'size-a2': 10,
        }
    },

    'blacklight-paper': {
        'ream-format-a4': 20,
        'ream-format-a3': 30,
    },
    
    'order-preparation-and-delivery': 50,
    'inter-rail-connection': 10,
    'cable-mounting-kit': 38,
    'power-cable': 20,
    '12v-power-supply': 55,
    'white-power-supply-cover': 20,

    "screen-orientation": {
        "portrait": {
            "size-a4": 0,
            "size-a3": 0,
            "size-a2": 0
        },
        "landscape": {
            "size-a4": 0,
            "size-a3": 0,
            "size-a2": 0,
        }
    },

    "edge-types": {
        "rounded": {
            "size-a2": 0,
            "size-a3": 0,
            "size-a4": 0,
        },
        "squares": {
            "size-a4": 0,
            "size-a3": 0,
            "size-a2": 0,
        }
    },

    "coloris": {
        "black": {
            "size-a4": 0,
            "size-a3": 0,
            "size-a2": 0
        },
        "grey": {
            "size-a4": 0,
            "size-a3": 0,
            "size-a2": 0,
        }
    },

    "number-of-columns": {
        1: 230,
        2: 250,
        3: 300,
        4: 350, 
        5: 400,
        6: 480,
        7: 520,
        8: 610,
        9: 660,
        10: 680,
        11: 720,
        12: 780,
        13: 810,
        14: 870,
        15: 900,
        16: 950,
        17: 1000,
        18: 1050,
        19: 1100,
        20: 1150
    }
}

export default prices;
