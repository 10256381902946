import React, { useCallback } from 'react';
import styled from 'styled-components';
import { grey } from '../Utils/styleConstants';
import fetchDistant from '../Utils/fetch';

const Container = styled.div`
    display: flex;
    justify-content: center;
    padding: 1em 0;
    background-color: ${grey};
    padding-bottom: 8em;
    margin-top: -8em;
`;

const MessageStructure = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1em;
    background-color: indianred;
    border-radius: 5px;
    width: 900px;
`;

const Message = styled.p`
    font-size: 15px;
    color: white;
`;

const Reset = styled.span`
    color: white;
    text-decoration: underline;
    cursor: pointer;
`;

const DeprecatedHeader = props => {
    // Callback called to reload page
    const reload = useCallback(() => {
        window.location.reload();
    }, []);

    // Callback to tarifs update
    const handleClickTarifsUpdate = useCallback(() => {
        let params = `id=${props.id}&lastTarifsRefId=${props.lastTarifsRefId}`;

        fetchDistant(`/tarifs/update?${params}`).then(reload);
    }, [props.id, props.lastTarifsRefId, reload]);

    // Callback to reset data
    const handleClickTarifsClear = useCallback(() => {
        const params = `id=${props.id}&lastTarifsRefId=${props.lastTarifsRefId}`;

        fetchDistant(`/tarifs/clear?${params}`).then(reload);
    }, [props.id, props.lastTarifsRefId, reload]);

    return (
        <Container>
            <MessageStructure>
                <Message>
                    Le référentiel des tarifs a été mis à jour : votre contrat a été généré avec des tarifs qui ne sont plus à jour.
                    Vous pouvez toujours naviguer dans votre contrat, cependant, il se peut que l'application ne réponde plus.
                    Dans ce cas, nous vous conseillons fortement de mettre à jour les tarifs de votre contrat.
                    <Reset onClick={() => handleClickTarifsUpdate()}>Cliquez ici pour mettre à jour les tarifs.</Reset>
                </Message>
                <Message>
                    Si vous rencontrez des problèmes avec le lien précédent, veuillez cliquer <Reset onClick={() => handleClickTarifsClear()}>Ici</Reset>.
                </Message>
            </MessageStructure>
        </Container>
    );
};

export default DeprecatedHeader;
