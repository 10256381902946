import React from 'react';
import styled from "styled-components";
import CheckableOption from './Option/CheckableOption';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Options = ({ options, ...props}) => {
    const { pricesDependOn, pathInDataState } = props;

    return (
        <Container>
            {options.map((option) =>
                <CheckableOption
                    dependsOn={pricesDependOn}
                    pathInDataState={pathInDataState}
                    name={option.name}
                    image={option.image}
                    label={option.label}
                    tarifsName={option.name}
                    counterLabel={option.counterLabel}
                    counterDependsOn={option.counterDependsOn}
                    full={option.full}
                    fullOptions={option.fullOptions}
                    setItselfFalse={option.setItselfFalse}
                    setInDataState={option.setInDataState}
                    default={option.default}
                    disabled={option.disabled}
                />
            )}
        </Container>
    );
}

export default Options;
