import React, {useCallback, useEffect, useState} from 'react';
import styled from 'styled-components';
import Toggle from '../Toggle';
import {UPDATE_DATA_VALUE} from '../../Store/actions';
import {getLabel} from '../../Utils/labels';
import {formatPrice} from '../../Utils/price';
import {deleteInDataState, getInDataState, setFinalPriceInTarifsState, setInDataState} from '../../Utils/storeHelpers';
import {getTarifsInfoByTarifsName, manualDependsOnTarifsInfo} from '../../Utils/jsonInterpreter';
import {
    FinalPriceOption,
    BasePriceOption,
    FinalPriceOptionSpecial,
    borderGrey
} from '../../Utils/styleConstants';
import Counter from "../Counter";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${props => props.width ?? `calc((100% - 90px - 15%) / ${props.total})`};
    padding: 2% 0;
    border-left: 1px solid ${borderGrey};
    ${props => props.borderBottom && `border-bottom: 1px solid ${borderGrey};`}
    ${props => props.borderRight && `border-right: 1px solid ${borderGrey};`}
`;

const Bold = styled.span`
    font-weight: bold;
`;

const ToggleStructure = styled.div`
    padding: 15px 0;
`;

const MinorOptionHektor = props => {
    const [counterValue, setCounterValue] = useState(getInDataState(props.pathInDataStateQuantity) ?? null);

    // Split label to deal with font style
    const splittedLabel = getLabel(props.name).split('*');
    const labelSup = getLabel(`${props.name}-$labelSup`);

    // Retrieve info about tarifs
    let tarifsInfo = getTarifsInfoByTarifsName(props.tarifsName);
    tarifsInfo = manualDependsOnTarifsInfo(tarifsInfo, props.dependsOn);

    // Callback used when user use the toggle
    const handleToggle = useCallback(() => {
        // Call the callback passed via props
        props.toggable && props.onToggle(props.index);

        if (props.pathInDataStateQuantity) {
            !props.toggle ?
                setInDataState(props.pathInDataStateQuantity, 1)
                : deleteInDataState(props.pathInDataStateQuantity);

            setCounterValue(1);
        }
    }, [props]);

    // useEffect whenever toogle hook changes
    useEffect(() => {
        // Reset final price of toggle is turned off
        if (!props.toggle) {
            setFinalPriceInTarifsState(props.name, null, undefined, props.partOfId);
        }
    }, [props.toggle, props.name, props.partOfId, props.pathInDataStateQuantity]);

    // Callback when user change counter
    const handleCounter = useCallback((value, event) => {
        event && event.stopPropagation();
        setCounterValue(value);
    }, []);

    useEffect(() => {
        if (null !== counterValue) {
            setInDataState(props.pathInDataStateQuantity, counterValue);
            setFinalPriceInTarifsState(props.tarifsName, null, undefined, props.dependsOn);
        }
    }, [counterValue, props]);

    return (
        <Container
            width={props.width}
            total={props.total}
            borderBottom={props.borderBottom}
            borderRight={props.borderRight}
        >
            {
                splittedLabel.length > 1 ? (
                    <p>
                        {splittedLabel[0]}
                        <Bold>{splittedLabel[1]}</Bold>
                        {splittedLabel[2]}
                    </p>
                ) : (
                    <p>
                        {splittedLabel[0]}
                    </p>
                )
            }
            {
                labelSup &&
                <p>
                    {labelSup}
                </p>
            }
            <ToggleStructure>
                <Toggle
                    action={UPDATE_DATA_VALUE}
                    pathInDataState={props.pathInDataState}
                    name={props.name}
                    value={props.toggle}
                    callback={handleToggle}
                />
            </ToggleStructure>
            {
                props.isIncludedIn ? (
                    <FinalPriceOptionSpecial>
                        INCLUS
                    </FinalPriceOptionSpecial>
                ) : (
                    isNaN(tarifsInfo.price) ? (
                        <FinalPriceOptionSpecial>
                            {tarifsInfo.price}
                        </FinalPriceOptionSpecial>
                    ) : (
                        <FinalPriceOption>
                            {`${formatPrice(tarifsInfo.price)} ${tarifsInfo.priceUnit.toUpperCase()}`}
                        </FinalPriceOption>
                    ))
            }
            {
                null !== tarifsInfo.insteadOf && !props.isIncludedIn && (
                    <BasePriceOption>
                        {
                            isNaN(tarifsInfo.insteadOf) && null !== tarifsInfo.insteadOf ?
                                tarifsInfo.insteadOf
                                : `au lieu de ${formatPrice(tarifsInfo.insteadOf)} ${tarifsInfo.priceUnit.toUpperCase()}`
                        }
                    </BasePriceOption>
                )
            }
            {
                props.pathInDataStateQuantity && props.toggle && (
                    <Counter
                        dontStandAlone
                        min={1}
                        pathInDataStateQuantity={props.pathInDataStateQuantity}
                        counterDependsOn={props.counterDependsOn}
                        margin='5px 0 0 0'
                        fontSize='18px'
                        masterId={props.pathInDataStateQuantity}
                        initialValue={getInDataState(props.pathInDataStateQuantity) ?? 1}
                        callback={handleCounter}
                        excludedOptionsFromCounter={props.excludedOptionsFromCounter}
                        name={props.name}
                    />
                )
            }
        </Container>
    );
};

export default MinorOptionHektor;
