import styled, { css } from 'styled-components';
import background from '../Data/background.svg';
import Select from 'react-select';

export const easeOutBack = 'cubic-bezier(0.175, 0.885, 0.320, 1.275)';
export const grey = '#5C5C5C';
export const mediumGrey = '#B1B1B1';
export const lightGrey = '#FAFAFA';
export const green = '#BED000';
export const borderGreen = '#99cc99';
export const borderRed = '#eba5a9';
export const darkGreen = '#005900';
export const darkRed = '#90161c';
export const borderGrey = '#E1E1E1';
export const orange = '#FFBB66';
export const darkOrange= '#F59A00';
export const borderRadius = '30px';
export const defaultBodyPadding = '1em';
export const defaultFooterPadding = 0;

export const socialNetworksData = [
    {
        name: 'facebook',
        color: '#1A76D2',
        icon: '/static/img/social/facebook.svg',
        checked: false
    },
    {
        name: 'twitter',
        color: '#55acee',
        icon: '/static/img/social/twitter.svg',
        checked: false
    },
    {
        name: 'linkedin',
        color: '#0084B1',
        icon: '/static/img/social/linkedin.svg',
        checked: false
    },
    {
        name: 'instagram',
        color: '#517fa4',
        icon: '/static/img/social/instagram.svg',
        checked: false
    },
    {
        name: 'youtube',
        color: '#CD2900',
        icon: '/static/img/social/youtube.svg',
        checked: false
    },
    {
        name: 'pinterest',
        color: '#cb2027',
        icon: '/static/img/social/pinterest.svg',
        checked: false
    }
];

export const WaitingContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
`;

export const Waiting = styled.p`
    text-align: center;
    font-size: 65px;
    font-weight: bold;
    padding: 0 0 30px;
`;

export const CenteredContainer = styled.section`
    margin: auto;
    width: 1170px;
`;

export const BackgroundContainer = styled.div`
    background: transparent url(${background}) no-repeat;
    background-position: top -131px right -165px;
    background-size: 500px 540px;
`;

export const Checkbox = styled.div`
    display: flex;
    height: ${props => props.size ? `${props.size}px` : '25px'};
    width: ${props => props.size ? `${props.size}px` : '25px'};
    border: 1px solid lightgray;
    border-radius: 4px;
    background-color: ${props => props.checked ? green : 'initial'};
    cursor: pointer;
    transition: .3s;

    :hover:not(.checked) {
        background-color: whitesmoke;
    }
`;

const checkboxCSS = css`
    position: relative;
    display: flex;
    margin: 1%;
    border: 1px solid ${props => props.checked ? green : borderGrey};
    border-radius: 3px;
    border-width: 1px;
    box-shadow: 0 0 0 3px ${props => props.checked ? green : 'transparent'};
    transition: all 0.5s ${easeOutBack};
`;

export const SimpleCheckbox = styled.div`
    ${checkboxCSS};
`;

export const StyledCheckbox = styled(BackgroundContainer)`
    ${checkboxCSS};
`;

export const ContainerOption = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    margin: 1%;
    ${props => props.checked ?
        `box-shadow: 0 0 0 3px ${green};`
        : `border: 1px solid ${borderGrey};`
    }
    border-radius: 4px;
    transition: all 0.5s ${easeOutBack};
`;

export const ImageOption = styled.img`
    width: ${props => props.little ? '60px' : '90px'};
    height: ${props => props.little ? '60px' : '90px'};
    padding: 10px;
`;

export const NameStructureOption = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    min-width: 15%;
    max-width: 60%;
    border-left: 1px solid ${borderGrey};
`;

export const NameOption = styled.p`
    padding: 0 0 0 10px;
`;

export const FinalPriceOption = styled.p`
    font-size: ${props => props.little ? '0.8em' : '0.9em'};
    color: ${mediumGrey};
    font-weight: bold;
`;

export const FinalPriceOptionSpecial = styled(FinalPriceOption)`
    color: ${green};
    text-transform: uppercase;
`;

export const BasePriceOption = styled.p`
    font-size: 0.8em;
    color: ${mediumGrey};
    font-weight: 400;
    font-style: italic;
`;

export const RecapChildTitle = styled.p`
    width: 100%;
    padding: 5px 0 5px 20px;
    background: ${lightGrey};
    color: ${grey};
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    letter-spacing: 1px;
`;

export const PriceStructure = styled.div`
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    &::after {
        order: -1;
        z-index: -1;
        content: '';
        margin: 0 30px;
        border-bottom: 1px dotted grey;
    }
`;

export const FakeInputContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 130px;
    padding: 10px 15px;
    background-color: #FFF;
    border-radius: 4px;
    border: 1px solid ${borderGrey};
`;

export const priceCSS = css`
    font-family: Roboto,sans-serif;
    font-weight: bold;
    color: ${grey};
`;

export const FakeInputPrice = styled.input`
    ${priceCSS};
    font-size: ${props => props.parent ? '20px' : '17px'};
    border: none;
    background: none;
    text-align: end;
    width: ${props => props.width};
    ${props => props.cursor && `cursor: ${props.cursor};`}
`;

export const PriceUnit = styled.p`
    ${priceCSS};
    font-size: 17px;
    cursor: default;
    padding: 0 0 0 5px;
`;

export const SubscriptionContainer = styled(BackgroundContainer)`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    border: 1px solid ${borderGrey};
`;

export const SubscriptionStructure = styled.div`
    & > :first-child > :first-child {
        line-height: 79px;
    }

    & > :nth-child(n) {
        padding: 2vh 0 0;
    }
`;

export const SubscriptionInsteadOf = styled.p`
    font-size: 20px;
    color: #B1B1B1;
    font-weight: lighter;
    font-style: italic;
`;

export const SubscriptionLogo = styled.img`
    width: 208px;
    height: 64px;
    object-fit: contain;
`;

export const SubscriptionOfferName = styled.p`
    font-size: 2em;
    font-weight: 500;
`;

export const SubscriptionAdditionalUser = styled(PriceStructure)`
    padding: 0.5vh 0 0;

    &::after {
        width: 16.5%;
    }
`;

export const SubscriptionCommissioning = styled(PriceStructure)`
    padding: 0.5vh 0 1vh;

    &::after {
        width: 25.5%;
    }
`;

export const GenericTextInfo = styled.p`
    text-align: center;
    font-weight: bold;
    font-style: italic;
    color: ${grey};
`;

export const SimpleInput = styled.input`
    padding: 0.5em;
    font-size: 1em;
    font-family: Roboto, sans-serif;
    color: initial;
    border: 1px solid ${props => props.error ? 'red' : borderGrey};
    border-radius: 4px;
`;

export const SimpleSelect = styled(Select)`
    div:first-child {
        border-color: ${borderGrey};
    }
`;

export const InputNdd = styled(SimpleInput)`
    flex-grow: 1;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
`;

export const SimpleAddButton = styled.button`
    background: rgb(92 92 92);
    color: white;
    font-size: 1em;
    font-family: Roboto, sans-serif;
    padding: 1em;
    border: none;
    border-radius: 5px;
    cursor: pointer;
`;
