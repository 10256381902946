import { connect } from 'react-redux'
import PriceElement from '../Components/PriceElement'

const getValue = tarifs => name => {
    if (tarifs[name] !== undefined) {
        return tarifs[name]
    }
    return 0
}

export const mapStateToProps = (state, ownProps) => ({
    value: getValue(state.tarifs)(ownProps.name)
})

export default connect(
    mapStateToProps
)(PriceElement)
