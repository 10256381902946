import React from 'react';
import styled from 'styled-components';
import Group from './Group';
import { CenteredContainer } from '../../Utils/styleConstants';
import { getInGenericState } from '../../Utils/storeHelpers';

const StyledCenteredContainer = styled(CenteredContainer)`
    position: relative;
    margin-top: -105px;
`;

const Page = props => {
    // Use of redux
    const pagesInfo = getInGenericState('document.pages');

    return (
        <StyledCenteredContainer>
            {
                pagesInfo && pagesInfo[props.currentPageState].groups.map((group, index) => (
                    <Group
                        key={index}
                        {...group}
                    />
                ))
            }
        </StyledCenteredContainer>
    );
};

export default Page;
