import React from 'react';
import styled from 'styled-components';
import { BackgroundContainer, easeOutBack, borderGrey, green, grey } from '../Utils/styleConstants';
import Checkmark from './Icons/Checkmark';
import Builder from '../Utils/builder';
import PriceContainer from '../Containers/PriceContainer';
import PriceLine from './PriceLine';
import LightboxButton from './LightboxButton';
import getLabel from '../Utils/labels';
import Lightbox from 'react-18-image-lightbox';
import PriceElement from './PriceElement';
import { getImage } from '../Utils/jsonInterpreter';

const StyledCheckbox = styled(BackgroundContainer)`
    position: relative;
    display: flex;
    flex-direction: ${props => props.beautiful ? 'column' : 'row'};
    justify-content: space-between;
    align-items: flex-start;
    width: ${props => props.beautiful ? 'initial' : '100%'};
    height: ${props => props.beautiful ? 'initial' : '100%'};
    min-height: ${props => props.checkType === 'lang' ? '90px' : '240px'};
    margin: 0 0 20px;
    padding: ${props => props.beautiful ? '15px 15px 10px 15px' : '0 0 10px 0'};
    border: 1px solid ${props => props.checked ? green : borderGrey};
    border-radius: 3px;
    border-width: 1px;
    ${props => props.checkType === 'lang' && 'background: initial;'}
    box-shadow: 0 0 0 3px ${props => props.checked ? green : 'transparent'};
    transition: all 0.5s ${easeOutBack};
`;

const notBottomCheck = ['listing, detail'];

const CheckmarkContainer = styled.div`
    opacity: ${props => props.checked ? 1 : 0};
    transform: translateY(${props => props.checked ? 0 : '40px'});
    position: absolute;
    width: 30px;
    height: 30px;
    background: ${green};
    border-radius: 50%;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: ${props => notBottomCheck.includes(props.checkType) ? '0' : 'auto'} ${props => notBottomCheck.includes(props.checkType) ? '0' : 'auto'} ${props => notBottomCheck.includes(props.checkType) ? 'auto' : '-17px'} ${props => notBottomCheck.includes(props.checkType) ? 'auto' : '50%'};
    margin-left: ${props => notBottomCheck.includes(props.checkType) ? 'auto' : '-15px'};
    margin-right: ${props => notBottomCheck.includes(props.checkType) ? '-15px' : 'auto'};
    margin-top: ${props => notBottomCheck.includes(props.checkType) ? '-15px' : 'auto'};
    transition: opacity 0.2s ease-in, transform 0.5s ${easeOutBack};
`;

const FullImgContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & > img {
        max-width: 100%;
        max-height: 100%;
        padding: 25px;
    }
`;

const ImgContainer = styled.div`
    height: 90px;
    width: 90px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 30px;
    margin-top: 50px;
    position: absolute;
    right: 0;
    top: 38px;
    ${props => props.contain ?
        `
        background: white;
        & img {
            max-width: 70%;
            max-height: 70%;
        }
        ` : `
            & img {
                width: 100%;
            }
        `
    }
`;

const Texts = styled.div`
    flex: 0 0 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: ${grey};
    padding: 30px 15px 15px;
    width: 50%;
`;

const TextsPriced = styled(Texts)`
    justify-content: space-between;
`;

const TextsLarge = styled(Texts)`
    flex: 1;
    width: 100%;
`;

const Subtitle = styled.p`
font-size: ${props => props.fontSize ?? '0.8em'};
    width: ${props => props.labelWidth ?? 'initial'};
`;

const Title = styled.p`
    font-size: ${props => props.fontSize ?? '1.5em'};
    ${props => props.labelBold && 'font-weight: bold;'}
    width: ${props => props.labelWidth ?? 'initial'};
`;

const Text = styled.p`
    padding: 0 15px;
`;

const TitleGross = styled.p`
    text-align: center;
    margin: 10px;
`;

const FieldContainer = styled.div`
    padding: 0 15px;
`;

const FullHeight = styled.div`
    height: ${props => props.gross ? 'auto' : '100%'};
    flex: 1;
`;

const LinkContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const GrossContainer = styled.div`
    width: ${props => props.forceSize ? '100%' : '547px'};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    & > img {
        object-fit: cover;
    }
`;

const ListingContainer = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;

const DetailContainer = styled(ListingContainer)`
    flex-direction: column;
    background: white;
    height: auto;
`;

const ExempleBand = styled.div`
    background: ${green};
    color: white;
    text-transform: uppercase;
    position: absolute;
    text-align: center;
    top: 0;
    left: 0;
    padding: 0.5em 3em;
    transform: rotate(-45deg) translate3d(-28%, -50%, 0);
`;

const ListingImageContainer = styled.div`
    flex: 0 0 65%;
    border-right: 1px solid ${borderGrey};
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const DetailImageContainer = styled.div`
    flex: 0 0 100%;
    border-bottom: 1px solid ${borderGrey};
    width: 100%;
    height: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RightTitle = styled.div`
    font-size: 2em;
`;
const RightContainer = styled.div`
    background: #fff;
    flex: 1;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: ${grey};
`;

const BottomContainer = styled(RightContainer)`
    flex-direction: row;
    padding: 40px 40px 20px;
`;

const RightSubTitle = styled.div`
    margin: 20px 0;
    color: ${green};
    font-size: 1.2em;
`;

const Imgh = styled.img`
    width: 100%;
    max-width: 100%;
`;

const LangContainer = styled.div`
    flex: 1;
    padding: 0 15px;
`;

const LangText = styled.div`
    text-align: center;
`;

const NakedContainer = styled(ListingContainer)`
    flex-direction: column;
    justify-content: ${props => props.verticalCenter ? 'center' : 'space-around'};
    align-items: center;
`;

const PriceLineContainer = styled.div`
    color: ${grey};
    font-size: ${props => props.fontSize ?? '0.6em'};
    font-weight: bolder;
    justify-self: flex-end;
    text-transform: uppercase;
`;

const GrossPriceContainer = styled.div`
    margin: 2em;
`;

class Link extends React.Component {
    constructor(props) {
        super(props);
        this.clickLink = this.clickLink.bind(this);
        this.state = {
            isOpen: false,
        };
    }

    clickLink() {
        this.props.forceLink !== undefined ?
            window.open(this.props.forceLink, '_blank')
            : this.setState({ isOpen: true });
    }

    render() {
        const { isOpen } = this.state;
        const { to } = this.props;

        return (
            <>
                <LightboxButton callbackOnClick={this.clickLink} />
                {
                    isOpen && (
                        <Lightbox
                            mainSrc={to}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                        />
                    )
                }
            </>
        );
    }
};

const getChild = (checkType) => (props) => {
    switch (checkType) {
        case 'image':
            return getImageComposant(props);
        case 'image-label':
            return getImageLabel(props);
        case 'image-label-priced':
            return getImageLabelPriced(props);
        case 'beautiful-image-label-priced':
            return getBeautifulImageLabelPriced(props);
        case 'text':
            return getText(props);
        case 'container':
            return getContainer(props);
        case 'container-naked':
            return getContainerNaked(props);
        case 'gross-image':
            return getGrossImage(props);
        case 'listing':
            return getListing(props);
        case 'detail':
            return getDetail(props);
        case 'lang':
            return getLang(props);
        case 'beautiful':
            return getBeautiful(props);
        default:
            return null;
    }
};

const getLang = ({ value, name }) => (
    <LangContainer>
        <Imgh
            src={`https://www.countryflags.io/${value}/flat/64.png`}
            alt={value}
        />
        <LangText>{getLabel({ name })}</LangText>
    </LangContainer>
);

const getDetail = ({ src, label }) => (
    <DetailContainer>
        <DetailImageContainer>
            <Imgh src={getImage(src)} alt='' />
        </DetailImageContainer>
        <BottomContainer>
            <RightTitle>{label}</RightTitle>
            <Link to={getImage(src)} />
        </BottomContainer>
    </DetailContainer>
);

const getListing = ({ src, label, desc }) => (
    <ListingContainer>
        <ExempleBand>Exemple</ExempleBand>
        <ListingImageContainer>
            <Imgh src={getImage(src)} alt='' />
        </ListingImageContainer>
        <RightContainer>
            <div>
                <RightTitle>{label}</RightTitle>
                {
                    desc && (
                        <>
                            <RightSubTitle>Elements distinctifs :</RightSubTitle>
                            <div>{desc}</div>
                        </>
                    )
                }
            </div>
            <Link to={getImage(src)} />
        </RightContainer>
    </ListingContainer>
);

const getGrossImage = (props) => (
    <GrossContainer {...props}>
        <div>
            <Imgh src={getImage(props.src)} alt='' />
        </div>
        {
            props.pricingName && (
                <GrossPriceContainer>
                    <PriceElement tarifsName={props.pricingName} />
                </GrossPriceContainer>
            )
        }
    </GrossContainer>
);

const getContainer = ({ fields, label, masterId, sublabel }) => (
    <div>
        <TextsLarge>
            {sublabel && (<Subtitle>{sublabel}</Subtitle>)}
            <Title>{label}</Title>
        </TextsLarge>
        <FieldContainer>
            {fields && fields.map(field => Builder.create(masterId, field))}
        </FieldContainer>
    </div>
);

const getContainerNaked = ({fields, masterId, band, tarifsName, verticalCenter, fontSize }) => (
    <NakedContainer verticalCenter={verticalCenter}>
        {band && (<ExempleBand>{band}</ExempleBand>)}
        <FieldContainer>
            {fields.map(field => Builder.create(masterId, field))}
        </FieldContainer>
        {
            tarifsName && (
                <PriceLineContainer fontSize={fontSize}>
                    <PriceLine tarifsName={tarifsName} />
                </PriceLineContainer>
            )
        }
    </NakedContainer>
);

const getText = ({ text, label }) => (
    <div>
        <TextsLarge>
            <Title>{label}</Title>
        </TextsLarge>
        <Text>{text}</Text>
    </div>
);

const getImageLabel = ({ sublabel, label, img, contain }) => (
    <div>
        <Texts>
            <Subtitle>{sublabel}</Subtitle>
            <Title>{label}</Title>
        </Texts>
        <ImgContainer contain={contain}>
            <img src={getImage(img)} alt='' />
        </ImgContainer>
    </div>
);

const getImageComposant = ({ img }) => (
    <FullImgContainer>
        <img src={getImage(img)} alt='' />
    </FullImgContainer>
);

const Checkbox = ({ checked, checkType, children, beautiful, ...props }) => (
    <FullHeight>
        {children}
        <StyledCheckbox
            checked={checked}
            checkType={checkType}
            beautiful={beautiful}
            {...props}
        >
            {getChild(checkType)(props)}
            <CheckmarkContainer checked={checked} checkType={checkType}>
                <Checkmark />
            </CheckmarkContainer>
        </StyledCheckbox>
    </FullHeight>
);

const getImageLabelPriced = ({ sublabel, label, img, pricingName }) => (
    <FullHeight>
        <TextsPriced>
            <div>
                <Subtitle>{sublabel}</Subtitle>
                <Title>{label}</Title>
            </div>
            <PriceContainer name={pricingName} />
        </TextsPriced>
        <ImgContainer>
            <img src={getImage(img)} alt='' />
        </ImgContainer>
    </FullHeight>
);

const getBeautifulImageLabelPriced = ({ sublabel, label, img, pricingName }) => {
    const splittedLabel = label.split(' ');
    const subTitleSize = '1.2em';
    const titleSize = '2em';

    return (
        <>
            <TextsPriced>
                <div>
                    <Subtitle fontSize={subTitleSize}>
                        {sublabel}
                    </Subtitle>
                    <Title fontSize={titleSize}>
                        {splittedLabel[0]}
                    </Title>
                    <Title labelBold fontSize={titleSize}>
                        {splittedLabel[1]}
                    </Title>
                </div>
                <PriceContainer name={pricingName} />
            </TextsPriced>
            <ImgContainer>
                <img src={getImage(img)} alt='' />
            </ImgContainer>
        </>
    );
};

const getBeautiful = ({ sublabel, label, pricingName, fields, masterId, ...props }) => {
    return (
        <>
            {sublabel && <Subtitle {...props}>{sublabel}</Subtitle>}
            {label && <Title {...props}>{label}</Title>}
            {fields && fields.map(field => Builder.create(masterId, field))}
            <PriceElement tarifsName={pricingName} />
        </>
    );
};

const CheckElement = props => {
    return (
        <FullHeight gross={props.checkType === 'gross-image'}>
            {
                props.checkType === 'gross-image' && (
                    <TitleGross>{getLabel(props)}</TitleGross>
                )
            }
            <label> 
                <Checkbox
                    checked={props.isChecked}
                    {...props}
                />
            </label>
            {
                props.checkType === 'gross-image' && (
                    <LinkContainer>
                        <Link
                            to={getImage(props.src)}
                            forceLink={props.forceLink}
                        />
                    </LinkContainer>
                )
            }
        </FullHeight>
    );
};

export default CheckElement;
