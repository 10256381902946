import {useContext} from "react";
import {CheckboxGroupContext} from "../CheckboxGroupContext";

const useCheckBox = (props) => {
    const context = useContext(CheckboxGroupContext);

    const {
        isSelected,
        value,
        onChange,
        isDisabled
    } = props;

    const isInContext = !!context;

    if (isInContext && isSelected) {
        console.warn('Checkbox is selected but is in a CheckboxGroup. This is not allowed. Please use the CheckboxGroup\'s value prop to set the selected value.')
    }

    if (!isInContext && value) {
        console.warn('Checkbox is selected but is not in a CheckboxGroup. This is not allowed. Please use the CheckboxGroup\'s value prop to set the selected value.')
    }

    const isSelectedProp = isInContext ? context?.groupState.includes(value): isSelected;
    const onValueChange = isInContext ? context.onChange : onChange;

    const handleValueChange = () => {
        if (isInContext) {
            onValueChange(value, !isSelectedProp);
        } else {
            onValueChange(!isSelectedProp);
        }
    }

    return { isSelectedProp, handleValueChange };
}

export default useCheckBox;

