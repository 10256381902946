import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDataState } from '../Store/selectors';
import styled from 'styled-components';
import { getInDataState } from '../Utils/storeHelpers';
import { green } from '../Utils/styleConstants';
import {getLabel} from "../Utils/labels";

const Container = styled.div`
    display: flex;
`;

const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => `calc(100% / ${props.nbrOfColumns})`};
    padding: 10px 2em;

    &:not(:last-child) {
        border-right: 2px solid ${green};
    }
`;

const Title = styled.p`
    color: ${green};
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 2px;
    margin: 0 0 10px;
`;

const Line = styled.p`
    font-size: 16px;

    &:not(:last-child) {
        padding: 0 0 5px;
    }
`;

const Data = styled.span`
    font-weight: bold;
`;

const Memory = props => {
    // Use of hooks
    const [nbrOfColumns, setNbrOfColumns] = useState(null);

    // Use of redux
    const dataState = useSelector(selectDataState);

    // Callback used to generate lines
    const getSectionLines = useCallback(section => {
        const sectionLines = section.lines.map((line, index) => {
            // Do not need to display line if corresponding product is not selected
            let lineToDisplay = false;
            lineToDisplay = line.hasOwnProperty('product')
                ? getInDataState('produits').includes(line.product)
                : true;

            // Retrieve data of line from paths
            const lineData = line.paths
                .flatMap(path => getInDataState(path))
                .map(value => {
                    const label = getLabel(value);
                    return label !== '' ? label : value;
                })
                .filter(label => label)
                .join(line.join ?? "");

            // Return the line as a component
            return 0 < lineData.length && lineToDisplay ? (
                <Line key={index}>
                    {`${line.label} : `}<Data>{lineData}</Data>
                </Line>
            ) : null;
        });

        return sectionLines.filter(sectionLine => sectionLine);
    }, []);

    // Callback used to get number of columns
    const getNbrOfColumns = useCallback(sections => {
        let counter = 0;

        sections.map(section => 0 < getSectionLines(section).length && counter++);

        return counter;
    }, [getSectionLines]);

    // useEffect when component is mounting
    useEffect(() => {
        null === nbrOfColumns && setNbrOfColumns(getNbrOfColumns(props.sections));
    }, [props.sections, nbrOfColumns, getNbrOfColumns])

    // useEffect whenever dataState changes
    useEffect(() => {
        setNbrOfColumns(getNbrOfColumns(props.sections));
    }, [props.sections, dataState, getNbrOfColumns])

    return (
        dataState && null !== nbrOfColumns && (
            <Container>
                {
                    props.sections.map((section, index) => {
                        const sectionLines = getSectionLines(section);

                        return 0 < sectionLines.length ? (
                            <Section
                                    key={index}
                                    nbrOfColumns={nbrOfColumns}
                                >
                                    <Title>{section.title}</Title>
                                    {sectionLines.map(sectionLine => sectionLine)}
                            </Section>
                        ) : null
                    })
                }
            </Container>
        )
    );
};

export default Memory;
