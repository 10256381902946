import React from "react";
import styled from "styled-components";
import {borderGrey, SimpleCheckbox} from "../../../Utils/styleConstants";
import CheckmarkContainer from "../../Icons/CheckmarkContainer";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%; 
    height: 100%;
`

const OptionsContainer = styled.div`
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    height: 90%;
    padding: 0 15%;
    gap: 10%;
`;

const OptionContainer = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
    width: 45%;
`

const CheckableContainer = styled(SimpleCheckbox)`
    border: 1px solid ${borderGrey};
    border-radius: 3px;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 10px;
`

const TitleBold = styled.p`
    font-size: 30px;
    font-weight: bold;
`;

/**
 * @typedef {Object} OptionProps
 * @property {() => void} onClick
 * @property {boolean} checked
 * @property {string} label
 */

/**
 *
 * @param {OptionProps} props
 * @return {JSX.Element}
 */
const Option = ({ onClick, checked = false, label }) => {
    return (
        <CheckableContainer onClick={onClick} checked={checked}>
            <TitleBold>{label}</TitleBold>
            <CheckmarkContainer checked={checked}/>
        </CheckableContainer>
    )
}

/**
 * @typedef {Object} GridOptionsProps
 * @property {(value:string, index:number) => void} onClick
 * @property {Object[]} options
 * @property {string|null} defaultSelected
 */

/**
 * @param {GridOptionsProps} props
 * @return {JSX.Element}
 */
const GridOptions = ({ onClick, options, selected = null}) => {
    return (
        <Container>
            <p style={{ fontSize: "13px", height: "10%" }}>Taille de l'écran : </p>
            <OptionsContainer>
                {options.map((option, index) => (
                    <OptionContainer key={index}>
                        <Option checked={option.value === selected} onClick={() => onClick?.(option.value, index)} label={option.label}/>
                    </OptionContainer>
                ))}
            </OptionsContainer>
        </Container>
    )
}

const inchOptions = [
    {
        value: 'inch-46',
        label: '46"'
    },
    {
        value: 'inch-55',
        label: '55"'
    },
    {
        value: 'inch-75',
        label: '75"'
    },
];

const inchOptionForPortraitOrientation = {
    value: 'inch-duo-55',
    label: 'Duo 55"'
}

/**
 * @typedef {Object} ScreenInchProps
 * @property {(value:string, index:number) => void} onClick
 * @property {string|null} defaultSelected
 * @property {string} orientation
 */

/**
 *
 * @param {ScreenInchProps & {type: string}} props
 * @return {JSX.Element}
 */
const ScreenInch = (props) => {
    const { type, orientation, ...rest } = props;
    let options = inchOptions;

    if (orientation === 'portrait' && type === 'prestige') {
        options = [...options, inchOptionForPortraitOrientation];
    }

    return (
        <GridOptions
            {...rest}
            options={options}
        />
    )
}

export default ScreenInch;
