import ProductInformation from "./Led/ProductInformation";
import CheckmarkContainer from "../Icons/CheckmarkContainer";
import styled from "styled-components";
import {SimpleCheckbox} from "../../Utils/styleConstants";
import Counter from "./Counter";
import Price from "./Price";

const CheckableContainer = styled(SimpleCheckbox)`
    display: flex;
    max-height: 300px;
    min-height: 100px;
`;

const InformationContainer = styled.div`
    flex: 0 0 15%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const SubTitle = styled.div`
    flex: 0 0 30%;
    display: flex;
    align-items: center;
    border-left: 1px solid #E1E1E1;
`;

const SubTitleText = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
`;

const CounterContainer = styled.div`
    flex: 1 0 35%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-left: 1px solid #E1E1E1;
`;

const PriceContainer = styled.div`
    flex: 1 0 15%;
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-end;
    padding-right: 10px;
    padding-bottom: 10px;
`;

const CounterOption = ({ title, imageSrc, price = null, counter = 0, setCounter }) => {
    const isOptionChecked = counter > 0;

    return (
        <CheckableContainer checked={isOptionChecked}>
            <InformationContainer>
                <ProductInformation imageSrc={imageSrc}/>
            </InformationContainer>
            <SubTitle>
                <SubTitleText>{title}</SubTitleText>
            </SubTitle>
            <CounterContainer>
                <Counter initialValue={counter} onCountChange={setCounter} />
            </CounterContainer>
            {price &&
                <PriceContainer>
                    <Price value={price} />
                </PriceContainer>
            }
            <CheckmarkContainer checked={isOptionChecked} notBottomCheckmark={true}/>
        </CheckableContainer>
    )
}

export default CounterOption;
