import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import Lightbox from 'react-18-image-lightbox';
import LightboxButton from '../LightboxButton';
import PriceElement from '../PriceElement';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import Counter from '../Counter';
import { borderGrey, green, StyledCheckbox } from '../../Utils/styleConstants';
import { getImage } from '../../Utils/jsonInterpreter';
import { beautifulGetLabel } from '../../Utils/labels';
import { getInDataState, setInDataState } from '../../Utils/storeHelpers';

const Image = styled.img`
    width: 70%;
    object-fit: contain;
`;

const ContentStructure = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 70%;
    padding: 2em;
    border-left: 1px solid ${borderGrey};
`;

const Content = styled.div`
    width: 100%;
`;

const Label = styled.p`
    font-weight: bold;
    font-size: 30px;
    padding: 0 0 10px;
`;

const FocusElements = styled.p`
    color: ${green};
    font-size: 20px;
    padding: 0 0 15px;
`;

const Description = styled.p`
    padding: 0 0 2em;
`;

const PriceElementStructure = styled.div`
    padding: 0 0 2em;
`;

const CheckableExtendableHorizontal = props => {
    // Use of hooks
    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [display, setDisplay] = useState(null);
    const [counterValue, setCounterValue] = useState(null);

    // useEffect when component is mounting
    useEffect(() => {
        if (props.option.displayIf) {
            const conditions = props.option.displayIf.conditions
            const correctValue = getInDataState(props.option.displayIf.path)
            const mode = props.option.displayIf.mode
            let isDisplay = mode === 'or' ? false : true;

            conditions.forEach(value => {
                if (mode === 'or') {
                    isDisplay = correctValue === value ? true : isDisplay;
                } else {
                    isDisplay = correctValue !== value ? false : isDisplay;
                }
            })
            setDisplay(isDisplay);
        } else {
            setDisplay(true);
        }
        if(null === counterValue){
            if (props.option.pathInDataStateQuantity && props.checked && undefined === getInDataState(props.option.pathInDataStateQuantity)) {
                setInDataState(props.option.pathInDataStateQuantity, 1);
            }
        }
    }, [props,counterValue])

     // Callback if user want to extend
     const handleClickExtend = useCallback(() => {
        props.option.mp4 ?
            window.open(props.option.mp4, '_blank')
            : setLightboxOpen(true);
    }, [props.option.mp4])

    // Callback when user change cro counter
    const handleCounter = useCallback((value, event) => {
        event.stopPropagation();
        setCounterValue(value);
    }, [])

    useEffect(() => {
        null !== counterValue &&
            setInDataState(props.option.pathInDataStateQuantity, counterValue);
    }, [counterValue, props.option.pathInDataStateQuantity])

    return (
        display && (
            <>
                {
                    lightboxOpen && (
                        <Lightbox
                            mainSrc={getImage(props.option.img)}
                            onCloseRequest={() => setLightboxOpen(false)}
                        />
                    )
                }
                <StyledCheckbox
                    checked={props.checked}
                    onClick={() => props.onClick(props.index, props.option)}
                >
                    <Image src={getImage(props.option.img)} alt='#' />
                    <ContentStructure>
                        <Content>
                            <Label>{beautifulGetLabel(props.option.label)}</Label>
                            {
                                props.option.description && (
                                    <>
                                        <FocusElements>Éléments distinctif :</FocusElements>
                                        <Description>{props.option.description}</Description>
                                    </>
                                )
                            }
                            {
                                props.option.tarifsName && (
                                    <>
                                        <PriceElementStructure>
                                            <PriceElement tarifsName={props.option.tarifsName} />
                                        </PriceElementStructure>
                                        {
                                            props.option.pathInDataStateQuantity && props.checked && (
                                                <Counter
                                                    dontStandAlone
                                                    min={1}
                                                    margin='auto'
                                                    fontSize='18px'
                                                    masterId={props.option.pathInDataStateQuantity}
                                                    initialValue={getInDataState(props.option.pathInDataStateQuantity) ?? 1}
                                                    callback={handleCounter}
                                                />
                                            )
                                        }
                                    </>
                                )
                                
                            }
                        </Content>
                        <LightboxButton callbackOnClick={() => handleClickExtend()} />
                    </ContentStructure>
                    <CheckmarkContainer checked={props.checked} />
                </StyledCheckbox>
            </>
        )
    );
};

export default CheckableExtendableHorizontal;
