import Counter from "../LbiMedia/Counter";
import useCheckbox from "./hooks/useCheckbox";
import Switch from "../Switch/Switch";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    gap: 15px;
`

const CheckboxCounter = ({ counterProps, checkboxProps }) => {
    const { isSelectedProp, handleValueChange } = useCheckbox(checkboxProps);

    return (
        <Container>
            <Switch checked={isSelectedProp} onChange={handleValueChange} />
            {isSelectedProp &&
                <Counter {...counterProps} />
            }
        </Container>
    )
}

export default CheckboxCounter;
