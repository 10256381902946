import React from 'react'
import styled from 'styled-components'
import { BackgroundContainer, borderGrey, grey } from '../Utils/styleConstants'
import Labeled from './Labeled'
import CounterContainer from '../Containers/CounterContainer'
import illustration from '../Data/illustration-abonnement.png'
import logoHektor from '../Images/logo-hektor.png'
import PriceContainer from '../Containers/PriceContainer'

const Container = styled(BackgroundContainer)`
    border: 1px solid ${borderGrey};
    display: flex;
    justify-content: flex-start;
    color: ${grey};
`

const ContentContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

const Content = styled.div`
    display: flex;

    & > * {
        margin: auto 10px;
    }
`

const BigPrice = styled.div`
    font-size: 2em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 15px;
`

const Mention = styled.div`
    font-size: 0.5em;
`

const ImgContainer = styled.div`
    padding: 0 15px;
`

const Abonnement = (props) => (
    <Container>
        <div><img src={illustration} alt='' /></div>
        <ContentContainer>
            <ImgContainer><img src={logoHektor} alt='logo hektor' /></ImgContainer>
            <BigPrice>
                <PriceContainer name={'abonnement'} />
                <Mention>pour 6 utilisateurs</Mention>
            </BigPrice>
            <div>
                <Labeled fitContent={true} name={'user-sup'}>
                    <Content>
                        <CounterContainer {...props} />
                        <PriceContainer name={'abonnement-sup'} />
                    </Content>
                </Labeled>
            </div>
        </ContentContainer>
    </Container>
);

export default Abonnement;
