import { connect } from 'react-redux'
import Menu from '../Components/Menu'
import { mapStateToPropsAddable } from '../Utils/mapToProps'

const mapStateToProps = (state, ownProps) => ({
    ...mapStateToPropsAddable(state, ownProps),
    gridElements: ['label', 'blank', 'raison', 'enseigne', 'adresse', 'cp', 'ville', 'pays', 'email', 'site-web', 'telephone'],
    spanElements: [],
    add: 'une autre agence',
    refs: id => ({
        label: {
            name: 'label',
            type: 'LabelAdd',
            bottomer: true,
            addOrder: 1,
            label: 'agence-order'
        },
        blank: {
            type: 'Blank',
            name: 'blank'
        },
        raison: {
            type: 'InputTextContainer',
            name: `${id}/raison`,
            label: 'raison',
            required: true
        },
        enseigne: {
            type: 'InputTextContainer',
            name: `${id}/enseigne`,
            label: 'enseigne',
            required: true
        },
        adresse: {
            type: 'InputTextContainer',
            name: `${id}/adresse`,
            label: 'adresse'
        },
        cp: {
            type: 'InputTextContainer',
            name: `${id}/cp`,
            label: 'cp',
            required: true
        },
        ville: {
            type: 'InputTextContainer',
            name: `${id}/ville`,
            label: 'ville',
            required: true
        },
        pays: {
            type: 'SelectContainer',
            name: `${id}/pays`,
            label: 'pays',
            labelWith: 'name',
            options: [
                'france',
                'belgique',
                'suisse',
                'luxembourg',
                'allemagne',
                'espagne',
                'portugal',
                'royaume-uni',
                'finlande'
            ]
        },
        email: {
            type: 'InputTextContainer',
            name: `${id}/email`,
            label: 'email',
            required: true
        },
        'site-web': {
            type: 'InputTextContainer',
            name: `${id}/site-web`,
            label: 'site-web',
            innerType: 'url'
        },
        telephone: {
            type: 'Addable',
            name: `${id}/telephone`,
            label: "telephone",
            addcomponent: {
                type: 'Combo',
                required: true,
                combo: ['select', 'input'],
                select: {
                    name: `type`,
                    options: ['tel','fixe','fax'],
                    size: 20
                },
                input: {
                    name: 'numero',
                    size: 80
                }
            }
        }
        
    })
})

export default connect(
    mapStateToProps
)(Menu)
