import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectDataState } from '../Store/selectors';
import PriceAdwordsEditor from './PriceAdwordsEditor';
import Builder from '../Utils/builder';
import { getInDataState, getInTarifsState } from '../Utils/storeHelpers';
import { getImage } from '../Utils/jsonInterpreter';
import { borderGrey } from '../Utils/styleConstants';

const Container = styled.div`
    display: flex;
    border: 1px solid ${borderGrey};
    justify-content: center;
    align-items: stretch;
`;

const Left = styled.div`
    border-right: 1px solid ${borderGrey};
    background: url(${getImage('/static/img/ref-nat/result.svg')}) no-repeat center center;
    background-origin: content-box;
    padding: 2.5em; 
    width: 343px;
    height: 217px;
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 3em 3em 0;
`;

const El = styled.div`
    margin: 0.5em 1em;
`;

const Adwords = props => {
    // Use of hooks
    const [formula, setFormula] = useState(undefined);
    const [reset, setReset] = useState(null);

    // Use of redux
    const dataState = useSelector(selectDataState);

    useEffect(() => {
        setReset(true);
    }, [dataState, props.masterId, props.name])

    useEffect(() => {
        if(reset){
            let data = getInDataState(`${props.masterId}.${props.name}`);
            if(data && 'adwords-noFormule' !== data) {
                setFormula(getInTarifsState(`${props.masterId}.price.${data}`));
            } else {
                setFormula(undefined);
            }
            setReset(false);
        }
    }, [reset, formula, props])
    return (
        <Container>
            <Left></Left>
            <Right>
                <El>Référencement commercial</El>
                {Builder.create(props.masterId, props.select)}
                {
                    undefined !== formula && !reset && (
                        <PriceAdwordsEditor
                            tarifsName={props.tarifsName}
                            formulaMinMax={formula}
                        />
                    )
                }
            </Right>
        </Container>
    );
};

export default Adwords;
