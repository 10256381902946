import styled from "styled-components";
import {getImage} from "../../../Utils/jsonInterpreter";
import React from "react";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%
`;

const Image = styled.img`
   width: 100%;
`;

const ProductInformation = ({imageSrc}) => {
    return(
        <Container>
            {imageSrc && <Image src={getImage(imageSrc)}/> }
        </Container>
    )
}

export default ProductInformation;
