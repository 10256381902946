import React from 'react';
import styled from 'styled-components';
import {mediumGrey} from "../../Utils/styleConstants";
import {getPriceDecimals} from "../../Utils/price";

const Container = styled.div`
    display: flex;
    align-items: center;
    white-space: nowrap;
  
    * {
      color: ${mediumGrey};
      font-weight: bold;
    }
`;

const PriceContainer = styled.p`
    display: flex;
    align-items: baseline;
    font-weight: bold;
    font-size: ${props => props.fontSize ?? '2.3em'};
`;

const PriceString = styled.p`
    text-transform: uppercase;
`;

const Price = ({ value, unit = '', white, fontSize = '13px', withFloor }) => {
    return (
        null !== value && (
            isNaN(value) ? (
                <PriceString white={white}>
                    {value}
                </PriceString>
            ) : (
                <Container white={white} >
                    <PriceContainer fontSize={fontSize}>
                        <span>{withFloor ? Math.floor(value) : value}</span>
                        <span>
                            {`,${getPriceDecimals(value, 2)} € `}
                            { unit !== null && <span> { unit === '' ? ' HT' : `HT ${unit}` }</span> }
                        </span>
                    </PriceContainer>
                </Container>
            )
        )
    );
};

export default Price;
