import Menu from '../Components/Menu'
import {connect} from "react-redux";
import {mapStateToPropsAddable} from "../Utils/mapToProps";

const mapStateToProps = (state, ownProps) => {

    return {
        ...mapStateToPropsAddable(state, ownProps),
        gridElements: ['intitule'],
        deletable: false,
        refs: id => ({
            intitule: {
                type: 'InputTextContainer',
                name: `${id}/intitule`,
                label: 'intitule'
            }
        })
    }
}

export default connect(mapStateToProps)(Menu);