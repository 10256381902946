import {useSelector} from "react-redux";
import React, {useEffect} from "react";
import {multipleSetInDataState, setInDataState} from "../../Utils/storeHelpers";
import ToggleLabeled from "../ToggleLabeled";
import styled from "styled-components";

const RowContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  margin: 30px 0;

  > section {
    padding: 0 3%;
  }
`;

const RefNatAboutCurrentPrestation = () => {
    const refNat = useSelector(({ data }) => {
        const refNat = data['ref-nat'] ?? {};

        return {
            refonte: refNat['refonte-site'] ?? false,
            seoActive: refNat['seo-active'] ?? false,
            seoRetention: refNat['seo-retention'] ?? false,
        }
    });

    useEffect(() => {
        if (refNat.refonte === false) {
            multipleSetInDataState([
                { path: 'ref-nat.seo-active', value: false },
                { path: 'ref-nat.seo-retention', value: false }
            ])
        }
    }, [refNat.refonte])

    useEffect(() => {
        if (refNat.seoActive === false) {
            setInDataState('ref-nat.seo-retention', false);
        }
    }, [refNat.seoActive])

    return (
        <RowContainer>
            <ToggleLabeled pathInDataState="ref-nat.refonte-site" label="refonte-site"/>
            { refNat.refonte && <ToggleLabeled pathInDataState="ref-nat.seo-active" label="seo-active"/> }
            { refNat.seoActive && <ToggleLabeled pathInDataState="ref-nat.seo-retention" label="seo-retention"/> }
        </RowContainer>
    )
}

export default RefNatAboutCurrentPrestation;
