import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import MinorOptionHektor from './MinorOptionHektor';
import { getLabel } from '../../Utils/labels';
import { getImage } from '../../Utils/jsonInterpreter';
import {
    getInDataState,
    getInTarifsState,
    isIkaLePackType,
    setInDataState
} from '../../Utils/storeHelpers';
import {
    ContainerOption,
    ImageOption,
    NameStructureOption,
    NameOption
} from '../../Utils/styleConstants';

const Container = styled(ContainerOption)`
    width: 98%;
`;

const AdvancedOptionHektor = props => {
    const hektorOffer = props.hektorOffer;
    const pathInDataState = props.pathInDataState;
    const hektorType = props.hektorType;
    const isIkaPackType = isIkaLePackType(hektorType);
    const partOfId = !isIkaPackType ? hektorOffer : undefined;

    // Use of hooks
    const [toggled, setToggled] = useState(null);
    const [includedIn, setIncludedIn] = useState(null);

    // Callback to determine if a toggle is on/off & logic if so
    const isSubOptionToggled = useCallback((subOptionName) => {
        let isToggled;

        // The state of the toggle will first depend on 'includedIn' property in tarifsState
        if (
            getInTarifsState(`${subOptionName}.includedIn`)
            && getInTarifsState(`${subOptionName}.includedIn`)[hektorType]
            && !(false === getInDataState(`${pathInDataState}.${subOptionName}`))
        ) {
            // If tarifsState for this subOption has the property 'includedIn'
            // AND this property is set to true for this hektor offer
            // AND the user did not specified a choice for this subOption yet
            // We can toggle the subOption
            isToggled = true;
        } else {
            // If tarifsState for this subOption does not have the property 'includedIn'
            // We base the state of the subOption toggle on dataState
            isToggled = getInDataState(`${pathInDataState}.${subOptionName}`) ?? false;
        }

        return isToggled;
    }, [pathInDataState, hektorType]);

    // Callback to determine if a subOption is includedIn
    const isSubOptionIncludedIn = useCallback((subOptionName) => {
        // The state of the includedIn will first depend on 'includedIn' property in tarifsState
        // If tarifsState for this subOption has the property 'includedIn'
        // AND this property is set to true for this hektor offer
        // AND the user did not specified a choice for this subOption yet

        return getInTarifsState(`${subOptionName}.includedIn`) && getInTarifsState(`${subOptionName}.includedIn`)[hektorType];
    }, [hektorType]);

    // useEffect when component is mounting
    useEffect(() => {
        if (!toggled) {
            const tmpToggle = [];

            props.subOptions.forEach(subOption => {
                const isToggled = isSubOptionToggled(subOption);

                tmpToggle.push(isToggled);
            });

            // This setup an array of boolean which determine if the subOptions are selected or not (one by one)
            setToggled(tmpToggle);
        }
    }, [props.subOptions, toggled, isSubOptionToggled]);

    // useEffect when component is mounting
    useEffect(() => {
        if (!includedIn) {
            const tmpIsIncludedIn = [];

            props.subOptions.forEach(subOption => {
                const isIncludedIn = isSubOptionIncludedIn(subOption);

                tmpIsIncludedIn.push(isIncludedIn);
            });

            // This setup an array of boolean which determine if the subOptions are includedIn or not (one by one)
            setIncludedIn(tmpIsIncludedIn);
        }
    }, [props.subOptions, includedIn, isSubOptionIncludedIn]);

    // Callback when user toggle a subOption
    const handleToggleSubOption = useCallback((subOptionIndex) => {
        // Only toggle or not the subOption (props.radio)
        // Either toggle the subOption or not by modifying the hook array of booleans (!props.radio)
        setToggled(
            toggled.map((value, index) =>
                index === subOptionIndex ? !value : (props.radio ? false : value)
            )
        );
    }, [props.radio, toggled]);

    // useEffect whenever toggled hook change
    useEffect(() => {
        // Change dataState to match hook
        toggled && toggled.map((toggle, index) =>
            setInDataState(`${pathInDataState}.${props.subOptions[index]}`, toggle)
        );
    }, [pathInDataState, props.subOptions, toggled]);

    return (
        toggled && (
            <Container checked={toggled.some((item => true === item))}>
                <ImageOption
                    src={getImage(props.image)}
                    alt='#'
                />
                <NameStructureOption>
                    <NameOption>
                        {getLabel(props.name)}
                    </NameOption>
                </NameStructureOption>
                {
                    props.subOptions.map((subOption, index) => (
                        <MinorOptionHektor
                            key={index}
                            index={index}
                            total={props.subOptions.length}
                            toggle={toggled[index]}
                            toggable
                            pathInDataState={pathInDataState}
                            name={subOption}
                            tarifsName={subOption}
                            dependsOn={hektorOffer}
                            partOfId={partOfId}
                            onToggle={handleToggleSubOption}
                            isIncludedIn={includedIn[index] && !isIkaPackType}
                            counterDependsOn={props.counterDependsOn}
                            excludedOptionsFromCounter={props.excludedOptionsFromCounter}
                            pathInDataStateQuantity={props.pathInDataStateQuantity ?? (props.basePathInDataStateQuantity ? `${props.basePathInDataStateQuantity}.${subOption}` : undefined)}
                        />
                    ))
                }
                <CheckmarkContainer
                    checked={toggled.some((item => true === item))}
                    notBottomCheckmark
                />
            </Container>
        )
    );
};

export default AdvancedOptionHektor;
