/**
 * @readonly
 * @enum {string}
 */
export const PaymentType = {
    COMPTANT: 'comptant',
    FINANCEMENT: 'financement',
}

/**
 * @typedef {Object} Prelevement
 * @property {string} day
 * @property {number} month
 * @property {number} year
 */

/**
 * @typedef {Object} Financing
 * @property {number} duration
 * @property {Prelevement} prelevement
 */

/**
 * @typedef {Object} Payment
 * @property {PaymentType} type
 * @property {Financing} financing
 * @property {number} [warrantyDuration]
 */
