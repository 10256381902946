import React from 'react';
import styled from 'styled-components';
import CheckableOption from './Option/CheckableOption';
import Notes from './Notes';
import {
    getInDataState,
} from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const OptionPrevisiteAdd = props => {
    return (
        <Container>
            {
                props.options.map((option, index) => (
                    <React.Fragment key={index}>
                        {
                            'checkableOption' === option.type && (
                                <CheckableOption
                                    pathInDataState={props.pathInDataState}
                                    image={option.image}
                                    name={option.name}
                                    label={option.label}
                                    tarifsName={option.name}
                                    dependsOn={props.previsiteType}
                                    partOfId={props.previsiteType}
                                    additionalDependsOn={getInDataState(option.pathToDependsOn)}
                                    setItselfFalse={option.setItselfFalse}
                                    setInDataState={option.setInDataState}
                                    disabled={true === option.disabled}
                                    pathInDataStateQuantity={option.pathInDataStateQuantity}
                                    counterLabel={option.counterLabel}
                                    counterDependsOn={option.counterDependsOn}
                                    default={option.default}
                                    checked={false}
                                />
                            )
                        }
                    </React.Fragment>
                ))
            }
            {
                props.withNotes && (
                    <Notes
                        pathInDataState={`${props.pathInDataState}.notes`}
                        name='notes'
                        padding='30px 1%'
                    />
                )
            }
        </Container>
    );
};

export default OptionPrevisiteAdd;
