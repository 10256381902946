import {useSelector} from "react-redux";
import {get} from "../../../Utils/object";
import ScreenConfiguration from "./ScreenConfiguration";
import { produce } from "immer";
import {setInDataState} from "../../../Utils/storeHelpers";
import styled from 'styled-components';
import Switch from "../../Switch/Switch";
import { trimLeft } from "url-parse";

const ITEMS_PATH = 'offre-lbi-media.screen.items';

const ScreenHeader = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`

const ScreenHeaderOptions = styled.div`
  display: flex;
  flex: 1 0 70%;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  font-size: 0.9em;
`

/**
 *
 * @param {array} items
 * @param {function(string, number): void} handleInchClick
 * @param {function(string, number): void} handleOrientationClick
 * @param {function(number): void} handlePlayerSelected
 * @param {function(number): void} handlerSettingsSelected
 * @return {*}
 */
const mapEventHandlers = (
    items,
    handleInchClick,
    handleOrientationClick,
    handleOptionClick,
) => {
    return items.map((item, index) => {
        return {
            ...item,
            handleInchClick: (value) => handleInchClick(value, index),
            handleOrientationClick: (value) => handleOrientationClick(value, index),
            handleOptionClick: (name) => handleOptionClick(name, index),
        }
    });
}

const ScreenConfigurations = () => {
    const items = useSelector(({ data }) => get(data, ITEMS_PATH, []));

    const handleInchClick = (value, index) => {
        setInDataState(ITEMS_PATH, produce(items, draftState => {
            const item = draftState[index];

            if (item) {
                item.inch = value;
            }
        }));
    }

    const handleOrientationClick = (value, index) => {
        setInDataState(ITEMS_PATH, produce(items, draftState => {
            const item = draftState[index];

            if (item) {
                if (value === 'paysage' && item.inch === 'inch-duo-55') {
                    item.inch = 'inch-55';
                }

                item.orientation = value;
            }
        }));
    }

    const handleOptionClick = (name, index) => {
        setInDataState(ITEMS_PATH, produce(items, draftState => {
            const item = draftState[index];

            if (item) {
                item[name] = item[name] ? !item[name] : true;
            }
        }));
    }

    const itemsWithHandlers = mapEventHandlers(
        items,
        handleInchClick,
        handleOrientationClick,
        handleOptionClick,
    );

    const simple = itemsWithHandlers.filter(x => x.type === 'simple');
    const prestige = itemsWithHandlers.filter(x => x.type === 'prestige');

    if (items.length === 0) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "50px" }}>
                <h1>Aucun pied d'écran sélectionné</h1>
            </div>
        )
    }

    return (
        <div>
            {simple.map((item, index) => (
                <div key={item.type + index}>
                    <ScreenHeader>
                        <h1>Ecran {item.type} n°{index + 1}</h1>
                        <ScreenHeaderOptions>
                            <p>CPL</p>
                            <Switch checked={item?.cpl} onChange={() => item.handleOptionClick('cpl')}/>
                            <p>Installation et paramétrage</p>
                            <Switch checked={item?.configuration} onChange={() => item.handleOptionClick('configuration')}/>
                        </ScreenHeaderOptions>
                    </ScreenHeader>
                    <ScreenConfiguration
                        orientationSelected={item.orientation}
                        inchSelected={item.inch}
                        onInchClick={item.handleInchClick}
                        onOrientationClick={item.handleOrientationClick}
                        type={item.type}
                    />
                </div>
            ))}
            {prestige.map((item, index) => (
                <div key={item.type + index}>
                    <ScreenHeader>
                        <h1>Ecran {item.type} n°{index + 1}</h1>
                        <ScreenHeaderOptions>
                            <p>CPL</p>
                            <Switch checked={item?.cpl} onChange={() => item.handleOptionClick('cpl')}/>
                            <p>Installation et paramétrage</p>
                            <Switch checked={item?.configuration} onChange={() => item.handleOptionClick('configuration')}/>
                        </ScreenHeaderOptions>
                    </ScreenHeader>
                    <ScreenConfiguration
                        orientationSelected={item.orientation}
                        inchSelected={item.inch}
                        onInchClick={item.handleInchClick}
                        onOrientationClick={item.handleOrientationClick}
                        type={item.type}
                    />
                </div>
            ))}
        </div>
    )
}

export default ScreenConfigurations;
