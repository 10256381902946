import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDataState } from '../../Store/selectors';
import styled from 'styled-components';
import Lightbox from 'react-18-image-lightbox';
import LightboxButton from '../LightboxButton';
import PriceElement from '../PriceElement';
import Counter from '../Counter';
import CheckmarkContainer from '../Icons/CheckmarkContainer';
import { getImage } from '../../Utils/jsonInterpreter';
import { borderGrey, SimpleCheckbox } from '../../Utils/styleConstants';
import { beautifulGetLabel } from '../../Utils/labels';
import { getInDataState, setInDataState } from '../../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    margin: 1%;
    padding: 0 0 20px;
    flex-direction: column;
    align-items: center;
    width: ${props => `calc((100% - (2% * ${props.perLine})) / ${props.perLine})`};
`;

const Label = styled.p`
    font-size: 20px;
`;

const ImageStructure = styled(SimpleCheckbox)`
    padding: 15px;
    margin: 20px 0;
`;

const ImagePriceStructure = styled(SimpleCheckbox)`
    flex-direction: column;
    align-items: center;
    margin: 20px 0;
`;

const PriceElementStructure = styled.div`
    display: flex;
    justify-content: center;
    padding: 20px 0;
    width: 100%;
    border-top: 1px solid ${borderGrey};
`;

const Image = styled.img`
    max-width: 100%;
    max-height: 100%;
`;

const ImageWithPad = styled.img`
    max-width: 100%;
    max-height: 100%;
    padding: 15px;
`;

const CheckableExtendableVertical = props => {
    // Use of hooks
    const [lightboxOpen, setLightboxOpen] = useState(null);
    const [display, setDisplay] = useState(null);
    const [counterValue, setCounterValue] = useState(null);

    // Use of redux selector
    const dataState = useSelector(selectDataState);

    // useEffect whenever dataState changes or when mount
    useEffect(() => {
        if (null === counterValue) {
            if (props.option.pathInDataStateQuantity && props.checked && undefined === getInDataState(props.option.pathInDataStateQuantity)) {
                setInDataState(props.option.pathInDataStateQuantity, 1);
            } else if (props.option.displayIf) {
                const conditions = props.option.displayIf.conditions;
                const correctValue = getInDataState(props.option.displayIf.path);
                const mode = props.option.displayIf.mode;
                let isDisplay = mode === 'or' ? false : true;
    
                conditions.forEach(value => {
                    if (mode === 'or') {
                        isDisplay = correctValue === value ? true : isDisplay;
                    } else {
                        isDisplay = correctValue !== value ? false : isDisplay;
                    }
                });
    
                setDisplay(isDisplay);
            } else {
                setDisplay(true);
            }
        } else {
            setCounterValue(null);
        }
    }, [props, dataState, counterValue])

    // Callback if user want to extend
    const handleClickExtend = useCallback(() => {
        props.option.mp4 ?
            window.open(props.option.mp4, '_blank')
            : setLightboxOpen(true);
    }, [props.option.mp4])

    // Callback when user change cro counter
    const handleCounter = useCallback((value, event) => {
        event.stopPropagation();
        setCounterValue(value);
    }, [])

    useEffect(() => {
        null !== counterValue &&
            setInDataState(props.option.pathInDataStateQuantity, counterValue);
    }, [counterValue, props.option.pathInDataStateQuantity])

    return (
        display && (
            <>
                {
                    lightboxOpen && (
                        <Lightbox
                            mainSrc={getImage(props.option.img)}
                            onCloseRequest={() => setLightboxOpen(false)}
                        />
                    )
                }
                <Container perLine={props.perLine}>
                    <Label>{beautifulGetLabel(props.option.label)}</Label>
                    {
                            props.option.tarifsName ? (
                                <ImagePriceStructure
                                    checked={props.checked}
                                    onClick={() => props.onClick(props.index, props.option)}
                                >
                                    <ImageWithPad src={getImage(props.option.img)} alt='#' />
                                    <PriceElementStructure>
                                        <PriceElement tarifsName={props.option.tarifsName} />
                                    </PriceElementStructure>
                                    {
                                        props.option.pathInDataStateQuantity && props.checked && (
                                            <Counter
                                                dontStandAlone
                                                min={1}
                                                margin='0 0 25px 0'
                                                fontSize='18px'
                                                masterId={props.option.pathInDataStateQuantity}
                                                initialValue={getInDataState(props.option.pathInDataStateQuantity) ?? 1}
                                                callback={handleCounter}
                                            />
                                        )
                                    }
                                    <CheckmarkContainer checked={props.checked} />
                                </ImagePriceStructure>
                            ) : (
                                <ImageStructure
                                    checked={props.checked}
                                    onClick={() => props.onClick(props.index, props.option)}
                                >
                                    <Image src={getImage(props.option.img)} alt='#' />
                                    <CheckmarkContainer checked={props.checked} />
                                </ImageStructure>
                            )
                    }
                    {
                        !props.option.notExtendable && (
                            <LightboxButton callbackOnClick={() => handleClickExtend()} />
                        )
                    }
                </Container>
            </>
        )
    );
};

export default CheckableExtendableVertical;
