import React from "react";
import styled from "styled-components";
import {borderGrey, easeOutBack, green, grey} from "../../Utils/styleConstants";
import HiddenCheckbox from "../HiddenCheckbox";
import Checkmark from "../Icons/Checkmark";
import Cross from "../Icons/Cross";

const InnerCheckboxContainer = styled.div`
    position: relative;
`;

const StyledCheckbox = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${props => props.checked ? green : borderGrey};
    border-radius: 36px;
    width: 68px;
    height: 36px;
    padding: 13px 9px;
    transition: all .5s ${easeOutBack};
    cursor: pointer;
`;

const Mask = styled.svg`
  height: 20px;
  width: 20px;
  fill: white;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  left: ${props => props.checked ? '37px' : '9px'};
  transition: all .2s ${easeOutBack};
  cursor: pointer;
`;

const InnerCheckbox = ({ checked, label, globalName, ...props }) => (
    <InnerCheckboxContainer>
        <Mask checked={checked} viewBox='0 0 100 100'><circle cx='50' cy='50' r='50' /></Mask>
        <HiddenCheckbox checked={checked} name={globalName} {...props} />
        <StyledCheckbox checked={checked}>
            <Checkmark color={grey} />
            <Cross color={grey} />
        </StyledCheckbox>
    </InnerCheckboxContainer>
);

const Switch = ({ checked, onChange }) => {
    return (
        <label>
            <InnerCheckbox
                checked={checked}
                onChange={onChange}
            />
        </label>
    )
}

export default Switch;

