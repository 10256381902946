import React from "react";
import useCheckBox from "./hooks/useCheckbox";
import Switch from "../Switch/Switch";

/**
 * Uncontrolled version of the Toggle component
 *
 * @return {JSX.Element}
 */
const Checkbox = (props) => {
    const { isSelectedProp, handleValueChange } = useCheckBox(props);

    return (
        <Switch checked={isSelectedProp} onChange={handleValueChange}/>
    )
}

export default Checkbox;
