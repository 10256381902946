import styled from "styled-components";
import React, {useEffect} from 'react';

import ToggleLabeled from "../ToggleLabeled";
import InputTextContainer from "../../Containers/InputTextContainer";
import {setInDataState} from "../../Utils/storeHelpers";
import {get} from "../../Utils/object";
import {useSelector} from "react-redux";
import SelectContainer from "../../Containers/SelectContainer";

const Wrap = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 20px;
`;

const options = [
    {
        "label": "lbi-media-hektor-customer",
        "pathInDataState": "lbi-media-customers-informations.hektor-customer",
        "required": true
    },
    {
        "name": "software-provider",
        "isLabeled": true,
        "label": "software-provider-lbi-media",
        "required": true,
    },
    {
        "name":"delivery-address",
        "isLabeled": true,
        "label": "delivery-address-lbi-media",
        "defaultValue": "agence.adresse",
        "required": true,
    },
    {
        "name":"postal-code",
        "isLabeled": true,
        "label": "postal-code-lbi-media",
        "defaultValue": "agence.cp",
        "required": true,
    },
    {
        "name":"city",
        "isLabeled": true,
        "label": "city-lbi-media",
        "defaultValue": "agence.ville",
        "required": true,
    }
]

const paysProps =  {
    "masterId": "lbi-media-customers-informations",
    "name":"pays",
    "labelWith": "name",
    "required": true,
    "defaultValue": "agence.pays",
    "options": [
        "france",
        "belgique",
        "suisse",
        "luxembourg",
        "allemagne",
        "espagne",
        "portugal",
        "royaume-uni",
        "finlande"
    ]
}

const CustomersInformations = () => {
    const isHektorCustomer =  useSelector(({ data }) => {
        return get(data, 'lbi-media-customers-informations.hektor-customer',false );
    });

    const softwareProvider =  useSelector(({ data }) => {
        return get(data, 'lbi-media-customers-informations.software-provider', null);
    });

    useEffect( () => {
        if (!isHektorCustomer && softwareProvider === 'hektor') {
            setInDataState('lbi-media-customers-informations.software-provider',undefined);
        }

        if (isHektorCustomer) {
            setInDataState('lbi-media-customers-informations.software-provider',"hektor" );
        }
    }, [isHektorCustomer])

    return (
        <Wrap>
            {options.map((option, index) => (
                <React.Fragment key={index}>
                    {option.label === "lbi-media-hektor-customer" ? (
                        <ToggleLabeled
                            masterId="lbi-media-customers-informations"
                            label={option.label}
                            pathInDataState={option.pathInDataState}
                        />
                    ) : option.name === "software-provider" ? (
                        !isHektorCustomer && (
                            <InputTextContainer
                                masterId="lbi-media-customers-informations"
                                name={option.name}
                                isLabeled={option.isLabeled}
                                label={option.label}
                                required={option.required}
                                defaultValue={option.defaultValue}
                            />
                        )
                    ) : (
                        <InputTextContainer
                            masterId="lbi-media-customers-informations"
                            name={option.name}
                            isLabeled={option.isLabeled}
                            label={option.label}
                            required={option.required}
                            defaultValue={option.defaultValue}
                        />
                    )}
                </React.Fragment>
            ))}
            <SelectContainer {...paysProps} />
        </Wrap>
    )
}
export default CustomersInformations