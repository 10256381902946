import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import Add from './Addable/Add';
import Delete from './Addable/Delete';
import { borderGrey, grey } from '../Utils/styleConstants';
import { getInDataState, setInDataState, deleteInDataState } from '../Utils/storeHelpers';
import { beautifulGetLabel } from '../Utils/labels';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    min-height: 70px;
    padding: 0 1.5em;
    margin: 0.5em;
    font-size: 0.8em;
    border: 1px dashed ${borderGrey};
`;

const Input = styled.input`
    width: 280px;
    border: 1px solid ${borderGrey};
    border-radius: 4px;
    padding: 0.5em;
    margin: 0 1em;
    font-size: 1em;
    text-align: center;
    font-weight: bold;
    color: ${grey};
`;

const AddElement = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0 5px 1em;
    margin: 0.5em;
    background: ${borderGrey};
    color: ${grey};
    border-radius: 4px;

    &:is(.first) {
        margin: 0.5em 0.5em 0.5em 2em;
    }
`;

const LineAddable = props => {
    // Use of hooks
    const [lineValue, setLineValue] = useState(null);
    const [adherents, setAdherents] = useState(null);

    // useEffect when component is mounting
    useEffect(() => {
        null === lineValue && setLineValue('');
        null === adherents && setAdherents(getInDataState(props.pathInDataState) ?? []);
    }, [lineValue, adherents, props.pathInDataState])

    // Callback when user click on add button
    const handleClickOnAdd = useCallback(() => {
        if ('' !== lineValue && !adherents.includes(lineValue)) {
            setAdherents([ ...adherents, lineValue]);
            setInDataState(props.pathInDataState, lineValue, true);
            setLineValue('');
        }
    }, [lineValue, adherents, props.pathInDataState])

    // Callback when user click on delete button
    const handleClickOnDelete = useCallback((adherent) => {
        setAdherents(adherents.filter(item => item !== adherent));
        deleteInDataState(props.pathInDataState, adherent);
    }, [adherents, props.pathInDataState])

    const handleKeyDown = useCallback((event) => {
        if (event.key === 'Enter' && '' !== lineValue.trim()) {
            setAdherents([ ...adherents, lineValue]);
            setInDataState(props.pathInDataState, lineValue, true);
            setLineValue('');
        }
    }, [lineValue, adherents, props.pathInDataState])

    return (
        null !== adherents && null !== lineValue && (
            <Container>
                <p>{`${beautifulGetLabel(props.label)} :`}</p>
                <Input type='text' value={lineValue} onKeyDown={(event) => handleKeyDown(event)} onChange={(event) => setLineValue(event.target.value)} />
                <Add handleClick={handleClickOnAdd} />
                {
                    adherents.map((adherent, index) => (
                        <AddElement
                            className={0 === index ? 'first' : ''}
                            key={index}
                        >
                            <p>{adherent}</p>
                            <Delete handleClick={() => handleClickOnDelete(adherent)} />
                        </AddElement>
                    ))
                }
            </Container>
        )
    );
};

export default LineAddable;
