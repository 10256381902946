import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import styled from 'styled-components';
import Add from './Addable/Add';
import Delete from './Addable/Delete';
import { borderGrey, grey } from '../Utils/styleConstants';
import { replaceDataInStore } from '../Utils/storeHelpers';

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
`;

const Passerelle = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    min-width: 550px;
    padding: 0 0 0.5em;

    :first-child {
        padding: 0.5em 0;
    }
`;

const InputContainer = styled.div`
    display: flex;
    align-items: center;
`;

const Input = styled.input`
    width: 400px;
    border: 1px solid ${borderGrey};
    border-radius: 4px;
    padding: 0.5em;
    margin: 0 1em;
    font-size: 1em;
    font-family: Roboto, sans-serif;
    text-align: center;
    font-weight: bold;
    color: ${grey};
`

const PasserelleImport = props => {
    // Use of hooks
    const [gateways, setGateways] = useState(null);

    // Use of redux
    const dataState = useStore().getState().data;

    // useEffect when component is mounting
    useEffect(() => {
        null === gateways &&
            setGateways(
                (dataState[props.masterId] && dataState[props.masterId][props.name]) ?? [''] 
            );
    }, [gateways, props.masterId, props.name, dataState])

    // Callback on change input
    const handleChange = useCallback((event, gatewayIndex) => {
        setGateways(
            gateways.map((gateway, index) => 
                index === gatewayIndex ?
                    event.target.value
                    : gateway
            )
        );
    }, [gateways])

    // Callback to add a gateway
    const handleAdd = useCallback(() => {
        setGateways([ ...gateways, '' ]);
    }, [gateways])

    // Callback to delete a gateway
    const handleDelete = useCallback((indexClick) => {
        setGateways(
            gateways.filter((gateway, index) => index !== indexClick)
        );
    }, [gateways])

    // useEffect whenever gateways hook value changes
    useEffect(() => {
        null !== gateways &&
            replaceDataInStore(props.masterId, props.name, gateways);
    }, [props.masterId, props.name, gateways]);

    return (
        null !== gateways && (
            <Container>
                {   
                    gateways.map((gateway, index) => (
                        <Passerelle key={index}>
                            {
                                index === 0 ?
                                    (<p>Logiciels :</p>)
                                    : (<span></span>)
                            }
                            <InputContainer>
                                <Input type='text' value={gateway} onChange={(event) => handleChange(event, index)} />
                                {
                                    index === 0 ? 
                                        (<Add handleClick={handleAdd} />)
                                        : (<Delete handleClick={() => handleDelete(index)} />)
                                }
                            </InputContainer>
                        </Passerelle>
                    ))
                }
            </Container>
        )
    );
};

export default PasserelleImport;
