import { connect } from 'react-redux'
import Menu from '../Components/Menu'
import { mapStateToPropsAddable } from '../Utils/mapToProps'

const mapStateToProps = (state, ownProps) => ({
    ...mapStateToPropsAddable(state, ownProps),
    gridElements: ['label','blank','titre'],
    spanElements: ['texte'],
    refs: id => ({
        label: {
            type: 'LabelAdd',
            label: 'textexpl'
        },
        blank: {
            type: 'Blank'
        },
        titre: {
            type: 'InputTextContainer',
            name: `${id}/titre`,
            label: 'titre'
        },
        texte: {
            type: 'TextAreaContainer',
            name: `${id}/texte`
        }
    })
})

export default connect(
    mapStateToProps
)(Menu)

