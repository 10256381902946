import React from 'react';
import styled from 'styled-components';
import { borderGrey } from '../Utils/styleConstants';
import Builder from '../Utils/builder';
import PriceElement from './PriceElement';

const Container = styled.div`
    display: flex;
    border: 1px solid ${borderGrey};
    justify-content: center;
    align-items: stretch;
`;

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
    width: 343px;
    height: 217px;
`;

const Left = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid ${borderGrey};
`;

const Right = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const PricePop = props => {
    return (
        <Container>
            <Left>
                <ImageContainer>
                    <PriceElement
                        tarifsName={props.tarifsName}
                        white={undefined !== props.isColorWith ? props.isColorWith : true}
                    />
                </ImageContainer>
            </Left>
            <Right>
                {props.custom ?? Builder.create(props.masterId, { ...props, type: props.rightcomponent })}
            </Right>
        </Container>
    )
}

export default PricePop;
